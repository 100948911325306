import { DotSiteRewardCard } from 'containers/vipRewards/modules/rewardCard/cards/dotsite';
import { HelloFreshAUOfferCard } from 'containers/vipRewards/modules/rewardCard/cards/helloFreshAUOffer';
import { HelloFreshNZOfferCard } from 'containers/vipRewards/modules/rewardCard/cards/helloFreshNZOffer';
import './_rewardCard.scss';
import { AflGrandFinalGiveaway2024Card } from './cards/aflGrandFinalGiveaway';
import { ColdplayCard } from './cards/coldplay';
import { DotOnlineRewardCard } from './cards/dotonline';
import { DotStoreReferralCard } from './cards/dotstore';
import { DotXyzReferralCard } from './cards/dotxyz';
import { NrlGiveawayCard } from './cards/nrlGiveaway';
import { NrlGiveawayCard2 } from './cards/nrlGiveaway2';
import { ReferralRewardCard } from './cards/referral';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RewardCard = {
    Referral: ReferralRewardCard,
    FreeDotStore2024: DotStoreReferralCard,
    FreeDotOnline2024: DotOnlineRewardCard,
    FreeDotXyz2024: DotXyzReferralCard,
    FreeDotSite2024: DotSiteRewardCard,
    NrlGiveaway: NrlGiveawayCard,
    NrlGiveaway2: NrlGiveawayCard2,
    AflGrandFinalGiveaway2024Card: AflGrandFinalGiveaway2024Card,
    HelloFreshNZ: HelloFreshNZOfferCard,
    HelloFreshAU: HelloFreshAUOfferCard,
    Coldplay: ColdplayCard
};
