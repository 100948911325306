/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Input.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXInputNamespace } from 'components/Form/Input/types';

const inputAppliedStylingPropKeys = ['readOnly', 'disabled', 'isPlaceholderItalic'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _Input: React.FC<NXInputNamespace.Props> = (props) => {
    const { value, name, placeholder, disabled, spellCheck = false, intrinsicProps = {}, size = 'full', readOnly, inputRef } = props;
    const { className, ...restIntrinsicProps } = intrinsicProps;

    /***** RENDER HELPERS *****/
    const appliedClassNames = createAppliedStylingClasses({
        props,
        keyBoundary: inputAppliedStylingPropKeys,
        delimiter: '--',
        componentName: 'NXInput'
    });

    /***** RENDER *****/
    return (
        <input
            className={classNames(
                'NXInput',
                appliedClassNames,
                {
                    [`NXInput--size-${size}`]: size
                },
                className
            )}
            ref={inputRef}
            value={value ?? undefined}
            name={name}
            type="text"
            disabled={disabled || readOnly}
            placeholder={placeholder}
            spellCheck={spellCheck}
            {...restIntrinsicProps}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
