/* eslint-disable react/jsx-pascal-case */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { touch } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZone as NXDropZone } from 'components/Form/NXDropZone/_NXDropZone';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * NXDropZone ReduxForm Wrapper
 */
export const _NXDropZoneReduxFormDefault: React.FC<NXDropZoneNamespace.ReduxForm.Default.Props> = (props) => {
    const { input, meta, label, disabled, ...rest } = props;
    const { form, dispatch } = meta;
    const { name, value, onChange } = input;

    /***** FUNCTIONS *****/
    function _onChange(files: NXDropZoneNamespace.Values) {
        dispatch(touch(form, name));
        onChange(files);
    }

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta} noBackground fullWidth>
                <NXDropZone onChange={_onChange} value={typeof value === 'string' ? null : value} {...rest} />
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
