/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaPages, katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorRoutes } from 'containers/katana/containers/ContentEditorLightbox/consts';
const { ADD_SECTION } = katanaPages;
const { PRESET_CONTENT } = katanaSubPageEnums;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AddSectionButton = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();

    /***** RENDER *****/
    return (
        <SolidButton
            to={getKatanaDestination(ADD_SECTION)}
            onMouseOver={() => {
                katanaContentEditorRoutes[PRESET_CONTENT].prefetch(id);
            }}
        >
            <Text semiBold>Add new section</Text>
        </SolidButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
