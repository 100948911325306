/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import AutocompleteAddress from 'components/AutocompleteAddress';
import RenderPhoneField from 'components/Form/PhoneInput/RenderPhoneField';
import RequestLoader from 'components/Loaders/Request';
import Note from 'components/Note';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainTransferLockQuery } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getIsLegacyDomainTransferLocked } from 'containers/domain/tppDomains/modules/transferLock/methods';
import {
    RenderField,
    emailFieldValidation,
    peopleNameFieldValidation,
    renderButton,
    requiredFieldValidation,
    validateReactPhoneNumberInput
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain contacts form
 */
let TPPDomainContactsForm = ({ isTppDomainUpdateContactsLoading, handleSubmit, form, pristine, valid, formValues }) => {
    /***** HOOKS *****/
    const params = useParams();

    /***** QUERIES *****/
    const { data: tpp_domain_transfer_lock_data } = useTppDomainTransferLockQuery(params.id);

    /***** RENDER HELPERS *****/
    const isTransferLocked = getIsLegacyDomainTransferLocked(tpp_domain_transfer_lock_data);

    /***** RENDER *****/
    return (
        <Padding xy={6}>
            <form className="tppDomainContactsForm" onSubmit={handleSubmit}>
                <div className="form__row rest">
                    <div className="form__column half">
                        <Field
                            label="First Name"
                            name="firstname"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation, peopleNameFieldValidation]}
                            disabled={isTransferLocked}
                        />
                    </div>
                    <div className="form__column half">
                        <Field
                            label="Last Name"
                            name="lastname"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation, peopleNameFieldValidation]}
                            disabled={isTransferLocked}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field label="Organisation" name="organisation" component={RenderField} type="text" disabled={isTransferLocked} />
                    </div>
                </div>

                <AutocompleteAddress
                    form={form}
                    selectedCountry={formValues?.country}
                    selectedState={formValues?.state}
                    onlyAUandNZ={false}
                    halfWidthFields={true}
                    fieldOrder={['address1', 'address2', 'suburb', 'state', 'country', 'postcode']}
                    disabled={isTransferLocked}
                />

                <div className="form__row">
                    <div className="form__column half">
                        <Field
                            label="Phone"
                            name="phone"
                            component={RenderPhoneField}
                            type="tel"
                            placeholder="+61 455 555 555"
                            validate={[requiredFieldValidation, validateReactPhoneNumberInput]}
                            disabled={isTransferLocked}
                        />
                    </div>
                    <div className="form__column half">
                        <Field
                            label="Email Address"
                            name="email"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation, emailFieldValidation]}
                            disabled={isTransferLocked}
                        />
                    </div>
                </div>

                <Padding y={2}>
                    <Note
                        beforeColon="Please Note"
                        text="Changes to Domain Name Contacts for legacy domains are not processed immediately, so please check back later if your changes are not reflected right away."
                    />
                </Padding>

                {renderButton(
                    pristine,
                    isTppDomainUpdateContactsLoading,
                    !isTransferLocked && valid,
                    isTppDomainUpdateContactsLoading ? <RequestLoader /> : 'Save Changes'
                )}
            </form>
        </Padding>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

TPPDomainContactsForm = reduxForm({
    form: 'tppDomainRegistrantContacts'
})(TPPDomainContactsForm);

const mapStateToProps = (state) => {
    const formValues = getFormValues('tppDomainRegistrantContacts')(state);

    return {
        formValues
    };
};

TPPDomainContactsForm = connect(mapStateToProps)(TPPDomainContactsForm);

export default TPPDomainContactsForm;
