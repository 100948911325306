/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useRecursivePropertiesRenderer from 'containers/katana/components/dynamicFormFieldRenderer/useRecursivePropertiesRenderer';
import { useModifiedFieldArrayProperties } from 'containers/katana/formFields/repeated/useModifiedFieldArrayProperties';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__GridFieldArrayChild.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {import('containers/katana/types').KatanaNamespace.RepeatedPropertyFieldArrayChildProps<any>} props
 */
function _GridFieldArrayChild({ member, index, fields, properties, property }) {
    /***** HOOKS *****/
    const PropertiesRenderer = useRecursivePropertiesRenderer();

    /***** FUNCTIONS *****/
    function removeService() {
        fields.remove(index);
    }

    /***** RENDER HELPERS *****/
    // have to modify the properties to include the member key
    const modifiedProperties = useModifiedFieldArrayProperties(member, properties, true);
    // const singularName = useGetCaveat(CaveatTypes.SINGULAR_NAME, property.caveats) ?? property.name;

    /***** RENDER *****/
    return (
        <div className="KatanaRepeatedFormField__gridDisplayItem">
            <OutlineButton className="KatanaRepeatedFormField__gridDisplayItem__removeButton" color="warn" type="onClick" onClick={removeService}>
                <PhosphorIcons.Trash.Bold />
            </OutlineButton>
            <ContentEditorModuleContent>
                <PropertiesRenderer properties={modifiedProperties} />
            </ContentEditorModuleContent>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _GridFieldArrayChild };
