/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormLabel } from 'components/Form/FormLabel';
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import useRecursivePropertiesRenderer from 'containers/katana/components/dynamicFormFieldRenderer/useRecursivePropertiesRenderer';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_grid.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The group field is like the form field, but it's used to group other form fields together under a key like an object rather than an array
 */
export const KatanaGroupFieldGridReduxForm: React.FC<{
    disabled?: boolean;
    className?: string;
    label: React.ReactNode;
    properties: KatanaNamespace.SectionDefinitions.PropertiesModified[];
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
}> = ({
    disabled,
    className,
    label,
    properties,
    /**
     * Redux Props
     */
    input,
    meta
}) => {
    /***** HOOKS *****/
    const PropertiesRenderer = useRecursivePropertiesRenderer();
    const { name } = input;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={classNames('KatanaGroupFieldGrid', className)}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Grid columns="1fr 1fr">
                <PropertiesRenderer properties={properties} />
            </Grid>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
