/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NavigationOrganiserChild from 'containers/katana/modules/presetContent/components/sectionOrganiser/sectionOrganiserChild/NavigationOrganiserChild';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSectionNameRouteKey } from 'containers/katana/hooks/methods';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { sortByMenuIDs } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sortByMenuIds';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
import './_sectionOrganiser.scss';

const { EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Name chosen because it will become interactive in the future
 */
export const NavigationOrganiser: React.FC<{
    isEditingNavigationMenu?: boolean;
    navigationMenuIDs?: number[];
    onNavigationMenuIDsChanged?: (menuIDs: number[]) => void;
}> = ({ isEditingNavigationMenu, navigationMenuIDs = [], onNavigationMenuIDsChanged }) => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();

    const isDraggingRef = useRef(false);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    /***** QUERIES *****/
    const { data: get_katana_section_definitions_data, isLoading: isGetKatanaSectionDefinitionsLoading } =
        katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);
    const {
        data: get_katana_site_sections_data,
        isLoading: isGetKatanaSiteSectionsLoading,
        isFetching: isGetKatanaSiteSectionsFetching
    } = katanaQuery.serviceID.getSections.useQuery(id);

    const { data: get_katana_service_data, isLoading: isGetKatanaServiceLoading } = katanaQuery.serviceID.getService.useQuery(id);

    /***** FUNCTIONS *****/
    const getSectionRoutes = () => {
        const dataSource = get_katana_site_sections_data ?? get_katana_service_data?.sections ?? [];
        const sortedSections = [...dataSource].sort((a, b) => a.order - b.order).sort(sortByMenuIDs(navigationMenuIDs));
        return sortedSections.map((section) => {
            const { section_id: sectionDefinitionID, id: sectionID, name: sectionName } = section;
            const hasSectionDefinitionID = _.has(get_katana_section_definitions_data, [sectionDefinitionID]);
            if (!hasSectionDefinitionID) {
                return {
                    sectionID,
                    title: `Section ID ${sectionDefinitionID} not found`,
                    route: getKatanaDestination(EDITOR.PRESET_CONTENT),
                    customTitle: sectionID
                };
            }

            const { name, navbar_title } = get_katana_section_definitions_data[sectionDefinitionID];
            const routeKey = getSectionNameRouteKey(name);
            return {
                sectionID,
                title: navbar_title,
                route: getKatanaDestination(EDITOR.PRESET_CONTENT, routeKey),
                customTitle: sectionName
            };
        });
    };

    const sectionRoutes = getSectionRoutes();
    const [sortableSectionRoutes, setSortableSectionRoutes] = useState(sectionRoutes.map(({ sectionID }) => sectionID));

    function handleSectionIDsUpdate(menuIDs) {
        const filteredSortableRouteIDs = sortableSectionRoutes.filter((sectionID) => menuIDs.includes(sectionID));
        onNavigationMenuIDsChanged(filteredSortableRouteIDs);
    }

    function handleDragStart() {
        isDraggingRef.current = true;
    }

    function handleDragEnd(event: DragEndEvent) {
        isDraggingRef.current = false;
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            setSortableSectionRoutes((sections) => {
                const oldIndex = sections.indexOf(active.id);
                const newIndex = sections.indexOf(over.id);
                return arrayMove(sections, oldIndex, newIndex);
            });
        }
    }
    function populateNewSectionRoutes() {
        if (!isDraggingRef.current) {
            setSortableSectionRoutes(sectionRoutes.map(({ sectionID }) => sectionID));
        }
    }

    /***** EFFECTS *****/
    useEffect(() => {
        handleSectionIDsUpdate(navigationMenuIDs);
    }, [sortableSectionRoutes]);

    useEffect(populateNewSectionRoutes, [get_katana_site_sections_data, get_katana_service_data, get_katana_section_definitions_data]);

    /***** RENDER HELPERS *****/
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        {
            condition: isGetKatanaSectionDefinitionsLoading,
            message: 'Loading section definitions...'
        },
        {
            condition: isGetKatanaServiceLoading || isGetKatanaSiteSectionsLoading,
            message: 'Loading site sections...'
        }
    ]);

    /***** RENDER *****/
    return (
        <RequestLoader.MultiLoader loaders={multiLoaderData}>
            {sectionRoutes?.length ? (
                <DndContext
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                >
                    <SortableContext items={sortableSectionRoutes} strategy={verticalListSortingStrategy}>
                        <div className="SectionOrganiser">
                            {sortableSectionRoutes
                                .filter((id) => sectionRoutes.find((section) => section.sectionID === id))
                                .map((id) => {
                                    const sectionData = sectionRoutes.find((section) => section.sectionID === id);
                                    const isShowingInNavigationMenuItem = navigationMenuIDs?.includes(id);

                                    function onShowInNavigationMenuItemChanged(showInNavigationMenu) {
                                        const clonedMenuIDs = _.clone(navigationMenuIDs);
                                        if (showInNavigationMenu) {
                                            clonedMenuIDs.push(id);
                                        } else {
                                            _.remove(clonedMenuIDs, (menuID) => menuID === id);
                                        }
                                        handleSectionIDsUpdate(clonedMenuIDs);
                                    }

                                    return (
                                        <NavigationOrganiserChild
                                            isEditingNavigationMenu={isEditingNavigationMenu}
                                            isShowingInNavigationMenuItem={isShowingInNavigationMenuItem}
                                            onShowInNavigationMenuItemChanged={
                                                onNavigationMenuIDsChanged ? onShowInNavigationMenuItemChanged : undefined
                                            }
                                            key={id}
                                            id={id}
                                            sectionData={sectionData}
                                        />
                                    );
                                })}
                        </div>
                    </SortableContext>
                </DndContext>
            ) : (
                <div className="SectionOrganiser__noSections">
                    {isGetKatanaSiteSectionsFetching ? (
                        <RequestLoader message="Checking site sections.." />
                    ) : (
                        <Text>You have no sections added...</Text>
                    )}
                </div>
            )}
        </RequestLoader.MultiLoader>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
