/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { writable } from 'svelte/store';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaNextSetupStageRoute } from 'containers/katana/hooks/useKatanaNextSetupStageRoute';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useSiteSectionsRouteData } from 'containers/katana/hooks/useSiteSectionsRouteData';
import useKatanaURL from './useKatanaURL';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import {
    SiteSetupStageOrder,
    SiteSetupStageToSubPage,
    katanaServicePages,
    katanaSubPageEnums,
    katanaSubPageToSetupStage
} from 'containers/katana/consts';
import { ADD_SECTION_SEARCH_PARAM_KEY } from 'containers/katana/containers/ContentEditorLightbox/routes/addSection/consts';

const { EDITOR, OVERVIEW } = katanaServicePages;
const { NAVIGATION, PRESET_CONTENT } = katanaSubPageEnums;
export const katanaNextPageRoutingURL = writable('');

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {import('containers/katana/types').Katana.SubPageRouteData} subpageRouteData
 */
function useKatanaNextPage(subpageRouteData) {
    /***** HOOKS *****/
    const params = useSetupEditorRouteParams();
    const { id, page, subpage, childpage } = params;
    const location = useLocation();
    const history = useHistory();
    const { getKatanaDestination } = useKatanaURL();
    const nextSetupRoute = useKatanaNextSetupStageRoute();
    const { siteSectionsRouteData, hasNextChildPage, indexOfChildPage } = useSiteSectionsRouteData();
    const currentRoutePageIndex = SiteSetupStageOrder.indexOf(katanaSubPageToSetupStage[subpage]);

    /***** QUERIES *****/
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);
    const { data: get_katana_site_sections_data } = katanaQuery.serviceID.getSections.useQuery(id);

    const newRoutingURL = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.size) {
            const allParams = searchParams.getAll(ADD_SECTION_SEARCH_PARAM_KEY);
            const targetChildPage = allParams[0];
            searchParams.delete(ADD_SECTION_SEARCH_PARAM_KEY, targetChildPage);
            const stringedSearchParams = searchParams.toString();
            const sectionRouteAddURLString = stringedSearchParams ? `?${stringedSearchParams}` : '';

            const finalURL = getKatanaDestination(EDITOR.PRESET_CONTENT, targetChildPage, sectionRouteAddURLString);
            return finalURL;
        }

        if (page === EDITOR.to) {
            return getKatanaDestination(OVERVIEW);
        }

        if (nextSetupRoute && subpage !== NAVIGATION && subpage !== PRESET_CONTENT) {
            return nextSetupRoute;
        }

        if (subpage === PRESET_CONTENT && !childpage && hasNextChildPage) {
            return getKatanaDestination(page, NAVIGATION);
        }

        if (childpage) {
            if (!hasNextChildPage) {
                return getKatanaDestination(page, SiteSetupStageToSubPage[SiteSetupStageOrder[currentRoutePageIndex + 1]]);
            }

            return getKatanaDestination(page, subpage, siteSectionsRouteData[indexOfChildPage + 1]);
        } else if (!hasNextChildPage) {
            return getKatanaDestination(page, SiteSetupStageToSubPage[SiteSetupStageOrder[currentRoutePageIndex + 1]]);
        }

        if (siteSectionsRouteData) {
            return getKatanaDestination(page, PRESET_CONTENT, siteSectionsRouteData[0]);
        }
    }, [
        id,
        page,
        subpage,
        childpage,
        location,
        nextSetupRoute,
        get_katana_section_definitions_data,
        get_katana_site_sections_data,
        hasNextChildPage
    ]);

    /***** FUNCTIONS *****/
    const performRouting = useCallback(() => {
        history.push(newRoutingURL);
    }, [newRoutingURL]);

    /***** EFFECTS *****/
    useEffect(() => {
        katanaNextPageRoutingURL.set(newRoutingURL);
    }, [newRoutingURL]);

    return { performRouting };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useKatanaNextPage };
