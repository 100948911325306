/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { has, keys } from 'lodash';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSectionNameRouteKey } from 'containers/katana/hooks/methods';
import { sortSectionIntoCorrectOrder } from 'containers/katana/queries/methods/sortSectionOrder';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/types';

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(/** @type {const} */ ['katana', 'service', serviceID, 'meta', 'sections']);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.site.service_id.meta.sections.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: Infinity,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function getQueryData(serviceID: number): undefined | Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.meta.sections.GET>> {
    return queryClient.getQueryData(createQueryKey(serviceID));
}

function prefetchQuery(serviceID: number) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function useIsFetching(serviceID: number) {
    return queryClient.isFetching({ queryKey: createQueryKey(serviceID) });
}

function ensureQueryData(serviceID: number) {
    return queryClient.ensureQueryData(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets section definitions for katana site
 */
function _useQuery(serviceID: number) {
    return useQuery(createQueryOptions(serviceID));
}

/**
 * Gets section definitions by their route key for katana site
 */
function useByRouteKeyQuery(serviceID: number) {
    return useQuery({
        ...createQueryOptions(serviceID),
        select: (response) => {
            if (response.status !== 200) {
                return [];
            }

            const get_katana_section_definitions = response.data;
            type ReduceResult = Record<string, NXUtils.ValueOf<typeof get_katana_section_definitions>>;

            const definitionKeys = keys(get_katana_section_definitions) as Array<keyof typeof get_katana_section_definitions>;

            return definitionKeys.reduce<ReduceResult>((acc, sectionDefinitionKey) => {
                const sectionDefinition = get_katana_section_definitions[sectionDefinitionKey];
                const routeKey = getSectionNameRouteKey(sectionDefinition.name);
                acc[routeKey] = sectionDefinition;
                return acc;
            }, {});
        }
    });
}

/**
 * Gets section definitions ordered
 */
function useOrderedQuery(serviceID: number) {
    return useQuery({
        ...createQueryOptions(serviceID),
        select: (response) => {
            if (response.status !== 200) {
                return response;
            }

            return sortSectionIntoCorrectOrder(Object.values(response.data));
        }
    });
}

function useDefinitionIDbyRouteKey(serviceID: number, routeKey: string) {
    const { data: get_katana_section_definitions_by_route_key_data } = useByRouteKeyQuery(serviceID);

    return get_katana_section_definitions_by_route_key_data?.[routeKey]?.id ?? null;
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**
 * Method to get the route key based on the definition ID using existing query data.
 *
 * Does not fetch new data.
 */
function getRouteKeyByDefinitionID(serviceID: number, definitionID: number) {
    const queryData = getQueryData(serviceID);
    if (!queryData || queryData.status !== 200) {
        return '';
    }

    const definitionData = queryData.data;
    if (!has(definitionData, definitionID)) {
        return '';
    }

    return getSectionNameRouteKey(definitionData[definitionID].name);
}

export const getSectionDefinitions = Object.freeze({
    useQuery: _useQuery,
    useByRouteKeyQuery,
    useOrderedQuery,
    useDefinitionIDbyRouteKey,
    useIsFetching,
    getRouteKeyByDefinitionID,
    getQueryData,
    prefetchQuery,
    createQueryKey,
    createQueryOptions,
    ensureQueryData
});
