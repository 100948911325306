/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { getKatanaServiceCurrentSetupStage } from 'containers/services/modules/katanaServicesList/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SiteSetupStageToTitle, katanaServicePages } from 'containers/katana/consts';

const { OVERVIEW, SETUP } = katanaServicePages;

/**
 * @param {number} serviceID
 * @param {import('containers/katana/types').katanaLaunchCheckListEnumValues[]} [launchChecklist]
 */
function useKatanaChecklistServiceSetupURL(serviceID, launchChecklist = []) {
    const { getKatanaDestination } = useKatanaURL(serviceID);
    const currentStage = getKatanaServiceCurrentSetupStage(launchChecklist);

    if (!currentStage) {
        return getKatanaDestination(OVERVIEW);
    }

    const setupState = SiteSetupStageToTitle[currentStage];

    return getKatanaDestination(SETUP[setupState]);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 * @returns {string}
 */
export function useKatanaServiceSetupURL(serviceID) {
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(serviceID);
    return useKatanaChecklistServiceSetupURL(serviceID, get_katana_service_data?.attributes?.launch_checklist);
}

/**
 * @param {number} serviceID
 * @returns {string}
 */
export function useKatanaServiceSiteListSetupURL(serviceID) {
    const { data: get_katana_site_list_data } = katanaQuery.getSiteList.useInfiniteQuery();
    const listDataLaunchCheckist = get_katana_site_list_data?.pages?.flat?.()?.find?.((site) => site.id === serviceID)?.attributes?.launch_checklist;

    return useKatanaChecklistServiceSetupURL(serviceID, listDataLaunchCheckist);
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
