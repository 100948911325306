/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _ActiveRadioRender as ActiveRadioRender } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/_ActiveRadioRender';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ActiveRadioRenderNamespace } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ActiveRadioRenderHookFormDefault: React.FC<ActiveRadioRenderNamespace.HookForm.Default.Props> = (props) => {
    const { label, disabled, children, onSelect, className, fullWidth, name } = props;

    /***** HOOKS *****/
    const { field } = useController({ name });

    /***** FUNCTIONS *****/
    function handleChange(v: string) {
        onSelect?.(v);
        field.onChange(v);
    }

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={className}>
            <FormLabel htmlFor={name}>{label}</FormLabel>

            <FormItemInner.HookForm name={name} noBackground fullWidth={fullWidth}>
                <ActiveRadioRender value={field.value} onChange={handleChange}>
                    {children}
                </ActiveRadioRender>
            </FormItemInner.HookForm>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
