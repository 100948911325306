/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';

/*   ACTIONS
 *****************************************************/
import { formatLuxonOrdinal, getCurrentDate, getIncludedObjBasedOnType, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: {
                id: null,
                domain: '',
                valid_from: '',
                valid_until: '',
                product: {
                    name: ''
                }
            }
        };
    }

    componentDidMount() {
        const { ssl_information_data } = this.props;

        if (ssl_information_data) {
            const product = getIncludedObjBasedOnType(ssl_information_data.included, 'product');

            this.setState(
                {
                    details: {
                        id: ssl_information_data.id,
                        product: { ...product.attributes },
                        ...ssl_information_data.attributes
                    }
                },
                () => {
                    registerScrollEvents(this, true);
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { ssl_information_status, ssl_information_data } = this.props;

        if (ssl_information_status === 'success' && prevProps.ssl_information_status === 'loading') {
            const product = getIncludedObjBasedOnType(ssl_information_data.included, 'product');

            this.setState({
                details: {
                    id: ssl_information_data.id,
                    product: { ...product.attributes },
                    ...ssl_information_data.attributes
                }
            });
        }
    }

    render() {
        const { details } = this.state;

        const renderExpiry = (status, expiry_date) => {
            const expiryDateLuxon = toLuxonDate(expiry_date, 'yyyy-MM-dd');
            const expiry = parseInt(getCurrentDate().diff(expiryDateLuxon, 'days').toObject()['days']);
            const expiryDateFormatted = formatLuxonOrdinal(expiryDateLuxon.toFormat('EEEE, MMM {d} yyyy'));

            if (status === 'Expired') {
                return (
                    <Fragment>
                        Expired {expiry} days ago <strong>({expiryDateFormatted})</strong>
                    </Fragment>
                );
            } else if (expiry === -1) {
                return (
                    <Fragment>
                        Expires in 1 day <strong>({expiryDateFormatted})</strong>
                    </Fragment>
                );
            } else if (expiry === 0) {
                return (
                    <Fragment>
                        Expires today <strong>({expiryDateFormatted})</strong>
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        Expires in {expiry.toString().substring(1)} days <strong>({expiryDateFormatted})</strong>
                    </Fragment>
                );
            }
        };

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        const conditionalProps = {
            className: `sslOverview__box`,
            split: {
                left: {
                    render: (
                        <Fragment>
                            <div className="sharedSplit__container--title">
                                <h2>Overview</h2>
                            </div>
                            <div className="sharedSplit__container">
                                <div className="sharedSplit__subheading">Domain Name</div>
                                <div className="sharedSplit__description domain">{details.domain}</div>
                            </div>
                            <div className="sharedSplit__container">
                                <div className="sharedSplit__subheading">Product</div>
                                <div className="sharedSplit__description">{details.product.name}</div>
                            </div>
                            <div className="sharedSplit__container">
                                <div className="sharedSplit__subheading">Date Issued</div>
                                <div className="sharedSplit__description">
                                    {formatLuxonOrdinal(toLuxonDate(details.valid_from, 'yyyy-MM-dd').toFormat('EEEE, MMM {d} yyyy'))}
                                </div>
                            </div>
                            <div className="sharedSplit__container">
                                <div className="sharedSplit__subheading">Expires</div>
                                <div className="sharedSplit__description expiry">{renderExpiry(details.status, details.valid_until)}</div>
                            </div>
                        </Fragment>
                    )
                },
                right: {
                    render: (
                        <Fragment>
                            <div className="sharedSplit__container--title">
                                <h2>Helpful Setup Guides</h2>
                            </div>
                            <div className="sharedSplit__container">
                                <div className="sharedSplit__link">
                                    <Anchor
                                        href="https://vip.ventraip.com.au/support/support-centre/ssl-certificates/manually-adding-an-ssl-certificate-to-cpanel-hosting"
                                        target="_blank"
                                    >
                                        How to install your SSL Certificate on a {company} hosting service?
                                    </Anchor>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="sslOverview"
            >
                <Box premounted={true} {...conditionalProps} />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        ssl_information_status: state.ssl.ssl_information_status,
        ssl_information_data: state.ssl.ssl_information_data
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));
