/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ActiveRadioRenderContext } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ActiveRadioRenderNamespace } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Radio Buttons rendered using ActiveRadioRender.Item, the main ActiveRadioRender handles some styling and context management
 *
 * The ActiveRadioRender.Item handles the actual rendering of the radio buttons and the selected item
 *
 */
export const _ActiveRadioRender: React.FC<ActiveRadioRenderNamespace.Props> = ({ value, onChange, children }) => {
    /***** RENDER HELPERS *****/
    const context = {
        _value: value,
        _onChange: onChange
    };

    /***** RENDER *****/
    return (
        <Flex className="ActiveRadioRender" direction="column">
            <ActiveRadioRenderContext.Provider value={context}>{children}</ActiveRadioRenderContext.Provider>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
