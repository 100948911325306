/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import Grid from 'components/Grid';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InfoTableSpan, InfoTableSubHeading } from 'containers/katana/components';
import { RenderLogoOrBrandFont } from 'containers/katana/modules/information/renderBrand/renderLogoOrBrandFont';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getFontFamilyByFontMetaData } from 'containers/katana/containers/contentEditorModules/webpageFonts/methods';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages, katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorRoutes } from 'containers/katana/containers/ContentEditorLightbox/consts';
import './_KatanaInformationAndAppearanceModule.scss';
const { COLOUR_FONTS } = katanaSubPageEnums;
const { EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _WebsiteAppearanceContent = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { data: get_katana_service_data, isLoading: isGetKatanaServiceLoading } = katanaQuery.serviceID.getService.useQuery(id);
    const { data: get_fonts_meta_data, isLoading: isGetFontsMetaLoading } = katanaQuery.serviceID.meta.getFonts.useQuery(id);
    const { data: get_colours_meta_data, isLoading: isGetColoursMetaLoading } = katanaQuery.serviceID.meta.getColours.useQuery(id);
    const colour = get_katana_service_data?.site?.color ? get_colours_meta_data?.[get_katana_service_data.site.color]?.hex_code : undefined;
    const { data: get_palette_meta_data } = katanaQuery.serviceID.meta.palette.useQuery({
        serviceID: id,
        colour: get_katana_service_data?.site?.color
    });

    /***** RENDER HELPERS *****/
    const selectedFontFamilies = get_fonts_meta_data
        ? getFontFamilyByFontMetaData(get_fonts_meta_data, get_katana_service_data?.site?.style?.fonts)
        : {};

    /***** RENDER *****/
    return (
        <Grid columns={isMobile ? '1fr' : '4fr 5fr'} fullWidth justify-children--stretch alignItems--start gap={5}>
            <Flex direction="column" fullHeight>
                <Flex direction="column" gap={1}>
                    <InfoTableSubHeading>website logo/colour</InfoTableSubHeading>
                    <Border all radius="C_RenderLogoOrBrandFont_border-radius">
                        <RenderLogoOrBrandFont />
                        {!isGetColoursMetaLoading && !isGetKatanaServiceLoading && !colour ? (
                            <Border top inject>
                                <Flex justify="center" items="center">
                                    <Text size--s medium secondary italic>
                                        No colour selected
                                    </Text>
                                </Flex>
                            </Border>
                        ) : (
                            <Grid columns="repeat(3,1fr)" gap={0}>
                                <div
                                    className={classNames('KatanaInformationAndAppearanceModule__colourBar')}
                                    style={{ backgroundColor: get_palette_meta_data?.colors?.['500'] || colour }}
                                />
                                <div
                                    className={classNames('KatanaInformationAndAppearanceModule__colourBar')}
                                    style={{ backgroundColor: get_palette_meta_data?.colors?.['50'] || colour }}
                                />
                                <div
                                    className={classNames('KatanaInformationAndAppearanceModule__colourBar')}
                                    style={{ backgroundColor: get_palette_meta_data?.colors?.['950'] || colour }}
                                />
                            </Grid>
                        )}
                    </Border>
                </Flex>
            </Flex>
            <Flex direction="column" fullHeight justify="between">
                <Flex direction="column" gap={0}>
                    <InfoTableSubHeading>Primary font</InfoTableSubHeading>
                    <InfoTableSpan>
                        <Text.Skeleton isLoading={isGetFontsMetaLoading} _style={{ fontFamily: selectedFontFamilies.heading }} size--m>
                            {selectedFontFamilies.heading || 'N/A'}
                        </Text.Skeleton>
                    </InfoTableSpan>
                </Flex>

                <Flex direction="column" gap={0}>
                    <InfoTableSubHeading>Secondary font</InfoTableSubHeading>
                    <InfoTableSpan>
                        <Text.Skeleton isLoading={isGetFontsMetaLoading} _style={{ fontFamily: selectedFontFamilies.body }} size--m>
                            {selectedFontFamilies.body || 'N/A'}
                        </Text.Skeleton>
                    </InfoTableSpan>
                </Flex>

                {!get_katana_service_data?.business?.logo && (
                    <Flex direction="column" gap={0}>
                        <InfoTableSubHeading>Logo font</InfoTableSubHeading>
                        <InfoTableSpan>
                            <Text.Skeleton isLoading={isGetFontsMetaLoading} _style={{ fontFamily: selectedFontFamilies.brand }} size--m>
                                {selectedFontFamilies.brand || 'N/A'}
                            </Text.Skeleton>
                        </InfoTableSpan>
                    </Flex>
                )}

                <Padding x={1} inject>
                    <OutlineButton
                        size="small"
                        color="secondary"
                        to={getKatanaDestination(EDITOR.COLOUR_FONTS)}
                        onMouseOver={() => {
                            katanaContentEditorRoutes[COLOUR_FONTS].prefetch(id);
                        }}
                        className="KatanaInformationAndAppearanceModule__editButton"
                    >
                        Edit colours and fonts
                    </OutlineButton>
                </Padding>
            </Flex>
        </Grid>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
