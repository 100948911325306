/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ScrollableComponent } from 'components/ScrollableComponent';
import OverviewSplit from './overviewSplit';
import SuggestionsSplit from './suggestionsSplit';
import { FreeOnlineNotificationTooltip } from 'components/Promo/FreeDomain/FreeOnlineNotificationTooltip';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import IconButton from 'components/Buttons/IconButton';

/*   ACTIONS
 *****************************************************/
import { getDomainRenew, getDomainSuggestions } from '../../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_domainOverview.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Domain overview module
 * @param {{
 *      ccTLD: boolean
 * }} props
 */
function Overview({ ccTLD }) {
    /***** HOOKS *****/
    const app_viewport = useAppViewport();
    const domain_information_data = useSelector((state) => state.domain.domain_information_data);
    const dispatch = useDispatch();

    const date = new Date(domain_information_data?.attributes.registration_date);
    const month = date?.toLocaleString('default', { month: 'long' });
    const year = date?.getFullYear() + 1;

    const localStorageKey = `${domain_information_data.id}_onlineGiftNotification`;

    /***** STATES *****/
    const [isNotificationShowing, setIsNotificationShowing] = useState(localStorage.getItem(localStorageKey) !== 'false');

    const isSuggestionsShowing = !['xs', 'sm'].includes(app_viewport);

    /***** EFFECTS *****/
    useEffect(() => {
        getDomainRenew(domain_information_data?.id);

        if (isSuggestionsShowing) {
            dispatch(getDomainSuggestions(domain_information_data?.id));
        }
    }, []);

    /***** HANDLERS *****/
    const handleNotificationClose = () => {
        setIsNotificationShowing(false);
        localStorage.setItem(localStorageKey, 'false');
    };

    /***** RENDER HELPERS *****/
    const splitSections = [<OverviewSplit key="overview" ccTLD={ccTLD} />];

    if (isSuggestionsShowing) {
        splitSections.unshift(<SuggestionsSplit key="suggestions" />);
    }

    const renderNotification = (giveaway) => {
        if (giveaway) {
            return (
                <div className="notification">
                    <Padding x={5} y={2}>
                        <Text>
                            You have been awarded a free gift to protect your brand. Your domain registration is complimentary until{' '}
                            <Text span bold>
                                {month} {year}
                                {'. '}
                            </Text>
                            <FreeOnlineNotificationTooltip type="text" />
                            <IconButton
                                className="closeButton"
                                icon={<PhosphorIcons.X size={24} />}
                                label="Close"
                                onClick={handleNotificationClose}
                            />
                        </Text>
                    </Padding>
                </div>
            );
        }
    };

    /***** RENDER *****/
    return (
        <ScrollableComponent className="domainOverview" allowImmediateReady>
            {isNotificationShowing && renderNotification(domain_information_data?.attributes.auto_online_giveaway)}
            <NXBox>
                <NXBox.Split sections={splitSections} />
            </NXBox>
        </ScrollableComponent>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Overview;
