/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import mergeRefs from 'merge-refs';
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Input as Input } from 'components/Form/Input/_Input';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXInputNamespace } from 'components/Form/Input/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type InputHookFormProps = NXInputNamespace.Props & {
    label: React.ReactNode;
    name: string;
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _InputHookFormDefault: React.FC<InputHookFormProps> = ({
    label,
    disabled,
    placeholder,
    isPlaceholderItalic,
    name,
    inputRef,
    size,
    intrinsicProps
}) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { onChange, onBlur, ref, value } = field;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name} size={size}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner.HookForm name={name}>
                <Input
                    disabled={disabled}
                    name={name}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    value={value}
                    inputRef={mergeRefs(ref, inputRef)}
                    intrinsicProps={{ ...intrinsicProps, onChange: (e) => onChange(e.target.value), onBlur }}
                />
            </FormItemInner.HookForm>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
