/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import PasswordForm from '../forms/password';

/*   ACTIONS
 *****************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { updatePassword } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPasswordDropdown: false
        };
        this.openPasswordDropdown = this.openPasswordDropdown.bind(this);
        this.closePasswordDropdown = this.closePasswordDropdown.bind(this);
        this.handlePasswordForm = this.handlePasswordForm.bind(this);
    }

    openPasswordDropdown() {
        this.setState({
            showPasswordDropdown: true
        });
    }

    closePasswordDropdown() {
        this.setState({
            showPasswordDropdown: false
        });
    }

    handlePasswordForm(values) {
        const { updatePassword } = this.props;
        const { old_password, password, password_confirmation } = values;
        updatePassword(old_password, password, password_confirmation);
    }

    componentDidMount() {
        const { location } = this.props;
        const { openPasswordDropdown } = this;
        const query = new URLSearchParams(location.search);
        const open = query.get('open');

        if (open) {
            openPasswordDropdown();
            scroll.scrollTo(this.scrollRef.getBoundingClientRect().top);
        }
    }

    componentDidUpdate(prevProps) {
        const { account_update_password_status } = this.props;
        registerScrollEvents(this, account_update_password_status === 'success' && prevProps.account_update_password_status === 'loading');

        if (account_update_password_status === 'success' && prevProps.account_update_password_status === 'loading') {
            this.setState({
                showPasswordDropdown: false
            });
        }
    }

    render() {
        const { account_update_password_status } = this.props;
        const { showPasswordDropdown } = this.state;
        const { openPasswordDropdown, closePasswordDropdown, handlePasswordForm } = this;

        /*   RENDER OPTIONS
         **********************************************************************************************************/

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountPassword"
            >
                <NXBox isLoading={account_update_password_status === 'loading'}>
                    <NXBox.Top title="Change Password" description="Keep your account secure by regularly changing your password">
                        <OutlineButton onClick={openPasswordDropdown}>Change Password</OutlineButton>
                    </NXBox.Top>
                    <NXBox.Dropdown isOpen={showPasswordDropdown}>
                        <div className="accountPassword__popup popup">
                            <div className="popup__row">
                                <div className="box__wrapper">
                                    <button onClick={() => closePasswordDropdown()} className="popup__close">
                                        <PhosphorIcons.X />
                                    </button>
                                    <div className="popup__main">
                                        <div className="popup__column popup__column--form">
                                            <PasswordForm onSubmit={handlePasswordForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NXBox.Dropdown>
                </NXBox>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    account_update_password_status: state.account.account_update_password_status
});

const mapDispatchToProps = {
    updatePassword
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
