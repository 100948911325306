/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(/** @type {const} */ ['katana', 'service', serviceID, 'domain-options']);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: async () => {
            return KATANA_API.katana.service_id.domain_options.GET(serviceID);
        },
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(serviceID: number) {
    return useQuery(createQueryOptions(serviceID));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getDomainOptions = Object.freeze({
    useQuery: _useQuery
});
