/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { UseResizeObserverCallback } from '@react-hook/resize-observer';
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { HideWebpackOverlayDuringDevelopment } from 'components/Utils/HideWebpackOverlayDuringDevelopment';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useFlooredNumber } from 'utilities/hooks/useFlooredNumber';
import { useStore } from 'utilities/hooks/useStore';
import { useStoreEffect } from 'utilities/hooks/useStoreEffect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PictureInPictureContext } from 'components/PictureInPicture/consts';
import { megaMaySpinBannerLightboxState } from 'config/containers/promotions/megamay2024/components/spinBanner/consts';
import mergeRefs from 'merge-refs';
import './_PictureInPicture.scss';

type Props = {
    children: React.ReactNode | ((options: { handleOnNewSize: () => void }) => React.ReactNode);
    leftAnchorElement?: HTMLElement | null;
    pictureInPictureRef?: React.MutableRefObject<HTMLDivElement | null>;
    stateStoreData: PictureInPicture.CreateStateData;
    minimiseOnMinWidth?: boolean;
    creepOutOnMinWidth?: boolean;
    className?: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * - minimiseOnMinWidth: When the width of the PictureInPicture is less than the minimum width, the state will be set to 'minimised'
 * - creepOutOnMinWidth - Default: `true` : When the width of the PictureInPicture is less than the minimum width, the PictureInPicture will creep into the side of the screen and become bigger on hover
 */
export const _PictureInPicture: React.FC<Props> = ({
    children,
    leftAnchorElement = null,
    stateStoreData,
    minimiseOnMinWidth,
    creepOutOnMinWidth = true,
    className,
    pictureInPictureRef
}) => {
    /***** STATE *****/
    const [leftAnchor, setLeftAnchor] = useFlooredNumber(0);

    /***** HOOKS *****/
    const [pictureState, setPictureState] = useStore(stateStoreData.store);
    const _pictureInPictureRef = useRef<HTMLDivElement>(null);

    const lastStateRef = useRef(pictureState);

    const storeEffectCallback = useCallback(
        (state) => {
            if (state) {
                lastStateRef.current = pictureState;
                setPictureState('hidden');
            } else {
                setPictureState(lastStateRef.current);
            }
        },
        [pictureState]
    );
    useStoreEffect(megaMaySpinBannerLightboxState, storeEffectCallback, false);

    /***** FUNCTIONS *****/
    function calculateLeftAnchor() {
        if (leftAnchorElement) {
            const newLeftAnchor = window.innerWidth - leftAnchorElement.getBoundingClientRect().right;
            setLeftAnchor(newLeftAnchor);
        } else {
            setLeftAnchor(0);
        }
    }

    const handleWindowResize = () => {
        if (pictureState === 'maximised') {
            return;
        }

        calculateLeftAnchor();
    };

    const isOnMinWidth = useMemo(() => leftAnchor < 350, [leftAnchor]);

    const handleOnNewSize = useCallback(() => {
        if (!_pictureInPictureRef.current) {
            return;
        }

        if (pictureState !== 'maximised') {
            _pictureInPictureRef.current.style.height = `auto`;
        }
    }, [pictureState]);

    /***** EFFECTS *****/
    const handleResizeObserver: UseResizeObserverCallback = (entry) => {
        if (pictureState === 'maximised') {
            return;
        }

        const isLeftAnchorElement = entry.target === leftAnchorElement;
        if (isLeftAnchorElement) {
            calculateLeftAnchor();
            return;
        }
    };

    useEffect(calculateLeftAnchor, [leftAnchorElement]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [leftAnchorElement]);

    useEffect(() => {
        if (minimiseOnMinWidth && isOnMinWidth && !['hidden', 'maximised'].includes(pictureState)) {
            setPictureState('minimised');
        }
    }, [leftAnchor]);

    useResizeObserver(leftAnchorElement, handleResizeObserver);

    const canCreepOutOnMinWidth = useMemo(
        () => pictureState === 'visible' && creepOutOnMinWidth && isOnMinWidth,
        [creepOutOnMinWidth, isOnMinWidth, pictureState]
    );

    /***** RENDER *****/
    return (
        <PictureInPictureContext.Provider
            value={{
                stateStoreData
            }}
        >
            <div
                className={classNames('PictureInPicture__psuedoBackDrop', {
                    'PictureInPicture__psuedoBackDrop--visible': pictureState === 'maximised'
                })}
            />
            <div
                ref={mergeRefs(_pictureInPictureRef, pictureInPictureRef)}
                className={classNames(
                    'PictureInPicture',
                    `PictureInPicture--state-${pictureState}`,
                    {
                        'PictureInPicture--isOnMinWidth': isOnMinWidth,
                        'PictureInPicture--creepOutOnMinWidth': canCreepOutOnMinWidth
                    },
                    className
                )}
                style={{
                    width: pictureState === 'maximised' ? '' : leftAnchor,
                    transform: canCreepOutOnMinWidth ? `translateX(${350 - leftAnchor}px)` : ''
                }}
            >
                {pictureState === 'maximised' && (
                    <Anchor
                        className="PictureInPicture__closeButton"
                        onClick={() => {
                            setPictureState('visible');
                        }}
                    >
                        <PhosphorIcons.X.Bold white />
                    </Anchor>
                )}
                {typeof children === 'function' ? children({ handleOnNewSize }) : children}
            </div>
            <HideWebpackOverlayDuringDevelopment />
        </PictureInPictureContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
