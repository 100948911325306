/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
import { createGetQueryDataMethod } from 'utilities/methods/tanstack/createGetQueryDataMethod';
import { createOptimisticUpdateMethod } from 'utilities/methods/tanstack/createOptimisticUpdateMethod';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MINUTE } from 'utilities/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXQuery } from 'utilities/methods/tanstack/types';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.user.socials.GET>>;

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(['katana', 'service', serviceID, 'user', 'socials'] as const);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.user.socials.GET(serviceID),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function invalidateQueries(serviceID: number) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

const setQueryData = createSetQueryDataMethod<number, TData>(createQueryKey);
const getQueryData = createGetQueryDataMethod<number, TData>(createQueryKey);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(serviceID: number, options?: NXQuery.TUseQueryOptions<typeof createQueryOptions>) {
    // This causes a type issue if you don't pass options
    return useQuery({ ...createQueryOptions(serviceID), ...options });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

function useQuerySelector<TSelected>(serviceID: number, selector: (data: TData) => TSelected) {
    return useQuery({ ...createQueryOptions(serviceID), select: selector });
}

export const listSocialAccounts = Object.freeze({
    useQuery: _useQuery,
    useQuerySelector,
    setQueryData,
    getQueryData,
    optimistic: createOptimisticUpdateMethod(setQueryData),
    invalidateQueries
});
