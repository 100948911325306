/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/Form/CheckBoxList';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaBooleanFormFieldCheckbox: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, validationFunctions } = property;

    return (
        <Field name={key} component={CheckBoxList.Item.ReduxForm} validate={validationFunctions}>
            {name}
        </Field>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaBooleanFormFieldCheckbox };
