/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { isEqual, merge } from 'lodash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorRenderModules from '../contentEditorRenderModules';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { selectorEqualityCheckValues, selectorValues } from 'components/Form/Button/reduxForm';
import { createContentEditorModulesFormName } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/createContentEditorModulesFormName';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useModifiedInitialValues } from 'containers/katana/hooks/useModifiedInitialValues';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {FormHandlerComponent}
 */
const WebPageColourThemeFormHandler = ({ subpageRouteData }) => {
    /***** STATE *****/
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initialValuesToUse, setInitialValuesToUse] = useState({});
    const { siteData, setSiteData } = useSiteDataPreviewValues();
    /***** HOOKS *****/

    const { id, subpage } = useSetupEditorRouteParams();
    const formName = createContentEditorModulesFormName(subpage);
    const formValues = useSelector(selectorValues(formName), { equalityFn: selectorEqualityCheckValues });
    /**
     * @type {import('redux').Dispatch<import('redux-form').FormAction>}
     */
    const dispatch = useDispatch();
    const lastSiteData = useRef(siteData);
    const lastFormValues = useRef(formValues);
    useLayoutEffect(() => {
        if (!isEqual(lastFormValues.current, formValues)) {
            setSiteData((prevSiteData) => {
                return merge({}, prevSiteData, formValues?.values);
            });
        } else if (!isEqual(lastSiteData.current, siteData)) {
            const siteDataKeys = Object.keys(siteData);
            siteDataKeys.forEach((key) => {
                dispatch(change(formName, key, siteData[key]));
            });
        }
        lastFormValues.current = formValues;
        lastSiteData.current = siteData;
    }, [formValues, siteData]);

    const { modules } = subpageRouteData;
    const { performRouting } = useKatanaNextPage(subpageRouteData);

    /***** QUERIES *****/
    const { data: get_katana_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(id);

    const { mutateAsync: mutateAsyncUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(id);

    /***** HOOKS *****/
    const newInitialValues = useModifiedInitialValues(get_katana_site_info_data, modules);

    useEffect(() => {
        if (!isSubmitting) {
            setInitialValuesToUse(newInitialValues);
        }
    }, [newInitialValues, isSubmitting]);

    /***** FUNCTIONS *****/
    function handleSubmit(values) {
        /**
         * Because `get_katana_site_info_data` gets an optimistic update, useModifiedInitialValues gets updated causing initialValues to change and the submitting prop to be set back to false since the form changed.
         * Manually handling the submitting state here so that the initial value can only update if the form is not currently submitting
         */
        setIsSubmitting(true);
        return mutateAsyncUpdateKatanaSiteInfo(values, {
            onSuccess: () => {
                katanaQuery.serviceID.getService.optimisticallyAddLaunchChecklistUpdate(id, 'theme-and-color-completed');
                performRouting();
            },
            onSettled: () => {
                setIsSubmitting(false);
            }
        });
    }

    /***** RENDER *****/
    if (isGetKatanaSiteInfoLoading) {
        return <RequestLoader message="Loading Colours & Fonts..." />;
    }

    return (
        <ContentEditorRenderModules
            subpageRouteData={subpageRouteData}
            subpage={subpage}
            onSubmit={handleSubmit}
            initialValues={initialValuesToUse}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default WebPageColourThemeFormHandler;
