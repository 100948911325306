/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import BackgroundColour from 'components/Utils/BackgroundColour';
import { Flex } from 'components/Utils/Flex';
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import RainbowCircleGradient from 'assets/images/katana/rainbow-circle-gradient.png';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param { 'light' |'dark' | 'standard' | 'white' | 'black' | 'photo_background' } style
 */
export function getColourStyleSelectContent(style) {
    switch (style) {
        case 'light':
            return (
                <NXSquare ratio={0.5}>
                    <Flex justify="center" items="center" fullHeight inject>
                        <BackgroundColour light-gray>
                            <PhosphorIcons.Sun secondary size={50} />
                        </BackgroundColour>
                    </Flex>
                </NXSquare>
            );
        case 'dark':
            return (
                <NXSquare ratio={0.5}>
                    <Flex justify="center" items="center" fullHeight inject>
                        <BackgroundColour grey>
                            <PhosphorIcons.MoonStars faded-light-gray size={50} />
                        </BackgroundColour>
                    </Flex>
                </NXSquare>
            );
        case 'standard':
            return (
                <NXSquare ratio={0.5}>
                    <Flex justify="center" items="center" fullHeight inject>
                        <BackgroundImage fill cover imageURL={RainbowCircleGradient}>
                            <PhosphorIcons.Palette white size={50} />
                        </BackgroundImage>
                    </Flex>
                </NXSquare>
            );

        case 'white':
            return (
                <NXSquare ratio={0.5}>
                    <Flex justify="center" items="center" fullHeight className="ColourStyleContent__iconsWrapper" inject>
                        <BackgroundColour light-gray>
                            <Flex justify="center" items="center" fullHeight>
                                <PhosphorIcons.PaintBucket.Fill white size={50} />
                                <PhosphorIcons.PaintBucket secondary size={50} />
                            </Flex>
                        </BackgroundColour>
                    </Flex>
                </NXSquare>
            );

        case 'black':
            return (
                <NXSquare ratio={0.5}>
                    <Flex justify="center" items="center" fullHeight className="ColourStyleContent__iconsWrapper" inject>
                        <BackgroundColour grey>
                            <Flex justify="center" items="center" fullHeight>
                                <PhosphorIcons.PaintBucket faded-light-gray size={50} />
                            </Flex>
                        </BackgroundColour>
                    </Flex>
                </NXSquare>
            );

        case 'photo_background':
            return (
                <NXSquare ratio={0.5}>
                    <Flex justify="center" items="center" fullHeight inject>
                        <BackgroundColour medium-gray>
                            <PhosphorIcons.Image size={50} light-gray />
                        </BackgroundColour>
                    </Flex>
                </NXSquare>
            );
        default:
            return null;
    }
}
