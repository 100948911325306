/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _FormItemInnerPrependedText } from 'components/Form/FormItemInner/_PrependedText/prependedText';
import { _FormItemInnerHookForm } from 'components/Form/FormItemInner/hookForm/Default';
import { _FormItemInner } from 'components/Form/FormItemInner/reduxForm/Default';

const FormItemInner = Object.assign(_FormItemInner, {
    HookForm: _FormItemInnerHookForm,
    PrependedText: _FormItemInnerPrependedText
});

export { FormItemInner };
