/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormLabel } from 'components/Form/FormLabel';
import { Input } from 'components/Form/Input';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { performValidations } from 'utilities/methods/commonActions/performValidation/performValidation';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { MultiFieldArrayInputNamespace } from 'containers/katana/containers/contentEditorModules/multiFieldArrayInput/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const MultiFieldArrayChild: React.FC<MultiFieldArrayInputNamespace.ChildProps> = (props) => {
    const { valueFormatter, valueChangeFilter, valueSelectFilter, valueExtractor, field, value, onChange } = props;
    const { key, label, placeholder, validate, description } = field;

    /***** STATE *****/
    const [touched, setTouched] = useState(false);

    /***** FUNCTIONS *****/
    function _onChange(v: React.ChangeEvent<HTMLInputElement>) {
        setTouched(true);
        const formattedValue = valueFormatter(key, v.target.value);
        const filteredValues = value?.filter?.(valueChangeFilter(field)) ?? [];
        const finalChangeValue = formattedValue ? [...filteredValues, formattedValue] : filteredValues;
        onChange(finalChangeValue);
    }

    const fieldValue = valueExtractor(value?.find?.(valueSelectFilter(field)) ?? '');
    // Check if the input is valid if one of them is invalid, the input is invalid return the value from the first invalidation
    const validationResult = validate?.length ? performValidations(validate, fieldValue) : undefined;

    /***** RENDER *****/
    return (
        <FormItem>
            <FormLabel htmlFor={key}>{label}</FormLabel>
            <Flex direction="column" gap={1}>
                <Input intrinsicProps={{ onChange: _onChange }} placeholder={placeholder} name={key} value={fieldValue} />
                {Boolean(description) && (
                    <Text secondary size--s italic>
                        {description}
                    </Text>
                )}
                <ValidationMessage open={touched} warn>
                    {validationResult}
                </ValidationMessage>
            </Flex>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default MultiFieldArrayChild;
