/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { BillingCycleSection } from './billingCycleSection';
import { RenewSection } from './renewSection';
import BillingInvoiceLightBox from './billingInvoiceLightBox';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ScrollableComponent } from 'components/ScrollableComponent';
import NXBox from 'components/NXBox';
import NXBoxDefaultPadding from 'components/NXBox/DefaultPadding';

/**********************************************************************************************************
 *   HOOKS
 **********************************************************************************************************/
import { useBillingModuleSelectorStatus, useDiscountRegeneration } from './hooks';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderPrepaidForDuration, toLuxonDate } from 'utilities/methods/commonActions';

/*   ACTIONS
 *****************************************************/
import { getHostingBillingCycles, getHostingInformation, getPrepayInformation } from '../../state/accountActions';
import BillingModuleDropdown from './billingModuleDropdown';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BillingHosting = () => {
    /***** STATE *****/
    const [showInvoiceLightbox, setShowInvoiceLightbox] = useState(false);

    /***** HOOKS *****/
    const dispatch = useDispatch();
    const { id: serviceId } = useParams<{ id: string }>();
    const { invoice } = useDiscountRegeneration();
    const status = useBillingModuleSelectorStatus();

    /***** SELECTORS *****/
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);
    const hosting_billingcycle_status = useSelector((state: any) => state.hosting.hosting_billingcycle_status);
    const hosting_prepay_info_status = useSelector((state: any) => state.hosting.hosting_prepay_info_status);

    const { billing_cycle, next_invoice_date, next_due_date } = hosting_information_data?.attributes ?? {};
    const prepaidFor = renderPrepaidForDuration(toLuxonDate(next_due_date, 'yyyy-MM-dd TT'));

    const isReady = hosting_billingcycle_status === 'success' && hosting_prepay_info_status === 'success';

    /***** FUNCTIONS *****/
    function toggleInvoiceLightbox() {
        setShowInvoiceLightbox(!showInvoiceLightbox);

        if (!invoice?.id) {
            getHostingInformation(serviceId)(dispatch);
        }
    }

    /***** EFFECTS *****/
    useEffect(() => {
        getPrepayInformation(serviceId)(dispatch);
        getHostingBillingCycles(serviceId)(dispatch);
    }, []);

    /***** RENDER HELPERS *****/
    function renderPrepaidForHeading() {
        if (prepaidFor) {
            return <NXBox.InfoPair key={3} title="Prepaid For" description={prepaidFor} />;
        }
        return null;
    }

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={isReady}>
            <NXBox isLoading={status === 'loading'} className="hostingBilling__box">
                <NXBoxDefaultPadding>
                    <NXBox.Title title="Billing & Payments" />
                </NXBoxDefaultPadding>
                <NXBox.Bottom>
                    <NXBox.BottomColumns
                        columns={[
                            <NXBox.InfoPair key={1} title="Current Billing Cycle" description={billing_cycle} />,
                            <NXBox.InfoPair
                                key={2}
                                title="Next Invoice Date"
                                description={toLuxonDate(next_invoice_date, 'yyyy-MM-dd TT').toFormat('cccc, d LLLL y')}
                            />,
                            renderPrepaidForHeading()
                        ]}
                    />
                </NXBox.Bottom>
                <BillingCycleSection />
                <RenewSection toggleInvoiceLightbox={toggleInvoiceLightbox} />
                <BillingModuleDropdown setShowInvoiceLightbox={setShowInvoiceLightbox} />
            </NXBox>

            {showInvoiceLightbox && (
                <BillingInvoiceLightBox
                    setShowInvoiceLightbox={setShowInvoiceLightbox}
                    toggleInvoiceLightbox={toggleInvoiceLightbox}
                    showInvoiceLightbox={showInvoiceLightbox}
                />
            )}
        </ScrollableComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default BillingHosting;
