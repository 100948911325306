/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useGetNavigationItemNavTitleAndName() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);

    /***** HOOK RESULTS *****/
    return get_katana_service_data?.sections
        ?.map((sectionData) => {
            const sectionDefinitionData = get_katana_section_definitions_data?.[sectionData?.section_id];
            const name = sectionData?.name;
            const navBarTitle = sectionDefinitionData?.navbar_title;
            return !!(name || navBarTitle) && { name, navBarTitle };
        })
        ?.filter(Boolean);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useGetNavigationItemNavTitleAndName };
