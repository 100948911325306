/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _PillMultiSelect as PillMultiSelect } from 'components/Form/PillMultiSelect/_PillMultiSelect';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { PillMultiSelectNamespace } from 'components/Form/PillMultiSelect/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RenderPillMultiSelectReduxFormDefault: React.FC<PillMultiSelectNamespace.ReduxForm.Props> = ({
    input,
    label,
    options,
    disabled,
    initialLimit,
    meta
}) => {
    const { value, name, onChange } = input;
    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className="RenderPillMultiSelect">
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner meta={meta} noBackground>
                <PillMultiSelect value={value} onChange={onChange} options={options} initialLimit={initialLimit} />
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
