/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { _AddSectionContentOptionsCard as AddSectionContentOptionsCard } from 'containers/katana/containers/ContentEditorLightbox/routes/addSection/_AddSectionContentOptionsCard';
import ContentOptionsCardGrid from 'containers/katana/containers/contentEditorModules/chooseWebLayout/contentOptionsCardGrid';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useMapSectionDefinitionToOptionsCardData } from 'containers/katana/containers/contentEditorModules/chooseWebLayout/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function AddSectionRoute() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_katana_section_definitions_data, isLoading: isGetKatanaSectionDefinitionsOrderedLoading } =
        katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);

    /***** RENDER HELPERS *****/
    const definitionKeys = _.keys(get_katana_section_definitions_data) as KatanaNamespace.SectionDefinitions.IDs[];

    const spareContentOptionsDataList = useMapSectionDefinitionToOptionsCardData(Number(id), definitionKeys);

    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isGetKatanaSectionDefinitionsOrderedLoading, message: 'Loading section definitions...' }
    ]);

    /***** RENDER *****/
    return (
        <ContentEditorModuleContent>
            <Text.Heading h2>What type of content would you like?</Text.Heading>
            <Text secondary size--m>
                You can choose to add more content to show on your website. Select the ones you like below.
            </Text>
            <RequestLoader.MultiLoader loaders={multiLoaderData}>
                <NXBox.Title title="Which section(s) would you like to add on your website?" />
                <NXBox.Description description="All available sections have been added for your site. You can configure or edit your sections from your VIPsites website's management area." />

                <ContentOptionsCardGrid contentOptionsDataList={spareContentOptionsDataList}>
                    {(props) => <AddSectionContentOptionsCard {...props} />}
                </ContentOptionsCardGrid>
            </RequestLoader.MultiLoader>
        </ContentEditorModuleContent>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { AddSectionRoute };
