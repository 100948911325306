import coldplayImg from 'assets/images/promo/coldplay/viprewards-coldplay.jpg';
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';
import { useBoolean } from 'usehooks-ts';
const promoId = 'coldplay-world-tour-2024';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ColdplayCard = () => {
    /***** STATE *****/
    const { value: isModalOpen, toggle: toggleModal } = useBoolean(false);

    /***** RENDER HELPER *****/
    function renderModal() {
        return (
            <Modal isOpen={isModalOpen} onClose={toggleModal} ariaLabel="Contest Entry Confirmation">
                <Flex direction="column" justify="center" items="center" gap={0}>
                    <Text.Heading align--center h2>
                        {`You're in!`}
                    </Text.Heading>
                    <Text align--center size--m secondary>
                        The winner will be drawn and contacted on 28 October 2024.
                    </Text>
                    <SolidButton color="primary" onClick={toggleModal} className="nrlGiveawayLightbox__button">
                        Close
                    </SolidButton>
                </Flex>
            </Modal>
        );
    }

    /***** RENDER *****/
    return (
        <>
            <RewardCard>
                <RewardCard.Image tag="GIVEAWAY">
                    <div className="rewardCard__bg rewardCard__bg--overflowHidden">
                        <img src={coldplayImg} alt="Coldplay Giveaway" width={278} />
                    </div>
                </RewardCard.Image>
                <RewardCard.Body
                    title="Win 2 tickets to see Coldplay at Accor Stadium, plus flights and accommodation"
                    description="Enter now for your chance to win two corporate box tickets to see Coldplay at Accor Stadium."
                    subDescription={
                        <Padding top={1} bottom={6}>
                            <Anchor
                                color="secondary"
                                hoverColor="secondary"
                                underline
                                href="https://ventraip.com.au/terms-policies-agreements/coldplay-giveaway/"
                                target="_blank"
                            >
                                <Text italic span size--xs>
                                    *Terms and conditions apply.
                                </Text>
                            </Anchor>
                        </Padding>
                    }
                >
                    <RewardCard.DrawButton promoId={promoId} toggleModal={toggleModal} />
                </RewardCard.Body>
            </RewardCard>
            {renderModal()}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
