/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { nxAxios } from 'store/nxAxios';
import invariant from 'tiny-invariant';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { urlQueryBuilder } from 'utilities/methods/commonActions';

/**
 * @template {object} TAttributes
 * @param {TAttributes} attributes
 */
function payload(attributes) {
    return {
        type: 'katana',
        attributes
    };
}

export const KATANA_API = /** @type {const} */ {
    katana: {
        /**
         * List
         */
        GET: (queryParams: KatanaAPI.Katana.GET.QueryParams): KatanaAPI.Katana.GET.ReturnType => {
            const apiQuery = `/api/katana/${urlQueryBuilder(queryParams)}`;
            return nxAxios.get(apiQuery);
        },

        /**
         * Create a Site
         */
        POST: (domain: KatanaAPI.Katana.POST.DomainParam): KatanaAPI.Katana.POST.ReturnType => {
            return nxAxios.post('/api/katana', payload({ domain }));
        },

        domains: {
            GET: (queryParams: KatanaAPI.Katana.domains.GET.QueryParams): KatanaAPI.Katana.domains.GET.ReturnType => {
                const apiQuery = `/api/katana/domains/${urlQueryBuilder(queryParams)}`;
                return nxAxios.get(apiQuery);
            }
        },

        check: {
            // TODO: Refactor to use namespace type instead
            POST: (domain: string): Artah.Katana.Check.POST.All.Promise => {
                return nxAxios.post('/api/katana/check', {
                    attributes: {
                        domain
                    }
                });
            }
        },

        service_id: {
            /**
             * Get Site
             */
            GET: (serviceID: KatanaAPI.Katana.ServiceID.GET.Params, signal: AbortSignal): KatanaAPI.Katana.ServiceID.GET.ReturnTypeModified => {
                return nxAxios.get(`/api/katana/${serviceID}`, { signal });
            },

            enable: {
                /**
                 * Enable site
                 */
                POST: ({
                    serviceID,
                    attributes
                }: KatanaAPI.Katana.ServiceID.Enable.POST.Params): KatanaAPI.Katana.ServiceID.Enable.POST.ReturnType => {
                    return nxAxios.post(`/api/katana/${serviceID}/enable`, { attributes });
                }
            },

            disable: {
                /**
                 * Disables site
                 */
                POST: (serviceID: KatanaAPI.Katana.ServiceID.Disable.POST.Params): KatanaAPI.Katana.ServiceID.Disable.POST.ReturnType => {
                    return nxAxios.post(`/api/katana/${serviceID}/disable`);
                }
            },

            domain_options: {
                /**
                 * Get Domain Options
                 */
                GET: (serviceID: KatanaAPI.Katana.ServiceID.DomainOptions.GET.Params): KatanaAPI.Katana.ServiceID.DomainOptions.GET.ReturnType => {
                    return nxAxios.get(`/api/katana/${serviceID}/domain-options`);
                }
            },

            DNS: {
                /**
                 * Gets dns for site
                 */
                GET: (serviceID: KatanaAPI.Katana.ServiceID.DNS.GET.Params): KatanaAPI.Katana.ServiceID.DNS.GET.ReturnType => {
                    return nxAxios.get(`/api/katana/${serviceID}/dns`);
                }
            },

            service_details: {
                /**
                 * Gets service details
                 */
                GET: (serviceID: KatanaAPI.Katana.ServiceID.ServiceDetails.GET.Params): KatanaAPI.Katana.ServiceID.ServiceDetails.GET.ReturnType => {
                    return nxAxios.get(`/api/katana/${serviceID}/service-details`);
                }
            },

            socials: {
                // TODO: Refactor to use namespace type instead
                /**
                 * Get Social Links
                 */
                GET: (serviceID: number): Artah.Katana.ID.Socials.GET.All.Promise => {
                    return nxAxios.get(`/api/katana/${serviceID}/socials`);
                },

                // TODO: Refactor to use namespace type instead
                /**
                 * Update Social Links
                 */
                POST: ({
                    serviceID,
                    attributes
                }: KatanaAPI.Katana.ServiceID.Socials.POST.Params): KatanaAPI.Katana.ServiceID.Socials.POST.ReturnType => {
                    return nxAxios.post(`/api/katana/${serviceID}/socials`, payload(attributes));
                },

                // TODO: Refactor to use namespace type instead
                /**
                 * Delete Social Links
                 * {Artah.Katana.ID.Socials._type_.DELETE.All.Promise}
                 */
                DELETE: ({ serviceID, type }) => {
                    return nxAxios.delete(`/api/katana/${serviceID}/socials/${type}`);
                }
            },

            info: {
                /**
                 * Get site info
                 */
                GET: (serviceID: KatanaAPI.Katana.ServiceID.Info.GET.Params): KatanaAPI.Katana.ServiceID.Info.GET.ReturnType => {
                    return nxAxios.get(`/api/katana/${serviceID}/info`);
                },

                /**
                 * Update site info
                 */
                POST: ({ serviceID, attributes }: KatanaAPI.Katana.ServiceID.Info.POST.Params): KatanaAPI.Katana.ServiceID.Info.POST.ReturnType => {
                    return nxAxios.post(`/api/katana/${serviceID}/info`, payload(attributes));
                }
            },

            business: {
                /**
                 * Get business details
                 */
                GET: (serviceID: KatanaAPI.Katana.ServiceID.Business.GET.Params): KatanaAPI.Katana.ServiceID.Business.GET.ReturnType => {
                    return nxAxios.get(`/api/katana/${serviceID}/business`);
                },

                /**
                 * update business details
                 */
                POST: ({
                    serviceID,
                    attributes
                }: KatanaAPI.Katana.ServiceID.Business.POST.Params): KatanaAPI.Katana.ServiceID.Business.POST.ReturnType => {
                    return nxAxios.post(`/api/katana/${serviceID}/business`, payload(attributes));
                }
            },
            uploads: {
                // TODO: Refactor to use namespace type instead
                /**
                 * Get uploads
                 */
                GET: ({ serviceID }: KatanaAPI.Katana.ServiceID.Uploads.GET.Params): KatanaAPI.Katana.ServiceID.Uploads.GET.ReturnType => {
                    return nxAxios.get(`/api/katana/${serviceID}/uploads`);
                },

                /**
                 * Uploads Image
                 */
                POST: ({
                    serviceID,
                    formData
                }: KatanaAPI.Katana.ServiceID.Uploads.POST.Parameters): KatanaAPI.Katana.ServiceID.Uploads.POST.ReturnType => {
                    return nxAxios.post(`/api/katana/${serviceID}/uploads`, formData);
                },

                uploadId: {
                    /**
                     * Get single file upload
                     */
                    file: {
                        GET: ({
                            serviceID,
                            uploadID
                        }: KatanaAPI.Katana.ServiceID.Uploads.UploadID.file.GET.Params): KatanaAPI.Katana.ServiceID.Uploads.UploadID.file.GET.ReturnType => {
                            return nxAxios.get(`/api/katana/${serviceID}/uploads/${uploadID}/file`);
                        }
                    },
                    /**
                     * Get single file details. Name, url, size, mime_type ect...
                     */
                    GET: ({
                        serviceID,
                        uploadID
                    }: KatanaAPI.Katana.ServiceID.Uploads.UploadID.file.GET.Params): KatanaAPI.Katana.ServiceID.Uploads.UploadID.file.GET.ReturnType => {
                        return nxAxios.get(`/api/katana/${serviceID}/uploads/${uploadID}`);
                    }
                }
            },

            user: {
                socials: {
                    $type: {
                        link: {
                            /**
                             * This endpoint returns an authorization link, this may be used initially to begin the account linking process.
                             */
                            GET: (serviceID: number, $type: 'instagram'): KatanaAPI.Katana.ServiceID.User.Socials.Type.Link.GET.ReturnType => {
                                return nxAxios.get(`/api/katana/${serviceID}/user/socials/${$type}/link`);
                            }
                        },

                        callback: {
                            /**
                             * This endpoint is used to finalize a callback received from an external social provider (i.e. Instagram).
                             */
                            POST: (
                                attributes: KatanaAPI.Katana.ServiceID.User.Socials.Type.Callback.POST.Attributes
                            ): KatanaAPI.Katana.ServiceID.User.Socials.Type.Callback.POST.ReturnType => {
                                return nxAxios.post(`/api/katana/${attributes.serviceID}/user/socials/${attributes.socialType}/callback`, {
                                    code: attributes.code
                                });
                            }
                        }
                    },

                    /**
                     * This endpoint returns all social accounts linked to the current user.
                     */
                    GET: (serviceID: number): KatanaAPI.Katana.ServiceID.User.Socials.GET.ReturnType => {
                        return nxAxios.get(`/api/katana/${serviceID}/user/socials`);
                    },

                    $socialID: {
                        /**
                         * This endpoint may be used to return a single social account given its ID.
                         */
                        GET: (serviceID: number, $socialID: number): Artah.Katana.ID.User.Socials.ID.GET.All.Promise => {
                            return nxAxios.get(`/api/katana/${serviceID}/user/socials/${$socialID}`);
                        },

                        /**
                         * This endpoint may be used to remove a social account given its ID.
                         */
                        DELETE: (serviceID: number, $socialID: number): Artah.Katana.ID.User.Socials.ID.DELETE.All.Promise => {
                            return nxAxios.delete(`/api/katana/${serviceID}/user/socials/${$socialID}`);
                        }
                    }
                }
            },

            navigation: {
                // TODO: Refactor to use namespace type instead
                /**
                 * Get Social Links
                 */
                GET: (serviceID: number): Artah.Katana.ID.Navigation.GET.All.Promise => {
                    return nxAxios.get(`/api/katana/${serviceID}/navigation`);
                },

                /**
                 * Update Navigation
                 */
                POST: ({ serviceID, attributes }: KatanaAPI.Katana.ServiceID.Navigation.POST.Params): Artah.Katana.ID.Navigation.POST.All.Promise => {
                    return nxAxios.post(`/api/katana/${serviceID}/navigation`, payload(attributes));
                },

                items: {
                    // TODO: Refactor to use namespace type instead
                    /**
                     * Get Social Links
                     */
                    GET: (serviceID: number): Artah.Katana.ID.Navigation.Items.GET.All.Promise => {
                        return nxAxios.get(`/api/katana/${serviceID}/navigation/items`);
                    },

                    // TODO: Refactor to use namespace type instead
                    /**
                     * Update Navigation Items
                     */
                    POST: ({
                        serviceID,
                        attributes
                    }: KatanaAPI.Katana.ServiceID.Navigation.POST.Params): Artah.Katana.ID.Navigation.Items.POST.All.Promise => {
                        return nxAxios.post(`/api/katana/${serviceID}/navigation/items`, payload(attributes));
                    }
                }
            },

            preview: {
                site: {
                    /**
                     * Preview Site
                     */
                    POST: (
                        { serviceID, attributes = {} }: KatanaAPI.Katana.ServiceID.Preview.Site.POST.Params,
                        signal: AbortSignal
                    ): KatanaAPI.Katana.ServiceID.Preview.Site.POST.ReturnType => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/preview/site`, attributes, { signal });
                    }
                },

                section: {
                    /**
                     * Preview Site Section
                     */
                    POST: (
                        { serviceID, sectionDefinitionID, properties, style }: KatanaAPI.Katana.ServiceID.Preview.Section.POST.Params,
                        signal: AbortSignal
                    ): KatanaAPI.Katana.ServiceID.Preview.Section.POST.ReturnType => {
                        return nxAxios.post(
                            `/api/katana/site/${serviceID}/preview/section`,
                            {
                                section_id: sectionDefinitionID,
                                properties,
                                style
                            },
                            {
                                signal
                            }
                        );
                    },

                    section_id: {
                        /**
                         * Preview Site Section with site
                         */
                        POST: (
                            {
                                serviceID,
                                sectionID,
                                katanaSectionID,
                                properties,
                                style,
                                site
                            }: KatanaAPI.Katana.ServiceID.Preview.Section.SectionID.POST.Params,
                            signal: AbortSignal
                        ): KatanaAPI.Katana.ServiceID.Preview.Section.SectionID.POST.ReturnType => {
                            return nxAxios.post(
                                `/api/katana/site/${serviceID}/preview/section/${sectionID}`,
                                {
                                    properties,
                                    style,
                                    section_id: katanaSectionID,
                                    ...(site ? { site } : {})
                                },
                                {
                                    signal
                                }
                            );
                        }
                    }
                },

                preset: {
                    /**
                     * Preview Site Section
                     */
                    POST: ({
                        serviceID,
                        presetID,
                        site
                    }: KatanaAPI.Katana.ServiceID.Preview.Preset.POST.Params): KatanaAPI.Katana.ServiceID.Preview.Preset.POST.ReturnType => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/preview/preset`, {
                            preset_id: presetID,
                            ...(site ? { site } : {})
                        });
                    }
                }
            },

            register: {
                /**
                 * Register Site
                 */
                POST: ({
                    serviceID,
                    attributes
                }: KatanaAPI.Katana.ServiceID.Register.POST.Params): KatanaAPI.Katana.ServiceID.Register.POST.ReturnType => {
                    return nxAxios.post(`/api/katana/${serviceID}/register`, payload(attributes));
                }
            },

            reset: {
                // TODO: Refactor to use namespace type instead
                /**
                 * Reset Site
                 */
                POST: (serviceID: number): Artah.Katana.ID.Reset.POST.All.Promise => {
                    return nxAxios.post(`/api/katana/${serviceID}/reset`);
                }
            }
        },

        site: {
            service_id: {
                meta: {
                    theme_presets: {
                        /**
                         * Get theme definitions
                         */
                        GET: (serviceID: number): KatanaAPI.Katana.Site.ServiceID.Meta.ThemePresets.GET.ReturnType => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/theme-presets`);
                        }
                    },

                    colors: {
                        /**
                         * Get Color Definitions
                         */
                        GET: (serviceID: number): KatanaAPI.Katana.Site.ServiceID.Meta.Colors.GET.ReturnType => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/colors`);
                        }
                    },

                    palette: {
                        /**
                         * Get Palette Definitions
                         */
                        GET: ({
                            serviceID,
                            colour
                        }: KatanaAPI.Katana.Site.ServiceID.Meta.Palette.GET.Params): KatanaAPI.Katana.Site.ServiceID.Meta.Palette.GET.ReturnType => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/palette/${colour}`);
                        }
                    },

                    sections: {
                        /**
                         * Get Section definitions
                         */
                        GET: (serviceID: number): KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.ReturnType => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/sections`);
                        }
                    },

                    socials: {
                        /**
                         * Get Socials definitions
                         */
                        GET: (serviceID: number): KatanaAPI.Katana.Site.ServiceID.Meta.Socials.GET.ReturnType => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/socials`);
                        }
                    },

                    fonts: {
                        /**
                         * Get Font  definitions
                         */
                        GET: (serviceID: number): KatanaAPI.Katana.Site.ServiceID.Meta.Fonts.GET.ReturnType => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/fonts`);
                        }
                    }
                },

                pages: {
                    GET: (serviceID: number): Artah.Katana.Site.ID.Pages.GET.All.Promise => {
                        return nxAxios.get(`/api/katana/site/${serviceID}/pages`);
                    },

                    POST: ({
                        serviceID,
                        attributes
                    }: KatanaAPI.Katana.Site.ServiceID.Pages.POST.Params): Artah.Katana.Site.ID.Pages.POST.All.Promise => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/pages`, attributes);
                    },

                    page_id: {
                        GET: ({
                            serviceID,
                            pageID
                        }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.GET.Params): Artah.Katana.Site.ID.Pages.ID.GET.All.Promise => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/pages/${pageID}`);
                        },

                        PUT: ({
                            serviceID,
                            pageID,
                            attributes
                        }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.PUT.Params): Artah.Katana.Site.ID.Pages.ID.PUT.All.Promise => {
                            return nxAxios.put(`/api/katana/site/${serviceID}/pages/${pageID}`, attributes);
                        },

                        DELETE: ({
                            serviceID,
                            pageID
                        }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.DELETE.Params): Artah.Katana.Site.ID.Pages.ID.DELETE.All.Promise => {
                            return nxAxios.delete(`/api/katana/site/${serviceID}/pages/${pageID}`);
                        },

                        duplicate: {
                            POST: ({
                                serviceID,
                                pageID
                            }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Duplicate.POST.Params): Artah.Katana.Site.ID.Pages.ID.Duplicate.POST.All.Promise => {
                                return nxAxios.post(`/api/katana/site/${serviceID}/pages/${pageID}/duplicate`);
                            }
                        },

                        sections: {
                            GET: ({
                                serviceID,
                                pageID
                            }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.GET.Params): Artah.Katana.Site.ID.Pages.ID.Sections.GET.All.Promise => {
                                return nxAxios.get(`/api/katana/site/${serviceID}/pages/${pageID}/sections`);
                            },

                            POST: ({
                                serviceID,
                                pageID,
                                attributes
                            }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.POST.Params): Artah.Katana.Site.ID.Pages.ID.Sections.POST.All.Promise => {
                                return nxAxios.post(`/api/katana/site/${serviceID}/pages/${pageID}/sections`, attributes);
                            },

                            section_id: {
                                GET: ({
                                    serviceID,
                                    pageID,
                                    sectionID
                                }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.SectionID.GET.Params): Artah.Katana.Site.ID.Pages.ID.Sections.ID.GET.All.Promise => {
                                    return nxAxios.get(`/api/katana/site/${serviceID}/pages/${pageID}/sections/${sectionID}`);
                                },

                                PUT: ({
                                    serviceID,
                                    pageID,
                                    sectionID,
                                    attributes
                                }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.SectionID.PUT.Params): Artah.Katana.Site.ID.Pages.ID.Sections.ID.PUT.All.Promise => {
                                    return nxAxios.put(`/api/katana/site/${serviceID}/pages/${pageID}/sections/${sectionID}`, attributes);
                                },

                                DELETE: ({
                                    serviceID,
                                    pageID,
                                    sectionID
                                }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.SectionID.DELETE.Params): Artah.Katana.Site.ID.Pages.ID.Sections.ID.DELETE.All.Promise => {
                                    return nxAxios.delete(`/api/katana/site/${serviceID}/pages/${pageID}/sections/${sectionID}`);
                                },

                                duplicate: {
                                    POST: ({
                                        serviceID,
                                        pageID,
                                        sectionID
                                    }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.SectionID.Duplicate.POST.Params): Artah.Katana.Site.ID.Pages.ID.Sections.ID.Duplicate.POST.All.Promise => {
                                        return nxAxios.post(`/api/katana/site/${serviceID}/pages/${pageID}/sections/${sectionID}/duplicate`);
                                    }
                                }
                            },

                            reorder: {
                                POST: ({
                                    serviceID,
                                    pageID,
                                    attributes
                                }: KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.Reorder.POST.Params): Artah.Katana.Site.ID.Pages.ID.Sections.Reorder.POST.All.Promise => {
                                    return nxAxios.post(`/api/katana/site/${serviceID}/pages/${pageID}/sections/reorder`, attributes);
                                }
                            }
                        }
                    }
                },

                theme: {
                    // TODO: Refactor to use namespace type instead
                    /**
                     * Apply theme
                     */
                    POST: ({
                        serviceID,
                        attributes
                    }: KatanaAPI.Katana.Site.ServiceID.Theme.POST.Params): Artah.Katana.Site.ID.Theme.POST.All.Promise => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/theme`, payload(attributes));
                    }
                },

                sections: {
                    // TODO: Refactor to use namespace type instead
                    /**
                     * List All Site Sections
                     */
                    GET: (serviceID: number): Artah.Katana.Site.ID.Sections.GET.All.Promise => {
                        return nxAxios.get(`/api/katana/site/${serviceID}/sections`);
                    },

                    /**
                     * Add Site Section
                     */
                    POST: ({
                        serviceID,
                        sectionDefinitionID,
                        attributes
                    }: KatanaAPI.Katana.Site.ServiceID.Sections.POST.Params): KatanaAPI.Katana.Site.ServiceID.Sections.POST.ReturnType => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/sections`, {
                            section_id: sectionDefinitionID,
                            ...attributes
                        });
                    },

                    reorder: {
                        // TODO: Refactor to use namespace type instead
                        POST: ({
                            serviceID,
                            sectionIDs
                        }: {
                            serviceID: number;
                            sectionIDs: number[];
                        }): Artah.Katana.Site.ID.Sections.Reorder.POST.All.Promise => {
                            return nxAxios.post(`/api/katana/site/${serviceID}/sections/reorder`, {
                                attributes: {
                                    section_ids: sectionIDs
                                }
                            });
                        }
                    }
                },

                section: {
                    section_id: {
                        /**
                         * Get Site Section
                         */
                        GET: ({
                            serviceID,
                            sectionID
                        }: KatanaAPI.Katana.Site.ServiceID.Section.SectionID.GET.Params): KatanaAPI.Katana.Site.ServiceID.Section.SectionID.GET.ReturnType => {
                            invariant(sectionID, 'sectionID is required');
                            return nxAxios.get(`/api/katana/site/${serviceID}/section/${sectionID}`);
                        },

                        /**
                         * Update Site Section
                         */
                        PUT: ({
                            serviceID,
                            sectionID,
                            attributes
                        }: KatanaAPI.Katana.Site.ServiceID.Section.SectionID.PUT.Params): KatanaAPI.Katana.Site.ServiceID.Section.SectionID.PUT.ReturnType => {
                            return nxAxios.put(`/api/katana/site/${serviceID}/section/${sectionID}`, attributes);
                        },

                        /**
                         * Remove Site Section
                         */
                        DELETE: ({
                            serviceID,
                            sectionID
                        }: KatanaAPI.Katana.Site.ServiceID.Section.SectionID.DELETE.Params): KatanaAPI.Katana.Site.ServiceID.Section.SectionID.DELETE.ReturnType => {
                            return nxAxios.delete(`/api/katana/site/${serviceID}/section/${sectionID}`);
                        }
                    }
                }
            }
        },
        trialDomains: {
            GET: (): Artah.Katana.TrialDomains.GET.All.Promise => {
                return nxAxios.get(`/api/katana/trial-domains`);
            }
        },

        unsplash: {
            search: {
                GET: (id: number, params: KatanaAPI.Katana.ServiceID.Unsplash.GET.Params): KatanaAPI.Katana.ServiceID.Unsplash.GET.ResponseType => {
                    return nxAxios.get(`/api/katana/${id}/unsplash/search/${urlQueryBuilder(params)}`);
                }
            },
            download: {
                trigger: {
                    POST: (id: number, urls: string[]) => {
                        return nxAxios.post(`/api/katana/${id}/unsplash/download/trigger`, { urls });
                    }
                }
            }
        }
    }
};
