/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaOverviewPictureInPictureState } from 'containers/katana/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PreviewWebpageButton() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** RENDER *****/
    return (
        <OutlineButton
            type="onClick"
            onClick={(e) => {
                e.stopPropagation();
                katanaOverviewPictureInPictureState.store.set('maximised');
            }}
            onMouseOver={() => {
                katanaQuery.serviceID.getSitePreview.prefetchQuery({ serviceID: id });
            }}
        >
            <Flex items="center">
                <PhosphorIcons.Eye />
                Preview Website
            </Flex>
        </OutlineButton>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { PreviewWebpageButton };
