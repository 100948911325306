import { contentEditorPictureInPictureState } from 'containers/katana/containers/ContentEditorLightbox/consts';
import { useAllowPreviewState } from 'containers/katana/containers/ContentEditorLightbox/hooks/useAllowPreviewState';
import { useEffect } from 'react';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useContentEditorPictureInPictureStateManager = (isOpen: boolean) => {
    /***** HOOKS *****/
    const isPreviewAllowed = useAllowPreviewState();
    const isMobileOrTablet = useAppViewport(['xs', 'sm', 'md']);

    /***** EFFECTS *****/
    useEffect(() => {
        if (isOpen && isPreviewAllowed && !isMobileOrTablet) {
            const storedPictureState = contentEditorPictureInPictureState.retrieve();
            contentEditorPictureInPictureState.store.set(storedPictureState || 'visible');
        }
    }, [isOpen, isPreviewAllowed]);

    useEffect(() => {
        if (!isPreviewAllowed) {
            contentEditorPictureInPictureState.store.set('hidden');
        }
    }, [isPreviewAllowed]);
};
