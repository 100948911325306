import BasicDetailsBGImage from 'assets/images/katana/content-editor-basic-details-bg.svg';
import FinaliseBGImage from 'assets/images/katana/content-editor-finalise-bg.svg';
import LaunchBGImage from 'assets/images/katana/content-editor-launch-bg.svg';
import LayoutBGImage from 'assets/images/katana/content-editor-layout-bg.svg';
import NavBGImage from 'assets/images/katana/content-editor-nav-bg.svg';
import ThemeBGImage from 'assets/images/katana/content-editor-theme-bg.svg';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { createPictureInPictureState } from 'components/PictureInPicture/consts';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { KatanaMobileViewEditorExclusion } from 'containers/katana/components/KatanaMobileViewEditorExclusion';
import { ConflictingRecordsForm } from 'containers/katana/components/launchWebsite/conflictingRecordsForm';
import { SiteSetupStage, katanaSubPageEnums } from 'containers/katana/consts';
import BasicDetailsMenu from 'containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/basicDetailsMenu';
import { SectionDefinitionsMenu } from 'containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/sectionDefinitionsMenu';
import { BasicDetailsFormHandler } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/basicDetailsFormHandler';
import { BasicInformationFormHandler } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/basicInformationFormHandler';
import FinalDetailsFormHandler from 'containers/katana/containers/ContentEditorLightbox/formHandlers/finaliseDetailsFormHandler';
import { LaunchFormHandler } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/launchFormHandler';
import { LayoutContentFormHandler } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/layoutContentFormHandler';
import NavigationFormHandler from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/navigationFormHandler';
import RegisterDomainFormHandler from 'containers/katana/containers/ContentEditorLightbox/formHandlers/registerDomainFormHandler';
import SectionDefinitionFormHandler from 'containers/katana/containers/ContentEditorLightbox/formHandlers/sectionDefinitionFormHandler';
import WebPageColourThemeFormHandler from 'containers/katana/containers/ContentEditorLightbox/formHandlers/webpageColourThemeFormHandler';
import { BasicDetailsContentModule } from 'containers/katana/containers/contentEditorModules/basicDetails';
import CallToActionContentModule from 'containers/katana/containers/contentEditorModules/callToAction';
import ChooseWebLayoutContentModule from 'containers/katana/containers/contentEditorModules/chooseWebLayout';
import { ContactEmailMobileContentModule } from 'containers/katana/containers/contentEditorModules/contactEmailMobile';
import FavIconContentModule from 'containers/katana/containers/contentEditorModules/favIcon';
import NavigationColourThemeContentModule from 'containers/katana/containers/contentEditorModules/navigationColourTheme';
import { NavigationLabelsContentModule } from 'containers/katana/containers/contentEditorModules/navigationLabels';
import SearchEngineOptimisationContentModule from 'containers/katana/containers/contentEditorModules/searchEngineOptimisation';
import { SocialMediaLinksContentModule } from 'containers/katana/containers/contentEditorModules/socialMediaLinks';
import { TutorialVideo } from 'containers/katana/containers/contentEditorModules/tutorialVideo';
import ValidFormSubmitButton from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/validFormSubmitButton';
import { ValidFormSubmitButtonHookForm } from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/ValidFormSubmitButtonHookForm';
import WebpageColourThemeContentModule from 'containers/katana/containers/contentEditorModules/webpageColourTheme';
import WebpageFontsContentModule from 'containers/katana/containers/contentEditorModules/webpageFonts';
import { WebPageTypeContentModule } from 'containers/katana/containers/contentEditorModules/webpageType';
import { tutorialVideoTimestamps } from 'containers/katana/data/tutorialVideoData';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import React from 'react';
import { writable } from 'svelte/store';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { BASIC_DETAILS, PRESET_CONTENT, NAVIGATION, COLOUR_FONTS, FINALISE, LAUNCH, CONTACT_INFORMATION, REGISTER_DOMAIN, FIX_DNS } =
    katanaSubPageEnums;
const { BasicDetails, ContactInformation, ColoursandFonts, LayoutAndContent, FinalTouches, Launch } = tutorialVideoTimestamps;
const { WebpageInfoCompleted, LayoutAndContentCompleted, ThemeAndColorCompleted, FinishingTouchesCompleted } = SiteSetupStage;

export const katanaContentEditorRoutes = {
    [BASIC_DETAILS]: {
        Icon: PhosphorIcons.ClipboardText,
        title: 'Basic Details',
        backgroundImage: BasicDetailsBGImage,
        checkListKey: WebpageInfoCompleted,
        modules: React.Children.toArray([
            <KatanaMobileViewEditorExclusion>
                <Padding bottom={4}>
                    <Text.Heading h2>{`Let's Start With the Basics`}</Text.Heading>
                    <Text secondary size--m>
                        To begin, we just need some basic information for your website. You can include a name, logo, and contact details for your
                        website.
                    </Text>
                </Padding>
            </KatanaMobileViewEditorExclusion>,
            <TutorialVideo tutorial={BasicDetails} />,
            <BasicDetailsContentModule />,
            <WebPageTypeContentModule />,
            <ValidFormSubmitButtonHookForm />
        ]),
        FormHandler: BasicDetailsFormHandler,
        ChildMenuRenderer: BasicDetailsMenu,
        prefetch: (serviceID: number) => {
            katanaQuery.serviceID.getBusinessInfo.prefetchQuery(serviceID);
        }
    },
    [CONTACT_INFORMATION]: {
        Icon: PhosphorIcons.ClipboardText,
        title: 'Contact Information',
        backgroundImage: BasicDetailsBGImage,
        checkListKey: WebpageInfoCompleted,
        modules: React.Children.toArray([
            <KatanaMobileViewEditorExclusion>
                <Text.Heading h2>Want to add contact information for your business?</Text.Heading>
                <Text secondary size--m>
                    {`If you don't want to add these right now, just skip to the next step. Don't worry, you can come back and add these details later.`}
                </Text>
            </KatanaMobileViewEditorExclusion>,
            <TutorialVideo tutorial={ContactInformation} />,
            <ContactEmailMobileContentModule />,
            <ValidFormSubmitButton />
        ]),
        FormHandler: BasicInformationFormHandler,
        ChildMenuRenderer: BasicDetailsMenu,
        prefetch: (serviceID: number) => {
            katanaQuery.serviceID.getBusinessInfo.prefetchQuery(serviceID);
        }
    },
    [PRESET_CONTENT]: {
        Icon: PhosphorIcons.Layout,
        title: 'Layout and Content',
        backgroundImage: LayoutBGImage,
        checkListKey: LayoutAndContentCompleted,
        modules: React.Children.toArray([<ChooseWebLayoutContentModule />]),
        FormHandler: LayoutContentFormHandler,
        ChildMenuRenderer: SectionDefinitionsMenu,
        ChildPageFormHandler: SectionDefinitionFormHandler,
        prefetch: (serviceID: number) => {
            katanaQuery.serviceID.meta.getSectionDefinitions.prefetchQuery(serviceID);
        }
    },
    [NAVIGATION]: {
        Icon: PhosphorIcons.NavigationArrow,
        title: 'Navigation',
        backgroundImage: NavBGImage,
        modules: React.Children.toArray([
            <KatanaMobileViewEditorExclusion>
                <Text.Heading h2>{`Customise Your Website's Menu`}</Text.Heading>
                <Text secondary size--m>
                    Your menu enables your website visitors to jump to different sections of your website. You can also include a customer engagement
                    button along with your social media links in the menu.
                </Text>
            </KatanaMobileViewEditorExclusion>,
            <TutorialVideo tutorial={LayoutAndContent} />,
            <NavigationColourThemeContentModule />,
            <NavigationLabelsContentModule />,
            <CallToActionContentModule />,
            <SocialMediaLinksContentModule />,
            <ValidFormSubmitButton />
        ]),
        FormHandler: NavigationFormHandler,
        prefetch: (serviceID: number) => {
            katanaQuery.serviceID.getNavigation.prefetchQuery(serviceID);
            katanaQuery.serviceID.getNavigationItems.prefetchQuery(serviceID);
            katanaQuery.serviceID.getSocials.prefetchQuery(serviceID);
            katanaQuery.serviceID.meta.getColours.prefetchQuery(serviceID);
            katanaQuery.serviceID.meta.getSocialsDefinitions.prefetchQuery(serviceID);
            katanaQuery.serviceID.getSiteInfo.prefetchQuery(serviceID);
        }
    },
    [COLOUR_FONTS]: {
        Icon: PhosphorIcons.Palette,
        title: 'Theme and Colour',
        backgroundImage: ThemeBGImage,
        checkListKey: ThemeAndColorCompleted,
        modules: React.Children.toArray([
            <KatanaMobileViewEditorExclusion>
                <Text.Heading h2>Pick the Colours and Fonts for Your Website</Text.Heading>
                <Text secondary size--m>
                    Customise the look and feel of your website with vibrant colours and stylish fonts.
                </Text>
            </KatanaMobileViewEditorExclusion>,
            <TutorialVideo tutorial={ColoursandFonts} />,
            <WebpageColourThemeContentModule />,
            <WebpageFontsContentModule />,
            <ValidFormSubmitButton />
        ]),
        FormHandler: WebPageColourThemeFormHandler,
        prefetch: (serviceID: number) => {
            katanaQuery.serviceID.meta.getColours.prefetchQuery(serviceID);
            katanaQuery.serviceID.meta.getFonts.prefetchQuery(serviceID);
            katanaQuery.serviceID.getSiteInfo.prefetchQuery(serviceID);
        }
    },
    [FINALISE]: {
        Icon: PhosphorIcons.RocketLaunch,
        title: 'Finishing Touches',
        backgroundImage: FinaliseBGImage,
        checkListKey: FinishingTouchesCompleted,
        modules: React.Children.toArray([
            <KatanaMobileViewEditorExclusion>
                <Text.Heading h2>Final Touches Before Launch </Text.Heading>
                <Text secondary size--m>
                    {`We're almost there! Before we launch, let's add some finishing touches to your website.`}
                </Text>
            </KatanaMobileViewEditorExclusion>,
            <TutorialVideo tutorial={FinalTouches} />,
            <SearchEngineOptimisationContentModule />,
            <FavIconContentModule />,
            <ValidFormSubmitButton />
        ]),
        FormHandler: FinalDetailsFormHandler
    },
    [REGISTER_DOMAIN]: {
        backgroundImage: FinaliseBGImage,
        FormHandler: RegisterDomainFormHandler
    },
    [LAUNCH]: {
        backgroundImage: LaunchBGImage,
        modules: React.Children.toArray([
            <Text.Heading align--center h2>
                {`Let's launch your website!`}
            </Text.Heading>,
            <Text secondary size--m align--center>
                {`The moment you've been waiting for!`}
                <br />
                {`You're all set to launch your website and establish your online presence!`}
            </Text>,
            <TutorialVideo tutorial={Launch} />,
            <ConflictingRecordsForm />
        ]),
        FormHandler: LaunchFormHandler
    },
    [FIX_DNS]: {
        backgroundImage: LaunchBGImage,
        modules: React.Children.toArray([
            <Text.Heading align--center h2>
                Fixing Your DNS Records
            </Text.Heading>,
            <Text secondary size--m align--center>
                This tool will add any necessary DNS records and remove any conflicting ones to help get your website online.
            </Text>,
            <ConflictingRecordsForm />
        ]),
        FormHandler: LaunchFormHandler
    }
} as const;

export const katanaContentEditorRouteKeys = Object.keys(katanaContentEditorRoutes);
export const contentEditorPictureInPictureState = createPictureInPictureState('hidden', 'contentEditorPictureInPictureState');
export const katanaContentEditorLightboxSkipWritable = writable(false);
export const katanaContentEditorLightboxHasChanges = writable(false);
