/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _, { keys } from 'lodash';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { katanaSubPageEnums } from 'containers/katana/consts';
import { getSectionNameRouteKey } from 'containers/katana/hooks/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { sortSectionIntoCorrectOrder } from 'containers/katana/queries/methods/sortSectionOrder';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaSubPageEnums } from 'containers/katana/types';
import type { NXUtils } from 'utilities/types';

const { PRESET_CONTENT, NAVIGATION } = katanaSubPageEnums;

const hasNextChildPageSubPageCheckList: NXUtils.ValueOf<KatanaSubPageEnums>[] = [PRESET_CONTENT, NAVIGATION];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

const useSiteSectionsRouteData = (): {
    siteSectionsRouteData: string[];
    hasNextChildPage: boolean;
    indexOfChildPage: number;
} => {
    /***** HOOKS *****/
    const params = useSetupEditorRouteParams();
    const { id, childpage, subpage } = params;

    /***** QUERIES *****/
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);
    const { data: get_katana_site_sections_data } = katanaQuery.serviceID.getSections.useQuery(id);
    /**
     * just a note, at the moment there's 1 place where it's routing manually,
     * This manual routing happens when the user is applying the theme preset.
     * It's not using the next page logic so don't get confused.
     */

    return useMemo(() => {
        const sectionDefinitionsAvailable = !!keys(get_katana_section_definitions_data).length;
        const orderedSiteSections = sortSectionIntoCorrectOrder(get_katana_site_sections_data) ?? [];
        const routeData = sectionDefinitionsAvailable
            ? orderedSiteSections.map(({ section_id }) => getSectionNameRouteKey(get_katana_section_definitions_data[section_id].name))
            : [];
        const isChildPageSectionID = _.isNumber(childpage);

        const sectionID = get_katana_site_sections_data?.find(({ id }) => id === childpage)?.section_id;

        const childPageSectionName =
            isChildPageSectionID && sectionID && get_katana_section_definitions_data?.[sectionID]
                ? getSectionNameRouteKey(get_katana_section_definitions_data?.[sectionID]?.name)
                : (childpage as string | undefined);

        const indexOfChildPage = childPageSectionName ? routeData.indexOf(childPageSectionName) : -1;
        return {
            siteSectionsRouteData: routeData,
            hasNextChildPage:
                (indexOfChildPage !== -1 && indexOfChildPage < routeData.length - 1) ||
                (hasNextChildPageSubPageCheckList.includes(subpage) && !childpage),
            indexOfChildPage
        };
    }, [get_katana_section_definitions_data, get_katana_site_sections_data]);
};

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useSiteSectionsRouteData };
