import Anchor from 'components/Anchor';
import { OverlayInvoiceContent } from 'components/Lightboxes/OverlayLightbox/Components/invoice/overlayInvoiceContent';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';
import { handleInvalidateOptimisticSingleInvoiceQueryData } from 'containers/billing/queries/invoice/methods';
import { useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate } from 'containers/billing/queries/invoice/useGetFilteredAndPaginatedInvoiceListInfiniteQuery';
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InvoiceLoadingError from './invoiceLoadingError';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let OverlayInvoice = (props) => {
    const {
        invoiceID,
        togglePostPayment,
        closeInvoice,
        /**
         * Redux Props
         */
        app_check_token_data,
        login_account_list_data
    } = props;

    /***** STATE *****/
    const [account, setAccount] = useState(null);

    /***** QUERIES *****/
    function handleSuccessResponse(paymentData) {
        const email = account?.attributes?.email ?? '';
        const postPayment = {
            status: 'success',
            icon: 'valid',
            title: 'Nice! Payment Successful',
            desc: (
                <Text>
                    You will receive a receipt email at <Anchor href={`mailto:${email}`}>{email}</Anchor> shortly!
                </Text>
            ),
            res: paymentData
        };
        const queryKey = useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate.createQueryKey({ page: 1 });
        handleInvalidateOptimisticSingleInvoiceQueryData(queryKey, invoiceID);
        togglePostPayment('success', postPayment);
    }

    const { status: get_invoice_status } = useGetInvoiceQuery(invoiceID);

    /***** FUNCTIONS *****/
    function setCurrentAccount() {
        setAccount(login_account_list_data.find(({ id }) => id === app_check_token_data?.account_id));
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (app_check_token_data?.account_id && login_account_list_data) {
            setCurrentAccount();
        }
    }, [app_check_token_data, login_account_list_data]);

    /***** RENDER HELPERS *****/

    const isLoading = () => {
        if (get_invoice_status === 'pending' || !app_check_token_data?.account_id || !login_account_list_data) {
            return 'loading';
        }
        return get_invoice_status;
    };

    function renderLoader() {
        return (
            <div className="OverlayInvoice">
                <RequestLoader message="Loading Invoice" />
            </div>
        );
    }

    /***** RENDER *****/
    const loading = isLoading();
    switch (loading) {
        case 'success':
            return <OverlayInvoiceContent invoiceID={invoiceID} handleSuccessResponse={handleSuccessResponse} closeInvoice={closeInvoice} />;
        case 'error':
            return <InvoiceLoadingError />;
        case 'loading':
        default:
            return renderLoader();
    }
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    app_check_token_data: state.app.app_check_token_data,
    login_account_list_data: state.login.login_account_list_data
});

OverlayInvoice = connect(mapStateToProps)(OverlayInvoice);

export default OverlayInvoice;
