/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import helloFreshImage from 'assets/images/promo/helloFreshAUOffer/helloFreshAU.jpg';
import Padding from 'components/Utils/Padding';
import '../_rewardCard.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HelloFreshAUOfferCard = () => (
    /***** RENDER *****/
    <RewardCard>
        <RewardCard.Image tag="SPECIAL OFFER">
            <div className="rewardCard__bg rewardCard__bg--overflowHidden">
                <img src={helloFreshImage} alt="Hello fresh box sitting on a table" width={278} />
            </div>
        </RewardCard.Image>
        <RewardCard.Body
            title="Save up to $210 with HelloFresh"
            description="HelloFresh delivers fresh, seasonal ingredients and delicious recipes to your door every week, so you can cook wholesome and satisfying meals everyone will love. T's&C's apply."
        >
            <Padding top={5}>
                <SolidButton
                    color="primary"
                    type="anchor"
                    target="_blank"
                    href="https://analytics.nexigen.digital/redirect?mtm_source=ccp&mtm_campaign=hello-fresh-au&mtm_medium=viprewards&siteId=8&redirect=https://www.hellofresh.com.au/pages/inspiration-delivered?c=VENTRAIP"
                >
                    Claim Now
                </SolidButton>
            </Padding>
        </RewardCard.Body>
    </RewardCard>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
