/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useIsMutating } from '@tanstack/react-query';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import {
    RenderCardExpiryFields,
    RenderField,
    creditcardValidation,
    isCVVValid,
    peopleNameFieldValidation,
    renderButton,
    renderCVVNumberField,
    renderCardNumberField,
    renderCheckboxField,
    requiredFieldValidation
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { PAY_INVOICE } from 'containers/billing/queries/invoice';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let CreditCardInvoiceForm = (props) => {
    const {
        handleSubmit,
        /**
         * Redux Props
         */
        pristine,
        submitting,
        valid
    } = props;

    /***** QUERIES *****/
    const isPayingAnInvoice = useIsMutating({ mutationKey: [PAY_INVOICE] }) > 0;

    /***** RENDER *****/
    return (
        <form className="OverlayInvoice__form" onSubmit={handleSubmit}>
            <div className="form__content">
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            name={CREDIT_CARD_FIELD_DATA.CARD_HOLDER_NAME.name}
                            autoComplete={CREDIT_CARD_FIELD_DATA.CARD_HOLDER_NAME.autoComplete}
                            component={RenderField}
                            disabled={isPayingAnInvoice}
                            type="text"
                            label="Card Holder Name"
                            validate={[requiredFieldValidation, peopleNameFieldValidation]}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            name={CREDIT_CARD_FIELD_DATA.CARD_NUMBER.name}
                            autoComplete={CREDIT_CARD_FIELD_DATA.CARD_NUMBER.autoComplete}
                            component={renderCardNumberField}
                            disabled={isPayingAnInvoice}
                            type="text"
                            label="Card Number"
                            validate={[requiredFieldValidation, creditcardValidation]}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column half">
                        <RenderCardExpiryFields parent={this} disabled={isPayingAnInvoice} />
                    </div>
                    <div className="form__column half">
                        <Field
                            name={CREDIT_CARD_FIELD_DATA.CARD_CVV.name}
                            autoComplete={CREDIT_CARD_FIELD_DATA.CARD_CVV.autoComplete}
                            component={renderCVVNumberField}
                            disabled={isPayingAnInvoice}
                            type="text"
                            label="CVV"
                            validate={[requiredFieldValidation, isCVVValid]}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            name="save_details"
                            disabled={isPayingAnInvoice}
                            type="checkbox"
                            component={renderCheckboxField}
                            label="Save for future payments"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        {renderButton(pristine, submitting, valid && !isPayingAnInvoice, isPayingAnInvoice ? <RequestLoader /> : 'Confirm and Pay')}
                    </div>
                </div>
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CreditCardInvoiceForm = reduxForm({
    form: 'creditcardinvoiceform',
    enableReinitialize: true
})(CreditCardInvoiceForm);

export default CreditCardInvoiceForm;
