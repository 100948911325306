/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_colourSelectCircle.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ColourSelectCircleNamespace } from 'components/ColourSelectCircle/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ColourSelectCircle: React.FC<ColourSelectCircleNamespace.Props> = ({ colour, onSelect, selected, borderColor }) => (
    <button
        type="button"
        onClick={() => onSelect(colour)}
        className={classNames('ColourSelectCircle', {
            'ColourSelectCircle--selected': selected
        })}
        style={{ backgroundColor: colour, borderColor }}
    >
        <NXSquare ratio={1} />
    </button>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
