/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useInterval } from 'usehooks-ts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getGoogleMapsAPIKey } from 'utilities/methods/getGoogleMapsAPIKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    onChange: (value: string) => void;
    children: (props: { autocompleteInputRef: React.RefObject<HTMLInputElement | null> }) => React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const MapsApiWrapper: React.FC<Props> = ({ children, onChange }) => {
    /***** STATE *****/
    const [isGoogleAvailable, setIsGoogleAvailable] = useState<boolean>(false);

    /***** HOOKS *****/
    useInterval(
        () => {
            if (typeof google !== 'undefined') {
                setIsGoogleAvailable(true);
            }
        },
        isGoogleAvailable ? null : 1000
    );

    const autocompleteInputRef = useRef<HTMLInputElement | null>(null);
    const autocomplete = useRef<any>();

    /***** FUNCTIONS *****/
    const handlePlaceChanged = () => {
        const place = autocomplete.current?.getPlace();

        if (place?.geometry && place?.formatted_address) {
            onChange(place.formatted_address);
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        if (!isGoogleAvailable || !autocompleteInputRef.current) return;

        autocomplete.current = new google.maps.places.Autocomplete(autocompleteInputRef.current, {
            types: ['geocode'],
            componentRestrictions: { country: ['au', 'nz'] }
        });

        autocomplete.current.addListener('place_changed', handlePlaceChanged);

        return () => {
            autocomplete.current?.removeListener?.('place_changed', handlePlaceChanged);
        };
    }, [isGoogleAvailable]);

    /***** RENDER *****/
    return (
        <>
            <Helmet>
                <script async src={`https://maps.googleapis.com/maps/api/js?key=${getGoogleMapsAPIKey()}&libraries=places`} />
            </Helmet>
            {children({ autocompleteInputRef })}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
