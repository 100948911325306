/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaGroupFieldGroupReduxForm } from 'components/Form/KatanaGroupField/groupReduxForm';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaGroupFormFieldGroup: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name = '', type, properties = [] } = property;

    /***** RENDER *****/
    return <Field name={key} label={name} component={KatanaGroupFieldGroupReduxForm} properties={properties} type={type} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
