/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ValidationMessage } from 'components/Form/ValidationMessage';
import MultiFieldArrayChild from 'containers/katana/containers/contentEditorModules/multiFieldArrayInput/multiFieldArrayChild';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { MultiFieldArrayInputNamespace } from 'containers/katana/containers/contentEditorModules/multiFieldArrayInput/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const MultiFieldArrayInput: React.FC<MultiFieldArrayInputNamespace.Props> = ({ fieldsContent, input, meta, ...props }) => {
    const { value, onChange, name } = input;

    /***** RENDER *****/
    return (
        <FormItem name={name}>
            {fieldsContent.map((field) => (
                <MultiFieldArrayChild key={name + field.key} {...{ field, value, onChange, name, ...props }} />
            ))}
            <ValidationMessage.ReduxForm.Default {...meta} />
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default MultiFieldArrayInput;
