/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _ActiveRadioRender as ActiveRadioRender } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/_ActiveRadioRender';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ActiveRadioRenderNamespace } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ActiveRadioRenderReduxFormDefault: React.FC<ActiveRadioRenderNamespace.ReduxForm.Default.Props> = (props) => {
    const { input, label, meta, disabled, children, onSelect, className, fullWidth } = props;
    const { value, onChange, name } = input;

    /***** FUNCTIONS *****/
    function handleChange(v: string) {
        onSelect?.(v);
        onChange(v);
    }

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={className}>
            <FormLabel htmlFor={name}>{label}</FormLabel>

            <FormItemInner meta={meta} noBackground fullWidth={fullWidth}>
                <ActiveRadioRender value={value} onChange={handleChange}>
                    {children}
                </ActiveRadioRender>
            </FormItemInner>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
