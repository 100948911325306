/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import NXCard from 'components/NXCard';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ContentOptionCard } from 'containers/katana/components/contentOptionCard';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useAddKatanaSiteSectionMutation } from 'containers/katana/queries/useAddKatanaSiteSectionMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDefaultValuesFromProperties } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/methods';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ContentOptionCardNamespace } from 'containers/katana/components/contentOptionCard/types';
const { OVERVIEW } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _AddSectionContentOptionsCard: React.FC<ContentOptionCardNamespace.Props> = (props) => {
    const { sectionDefinitionID } = props;
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL(id);
    const destination = getKatanaDestination(OVERVIEW);

    /***** QUERIES *****/
    const { mutateAsync: mutateAsyncAddSection } = useAddKatanaSiteSectionMutation({ serviceID: id, katanaSectionID: sectionDefinitionID });

    /***** FUNCTIONS *****/
    async function handleMutateAddSection() {
        const sectionDefinitionData = await katanaQuery.serviceID.meta.getSectionDefinitions.ensureQueryData(id);
        if (!sectionDefinitionData) {
            return;
        }
        const sectionDefinitionPropertiesData = sectionDefinitionData.data?.[sectionDefinitionID]?.properties ?? [];
        const properties = getDefaultValuesFromProperties(sectionDefinitionPropertiesData);
        mutateAsyncAddSection({ attributes: { properties }, sectionDefinitionID });
    }

    /***** RENDER *****/
    return (
        <ContentOptionCard {...props}>
            <NXCard.Actions>
                <SolidButton to={destination} onClick={handleMutateAddSection}>
                    Add Section
                </SolidButton>
            </NXCard.Actions>
        </ContentOptionCard>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
