/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { TextArea } from 'components/Form/TextArea';

/*   ACTIONS
 *****************************************************/
import { RenderSelectField, renderButton, requiredFieldValidation } from 'utilities/methods/form';
import { getSLAClaimServices } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SLAClaimForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSLAClaimForm: false
        };
    }

    componentDidMount() {
        const { account_sla_claim_events_status, account_sla_claim_events_data } = this.props;

        this.setState({
            showSLAClaimForm: account_sla_claim_events_status === 'success' && account_sla_claim_events_data.length >= 0
        });
    }

    componentDidUpdate(prevProps) {
        const { selectedEvent } = this.props;

        if (selectedEvent && prevProps.selectedEvent !== selectedEvent) {
            store.dispatch(getSLAClaimServices(selectedEvent));
            // Options not generated. the affected service will be omitted from the request
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { pristine, submitting, valid, handleSubmit, selectedEvent, account_sla_claim_events_data, account_sla_claim_services_data } =
            this.props;
        const { showSLAClaimForm } = this.state;

        const eventsOptions =
            account_sla_claim_events_data?.map(({ attributes: { event_name }, id }) => ({
                label: event_name,
                value: id
            })) ?? [];

        const affectedServiceOptions =
            account_sla_claim_services_data?.map(({ id, attributes: { name } }) => ({
                label: name,
                value: id
            })) ?? [];

        const handleFormRender = () => {
            const hasNoEvents = eventsOptions.length === 0;

            if (showSLAClaimForm && !hasNoEvents) {
                return (
                    <form className="accountSLA__form" onSubmit={handleSubmit}>
                        <div className="form__row">
                            <div className="form__column form__column--half">
                                <Field
                                    label="Select Event"
                                    name="event_id"
                                    component={RenderSelectField}
                                    type="select"
                                    validate={[requiredFieldValidation]}
                                    className="form__dropdown accountSLA__form--event"
                                    options={eventsOptions}
                                />
                            </div>
                            <div className="form__column form__column--half">
                                <Field
                                    label="Affected Service"
                                    name="hosting_id"
                                    component={RenderSelectField}
                                    type="select"
                                    className="form__dropdown accountSLA__form--services"
                                    readOnly={!selectedEvent}
                                    options={affectedServiceOptions}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__column form__column--full">
                                <Field
                                    label="Comments"
                                    name="comments"
                                    component={TextArea.ReduxForm}
                                    type="message"
                                    placeholder=""
                                    validate={[requiredFieldValidation]}
                                    className="form__textfield"
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__column full">{renderButton(pristine, submitting, valid, 'Submit SLA Claim')}</div>
                        </div>
                    </form>
                );
            }

            return (
                <div className="accountSLA__form--disabled">
                    <div className="noEvent__text">
                        There are currently no SLA Credit Claim events available, please check back later.
                        <Link to="/support/support-centre/accounts/making-a-claim-under-sla" className="noEvent__button">
                            {' '}
                            Learn more
                        </Link>
                    </div>
                </div>
            );
        };

        return handleFormRender();
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
SLAClaimForm = reduxForm({
    form: 'slaClaimForm'
})(SLAClaimForm);

const mapStateToProps = (state, props) => {
    const selector = formValueSelector('slaClaimForm');
    const selectedEvent = selector(state, 'event_id');

    return {
        form: 'slaClaimForm',
        account_sla_claim_events_status: state.account.account_sla_claim_events_status,
        account_sla_claim_events_data: state.account.account_sla_claim_events_data,
        account_sla_claim_services_data: state.account.account_sla_claim_services_data,
        selectedEvent
    };
};

SLAClaimForm = connect(mapStateToProps, null)(SLAClaimForm);

export default withRouter(SLAClaimForm);
