/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ValidationRuleTypesValues } from 'containers/katana/formFields/types';
import type { KatanaNamespace } from 'containers/katana/types';

export function getValidationValue(
    validations: KatanaNamespace.SectionDefinitions.ValidationsWithTypedNames[] = [],
    name: ValidationRuleTypesValues
) {
    const validation = validations.find(({ name: validationName }) => validationName === name);

    if (!validation) {
        return null;
    }

    const { value } = validation;

    return value;
}
