/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { hostingRenewPromoBillingCycles } from 'config/config';
import { useDispatch } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BillingCycleForm from '../../forms/billinCycleForm';
import RenewForm from '../../forms/renewForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { Flex } from 'components/Utils/Flex';
import NXBox from 'components/NXBox';
import NXBoxDefaultPadding from 'components/NXBox/DefaultPadding';

/**********************************************************************************************************
 *   HOOKS
 **********************************************************************************************************/
import { useDiscountRegeneration } from './hooks';
import { useUrlDropdown } from 'components/NXBox/Dropdown/hooks/useUrlDropdown';

/*   ACTIONS
 *****************************************************/
import { prepayHostingService, updateBillingCycle } from 'containers/hosting/state/accountActions';
import { activePromotion } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type BillingModuleDropdownProps = {
    setShowInvoiceLightbox: React.Dispatch<React.SetStateAction<boolean>>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function BillingModuleDropdown({ setShowInvoiceLightbox }: BillingModuleDropdownProps) {
    /***** HOOKS *****/
    const dispatch = useDispatch();
    const { id: serviceId }: { id: string } = useParams();
    const { invoice, regenerateWithDiscountOptions } = useDiscountRegeneration();
    const { isDropdownOpen: isRenewDropDownOpen, closeDropdown: closeRenewDropDown } = useUrlDropdown(
        `/my-services/hosting/account/renew/${serviceId}`,
        {
            type: 'renew',
            open: true
        }
    );

    const { isDropdownOpen: isBillingDropDownOpen, closeDropdown: closeBillingDropDown } = useUrlDropdown(
        `/my-services/hosting/account/renew/${serviceId}`,
        {
            type: 'billing',
            open: true
        }
    );

    /***** SELECTORS *****/
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);
    const hosting_billingcycle_data = useSelector((state: any) => state.hosting.hosting_billingcycle_data);
    const hosting_prepay_info_data = useSelector((state: any) => state.hosting.hosting_prepay_info_data);

    const { prices } = hosting_prepay_info_data ?? {};

    const { billing_cycles } = hosting_billingcycle_data?.attributes ?? {};
    const billingOptions = billing_cycles?.map((item: any) => ({
        label: `${item.name.name} - $${item.amount} AUD${
            hosting_information_data && hosting_information_data.attributes.billing_cycle_name === item.name.name ? ' (Same As Current)' : ''
        }`,
        value: item.id
    }));

    /***** FUNCTIONS *****/
    function submitBillingRequest(newBillingCycleID) {
        const { newBillingCycle } = newBillingCycleID;
        const attributes = {
            billing_cycle_id: newBillingCycle
        };
        updateBillingCycle(serviceId, attributes, invoice?.id)(dispatch);
    }

    function submitPrepayRequest(billingCycle) {
        const { cycle, ...rest } = billingCycle;
        const attributes = { billing_cycle: cycle, ...rest };
        function successCallback() {
            setShowInvoiceLightbox(true);
        }

        prepayHostingService(serviceId, attributes, successCallback)(dispatch);
    }

    function closeDropdowns() {
        closeBillingDropDown();
        closeRenewDropDown();
    }

    /***** RENDER HELPERS*****/
    function renderProcessOptions() {
        if (!prices) return [];

        const { billing_cycle_name } = hosting_information_data?.attributes ?? {};

        /**
         * Whenever there is a promotion that requires invoice regeneration insert the promotion's config key into this so that it verifies it during the promotion period
         * i.e.: `activePromotion('mega_may_2024') && invoice?.id && !!this.options.length`
         */
        const optionsToDisplay = activePromotion()
            ? Object.entries(prices).filter(([key]) => hostingRenewPromoBillingCycles[key])
            : Object.entries(prices);

        return optionsToDisplay.map(([key, value]) => {
            const { duration, total, sub_total: subtotal, discount_available: isDiscount } = value;
            const currentBillingCycle = `${key === billing_cycle_name ? ' (Current Billing Cycle)' : ''}`;
            return {
                label: isDiscount ? (
                    <Flex items="baseline" gap={2}>
                        <Text primary>{`${duration} - $${total} ${currentBillingCycle} `}</Text>
                        <del>${subtotal}</del>
                    </Flex>
                ) : (
                    <Text>{`${duration} - $${total} ${currentBillingCycle} `}</Text>
                ),
                value: key
            };
        });
    }

    return (
        <>
            <NXBox.Dropdown isOpen={isBillingDropDownOpen || isRenewDropDownOpen}>
                <NXBoxDefaultPadding>
                    {isBillingDropDownOpen && (
                        <>
                            <Flex justify="between" items="baseline">
                                <Padding bottom={5}>
                                    <Text secondary bold size--l>
                                        Change Billing Cycle
                                    </Text>
                                </Padding>
                                <NXBox.Dropdown.CloseButton onClose={closeDropdowns} />
                            </Flex>
                            <BillingCycleForm onSubmit={submitBillingRequest} options={billingOptions} />
                        </>
                    )}

                    {isRenewDropDownOpen && (
                        <>
                            <Flex justify="between" items="baseline">
                                <Padding bottom={5}>
                                    <Text secondary bold size--l>
                                        Renew For
                                    </Text>
                                </Padding>
                                <NXBox.Dropdown.CloseButton onClose={closeDropdowns} />
                            </Flex>
                            <RenewForm
                                hostingid={serviceId}
                                // If we're showing the form while there's an unpaid invoice, then that means the customer must be regenerating the invoice to have a discount applied, so we use only the billing cycles with available discounts
                                options={
                                    invoice?.id
                                        ? renderProcessOptions().filter(({ value }) => regenerateWithDiscountOptions.includes(value))
                                        : renderProcessOptions()
                                }
                                prices={prices}
                                submitPrepayRequest={submitPrepayRequest}
                            />
                        </>
                    )}
                </NXBoxDefaultPadding>
            </NXBox.Dropdown>
        </>
    );
}
