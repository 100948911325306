/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import _ from 'lodash';
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { Shimmer } from 'components/Shimmer';
import { _Text as Text } from 'components/Utils/Text/_Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__Skeleton.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { TextNamespace } from 'components/Utils/Text/_Text/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _TextSkeleton: React.FC<TextNamespace.Skeleton.Props> = ({ isLoading, width, children, ...textProps }) => {
    /***** HOOKS *****/
    const randomWidthRef = useRef(_.random(10, 100));

    /***** RENDER HELPERS *****/
    const _width = width === 'random' ? `${randomWidthRef.current}%` : width;

    const appliedStylingClasses = createAppliedStylingClasses({
        props: textProps,
        keyBoundary: [
            'size--xs',
            'size--xss',
            'size--s',
            'size--m',
            'size--xm',
            'size--l',
            'size--xl',
            'size--xxl',
            'size--h2',
            'size--xxxl',
            'lead--1',
            'lead--xs',
            'lead--s',
            'lead--l'
        ],
        componentName: 'TextSkeleton',
        delimiter: '--'
    });

    /***** RENDER *****/
    if (isLoading) {
        return <Shimmer className={classNames('TextSkeleton', appliedStylingClasses)} style={{ width: _width }} />;
    }
    return <Text {...textProps}>{children}</Text>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
