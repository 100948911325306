/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { QueryOptions } from '@tanstack/react-query';
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as AccountAPI } from 'utilities/api/account';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MINUTE } from 'utilities/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseSubscriptionsQueryOptions = Omit<typeof createBaseQueryOptions, 'queryKey' | 'queryFn'>;
type TData = ReturnType<typeof createBaseQueryOptions> extends QueryOptions<infer TData> ? TData : never;

/**********************************************************************************************************
 *   QUERY
 **********************************************************************************************************/
const createQueryKey = () => createBaseQueryKey(['account', 'subscriptions']);
const createBaseQueryOptions = () =>
    queryOptions({
        queryKey: createQueryKey(),
        staleTime: MINUTE * 5,
        queryFn: AccountAPI.account.GET.subscription,
        select: ({ data }) => data?.data
    });

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useSubscriptionsQuery = (options?: UseSubscriptionsQueryOptions) => {
    /***** QUERIES *****/
    return useQuery({
        ...createBaseQueryOptions(),
        ...options
    });
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/***** FUNCTIONS *****/
const invalidate = () => {
    return queryClient.invalidateQueries({ queryKey: createQueryKey() });
};

const optimistic = (updater: (old: TData) => TData) => {
    return queryClient.setQueryData(createQueryKey(), updater);
};

/***** EXPORTS *****/
export const useSubscriptionsQuery = Object.assign(_useSubscriptionsQuery, {
    createQueryKey,
    queryOptions,
    invalidate,
    optimistic
});
