/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useRef } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXDropZone } from 'components/Form/NXDropZone';
import { useStateValueHasFile } from 'components/Form/NXDropZone/hooks/useStateValueHasFile';
import ImageSelectControl from 'components/ImageSelectControl';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { imageAcceptsTypes } from 'components/Form/NXDropZone/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A component that renders a drop zone for file uploads with an image control.
 */
export const NXDropZoneWithImageControl: React.FC<NXDropZoneNamespace.Variants.WithImageControl.Props> = ({
    input,
    label,
    meta,
    imageControlProps,
    accept = imageAcceptsTypes,
    singleFile = false
}) => {
    const { name, value, onChange } = input;

    /***** HOOKS *****/
    const zoneRef = useRef<DropzoneRef | null | undefined>();
    const [hasFile, setHasFile] = useStateValueHasFile(value);

    /***** FUNCTIONS *****/
    function handleOnDrop() {
        setHasFile(false);
    }

    /***** RENDER *****/
    return (
        <>
            <Field
                name={name}
                label={label}
                component={NXDropZone.ReduxForm}
                zoneRef={zoneRef}
                className={classNames('NXDropZoneWithImageControl', { hidden: hasFile })}
                onDropZoneDrop={handleOnDrop}
                type="dropzone"
                accept={accept}
                singleFile={singleFile}
            />
            <ImageSelectControl
                imageURL={value}
                className={classNames('NXDropZoneWithImageControl', { hidden: !hasFile })}
                onClickUploadNew={() => {
                    zoneRef?.current?.open?.();
                }}
                onClickRemove={() => {
                    onChange(singleFile ? null : []);
                }}
                {...imageControlProps}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
