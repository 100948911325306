/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { keys } from 'lodash';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import RecordHealth from 'components/DNS/RecordHealth';
import Grid from 'components/Grid';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';
import SimpleTable from 'components/SimpleTable';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
import './_KatanaDNSModule.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaDNSModule = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();
    const history = useHistory();

    /***** QUERIES *****/
    const {
        data: get_dns_data,
        isLoading: isGetDNSLoading,
        isError: isSuccess,
        isFetching: isGetDNSFetching
    } = katanaQuery.serviceID.getDNS.useQuery(id);

    /***** RENDER HELPERS *****/
    const renderSimpleTables = () => {
        if (isGetDNSLoading) {
            return Array.from({ length: 2 }, (_, index) => (
                <>
                    <RecordHealth configured={false} />
                    <SimpleTable
                        key={index}
                        rows={Array.from({ length: 4 }, (_, index) => (
                            <Grid key={index} columns="1fr 8fr">
                                <Text.Skeleton isLoading size--s />
                                <Text.Skeleton isLoading size--s width="random" />
                            </Grid>
                        ))}
                    />
                </>
            ));
        }

        if (!get_dns_data?.required_dns_records?.length) return [];

        return get_dns_data.required_dns_records.map((record, index) => {
            const recordKeys = keys(record).filter((key) => key !== 'is_configured');
            const rows = recordKeys.map((key) => (
                <Grid key={key} columns="1fr 8fr">
                    <Text.TableContent capitalize={key !== 'ttl'} uppercase={key === 'ttl'}>
                        {key}
                    </Text.TableContent>
                    <Text.TableContent>{record[key]}</Text.TableContent>
                </Grid>
            ));

            const isConfigured = !!record.is_configured;
            return (
                <>
                    <WrapWithComponent wrap={!isConfigured} component={Flex} fullWidth justify="between" className="KatanaDNSModule__recordHealth">
                        <RecordHealth configured={isGetDNSFetching ? 'loading' : isConfigured} />
                        {!isConfigured && (
                            <SolidButton
                                type="onClick"
                                size="small"
                                color="info"
                                width="auto"
                                onClick={() => history.push(getKatanaDestination(katanaServicePages.EDITOR.FIX_DNS))}
                            >
                                Fix DNS
                            </SolidButton>
                        )}
                    </WrapWithComponent>
                    <SimpleTable key={JSON.stringify(record) + index} rows={rows} />
                </>
            );
        });
    };

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={isSuccess}>
            <NXBox.TopAccordion
                title="VIPsites DNS Details"
                description="The following details are used when connecting your domain name to your VIPsites service."
            >
                <NXBox.DefaultPadding>
                    <Flex direction="column" gap={1}>
                        <NXBox.SubHeading>Required Records</NXBox.SubHeading>

                        <Flex direction="column" gap={2} items="start">
                            {renderSimpleTables()}
                        </Flex>
                    </Flex>
                </NXBox.DefaultPadding>
            </NXBox.TopAccordion>
        </ScrollableComponent>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaDNSModule;
