/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _ColourSelect as ColourSelect } from 'components/Form/ColourSelect/_ColourSelect';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ColourSelectNamespace } from 'components/Form/ColourSelect/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ColourSelectReduxFormDefault: React.FC<ColourSelectNamespace.ReduxForm.Props> = ({ input, options, preventDeselect }) => {
    const { value, onChange } = input;

    /***** RENDER *****/
    return <ColourSelect value={value} onChange={onChange} options={options} preventDeselect={preventDeselect} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
