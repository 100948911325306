/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _WebsiteInformationContent as WebsiteInformationContent } from 'containers/katana/modules/informationAndAppearance/_WebsiteInformationContent';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WebsiteInformationCondensedModule = () => {
    /***** RENDER *****/
    return (
        <NXBox.TopAccordion title="Website Information">
            <WebsiteInformationContent />
        </NXBox.TopAccordion>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
