/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _TextArea as TextArea } from 'components/Form/TextArea/_TextArea';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXTextAreaNamespace } from 'components/Form/TextArea/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * NXTextArea ReduxForm Wrapper
 */
export const _TextAreaReduxFormDefault: React.FC<NXTextAreaNamespace.ReduxForm.Props> = (props) => {
    const { input, meta, label, disabled, rows, isPlaceholderItalic, placeholder, className, intrinsicProps } = props;
    const { name, value, onChange, onBlur } = input;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name} className={className}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta}>
                <TextArea
                    disabled={disabled}
                    value={value}
                    name={name}
                    rows={rows}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    intrinsicProps={{ ...intrinsicProps, onChange, onBlur }}
                />
            </FormItemInner>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
