/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { cloneDeep, has, mergeWith } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleRecursiveAttributesCaveatController } from 'containers/katana/queries/methods/handleRecursiveAttributesCaveatController';
import { mergeWithAttributeObjectPicker } from 'containers/katana/queries/methods/mergeWithAttributeObjectPicker';
import { recursivelyTransformPropertyAttachmentData } from 'containers/katana/queries/methods/recursivelyTransformPropertyAttachmentData';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {{
 *      serviceID: number;
 *      katanaSectionID?: import('utilities/api/katana/types').KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.SectionKeys;
 * }} params
 */
export function useHandleDynamicSectionMethods({ serviceID, katanaSectionID }) {
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(serviceID);
    const sectionDefinitionData = get_katana_section_definitions_data?.[katanaSectionID];

    /**
     * @param {import('utilities/api/katana/types').KatanaGetSectionAttributes} attributes
     */
    function handleModifyEntireAttributeObject(attributes) {
        if (!has(attributes, 'properties')) {
            return attributes;
        }

        const caveatsModifiedAttributes = handleRecursiveAttributesCaveatController({ sectionDefinitionData, attributes });
        // Convert the values so that we're only sending the attachment id
        const finalPropertiesObject = recursivelyTransformPropertyAttachmentData({
            sectionDefinitionData,
            properties: attributes.properties
        });
        const updatedAttributes = mergeWith(
            cloneDeep(caveatsModifiedAttributes),
            { properties: finalPropertiesObject },
            mergeWithAttributeObjectPicker
        );

        return updatedAttributes;
    }

    return {
        handleModifyEntireAttributeObject
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
