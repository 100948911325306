/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import { CardSelectGrid } from 'components/Form/CardSelectGrid';
import BackgroundColour from 'components/Utils/BackgroundColour';
import { NXSquare } from 'components/Utils/NXSquare';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { getColourStyleSelectContent } from 'containers/katana/containers/ContentEditorLightbox/methods/getColourStyleSelectContent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import './_square_grid.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaChoiceFormFieldSquareGrid: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, options, validationFunctions, caveats } = property;

    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const colourStyleSelectTilesPerRow = useGetCaveat(CaveatTypes.COLOUR_STYLE_SELECT_TILES, caveats) as number | undefined;
    const itemsPerRow = useGetCaveat(CaveatTypes.ITEMS_PER_ROW, caveats) as number | undefined;

    const newOptions = useMemo(() => {
        if (!options) {
            return [];
        }

        if (colourStyleSelectTilesPerRow) {
            return options.map((option) => {
                const { key, value } = option;

                const customOption: CardSelectGridNamespace.SelectCard.CustomOption = {
                    type: 'custom',
                    content: getColourStyleSelectContent(key),
                    value: key,
                    selectionText: value
                };
                return customOption;
            });
        }

        return options.map((option) => {
            const { key, value, icon } = option;
            if (icon) {
                const imageOption: CardSelectGridNamespace.SelectCard.CustomOption = {
                    type: 'custom',
                    content: (
                        <BackgroundColour light-gray>
                            <NXSquare ratio={0.58}>
                                <BackgroundImage imageURL={icon} contain fill />
                            </NXSquare>
                        </BackgroundColour>
                    ),
                    selectionText: value,
                    value: key
                };
                return imageOption;
            }

            return { label: value, value: key };
        });
    }, [options]);

    const itemsPerRowValue = itemsPerRow || colourStyleSelectTilesPerRow || 4;
    const finalItemsPerRow = isMobile ? Math.ceil(itemsPerRowValue / 2) : itemsPerRowValue;

    /***** RENDER *****/
    return (
        <Field
            name={key}
            label={name}
            description={
                Boolean(colourStyleSelectTilesPerRow) && (
                    <Text secondary size--s>
                        Choose your colour theming. <Text italic>Colour Contrast</Text> is the primary colour.
                    </Text>
                )
            }
            component={CardSelectGrid.ReduxForm}
            alignItems="stretch"
            textAlign="left"
            itemsPerRow={finalItemsPerRow}
            options={newOptions}
            validate={validationFunctions}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
