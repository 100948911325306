/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { cloneDeep, mergeWith, pick } from 'lodash';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { mergeWithAttributeObjectPicker } from 'containers/katana/queries/methods/mergeWithAttributeObjectPicker';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { KATANA_API } from 'utilities/api/katana';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**
 * @param {KatanaQuery.ServiceID.GetSectionSitePreview.QueryParams} params
 */
function createQueryKey({ serviceID, sectionID, ...attributes }) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'service', serviceID, 'preview', 'site-section', sectionID, attributes]));
}

/**
 * @param {number} serviceID
 */
function invalidateAllQueries(serviceID) {
    queryClient.invalidateQueries({
        queryKey: createBaseQueryKey(/** @type {const} */ (['katana', 'service', serviceID, 'preview', 'site-section']))
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {KatanaQuery.ServiceID.GetSectionSitePreview.QueryParams} params
 */
function _useQuery(params) {
    const { serviceID, katanaSectionID } = params;
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID, katanaSectionID });
    return useQuery(
        queryOptions({
            queryKey: createQueryKey(params),
            queryFn: ({ signal }) => {
                const attributes = pick(params, ['style', 'properties']);
                const updatedAttributes = handleModifyEntireAttributeObject(attributes);
                const updatedParams = mergeWith(cloneDeep(params), updatedAttributes, mergeWithAttributeObjectPicker);

                return KATANA_API.katana.service_id.preview.section.section_id.POST(updatedParams, signal);
            },
            enabled: TruthKeys(params),
            select: (data) => {
                if (typeof data === 'string') {
                    return data;
                }
            }
        })
    );
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSectionSitePreview = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    invalidateAllQueries
});
