/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ColourSelectCircle } from 'components/ColourSelectCircle';
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    colour: string;
    setColour: React.Dispatch<string>;
    closeColourSelector: () => void;
    preventClickRef: React.MutableRefObject<HTMLElement | null>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const PresetCustomiserColourSelect: React.FC<Props> = ({ colour, setColour, closeColourSelector, preventClickRef }) => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const colourSelectRef = useRef<HTMLDivElement>(null);

    /***** QUERIES *****/
    const { data: get_colours_meta_data } = katanaQuery.serviceID.meta.getColours.useQuery(id);

    useClickAway([colourSelectRef, preventClickRef], closeColourSelector);

    /***** RENDER HELPERS *****/
    const colourOptions = _.values(get_colours_meta_data).map(({ id, hex_code }) => ({ id, hex_code }));

    /***** RENDER *****/
    return (
        <Grid columns="repeat(10, 1fr)" className="PresetCustomiserColourSelect" inject gap={1}>
            <div ref={colourSelectRef}>
                {colourOptions.map(({ id, hex_code }) => (
                    <ColourSelectCircle
                        key={id}
                        colour={hex_code}
                        selected={colour === id}
                        onSelect={() => {
                            setColour(id);
                        }}
                    />
                ))}
            </div>
        </Grid>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
