import NXPicturedIframeSourceDocPreview from 'components/PicturedIframeSourceDocPreview';
import { keys, set } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { contentEditorPictureInPictureState } from 'containers/katana/containers/ContentEditorLightbox/consts';
import { useActiveFormDynamicDebounceTimer } from 'containers/katana/containers/ContentEditorLightbox/hooks/useActiveFormDynamicDebounceTimer';
import { useActiveValidFormValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useActiveValidFormValues';
import { useContentEditorPictureInPictureStateManager } from 'containers/katana/containers/ContentEditorLightbox/hooks/useContentEditorPictureInPictureStateManager';
import { useReduxOrHookFormData } from 'containers/katana/containers/ContentEditorLightbox/hooks/useReduxOrHookFormData';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useTransformedActiveFormValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useTransformedActiveFormValues';
import { useGetContentEditorSiteSectionPreviewQuery } from 'containers/katana/containers/ContentEditorLightbox/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import useDebounce from 'utilities/hooks/useDebounce';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    isOpen: boolean;
    leftAnchorElement?: HTMLElement | null;
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const ContentEditorPicturePreview: React.FC<Props> = ({ isOpen, leftAnchorElement }) => {
    /***** STATE *****/
    const [displayingPreview, setDisplayingPreview] = useState('');

    /***** HOOKS *****/
    const { childpage, subpage, id } = useSetupEditorRouteParams();
    const { siteData } = useSiteDataPreviewValues();

    /***** QUERIES *****/
    const {
        data: get_section_preview_data,
        isLoading: isGetSectionPreviewLoading,
        isError: isGetSectionPreviewError
    } = useGetContentEditorSiteSectionPreviewQuery(isOpen, siteData);

    // Section Preview data or Site Preview data gets determined here
    const formData = useReduxOrHookFormData();
    const finalFormValues = useActiveValidFormValues(formData.values, formData.errors);
    const transformedActiveFormValues = useTransformedActiveFormValues(finalFormValues, subpage);
    const isGetSectionDefinitionsFetching = katanaQuery.serviceID.meta.getSectionDefinitions.useIsFetching(id);
    const isGetSectionsFetching = katanaQuery.serviceID.getSections.useIsFetching(id);

    const [finalQueryArguments, setFinalQueryArguments] = useState({ serviceID: id });

    const sitePreviewData = useMemo(() => {
        const previewData = keys(transformedActiveFormValues).length ? { serviceID: id, attributes: transformedActiveFormValues } : { serviceID: id };

        if (keys(siteData).length) {
            set(previewData, 'attributes.site', siteData);
        }
        return previewData;
    }, [transformedActiveFormValues, id, siteData]);

    const dynamicDebounceValue = useActiveFormDynamicDebounceTimer(transformedActiveFormValues);

    useDebounce(
        () => {
            setFinalQueryArguments(sitePreviewData);
        },
        dynamicDebounceValue,
        [sitePreviewData, transformedActiveFormValues]
    );

    const {
        data: get_site_preview_data,
        isLoading: isGetSitePreviewLoading,
        isError: isGetSitePreviewError
    } = katanaQuery.serviceID.getSitePreview.useQuery(finalQueryArguments);

    /***** RENDER HELPERS *****/
    const sectionEditorLoadingData = useMemo(
        () => [
            { condition: Boolean(isGetSectionDefinitionsFetching) || Boolean(isGetSectionsFetching), message: 'Loading section data...' },
            { condition: isGetSectionPreviewLoading, message: 'Fetching latest section preview...' }
        ],
        [isGetSectionDefinitionsFetching, isGetSectionsFetching, isGetSectionPreviewLoading]
    );
    const siteEditorLoadingData = useMemo(
        () => [{ condition: isGetSitePreviewLoading, message: 'Fetching latest site preview...' }],
        [isGetSitePreviewLoading]
    );

    /***** EFFECTS *****/
    useEffect(() => {
        if (childpage && get_section_preview_data) {
            setDisplayingPreview(get_section_preview_data);
        } else if (!childpage && get_site_preview_data) {
            setDisplayingPreview(get_site_preview_data);
        }
    }, [get_section_preview_data, get_site_preview_data, childpage]);

    useContentEditorPictureInPictureStateManager(isOpen);

    /***** RENDER *****/
    return (
        <NXPicturedIframeSourceDocPreview
            leftAnchorElement={leftAnchorElement}
            className="ContentEditorPicturePreview"
            stateStoreData={contentEditorPictureInPictureState}
            key={childpage ? 'childpage' : 'site'}
            loaderData={childpage ? sectionEditorLoadingData : siteEditorLoadingData}
            srcDoc={displayingPreview}
            isError={childpage ? isGetSectionPreviewError : isGetSitePreviewError}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
