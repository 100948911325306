/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MapsApiWrapper } from 'components/Form/SingleFieldAutoCompleteAddress/MapsApiWrapper';

/**********************************************************************************************************
 *   Types
 **********************************************************************************************************/
type Props = {
    name: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SingleFieldAutoCompleteAddress: React.FC<Props> = ({ placeholder, value, onChange, name }) => (
    <MapsApiWrapper onChange={onChange}>
        {({ autocompleteInputRef }) => (
            <Input
                name={name}
                value={value}
                inputRef={autocompleteInputRef}
                placeholder={placeholder}
                intrinsicProps={{
                    onChange: (e) => onChange(e.target.value)
                }}
            />
        )}
    </MapsApiWrapper>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
