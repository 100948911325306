/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_shimmer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Shimmer: React.FC<Props> = ({ children, className, style }) => (
    <div className={classNames('Shimmer', className)} style={style}>
        {children}
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
