import { useStore } from '@tanstack/react-store';
import { katanaHookFormData } from 'containers/katana/components/HookFormHandler/consts';
import { katanaSubPageEnums } from 'containers/katana/consts';
import { createContentEditorModulesFormName } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/createContentEditorModulesFormName';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import type { KatanaNamespace } from 'containers/katana/types';
import { useReduxForm } from 'utilities/hooks/useReduxForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { BASIC_DETAILS } = katanaSubPageEnums;
const hookFormSubPages: KatanaNamespace.SubPages[] = [BASIC_DETAILS];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useReduxOrHookFormData = () => {
    /***** HOOKS *****/
    const { subpage } = useSetupEditorRouteParams();

    const activeFormKey = createContentEditorModulesFormName(subpage);
    const reduxFormData = useReduxForm(activeFormKey);
    const hookFormData = useStore(katanaHookFormData);

    /***** HOOK RESULTS *****/
    if (subpage && hookFormSubPages.includes(subpage)) {
        return hookFormData;
    }

    return { values: reduxFormData?.values, errors: reduxFormData?.syncErrors };
};
