/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/hosting';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import NXBox from 'components/NXBox';
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export function Monarx() {
    /***** HOOKS *****/
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);

    /***** FUNCTIONS *****/
    const monarxLogin = async () => {
        const { closePopup, goToTargetUrl } = createPopup();
        try {
            const response = await API.hosting.GET.login.cpanel(hosting_information_data?.id, 'monarx_Home');
            const success = getDataFromSuccessResponse(response);
            goToTargetUrl(success.attributes.url);
        } catch (error) {
            pushNotification(getErrorFromFailResponse(error));
            closePopup();
        }
    };

    /***** RENDER *****/
    return (
        <NXBox>
            <NXBox.Top title="cPanel Monarx Security Login" description="Login to the cPanel Monarx Security plugin.">
                <SolidButton type="onClick" onClick={monarxLogin}>
                    Monarx Login
                </SolidButton>
            </NXBox.Top>
        </NXBox>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
