/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ContentEditorRenderModulesNamespace } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/types';

export const ContentEditorRenderModulesContext = createContext<ContentEditorRenderModulesNamespace.Context>({
    pristine: true,
    submitting: false,
    valid: false,
    anyTouched: false,
    form: null,
    submitButtonClickEvent: null
});
