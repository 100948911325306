// Mutations will go here
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import queryClient from 'store/queryClient';
import { API } from 'utilities/api/services';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   Cancel a service
 **********************************************************************************************************/
export function useCancelServiceMutation(serviceID, options = {}) {
    return useMutation({
        mutationKey: useCancelServiceMutation.mutationKey,
        mutationFn: (attributes) => API.service.post.cancel(serviceID, attributes),
        onSuccess: () => {
            const queryKey = [...createBaseQueryKey()];
            queryClient.resetQueries({
                queryKey,
                predicate: (query) => query.queryKey.includes(serviceID)
            });
        },
        ...options
    });
}
useCancelServiceMutation.mutationKey = ['useCancelServiceMutation'];
