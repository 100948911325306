/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { FieldArray } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RepeatedFieldArrayRenderer } from 'containers/katana/formFields/repeated/types';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
    component: RepeatedFieldArrayRenderer.Component;
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaRepeatedFormFieldWrapper: React.FC<Props> = ({ property, component }) => {
    const { key, name, validationFunctions } = property;

    /***** RENDER *****/
    return (
        <FormItem>
            <FormLabel htmlFor={name}>{name}</FormLabel>
            <FieldArray
                name={key}
                component={component}
                rerenderOnEveryChange
                property={property}
                properties={property.properties}
                validate={validationFunctions}
            />
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
