/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectNamespace } from 'components/Form/Select/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useActiveLabel(value: any, options: SelectNamespace.Option[]) {
    return useMemo(() => {
        const foundOption = options.find((option) => option.value === value);
        if (foundOption) {
            return foundOption.label;
        }
        return 'Please Select';
    }, [value, options]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
