/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { Select } from 'components/Form/Select';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaChoiceFormFieldSelect: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, options, validationFunctions } = property;

    /***** RENDER HELPERS *****/
    const newOptions = useMemo(() => options?.map((option) => ({ label: option.value, value: option.key })) ?? [], [options]);

    return <Field name={key} label={name} component={Select.ReduxForm} options={newOptions} validate={validationFunctions} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
