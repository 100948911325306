/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash';
import queryClient from 'store/queryClient';
import store from 'store/store';

/**********************************************************************************************************
 *  TYPES
 **********************************************************************************************************/
/**
 * @template T
 * @template G
 * @typedef { import('./types').TCreateQueryBoilerPlateOptions<T, G> } TCreateQueryBoilerPlateOptions
 */
/**
 * @template T
 * @template G
 * @typedef { import('./types').TCreateQueryBoilerPlateReturnType<T, G> } TCreateQueryBoilerPlateReturnType
 */
/**
 * @template G
 * @typedef { import('./types').GetDefaultReturnType<G> } GetDefaultReturnType
 */
/**
 * @template  T
 * @typedef { import('./types').DefaultOptions<T> } DefaultOptions
 */
/**
 * @template T
 * @typedef { import('@tanstack/react-query').Updater<T> } Updater
 */
/**
 * @template T
 * @typedef { typeof queryClient.setQueryData<T>} SetQueryData
 */

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**
 * Returns an object with default query options based on the provided defaultOptionsObject.
 * If an option is a function, it will be called with the optionsArguments and the result will be used as the value.
 * If an option is not a function, it will be used directly as the value.
 * @template G
 */
export function getDefaultQueryOptions(defaultOptionsObject, optionsArguments) {
    const defaultQueryOptions = Object.entries(defaultOptionsObject).reduce((acc, [key, option]) => {
        const optionType = typeof option;
        switch (optionType) {
            case 'function':
                acc[key] = option(optionsArguments);
                return acc;
            default:
                acc[key] = option;
                return acc;
        }
    }, {});

    return /** @type {GetDefaultReturnType<G>} */ (defaultQueryOptions);
}

function mapParams(param) {
    const paramType = typeof param;
    switch (paramType) {
        case 'string':
        case 'number':
        case 'boolean':
            return String(param);

        case 'object':
            if (Array.isArray(param)) {
                return param.map(mapParams).flat();
            }

            if (param instanceof Date) {
                return JSON.stringify(param);
            }

            if (param === null) {
                return null;
            }

            return Object.values(param);

        default:
            return JSON.stringify(param);
    }
}

export function baseQueryKey() {
    // Each query needs the account id in the key so that the cache is specific to each user
    const reduxState = store.getState();
    const accountId = reduxState.app.app_check_token_data?.account_id;
    return [String(accountId)];
}

/**
 * @deprecated use `queryOptions` instead see `katanaQuery` for an example
 * Creates a prefetch boilerplate object with utility functions for query handling.
 * @template {any} T
 * @template {any} G
 * @param {TCreateQueryBoilerPlateOptions<T, G>} boilerPlateOptions
 */

export function createQueryBoilerPlate(boilerPlateOptions) {
    const { service, serviceKey, APIRoute, defaultOptions } = boilerPlateOptions;

    /** @param {unknown[]} params */
    const createQueryKey = (...params) => {
        const serviceKeyResult = typeof serviceKey === 'function' ? serviceKey(...params) : serviceKey;
        const _serviceKey = isArray(serviceKeyResult) ? serviceKeyResult : [serviceKeyResult];
        return [
            ...baseQueryKey(),
            service,
            ..._serviceKey,
            ...params
                .map(mapParams)
                .flat()
                .filter((queryKeySegment) => queryKeySegment)
        ];
    };

    /** @param {T} params */
    const queryFn = (params) => APIRoute(params);

    /** @param {T} params */
    const invalidate = (params) => queryClient.invalidateQueries({ queryKey: createQueryKey(params) });

    const defaultQueryOptions = {
        queryFn,
        queryKey: createQueryKey,
        ...defaultOptions
    };

    /** @param {T} params */
    const getDefaults = (params) => /** @type {GetDefaultReturnType<G>} */ (getDefaultQueryOptions(defaultQueryOptions, params));

    /** @param {T} params */
    const prefetch = (params) => queryClient.prefetchQuery(getDefaults(params));

    /** @param {T} params */
    const getQueryData = (params) => queryClient.getQueryData(createQueryKey(params));

    /**
     * @param {T} params
     * @param {Updater<T>} updater
     */
    const setQueryData = (params, updater) => /** @type {SetQueryData<T>} */ (queryClient.setQueryData(createQueryKey(params), updater));

    /** @param {T} params */
    const resetQueries = (params) => queryClient.resetQueries({ queryKey: createQueryKey(params) });

    /** @param {T} params */
    const cancelQueries = (params) => queryClient.cancelQueries({ queryKey: createQueryKey(params), exact: true });

    /** @param {T} params */
    const fetchQuery = (params) => queryClient.fetchQuery({ queryKey: createQueryKey(params), queryFn: queryFn(params) });

    /**
     *
     * @param {T} params
     * @param {object} options
     * @returns {import('@tanstack/react-query').UseQueryResult< G['data'] | null >}
     */
    function useTypedQuery(params, options) {
        const optionDefaults = getDefaults(params);
        return useQuery({
            ...optionDefaults,
            ...options,
            queryFn: optionDefaults.queryFn
        });
    }

    return {
        createQueryKey,
        queryFn,
        getDefaults,
        invalidate,
        fetchQuery,
        prefetch,
        getQueryData,
        setQueryData,
        resetQueries,
        cancelQueries,
        useTypedQuery
    };
}
