/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _RadioCheckBoxPanel as RadioCheckBoxPanel } from 'components/Form/Radio/_CheckBoxCirclePanel';
import { _RadioCircle as RadioCircle } from 'components/Form/Radio/_Circle/_RadioCircle/_RadioCircle';
import { _ActiveRadioRender as ActiveRadioRender } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/_ActiveRadioRender';
import { _ActiveRadioRenderItem as ActiveRadioRenderItem } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/_ActiveRadioRenderItem';
import { _ActiveRadioRenderHookFormDefault as ActiveRadioRenderHookFormDefault } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/wrappers/hookForm/Default';
import { _ActiveRadioRenderReduxFormDefault as ActiveRadioRenderReduxFormDefault } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/wrappers/reduxForm/Default';
import { _RadioCircleButton as RadioCircleButton } from 'components/Form/Radio/_Circle/_RadioCircleButton/_RadioCircleButton';
import { _IdentifierMatch as IdentifierMatch } from 'components/Form/Radio/_IdentifierMatch';
import { _Radio } from 'components/Form/Radio/_Radio';
import { _RadioHookFormDefault as RadioHookFormDefault } from 'components/Form/Radio/wrappers/hookForm/Default';
import { _RadioReduxFormDefault as RadioReduxFormDefault } from 'components/Form/Radio/wrappers/reduxform/Default';

const Radio = Object.assign(_Radio, {
    ReduxForm: RadioReduxFormDefault,
    HookForm: RadioHookFormDefault,
    CheckBoxPanel: RadioCheckBoxPanel,
    IdentifierMatch,
    Circle: Object.assign(RadioCircle, {
        Button: Object.assign(RadioCircleButton, {
            ActiveRender: Object.assign(ActiveRadioRender, {
                ReduxForm: ActiveRadioRenderReduxFormDefault,
                HookForm: ActiveRadioRenderHookFormDefault,
                Item: ActiveRadioRenderItem
            })
        })
    })
});

export { Radio };
