/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import { TextArea } from 'components/Form/TextArea';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { renderButton, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { submitServerJob } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ServerJobsForm extends Component {
    render() {
        const { handleSubmit, pristine, submitting, valid, parent, vps_submit_job_status } = this.props;

        if (vps_submit_job_status === 'loading') {
            return (
                <div className="ComponentLoading">
                    <div className="ComponentLoading__wrapper">
                        <RequestLoader />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="VPS-ServerJobs-Popup box">
                    <div className="box__wrapper VPS-ServerJobs-Popup__wrapper">
                        <button
                            className="VPS-ServerJobs-Popup__close"
                            onClick={(e) => {
                                e.preventDefault();
                                parent.setState({
                                    ...parent.state,
                                    is_popup_open: false
                                });
                            }}
                        >
                            <PhosphorIcons.X />
                        </button>
                        <div className="VPS-ServerJobs-Popup__form">
                            <form className="form clerfix" onSubmit={handleSubmit}>
                                <div className="form__row">
                                    <div className="form__column form__column--full">
                                        <Field
                                            name="details"
                                            component={TextArea.ReduxForm}
                                            rows={12}
                                            label="Please explain the task that we will carry out:"
                                            validate={[requiredFieldValidation]}
                                        />
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__column form__column--full">{renderButton(pristine, submitting, valid, 'Submit')}</div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

ServerJobsForm = reduxForm({
    form: 'ServerJobForm',
    enableReinitialize: true
})(ServerJobsForm);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ServerJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_popup_open: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { vps_submit_job_status } = this.props;
        registerScrollEvents(this, vps_submit_job_status === 'success' && prevProps.vps_submit_job_status === 'loading');

        if (vps_submit_job_status === 'success' && prevProps.vps_submit_job_status === 'loading') {
            this.setState({
                is_popup_open: false
            });
        }
    }

    handleSubmit(values) {
        const { details } = values;
        const { vps_information_data, submitServerJob } = this.props;
        const { id } = vps_information_data;

        submitServerJob(id, details);
    }

    render() {
        const { handleSubmit } = this;
        const { is_popup_open } = this.state;
        const { vps_information_data, vps_submit_job_status } = this.props;

        if (vps_information_data) {
            const { attributes } = vps_information_data;
            const { vps_type } = attributes;

            if (vps_type === 'fully_managed' || vps_type === 'self_managed') {
                const desc =
                    vps_type === 'self_managed'
                        ? 'Need to get in touch with the experts? A member of our team is able to carry out jobs on your self-managed VPS with the purchase of a server management job.'
                        : 'Need to get in touch with the experts? A member of our team can carry out jobs on your VPS for you.';

                return (
                    <div
                        ref={(el) => {
                            this.scrollRef = el;
                        }}
                        className={`vps-box-wrapper VPS-ServerJobs${is_popup_open ? ' VPS-ServerJobs--open' : ''}`}
                    >
                        <Box
                            className="VPS-ServerJobs__box"
                            title="Server Jobs"
                            desc={desc}
                            premounted={true}
                            action={{
                                label: 'Submit New Job',
                                type: 'onClick',
                                className: '',
                                size: 'large',
                                onClick: (e) => {
                                    e.preventDefault();
                                    this.setState({
                                        is_popup_open: true
                                    });
                                }
                            }}
                        />
                        {is_popup_open && <ServerJobsForm vps_submit_job_status={vps_submit_job_status} parent={this} onSubmit={handleSubmit} />}
                    </div>
                );
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        vps_information_data: state.vps.vps_information_data,
        vps_submit_job_status: state.vps.vps_submit_job_status
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            submitServerJob
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServerJobs));
