/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/

import {
    validatorExcludeAmpersands,
    validatorExcludeAxigen,
    validatorExcludeBackslashes,
    validatorExcludeBackticks,
    validatorExcludeCurlyBraces,
    validatorExcludeDoubleQuotes,
    validatorExcludePercentages,
    validatorExcludeSingleQuotes,
    validatorExcludeWhitespace,
    validatorMaxLength,
    validatorMinLength,
    validatorNumberCheck,
    validatorSpecialCharAxigenRegexTest,
    validatorSpecialCharOrNumberRegexTest,
    validatorSpecialCharRegexTest,
    validatorUpperAndLowerRegexTest
} from 'utilities/methods/validators';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
// Creates an object to hold all the functionality for a given requirement
export class PasswordRequirement {
    constructor(validation, flag, error) {
        // The function to call to validate the requirement
        this.validation = validation;
        // The prompt to show the user under the "Your password must contain" section
        this.flag = flag;
        // The form field error message to show when validation fails (fallback to using flag text if no error text is provided)
        this.error = error || flag;
    }
}

/**
 * Positive Password Requirements (e.g. INCLUDE <Requirement>)
 */
export const passwordRequirements = {
    minLength8: new PasswordRequirement((v) => validatorMinLength(v, 8), '8 or more characters', 'Must be at least 8 characters long'),
    minLength10: new PasswordRequirement((v) => validatorMinLength(v, 10), '10 or more characters', 'Must be at least 10 characters long'),
    maxLength100: new PasswordRequirement((v) => validatorMaxLength(v, 100), 'Less than 100 characters', 'Must be less than 100 characters long'),
    upperAndLower: new PasswordRequirement(
        validatorUpperAndLowerRegexTest,
        'At least 1 uppercase and lowercase character',
        'Must contain at least one uppercase and lowercase character'
    ),
    numOrSpecial: new PasswordRequirement(
        validatorSpecialCharOrNumberRegexTest,
        'At least 1 number or special character (eg. !@$*)',
        'Must contain at least one number or special character'
    ),
    num: new PasswordRequirement(validatorNumberCheck, 'At least 1 number', 'Must contain at least one number'),
    specialChar: new PasswordRequirement(
        validatorSpecialCharRegexTest,
        'At least 1 special character (eg. !@$*)',
        'Must contain at least one special character'
    ),
    specialCharAxigen: new PasswordRequirement(
        validatorSpecialCharAxigenRegexTest,
        'At least 1 special character (eg. !@*)',
        'Must contain at least one special character'
    )
};

/**
 * Negative Password Requirements (e.g. DO NOT INCLUDE <Requirement>)
 */
export const passwordExcludeRequirements = {
    doubleQuotes: new PasswordRequirement(validatorExcludeDoubleQuotes, 'No double quotes (")', 'Password cannot contain double quotes (")'),
    singleQuotes: new PasswordRequirement(validatorExcludeSingleQuotes, "No single quotes ('')", "Password cannot contain single quotes ('')"),
    backticks: new PasswordRequirement(validatorExcludeBackticks, 'No backticks (`)', 'Password cannot contain backticks (`)'),
    backslashes: new PasswordRequirement(validatorExcludeBackslashes, 'No backslashes (\\)', 'Password cannot contain backslashes (\\)'),
    curlyBraces: new PasswordRequirement(validatorExcludeCurlyBraces, 'No curly braces ({})', 'Password cannot contain curly braces ({})'),
    ampersands: new PasswordRequirement(validatorExcludeAmpersands, 'No ampersands (&)', 'Password cannot contain ampersands (&)'),
    whitespace: new PasswordRequirement(validatorExcludeWhitespace, 'No whitespace ( )', 'Password cannot contain whitespace'),
    percentages: new PasswordRequirement(validatorExcludePercentages, 'No percentages (%)', 'Password cannot contain percentages (%)'),
    axigen: new PasswordRequirement(
        validatorExcludeAxigen,
        "Cannot use any of the following characters ” ' \\ [ ] { } ` & % # $",
        "Password cannot contain any of the following characters ” ' \\ [ ] { } ` & % # $"
    )
};
