/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useRef } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXDropZone } from 'components/Form/NXDropZone';
import { useStateValueHasFile } from 'components/Form/NXDropZone/hooks/useStateValueHasFile';
import ImageSelectControl from 'components/ImageSelectControl';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { imageAcceptsTypes } from 'components/Form/NXDropZone/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A component that renders a drop zone for file uploads with an image control.
 */
export const _NXDropZoneHookFormWithImageControl: React.FC<NXDropZoneNamespace.HookForm.WithImageControl.Props> = ({
    label,
    name,
    imageControlProps,
    accept = imageAcceptsTypes,
    singleFile = false
}) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value } = field;

    const zoneRef = useRef<DropzoneRef>();
    const [hasFile, setHasFile] = useStateValueHasFile(value);

    /***** FUNCTIONS *****/
    function handleOnDrop() {
        setHasFile(false);
    }

    function onChange(files: NXDropZoneNamespace.Values) {
        field.onChange(files);
        field.onBlur();
    }

    /***** RENDER *****/
    return (
        <>
            <NXDropZone.HookForm
                name={name}
                label={label}
                zoneRef={zoneRef}
                className={classNames('NXDropZoneWithImageControl', { hidden: hasFile })}
                onDropZoneDrop={handleOnDrop}
                accept={accept}
                singleFile={singleFile}
            />

            <ImageSelectControl
                imageURL={value}
                className={classNames('NXDropZoneWithImageControl', { hidden: !hasFile })}
                onClickUploadNew={() => {
                    zoneRef?.current?.open?.();
                }}
                onClickRemove={() => {
                    onChange(singleFile ? null : []);
                }}
                {...imageControlProps}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
