/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { processRichTextProperty } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/processRichTextProperty';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

// Better Types in lod-13, Katana -> KatanaNamespace
export function processSectionProperties(properties: Object, definitionData: KatanaNamespace.SectionDefinitions.Properties[]) {
    /**
     * For each key in the properties object:
     * check if the key is in the definitionData object
     */
    const clonedProperties = _.cloneDeep(properties);

    const propertyKeys = _.entries(clonedProperties);

    const processedProperties = propertyKeys.reduce(
        (acc, [key, value]) => {
            const foundPropertyDefinition = definitionData.find((property) => property.key === key);
            if (foundPropertyDefinition) {
                acc[key] = processProperty(value, foundPropertyDefinition);
            }
            return acc;
        },
        clonedProperties as Record<string, unknown>
    );
    return processedProperties;
}

// Better Types in lod-13, Katana -> KatanaNamespace
function processProperty(value: unknown, propertyDefinition: KatanaNamespace.SectionDefinitions.Properties) {
    switch (propertyDefinition.type) {
        case 'group':
            return processSectionProperties(value as Object, propertyDefinition.properties);

        case 'repeated':
            return (value as Object[]).map((item) => processSectionProperties(item, propertyDefinition.properties));

        case 'text':
            return processRichTextProperty(value, propertyDefinition);

        default:
            return value;
    }
}
