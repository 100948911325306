import { queryOptions, useQuery } from '@tanstack/react-query';
import { KATANA_API } from 'utilities/api/katana';
import { SECOND } from 'utilities/consts';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(params: number) {
    return createBaseQueryKey(['katana', 'service', params, 'pages']);
}

function createQueryOptions(params: number) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () => KATANA_API.katana.site.service_id.pages.GET(params),
        staleTime: SECOND * 25,
        enabled: Boolean(params),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the pages on the service
 */
function _useQuery(serviceID: number) {
    return useQuery(createQueryOptions(serviceID));
}

export const pages = {
    useQuery: _useQuery
} as const;
