/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

type FileReadResolve = {
    file_name: string;
    attachment: string;
};

type FileReadReject = {
    file: File;
    event: string;
    message: string;
};

type FileReadResult = FileReadResolve | FileReadReject;

type GetRejectedReturn = Array<{
    status: 'rejected';
    reason: FileReadReject;
}>;

const getRejected = (results: PromiseSettledResult<any>[]): GetRejectedReturn => results.filter((result) => result.status === 'rejected');

type GetFulfilledReturn = Array<{
    status: 'fulfilled';
    value: FileReadResolve;
}>;

const getFulfilled = (results: PromiseSettledResult<any>[]): GetFulfilledReturn => results.filter((result) => result.status === 'fulfilled');

const getEventAsString = (e: ProgressEvent<FileReader> | unknown) => {
    if (e instanceof Error) {
        return JSON.stringify(e, Object.getOwnPropertyNames(e));
    }
    return JSON.stringify(e);
};

/**
 * Reads files and converts them to base64 format.
 * @param files Array of files to read
 * @returns Promise resolving with an array of FileReadResult or undefined if an error occurs
 */
export const handleReadFiles = async (files?: File[]): Promise<FileReadResolve[] | undefined> => {
    if (!files || !files.length) return [];

    const readFile = (file: File): Promise<FileReadResult> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onabort = (e) => reject({ message: `Reading of File: ${file.name} was aborted`, event: getEventAsString(e), file });
            reader.onerror = (e) => reject({ message: `Failed to read File: ${file.name}`, event: getEventAsString(e), file });
            reader.onload = () => {
                try {
                    resolve({
                        file_name: file.name,
                        attachment: reader.result?.toString().split('base64,')[1] ?? ''
                    });
                } catch (e) {
                    reject({ message: `Failed to encode file: ${file.name}`, event: getEventAsString(e), file });
                }
            };

            reader.readAsDataURL(file);
        });
    };

    try {
        const results = await Promise.allSettled(files.map(readFile));
        const rejected = getRejected(results);
        const fulfilled = getFulfilled(results);

        if (rejected.length) {
            Sentry.captureMessage(JSON.stringify(rejected));

            pushNotification({
                status: 500,
                details: rejected[0].reason.message
            });
            return undefined;
        }

        return fulfilled.map(({ value }) => value);
    } catch (error) {
        return undefined;
    }
};
