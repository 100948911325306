/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**
 * @param {number} serviceID
 */
export function createQueryKey(serviceID) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'service', serviceID, 'service_details']));
}

/**
 * @param {number} serviceID
 */
function createQueryOptions(serviceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.service_details.GET(serviceID),
        retryOnMount: false,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**
 * @param {number} serviceID
 */
function resetQueries(serviceID) {
    return queryClient.resetQueries({ queryKey: createQueryKey(serviceID) });
}

/**
 * @param {number} serviceID
 */
function fetchQuery(serviceID) {
    return queryClient.fetchQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Checks Katana Service Details
 * @param {number} serviceID
 */
function _useQuery(serviceID) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getServiceDetails = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions,
    fetchQuery,
    resetQueries
});
