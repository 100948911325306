/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { validationRuleTypes } from 'containers/katana/formFields/validationRuleTypes';

const { REQUIRED, ARRAY } = validationRuleTypes;
const omittableKeys = /** @type {string[]} */ ([REQUIRED, ARRAY]);

/**
 *
 * @param {string[]} erroredKeys
 * @param {import('containers/katana/types').KatanaNamespace.SectionDefinitions.ValuesModifiedPropertiesAndValidations} sectionDefinition
 */
export function recursiveWalkDefinitionToOmitPathsByValidationRules(erroredKeys, sectionDefinition) {
    if (!sectionDefinition) {
        return erroredKeys;
    }

    /**
     *
     * @param {string[]} path
     * @param {import('containers/katana/types').KatanaNamespace.SectionDefinitions.ValuesModifiedPropertiesAndValidations | import('containers/katana/types').KatanaNamespace.SectionDefinitions.ValuesModifiedPropertiesAndValidations['properties']} definitionPart
     * @param {number} depth
     */
    function isPathRequiredInDefinition(path, definitionPart = sectionDefinition, depth = 0) {
        if (depth >= path.length) {
            return path;
        }

        if (_.isArray(definitionPart)) {
            const currentPath = path[depth];
            const foundDefinitionPart = definitionPart.find((definitionPart) => definitionPart.key === currentPath);

            if (foundDefinitionPart?.validation.some((validation) => omittableKeys.includes(validation.name))) {
                return path.slice(0, depth + 1);
            }

            return path;
        }

        if (_.has(sectionDefinition, path[depth])) {
            const definitionPart = _.get(sectionDefinition, path[depth]);
            return isPathRequiredInDefinition(path, definitionPart, depth + 1);
        }
    }

    const filteredErroredKeys = _.uniq(erroredKeys.map((path) => isPathRequiredInDefinition(path.split('.')).join('.')));

    return filteredErroredKeys;
}
