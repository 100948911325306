/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has, isArray, upperFirst } from 'lodash';
import { useMemo } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import MultiFieldArrayInput from 'containers/katana/containers/contentEditorModules/multiFieldArrayInput/multiFieldArrayInput';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';
import { useUpdateSocialLinksMutation } from 'containers/katana/queries/useUpdateSocialLinksMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import {
    useGetDynamicFormFieldValue,
    useGetDynamicFormFieldValues
} from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { performValidations } from 'utilities/methods/commonActions/performValidation/performValidation';
import { validateMinimum, validatorSocialMediaURLS } from 'utilities/methods/validators';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { MultiFieldArrayInputNamespace } from 'containers/katana/containers/contentEditorModules/multiFieldArrayInput/types';
import type { KatanaAPI } from 'utilities/api/katana/types';

const { SOCIALS } = useUpdateSocialLinksMutation.FORM_FIELD_KEYS;
const { SHOW_SOCIALS_IN_FOOTER, SHOW_SOCIALS_IN_HEADER } = usePostNavigationMutation.FORM_FIELD_KEYS;

const valueFormatter: MultiFieldArrayInputNamespace.ValueFormatter<KatanaAPI.Social | undefined> = (key, value) => {
    if (!value) {
        return undefined;
    }

    return {
        type: key,
        url: value
    };
};

const valueChangeFilter: MultiFieldArrayInputNamespace.FieldValueFilter<KatanaAPI.Social> = (field) => (fieldValue) => fieldValue.type !== field.key;

const valueSelectFilter: MultiFieldArrayInputNamespace.FieldValueFilter<KatanaAPI.Social> = (field) => (fieldValue) => fieldValue.type === field.key;

const valueExtractor: MultiFieldArrayInputNamespace.FieldValueExtractor<KatanaAPI.Social> = (fieldValue) =>
    typeof fieldValue === 'string' ? fieldValue : fieldValue.url;

const socialFormatFilter = (social: string): MultiFieldArrayInputNamespace.InputField => {
    const titleCaseSocial = upperFirst(social);
    function getURLFromSocial() {
        switch (social) {
            case 'twitter':
                return ['x', 'twitter'];

            default:
                return social;
        }
    }
    function getSocialTitle() {
        switch (social) {
            case 'twitter':
                return 'Twitter/X';

            default:
                return titleCaseSocial;
        }
    }

    function getPlaceholderURL() {
        const domain = getURLFromSocial();
        const domainValue = isArray(domain) ? domain[0] : domain;
        switch (social) {
            case 'linkedin':
                return `https://www.${domainValue}.com/company/ventraip`;

            case 'tiktok':
                return `https://www.${domainValue}.com/@ventraip`;
            case 'youtube':
                return `https://www.${domainValue}.com/c/ventraipau/videos`;
            default:
                return `https://www.${domainValue}.com/ventraip`;
        }
    }

    function validSocialDomainURLS(value: string) {
        const domain = getURLFromSocial();
        const domainValue = isArray(domain) ? domain : [domain];
        return domainValue.some((domain) => {
            return value.startsWith(`https://${domain}.com/`) || value.startsWith(`https://www.${domain}.com/`);
        });
    }

    return {
        key: social,
        label: getSocialTitle(),
        placeholder: `E.g. ${getPlaceholderURL()}`,
        description: `Please paste the ${titleCaseSocial} URL for your account. This can be found in the search bar of your web browser.`,
        validate: [
            (value: string) => {
                const validSocialLink = social === 'twitter' ? value.includes(`twitter`) || value.includes('x') : value.includes(social);
                return !value || validSocialLink ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
            },
            (value: string) =>
                !value || value.toLowerCase().startsWith('https://') ? undefined : `Please include https:// in the ${titleCaseSocial} URL`,
            (value: string) => {
                const validationResult = validSocialDomainURLS(value);
                const hasValue = Boolean(value);

                return !hasValue || validationResult ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
            },
            (value: string) => {
                const validationResult = validatorSocialMediaURLS(value);
                const hasValue = Boolean(value);

                return !hasValue || validationResult ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
            }
        ]
    };
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SocialMediaLinksContentModule = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const socialsInFooterValue = useGetDynamicFormFieldValue(SHOW_SOCIALS_IN_HEADER);
    const socialsInHeaderValue = useGetDynamicFormFieldValue(SHOW_SOCIALS_IN_FOOTER);
    /***** QUERIES *****/
    const { data: get_socials_meta_data, isLoading: isGetSocialsMetaLoading } = katanaQuery.serviceID.meta.getSocialsDefinitions.useQuery(id);

    const socialFields: Array<MultiFieldArrayInputNamespace.InputField> = useMemo(
        () => get_socials_meta_data?.map?.(socialFormatFilter) || [],
        [get_socials_meta_data]
    );

    const validateAllSocialMediaLinks = useMemo(
        () => (value: KatanaAPI.Social[]) => {
            for (const field of socialFields) {
                const { validate } = field;
                const foundValue = valueExtractor(value?.find?.(valueSelectFilter(field)) ?? '');
                if (!foundValue) {
                    continue;
                }
                const result = validate?.length ? performValidations(validate, foundValue) : undefined;
                if (result) {
                    return result;
                }
            }

            return undefined;
        },
        [socialFields]
    );

    const formValues = useGetDynamicFormFieldValues();

    const validateMinimumSocialMediaLinks = useMemo(
        () => (value: KatanaAPI.Social[]) => {
            if (
                (has(formValues.values, SHOW_SOCIALS_IN_HEADER) && formValues.values[SHOW_SOCIALS_IN_HEADER]) ||
                (has(formValues.values, SHOW_SOCIALS_IN_FOOTER) && formValues.values[SHOW_SOCIALS_IN_FOOTER])
            ) {
                return validateMinimum(value, 1) ? undefined : 'Please add at least one social media link';
            }

            return undefined;
        },
        []
    );

    /***** RENDER HELPERS *****/
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isGetSocialsMetaLoading, message: 'Loading Available Socials..' }
    ]);

    const isSocialsDisplayEnabled = socialsInFooterValue || socialsInHeaderValue;

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            title="Social Media Links"
            description="You can include links to your social media accounts in both the menu and footer of your website. These will display as icons linked to your social profiles."
        >
            <NXBox.DefaultPadding>
                <RequestLoader.MultiLoader loaders={multiLoaderData}>
                    <CheckBoxList>
                        <Field name={SHOW_SOCIALS_IN_HEADER} component={CheckBoxList.Item.ReduxForm}>
                            Display my social media links in the header
                        </Field>

                        <Field name={SHOW_SOCIALS_IN_FOOTER} component={CheckBoxList.Item.ReduxForm}>
                            Add social media links to the footer too
                        </Field>

                        {isSocialsDisplayEnabled && (
                            <ContentEditorModuleContent>
                                <Field
                                    component={MultiFieldArrayInput}
                                    name={SocialMediaLinksContentModule.formFields.SOCIALS}
                                    fieldsContent={socialFields}
                                    valueFormatter={valueFormatter}
                                    valueChangeFilter={valueChangeFilter}
                                    valueSelectFilter={valueSelectFilter}
                                    valueExtractor={valueExtractor}
                                    validate={[validateAllSocialMediaLinks, validateMinimumSocialMediaLinks]}
                                />
                            </ContentEditorModuleContent>
                        )}
                    </CheckBoxList>
                </RequestLoader.MultiLoader>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const SocialMediaLinksContentModule = Object.assign(_SocialMediaLinksContentModule, {
    /**
     * formFields is used to provide initial values to the form.
     */
    formFields: { SOCIALS, SHOW_SOCIALS_IN_HEADER, SHOW_SOCIALS_IN_FOOTER }
});

export { SocialMediaLinksContentModule };
