/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useIsMutating } from '@tanstack/react-query';
import { serviceNav } from 'config/config';
import { useHistory } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import { CancelServiceContent } from 'components/CancelServiceContent';
import { Modal } from 'components/Lightboxes/Modal';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { CancelServiceForm } from 'containers/services/forms/cancelServiceForm';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useCancelServiceMutation } from 'containers/services/queries/mutations';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ScrollableComponent } from 'components/ScrollableComponent';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const CancelKatanaServiceModule = function CancelKatanaServiceModule() {
    /***** STATE *****/
    const { value: isConfirmCancelShowing, setTrue: showConfirmCancel, setFalse: closeConfirmCancel } = useBoolean(false);

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const history = useHistory();

    /***** QUERIES *****/
    const { data: katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);
    const { mutate: mutateCancelService } = useCancelServiceMutation(id);
    const cancelServiceMutations = useIsMutating({ mutationKey: useCancelServiceMutation.mutationKey });

    /***** FUNCTIONS *****/
    function handleConfirmCancelService(values) {
        mutateCancelService(
            {
                type: 'immediate',
                refund_method: 'none',
                additional_services: [],
                ...values
            },
            {
                onSuccess: () => {
                    history.push(serviceNav.VIPsites.link);
                }
            }
        );
    }
    /***** RENDER *****/
    return (
        <ScrollableComponent ready>
            <NXBox warn>
                <NXBox.Top
                    title="Cancel VIPsites Service"
                    description="Upon cancelling this service, all content, files, and configurations associated with it will be deleted. Choose this option if you no longer require this VIPsites service."
                >
                    <OutlineButton color="warn" onClick={showConfirmCancel}>
                        Cancel VIPsites Service
                    </OutlineButton>
                </NXBox.Top>

                <Modal isOpen={isConfirmCancelShowing} onClose={closeConfirmCancel} ariaLabel="Cancel VIPsites Service">
                    {cancelServiceMutations > 0 ? (
                        <RequestLoader message="Cancelling your VIPsites service..." heightAuto fillHeight={66} />
                    ) : (
                        <CancelServiceContent
                            title="Cancel VIPsites Service"
                            primaryName={katana_service_data?.attributes?.name}
                            primaryProduct="VIPsites"
                        >
                            <CancelServiceForm.ReduxForm.Default
                                onSubmit={handleConfirmCancelService}
                                acknowledgements={[
                                    'confirm_delete',
                                    'confirm_dns_change',
                                    ['confirm_backup', 'I understand that my VIPsite data will be deleted and cannot be recovered.']
                                ]}
                            />
                        </CancelServiceContent>
                    )}
                </Modal>
            </NXBox>
        </ScrollableComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { CancelKatanaServiceModule };
