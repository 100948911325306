/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_moduleCTA.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @type {React.FC<{
 *      image: string;
 *      alt: string;
 *      text: string | React.ReactNode;
 *      buttonText: string;
 *      to: string;
 *      disabled?: boolean;
 * }>}
 */
const KatanaModuleCTA = ({ image, alt, text, buttonText, to, disabled = true }) => {
    /***** RENDER *****/
    return (
        <div className="KatanaModuleCTA">
            <img className="KatanaModuleCTA__image" src={image} width="300" height="auto" alt={alt} />
            <Text size--xm align--center secondary medium className="KatanaModuleCTA__text">
                <p>{text}</p>
            </Text>
            <SolidButton to={to} disabled={disabled}>
                <Text semiBold>{buttonText}</Text>
            </SolidButton>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaModuleCTA;
