/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { zodResolver } from '@hookform/resolvers/zod';
import { KatanaPreviewFormDataExtractor } from 'containers/katana/components/HookFormHandler/KatanaPreviewFormDataExtractor';
import type { DefaultValues, FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import type { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type KatanaHookFormHandlerProps<TFieldValues extends FieldValues, TZodSchema extends z.Schema<any, any>> = {
    defaultValues: DefaultValues<TFieldValues>;
    zodSchema: TZodSchema;
    children: React.ReactNode;
    handleOnSubmit: SubmitHandler<TFieldValues>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaHookFormHandler = <TFieldValues extends FieldValues, TZodSchema extends z.Schema<any, any>>({
    defaultValues,
    zodSchema,
    children,
    handleOnSubmit
}: KatanaHookFormHandlerProps<TFieldValues, TZodSchema>) => {
    /***** HOOKS *****/
    const form = useForm({
        defaultValues,
        resolver: zodResolver(zodSchema),
        mode: 'all'
    });

    /***** RENDER *****/
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleOnSubmit)}>{children}</form>
            <KatanaPreviewFormDataExtractor />
        </FormProvider>
    );
};
