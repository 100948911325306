/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { WrappedFieldProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MapsApiWrapper } from 'components/Form/SingleFieldAutoCompleteAddress/MapsApiWrapper';

/**********************************************************************************************************
 *   Types
 **********************************************************************************************************/
type Props = WrappedFieldProps & {
    label: JSX.Element;
    placeholder: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SingleFieldAutoCompleteAddressReduxFormDefault: React.FC<Props> = ({ placeholder, input, meta, label }) => {
    const { onChange } = input;

    /***** RENDER *****/
    return (
        <MapsApiWrapper onChange={onChange}>
            {({ autocompleteInputRef }) => (
                <Input.ReduxForm input={input} label={label} meta={meta} placeholder={placeholder} inputRef={autocompleteInputRef} />
            )}
        </MapsApiWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
