/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_colourSelectCircle.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      colour: import('utilities/types').NXUtils.HexCode,
 *      selected?: boolean,
 *      borderColor?: React.CSSProperties['borderColor'],
 * }>}
 */
const ColourCircle = ({ selected, colour, borderColor }) => (
    <div
        className={classNames('ColourSelectCircle', {
            'ColourSelectCircle--selected': selected
        })}
        style={{ backgroundColor: colour, borderColor }}
    >
        <NXSquare ratio={1} />
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ColourCircle };
