/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Text from 'components/Utils/Text';
import Tooltip from 'components/Tooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NotificationTooltip.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NotificationTooltipProps } from './types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function FreeOnlineNotificationTooltip({ domainRegistrationDate, tooltipText, giveaway, type = 'icon' }: NotificationTooltipProps) {
    /***** SELECTORS *****/
    const domainData = useSelector((state: any) => state.domain.domain_information_data);
    const renewalData = useSelector((state: any) => state.domain.domain_renew_options_data);

    const isOnlineGiveaway = giveaway ?? domainData?.attributes.auto_online_giveaway;

    /***** RENDER HELPERS *****/
    const renderTooltipText = () => {
        const date = new Date(domainRegistrationDate || domainData?.attributes.registration_date);
        const month = date?.toLocaleString('default', { month: 'long' });
        const year = date?.getFullYear() + 1;
        const renewalCost = renewalData?.attributes?.prices[0]?.price;
        const renewalText = renewalCost ? ` of $${renewalCost}/yr` : ',';
        const text =
            tooltipText ??
            `You have been awarded a free gift to protect your brand. Your domain registration is complimentary until ${month} ${year}. At the end of the free period you will be able to renew the name at the standard renewal rate${renewalText} should you wish to keep the name past the complimentary year. If auto-renew is enabled, your domain will attempt renewal automatically 14 days before the end of the free period. Please note the renewal price is subject to change.`;

        return text;
    };

    /***** RENDER *****/
    if (isOnlineGiveaway) {
        return (
            <>
                {type === 'icon' && (
                    <Tooltip className="tooltip" iconOverride={<PhosphorIcons.Gift.Fill primary size={24} />} info={renderTooltipText()} />
                )}
                {type === 'text' && (
                    <Tooltip
                        className="textTooltip"
                        iconOverride={
                            <Text size--s color="S_color_text_info_base">
                                More info
                            </Text>
                        }
                        info={renderTooltipText()}
                    />
                )}
            </>
        );
    }
    return null;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
