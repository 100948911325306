/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { KeyboardEventHandler } from 'react';
import React, { useCallback, useRef } from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXSelectOptions as NXSelectOptions } from 'components/Form/Select/_NXSelectOptions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useActiveLabel } from 'components/Form/Select/useActiveLabel';
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__Select.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectNamespace } from 'components/Form/Select/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _Select: React.FC<SelectNamespace.Props> = ({ value, options, onChange }) => {
    /***** STATE *****/
    const { value: isOptionsListVisible, setFalse: hideOptionList, toggle: toggleOptionListVisibility } = useBoolean(false);
    const { value: isDropdownButtonFocused, setTrue: focusDropdownButton, setFalse: blurDropdownButton } = useBoolean(false);

    /***** HOOKS *****/
    const dropdownRef = useRef<HTMLButtonElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const activeLabel = useActiveLabel(value, options);

    /***** FUNCTIONS *****/
    const handleClickAway = useCallback(() => {
        hideOptionList();
        blurDropdownButton();
    }, []);

    function handleOnChange(optionValue: SelectNamespace.Props['value']) {
        if (optionValue) {
            onChange(optionValue);
        }
    }

    // TODO: This function needs to be abstracted or cleaned up if more functionality is added so that this component doesn't become 4 billion lines
    const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
        if (!isDropdownButtonFocused) {
            return;
        }

        const selectedIndex = options.findIndex((option) => option.value === value);

        // scroll the options menu so that the selected option is always in view
        if (isOptionsListVisible && options.length > 5 && menuRef?.current) {
            if (e.key === 'ArrowUp') {
                if (menuRef?.current?.scrollTop > (selectedIndex - 1) * 40 && selectedIndex > 0) {
                    menuRef.current.scrollTop = (selectedIndex - 1) * 40;
                }
            }

            if (e.key === 'ArrowDown') {
                if (selectedIndex + 1 > 4 && menuRef.current?.scrollTop < (selectedIndex - 3) * 40 && selectedIndex + 1 < options.length) {
                    menuRef.current.scrollTop = (selectedIndex - 3) * 40;
                }
            }
        }

        switch (e.key) {
            case 'Tab':
                hideOptionList();
                blurDropdownButton();
                break;
            case 'ArrowDown':
            case 'Down':
                e.preventDefault();
                if (selectedIndex === -1) {
                    handleOnChange(options[0].value);
                } else if (selectedIndex < options.length - 1) {
                    handleOnChange(options[selectedIndex + 1].value);
                }
                break;
            case 'ArrowUp':
            case 'Up':
                e.preventDefault();
                if (selectedIndex === -1) {
                    handleOnChange(options[options.length - 1].value);
                } else if (selectedIndex > 0) {
                    handleOnChange(options[selectedIndex - 1].value);
                }
                break;
            case 'Enter':
                e.preventDefault();
                toggleOptionListVisibility();
                break;
            case 'Escape':
            case 'Esc':
                e.preventDefault();
                hideOptionList();
                break;
            default:
                break;
        }
    };

    /***** EFFECTS *****/
    useClickAway([dropdownRef], handleClickAway);

    /***** RENDER *****/
    return (
        <div className="Select">
            <button
                ref={dropdownRef}
                type="button"
                onClick={toggleOptionListVisibility}
                onFocus={focusDropdownButton}
                onBlur={blurDropdownButton}
                onKeyDown={handleKeyDown}
                className="Select__toggle"
            >
                {activeLabel}
                <PhosphorIcons.Chevron state={isOptionsListVisible ? 'up' : 'down'} />
            </button>

            {isOptionsListVisible && (
                <div ref={menuRef} className="Select__menu">
                    <NXSelectOptions value={value} options={options} onChange={onChange} />
                </div>
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
