/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { cloneDeep, has } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MINUTE } from 'utilities/consts';

/**
 * @param {number} serviceID
 */
function createQueryKey(serviceID) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'service', serviceID, 'navigation']));
}

/**
 * @param {number} serviceID
 */
function createQueryOptions(serviceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.navigation.GET(serviceID),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**
 * @template {Awaited<ReturnType<typeof KATANA_API.katana.service_id.navigation.GET>>} TData
 * @param {number} serviceID
 * @param {(oldData: TData) => TData | undefined} updater
 */
function setQueryData(serviceID, updater) {
    return queryClient.setQueryData(createQueryKey(serviceID), updater);
}

/**
 * @param {number} serviceID
 */
function cancelQueries(serviceID) {
    return queryClient.cancelQueries({
        queryKey: createQueryKey(serviceID)
    });
}

/**
 * @param {number} serviceID
 */
function resetQueries(serviceID) {
    return queryClient.resetQueries({
        queryKey: createQueryKey(serviceID)
    });
}

/**
 * @param {number} serviceID
 */
function prefetchQuery(serviceID) {
    queryClient.prefetchQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the navigation  for the site
 * @param {number} serviceID
 */
function _useQuery(serviceID) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *  HOOK END
 * **********************************************************************************************************/

/**
 *
 * @param {number} serviceID
 * @param {string} colour
 */
function optimisticNavigationColourUpdate(serviceID, colour) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.style.color')) {
            return;
        }

        if (['black', 'white'].includes(clonedData.data.style.color)) {
            return;
        }

        clonedData.data.style.color = colour;

        return clonedData;
    });
}

export const getNavigation = Object.freeze({
    useQuery: _useQuery,
    setQueryData,
    cancelQueries,
    prefetchQuery,
    resetQueries,
    optimisticNavigationColourUpdate,
    createQueryKey,
    createQueryOptions
});
