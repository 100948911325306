/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MapsApiWrapper } from 'components/Form/SingleFieldAutoCompleteAddress/MapsApiWrapper';

/**********************************************************************************************************
 *   Types
 **********************************************************************************************************/
type Props = {
    label: React.ReactNode;
    placeholder: string;
    name: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SingleFieldAutoCompleteAddressHookForm: React.FC<Props> = ({ placeholder, name, label }) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { onChange } = field;

    /***** RENDER *****/
    return (
        <MapsApiWrapper onChange={onChange}>
            {({ autocompleteInputRef }) => <Input.HookForm label={label} name={name} placeholder={placeholder} inputRef={autocompleteInputRef} />}
        </MapsApiWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
