/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { WrapperBox } from 'components/Form/WrapperBox/WrapperBox';
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EntryCreationBehaviourHandlerWrapper } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper';
import { _GridFieldArrayChild as GridFieldArrayChild } from 'containers/katana/formFields/repeated/inputTypes/grid/_GridFieldArrayChild';
import { useFieldArrayMetaOverrideOnSubmitButtonClick } from 'containers/katana/formFields/repeated/methods/useFieldArrayMetaOverrideOnSubmitButtonClick';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RepeatedFieldArrayRenderer } from 'containers/katana/formFields/repeated/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _GridFieldArrayRenderer: RepeatedFieldArrayRenderer.Component = (props) => {
    const { fields, properties, property, meta } = props;
    const metaOverride = useFieldArrayMetaOverrideOnSubmitButtonClick(meta);

    /***** RENDER *****/
    return (
        <WrapperBox error={metaOverride.error && !metaOverride.pristine} fullWidth>
            <Grid columns="repeat(4, 1fr)" className="GridFieldArrayRenderer" justify-children--stretch alignItems--end>
                <EntryCreationBehaviourHandlerWrapper {...{ property, properties, fields }}>
                    {fields.map((member, index, fields) => {
                        return <GridFieldArrayChild key={index} {...{ member, index, fields, properties, property }} />;
                    })}
                </EntryCreationBehaviourHandlerWrapper>
            </Grid>
            <ValidationMessage.ReduxForm.FieldArray {...metaOverride} />
        </WrapperBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
