/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ControllerFieldState } from 'react-hook-form';

export function _getMeta(fieldState: ControllerFieldState) {
    return {
        touched: fieldState.isTouched,
        error: fieldState.error?.message
    };
}
