/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SocialMediaLinksContentModule } from 'containers/katana/containers/contentEditorModules/socialMediaLinks';
import ContentEditorRenderModules from '../../contentEditorRenderModules';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationItemsMutation } from 'containers/katana/queries/usePostNavigationItemsMutation';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';
import { useUpdateKatanaSiteSectionMutation } from 'containers/katana/queries/useUpdateKatanaSiteSectionMutation';
import { useUpdateSocialLinksMutation } from 'containers/katana/queries/useUpdateSocialLinksMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useModifiedInitialValues } from 'containers/katana/hooks/useModifiedInitialValues';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @type {FormHandlerComponent}
 */
const NavigationFormHandler = ({ subpageRouteData }) => {
    /***** HOOKS *****/
    const { performRouting } = useKatanaNextPage(subpageRouteData);
    const { id, subpage } = useSetupEditorRouteParams();
    const { modules } = subpageRouteData;
    const { submitSiteData } = useSiteDataPreviewValues();

    /***** QUERIES *****/
    const { data: get_navigation_items_data } = katanaQuery.serviceID.getNavigationItems.useQuery(id);
    const { data: get_navigation_data } = katanaQuery.serviceID.getNavigation.useQuery(id);
    const { data: get_katana_socials_data } = katanaQuery.serviceID.getSocials.useQuery(id);

    const { mutateAsync: mutateAsyncPostNavigationItems } = usePostNavigationItemsMutation(id);
    const { mutateAsync: mutateAsyncPostNavigation } = usePostNavigationMutation(id);
    const { mutateAsync: mutateAsyncUpdateSocialLinks } = useUpdateSocialLinksMutation(id);
    const { mutateAsync: mutateUpdateKatanaSiteSection } = useUpdateKatanaSiteSectionMutation({ serviceID: id });

    /***** RENDER HELPERS *****/
    const queryData = useMemo(() => {
        const data = {
            ...(get_navigation_items_data ?? {}),
            ...(get_navigation_data ?? {})
        };

        if (Object.values(get_katana_socials_data ?? {}).length) {
            data[SocialMediaLinksContentModule.formFields.SOCIALS] = get_katana_socials_data;
        }

        return data;
    }, [get_navigation_items_data, get_katana_socials_data, get_navigation_data]);

    const newInitialValues = useModifiedInitialValues(queryData, modules);

    /***** FUNCTIONS *****/
    /**
     * @param {object} values
     */
    async function handleSubmit(values) {
        const promises = [mutateAsyncPostNavigationItems(values), mutateAsyncPostNavigation(values)];

        if (has(values, SocialMediaLinksContentModule.formFields.SOCIALS)) {
            const socials = /** @type {SocialLinksDataTypeDef[]} */ (values[SocialMediaLinksContentModule.formFields.SOCIALS]);

            const filteredSocials = socials.filter(({ url }) => Boolean(url));

            promises.push(mutateAsyncUpdateSocialLinks(filteredSocials));
        }

        if (has(values, 'sections')) {
            values.sections.forEach((/** @type {{id: number, name: string}} */ section) => {
                const mutation = mutateUpdateKatanaSiteSection({ sectionID: section.id, attributes: { name: section.name } });
                promises.push(mutation);
            });
        }

        submitSiteData(promises);

        return Promise.all(promises)
            .then(() => {
                performRouting();
            })
            .catch((e) => {
                return e;
            });
    }

    /***** RENDER *****/
    return (
        <ContentEditorRenderModules subpageRouteData={subpageRouteData} subpage={subpage} onSubmit={handleSubmit} initialValues={newInitialValues} />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NavigationFormHandler;
