/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _ColourSelect } from 'components/Form/ColourSelect/_ColourSelect';
import { _ColourSelectHookFormDefault as ColourSelectHookFormDefault } from 'components/Form/ColourSelect/wrappers/hookForm/Default';
import { _ColourSelectReduxFormDefault as ColourSelectReduxFormDefault } from 'components/Form/ColourSelect/wrappers/reduxForm/Default';

const ColourSelect = Object.assign(_ColourSelect, {
    ReduxForm: ColourSelectReduxFormDefault,
    HookForm: ColourSelectHookFormDefault
});

export { ColourSelect };
