import _ from 'lodash';

export function filterEndpointAttributeKeys<TAttributes extends Object>(attributes: TAttributes, acceptableKeys: string[]): TAttributes {
    if (!attributes) {
        return {} as any;
    }

    return acceptableKeys
        .filter((key) => _.has(attributes, key))
        .reduce((acc, valueKey) => {
            _.set(acc, valueKey, _.get(attributes, valueKey));
            return acc;
        }, {}) as any;
}
