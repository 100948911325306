/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ActiveRadioRenderNamespace } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/types';

export const ActiveRadioRenderContext = createContext<ActiveRadioRenderNamespace.Context>({
    _value: undefined,
    _onChange: () => {}
});
