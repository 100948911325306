/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import { NXSquare } from 'components/Utils/NXSquare';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RenderBrand } from 'containers/katana/modules/information/renderBrand';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useImageColourContrastBackgroundColour } from 'components/ImageSelectControl/hooks/useImageColourContrastBackgroundColour';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_renderBrand.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RenderLogoOrBrandFont = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data, isLoading } = katanaQuery.serviceID.getService.useQuery(id);
    const { classes } = useImageColourContrastBackgroundColour(data?.business?.logo);

    if (isLoading) {
        return <RequestLoader />;
    }

    if (data?.business?.logo) {
        return (
            <NXSquare className={classes.backgroundContrast}>
                <Padding xy={2} paddingOnly>
                    <NXSquare>
                        <BackgroundImage imageURL={data.business.logo} contain fill />
                    </NXSquare>
                </Padding>
            </NXSquare>
        );
    }

    if (data?.site?.style?.fonts?.brand) {
        return <RenderBrand font={data.site.style.fonts.brand}>{data?.business?.name}</RenderBrand>;
    }

    return (
        <NXSquare>
            <Padding xy={2} paddingOnly>
                <NXSquare>
                    <Flex justify="center" items="center" className="RenderBrandFont">
                        <Text size--m secondary italic align--center>
                            Website Logo Not <br />
                            Currently Set
                        </Text>
                    </Flex>
                </NXSquare>
            </Padding>
        </NXSquare>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
