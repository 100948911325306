/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRadioContext } from 'components/Form/Radio/_Radio/methods';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface IdentifierMatchProps {
    identifier: string;
    children: (isMatched: boolean) => React.ReactNode;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _IdentifierMatch: React.FC<IdentifierMatchProps> = ({ identifier, children }) => {
    /***** HOOKS *****/
    const { activeRadioButton } = useRadioContext();

    /***** RENDER *****/
    return children(activeRadioButton === identifier);
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
