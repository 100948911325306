/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/
import { validatePasswordCheckerCustomRequirements } from './index';

/**********************************************************************************************************
 *   AXIGEN REQUIREMENTS LISTS
 **********************************************************************************************************/
export const dedicatedEmailsRequirements = {
    require: ['minLength8', 'upperAndLower', 'num', 'specialCharAxigen'],
    exclude: ['axigen']
};

/**********************************************************************************************************
 * AXIGEN CHARACTERS TO EXCLUDE
 **********************************************************************************************************/
export const excludedCharacters = ['"', "'", '`', '\\', '{', '}', '&', '%', ' ', '[', ']', '#', '$'];

/**********************************************************************************************************
 *   AXIGEN PASSWORD VALIDATIONS
 **********************************************************************************************************/
export const validateDedicatedEmailsRequirements = (value) => validatePasswordCheckerCustomRequirements(value, dedicatedEmailsRequirements);
