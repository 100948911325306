/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useSiteSectionsRouteData } from 'containers/katana/hooks/useSiteSectionsRouteData';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import {
    SiteSetupStageOrder,
    SiteSetupStageToTitle,
    katanaServicePages,
    katanaSubPageEnums,
    katanaSubPageToSetupStage
} from 'containers/katana/consts';

const { SETUP, EDITOR } = katanaServicePages;
const { BASIC_DETAILS } = katanaSubPageEnums;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useKatanaNextSetupStageRoute() {
    /***** HOOKS *****/
    const { id, subpage, page } = useSetupEditorRouteParams();

    const { getKatanaDestination } = useKatanaURL();
    const { hasNextChildPage } = useSiteSectionsRouteData();

    /**********************************************************************************************************
     *   QUERIES
     **********************************************************************************************************/
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);

    if (!subpage || hasNextChildPage) {
        return null;
    }

    const currentStageChecklistKey = katanaSubPageToSetupStage[subpage];
    const launchChecklist = get_katana_service_data?.attributes?.launch_checklist ?? [];

    const availableSetupStages = SiteSetupStageOrder.filter((stage) => !launchChecklist.includes(stage));
    const remainingSetupStages = availableSetupStages.filter((stage) => stage !== currentStageChecklistKey);

    /***** FUNCTIONS *****/
    /**
     * @param {number} index
     */
    function findNextSetupStage(index) {
        if (!remainingSetupStages.length) {
            return null;
        }

        if (index > SiteSetupStageOrder.length - 1) {
            return findNextSetupStage(0);
        }

        const currentStageKey = SiteSetupStageOrder[index];
        if (remainingSetupStages.includes(currentStageKey)) {
            return currentStageKey;
        }

        return findNextSetupStage(index + 1);
    }

    const currentStageKeyIndex = SiteSetupStageOrder.indexOf(currentStageChecklistKey);
    const nextStageKey = findNextSetupStage(currentStageKeyIndex);

    if (subpage === BASIC_DETAILS) {
        return getKatanaDestination(page === 'editor' ? EDITOR.CONTACT_INFORMATION : SETUP.CONTACT_INFORMATION);
    }

    if (!nextStageKey) {
        return getKatanaDestination(EDITOR.LAUNCH);
    }

    return getKatanaDestination(SETUP[SiteSetupStageToTitle[nextStageKey]]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
