/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useContext } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _RadioCircleButton as RadioCircleButton } from 'components/Form/Radio/_Circle/_RadioCircleButton/_RadioCircleButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ActiveRadioRenderContext } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ActiveRadioRenderNamespace } from 'components/Form/Radio/_Circle/_RadioCircleButton/_ActiveRadioRender/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ActiveRadioRenderItem: React.FC<ActiveRadioRenderNamespace.Item.Props> = ({
    children,
    label,
    value,
    color,
    alwaysRenderChildren,
    isSelected,
    disabled
}) => {
    /***** HOOKS *****/
    const { _value, _onChange } = useContext(ActiveRadioRenderContext);

    /***** FUNCTIONS *****/
    function handleItemClick() {
        _onChange(value);
    }

    /***** RENDER HELPERS *****/
    const _isSelected = isSelected || _value === value;
    const shouldRenderChildren = Boolean(_isSelected || alwaysRenderChildren);

    /***** RENDER *****/
    return (
        <Flex direction="column" className="ActiveRadioRenderItem">
            <RadioCircleButton disabled={disabled} color={color} isActive={_isSelected} onClick={handleItemClick}>
                {label}
            </RadioCircleButton>
            {typeof children === 'function' ? children({ shouldRenderChildren }) : shouldRenderChildren && children}
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
