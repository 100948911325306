/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';

function createQueryKey({ serviceID, presetID, site }: KatanaAPI.Katana.ServiceID.Preview.Preset.POST.Params) {
    return createBaseQueryKey(/** @type {const} */ ['katana', 'service', serviceID, 'preview', 'preset', presetID, JSON.stringify(site)]);
}

function createQueryOptions({ serviceID, presetID, site, enabled }: KatanaAPI.Katana.ServiceID.Preview.Preset.POST.Params & { enabled?: boolean }) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, presetID, site }),
        queryFn: () => KATANA_API.katana.service_id.preview.preset.POST({ serviceID, presetID, site }),
        enabled: Boolean(serviceID && presetID && enabled),
        staleTime: Infinity,
        select: (data) => {
            if (typeof data === 'string') {
                return data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the preview for the preset
 */
function _useQuery({ serviceID, presetID, site, enabled }: KatanaAPI.Katana.ServiceID.Preview.Preset.POST.Params & { enabled?: boolean }) {
    return useQuery(createQueryOptions({ serviceID, presetID, site, enabled }));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getPresetPreview = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions
});
