/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BurnedToast from 'components/BurnedToast';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useDomainIsNotRegistered from 'containers/katana/containers/ContentEditorLightbox/hooks/useDomainIsNotRegistered';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaSubPageEnums } from 'containers/katana/consts';
const { REGISTER_DOMAIN } = katanaSubPageEnums;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BurnedToastDomainNotRegistered = () => {
    /***** HOOKS *****/
    const { id, page } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);
    const { name } = get_katana_service_data?.attributes ?? {};

    /***** RENDER HELPERS *****/
    const domainIsNotRegistered = useDomainIsNotRegistered();

    /***** RENDER *****/
    if (domainIsNotRegistered)
        return (
            <BurnedToast to={getKatanaDestination(page, REGISTER_DOMAIN)} icon={<PhosphorIcons.LockSimpleOpen black size={20} />}>
                <Text black lead--1 medium>
                    <Text semiBold>{name}</Text> not registered
                </Text>
                <Text black size--s lead--s>
                    Click here to register this domain now
                </Text>
            </BurnedToast>
        );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default BurnedToastDomainNotRegistered;
