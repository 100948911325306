/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DotStoreBanner, { dotStoreOnlineAndXYZAvailabilityRequestParams } from 'components/Promo/FreeDomain/DotStoreBanner';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import BannerCarousel from 'components/Promo/Carousel/Banner';
import WithAnimationLoop from 'components/Promo/Carousel/Banner/WithAnimationLoop';
import { ScrollableComponent } from 'components/ScrollableComponent';

/**********************************************************************************************************
/*   ACTIONS
 *****************************************************/
import { checkPromoCodeEligibility, checkYearPromoCode } from 'config/containers/promotions/methods';
import { createDomainAvailabilityQueryKey, postDomainAvailability } from 'containers/domain/action';
import { activePromotion, getCurrentDate, truncateSimple } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { freeDotOnlinePromoCode, freeDotStorePromoCode } from 'components/Promo/promoCodes';
import { Jackpot20kBanner } from './jackpot20kBanner';
import DotOnlineBanner from 'components/Promo/FreeDomain/DotOnlineBanner';
import ReferralBanner from 'components/Promo/ReferralBanner';
import { AdditionalUserBanner } from 'config/containers/promotions/additionalUsersBanner';
import { MegaMayDashboardBanner } from 'config/containers/promotions/megamay2024/components/dashboardBanner';
import VIPControlAppBanner from 'config/containers/promotions/vipcontrolApp/banner';
import { PromotionalVIPSiteBanner } from 'containers/services/modules/banners/katana/promotionalVIPSiteBanner';
import { SoftlockVIPSiteBanner } from 'containers/services/modules/banners/katana/softlockVIPSitesBanner';

/***** VIPControl App START *****/
/***** VIPControl App END *****/

/***** Additional User Banner START *****/
/***** Additional User Banner END *****/

/***** MEGAMAY24 START *****/
/***** MEGAMAY24 END *****/

/**********************************************************************************************************
 *   TYPES
 **********************************************************************************************************/
type User = {
    id: string | null;
    firstName: string | null;
    email: string | null;
    contact: string | null;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Account = ({ promoDealsRef }) => {
    /***** STATE *****/
    const [user, setUser] = useState<User>({
        id: null,
        firstName: null,
        email: null,
        contact: null
    });

    /***** HOOKS *****/
    const app_user_status = useSelector((state) => state.app.app_user_status);
    const app_user_data = useSelector((state) => state.app.app_user_data);
    const promotion_promo_code_status_request_data = useSelector((state) => state.promotion.promotion_promo_code_status_request_data);
    const domain_availability_data = useSelector((state) => state.domain.domain_availability_data);

    const history = useHistory();

    /***** QUERIES *****/
    const { data: trialDomains, isLoading: isTrialDomainsLoading } = katanaQuery.getTrialDomains.useQuery();

    /***** EFFECTS *****/
    useEffect(() => {
        let shouldFetchTldPricing = false;
        if (activePromotion('freeDotOnline2024')) {
            checkYearPromoCode(freeDotOnlinePromoCode, '.online');
            shouldFetchTldPricing = true;
        }
        if (activePromotion('freeDotStore2024')) {
            checkYearPromoCode(freeDotStorePromoCode, '.store');
            shouldFetchTldPricing = true;
        }
        if (shouldFetchTldPricing) {
            const domainAvailabilityQueryKey = createDomainAvailabilityQueryKey(dotStoreOnlineAndXYZAvailabilityRequestParams);

            if (!domain_availability_data[domainAvailabilityQueryKey]?.data) {
                postDomainAvailability(dotStoreOnlineAndXYZAvailabilityRequestParams, domainAvailabilityQueryKey);
            }
        }
    }, []);

    useEffect(() => {
        if (app_user_status === 'success') {
            setUser({
                id: app_user_data.id,
                firstName: app_user_data.firstname,
                email: app_user_data.email,
                contact: app_user_data.phone
            });
        }
    }, [app_user_status]);

    /***** RENDER HELPERS *****/
    const getWelcome = () => {
        let welcome = null;
        const now = getCurrentDate();

        const split_afternoon = 12;
        const split_evening = 17;
        const currentHour = parseFloat(now.toFormat('HH'));

        if (currentHour >= split_afternoon && currentHour <= split_evening) {
            welcome = 'afternoon';
        } else if (currentHour >= split_evening) {
            welcome = 'evening';
        } else {
            welcome = 'morning';
        }

        return htmr(`Good ${welcome}, <span className='firstName'>${truncateSimple(user.firstName, 60, '...')}</span>.`);
    };

    const handleProfileRender = (text: string, icon: string) => {
        return (
            <div key={icon} className="profile__item">
                <div className="profile__icon">
                    <i className={`icon icon-${icon}`}></i>
                </div>
                <div className="profile__text">{text}</div>
            </div>
        );
    };

    const getInitials = () => {
        const { firstname, lastname } = app_user_data;
        let initial = '';
        if (firstname) {
            initial += firstname.substring(0, 1);
        }
        if (lastname) {
            initial += lastname.substring(0, 1);
        }
        return initial;
    };

    const renderPromoBanner = () => {
        const initialBanners = [
            {
                key: 1,
                ui: <SoftlockVIPSiteBanner />,
                dismissKey: '',
                showCondition: trialDomains?.length > 0
            },
            {
                key: 2,
                ui: <PromotionalVIPSiteBanner />,
                dismissKey: '',
                showCondition: trialDomains?.length === 0
            },
            {
                key: 3,
                ui: <Jackpot20kBanner />,
                dismissKey: Jackpot20kBanner.JACKPOT_DISMISS_KEY,
                showCondition: activePromotion('jackpot20k')
            },
            /***** MEGAMAY24 START *****/
            {
                key: 4,
                ui: <MegaMayDashboardBanner promoDealsRef={promoDealsRef} />,
                dismissKey: MegaMayDashboardBanner.MEGAMAY24_DISMISS_KEY,
                showCondition: activePromotion('megamay2024')
            },
            /***** MEGAMAY24 END *****/
            {
                key: 5,
                ui: <DotStoreBanner />,
                dismissKey: DotStoreBanner.DOT_STORE_RADIX_DISMISS_KEY,
                showCondition:
                    activePromotion('freeDotStore2024') && checkPromoCodeEligibility(freeDotStorePromoCode, promotion_promo_code_status_request_data)
            },
            /***** VIPControl App START *****/
            {
                key: 6,
                ui: <VIPControlAppBanner />,
                dismissKey: 'vipcontrolAppBannerDismiss',
                showCondition: activePromotion('vipcontrolApp')
            },
            /***** VIPControl App END *****/
            {
                key: 7,
                ui: <DotOnlineBanner />,
                dismissKey: DotOnlineBanner.DOT_ONLINE_RADIX_DISMISS_KEY,
                showCondition:
                    activePromotion('freeDotOnline2024') &&
                    checkPromoCodeEligibility(freeDotOnlinePromoCode, promotion_promo_code_status_request_data)
            },
            {
                key: 8,
                ui: <ReferralBanner />,
                dismissKey: '',
                showCondition: true
            },
            {
                key: 9,
                ui: <AdditionalUserBanner />,
                dismissKey: AdditionalUserBanner.ADDITIONAL_USER_DISMISS_KEY,
                showCondition: activePromotion('additionalUser')
            }
        ];

        // Use this condition to hold back the carousel rendering until any requests that certain banners depend on have completed (change it to a "let" variable when its needed)
        const showBannerCarousel = !isTrialDomainsLoading; //This is needed for the <SoftlockVIPSiteBanner />. Wait until the trial domain data is fetched before rendering the banners.

        if (!showBannerCarousel) return '';

        return (
            <WithAnimationLoop>
                {(elapsed, start, pause, resume, restart) => (
                    <BannerCarousel initialBanners={initialBanners} elapsed={elapsed} start={start} pause={pause} resume={resume} restart={restart} />
                )}
            </WithAnimationLoop>
        );
    };

    const accountContent = () => {
        switch (app_user_status) {
            case 'loading':
                return (
                    <div className="ComponentLoading">
                        <div className="ComponentLoading__wrapper">
                            <RequestLoader />
                        </div>
                    </div>
                );

            case 'success':
                return (
                    <div className="dashboardAccount">
                        {renderPromoBanner()}
                        <div className="dashboardAccount__footer">
                            <div className="dashboardAccount__client">
                                <div className="client__profile--image">
                                    <div className="client__profile--initials">{getInitials()}</div>
                                </div>
                                <div className="client__profile--name">{getWelcome()}</div>
                            </div>
                            <div className="dashboardAccount__profile">
                                {handleProfileRender(user.email, 'email2')}
                                {handleProfileRender(user.contact, 'phone1')}
                                <OutlineButton
                                    type="onClick"
                                    color="primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push('/account/general/overview');
                                    }}
                                    className="profile__action"
                                >
                                    Edit Profile
                                </OutlineButton>
                            </div>
                        </div>
                    </div>
                );

            case 'error':
                return (
                    <div className="ComponentError">
                        <div className="ComponentError__wrapper">
                            <FetchComponentError />
                            <div className="error__refresh">
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.reload();
                                    }}
                                >
                                    <i className="icon icon-reload"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                );

            default:
                return '';
        }
    };

    /***** RENDER *****/
    return <ScrollableComponent ready={app_user_status === 'success'}>{accountContent()}</ScrollableComponent>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
