/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _TextArea } from 'components/Form/TextArea/_TextArea';
import { _TextAreaHookFormDefault as TextAreaHookFormDefault } from 'components/Form/TextArea/wrappers/hookForm/Default';
import { _TextAreaReduxFormDefault as TextAreaReduxFormDefault } from 'components/Form/TextArea/wrappers/reduxform/Default';

const TextArea = Object.assign(_TextArea, {
    ReduxForm: TextAreaReduxFormDefault,
    HookForm: TextAreaHookFormDefault
});

export { TextArea };
