/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { values } from 'lodash';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';

const { NAME } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useFilteredBrandFonts() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { field } = useController({ name: NAME });

    /***** QUERIES *****/
    const { data: get_fonts_meta_data } = katanaQuery.serviceID.meta.getFonts.useQuery(id);

    /***** HOOK RESULTS *****/
    return useMemo(
        () =>
            values(get_fonts_meta_data)
                .filter((font) => font.types.includes('brand'))
                .map<CardSelectGridNamespace.SelectCard.FontOption>((font) => ({
                    type: 'font',
                    fontID: font.id,
                    fontFamily: font.font_family,
                    text: field.value || font.font_family,
                    additionalContent: Boolean(field.value) && (
                        <Text className="PickLogoStyle__additionalContent" italic secondary size--s>
                            {font.name}
                        </Text>
                    )
                })),
        [get_fonts_meta_data, field.value]
    );
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
