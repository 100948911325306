/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const createQueryKey = () => createBaseQueryKey(['billing', 'GET_MERGEABLE_INVOICES']);
const createDefaultQueryOptions = () =>
    queryOptions({
        queryKey: createQueryKey(),
        queryFn: ACCOUNT_API.user.invoice.merge.GET,
        placeholderData: [] as any
    });

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useGetMergeableInvoicesQuery = (options = {}) => {
    return useQuery({
        ...createDefaultQueryOptions(),
        ...options
    });
};

const invalidate = () => queryClient.invalidateQueries({ queryKey: createQueryKey() });

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const useGetMergeableInvoicesQuery = Object.assign(_useGetMergeableInvoicesQuery, {
    invalidate,
    createQueryKey
});
