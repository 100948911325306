/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useIsMutating } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import {
    RenderCardExpiryFields,
    RenderField,
    creditcardValidation,
    isCVVValid,
    numberValidation,
    peopleNameFieldValidation,
    renderButton,
    renderCVVNumberField,
    renderCardNumberField,
    renderCheckboxField,
    requiredFieldValidation
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { CREATE_PAYMENT_METHOD_DATA } from '../queries';
import { useGetAvailablePaymentMethodsQuery } from '../queries/useGetAvailablePaymentMethodsQuery';
import { useGetUserPaymentMethodDataListQuery } from '../queries/useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import cvv_shot from 'assets/images/cvv_shot.png';
import { PAYMENT_METHODS } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';
import './_creditCardForm.scss';

export const creditCardFormName = 'CreditCardForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let CreditCardForm = ({
    handleSubmit,
    /**
     * Redux Props
     */
    pristine,
    submitting,
    valid
}) => {
    /***** QUERIES *****/
    const { data: paymentMethodID } = useGetAvailablePaymentMethodsQuery({
        select: useGetAvailablePaymentMethodsQuery.selectFilters.getPaymentMethodID(PAYMENT_METHODS.CREDIT_CARD)
    });

    const { data: get_user_payment_method_data_list_data } = useGetUserPaymentMethodDataListQuery({
        select: useGetUserPaymentMethodDataListQuery.selectFilters.getPaymentMethodType(PAYMENT_METHODS.CREDIT_CARD)
    });

    /***** RENDER HELPERS *****/
    const isCreatePaymentMethodDataMutating = useIsMutating({ mutationKey: [CREATE_PAYMENT_METHOD_DATA] }) > 0;

    /***** FUNCTIONS *****/
    const canSelectDefaultCreditCard = Boolean(
        get_user_payment_method_data_list_data?.filter?.(({ isMockAdditionLoading }) => !isMockAdditionLoading).length
    );

    /***** RENDER *****/
    return (
        <form className="AddCreditForm__form form" onSubmit={handleSubmit}>
            <div className="form__content">
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            name={CREDIT_CARD_FIELD_DATA.CARD_HOLDER_NAME.name}
                            autoComplete={CREDIT_CARD_FIELD_DATA.CARD_HOLDER_NAME.autoComplete}
                            component={RenderField}
                            disabled={isCreatePaymentMethodDataMutating}
                            type="text"
                            label="Card Holder Name"
                            validate={[requiredFieldValidation, peopleNameFieldValidation]}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            name={CREDIT_CARD_FIELD_DATA.CARD_NUMBER.name}
                            autoComplete={CREDIT_CARD_FIELD_DATA.CARD_NUMBER.autoComplete}
                            component={renderCardNumberField}
                            disabled={isCreatePaymentMethodDataMutating}
                            type="text"
                            label="Card Number"
                            validate={[requiredFieldValidation, creditcardValidation]}
                        />
                    </div>
                </div>

                <div className="form__row">
                    <div className="form__column half">
                        <RenderCardExpiryFields parent={this} disabled={isCreatePaymentMethodDataMutating} />
                    </div>
                    <div className="form__column half">
                        <Field
                            name={CREDIT_CARD_FIELD_DATA.CARD_CVV.name}
                            autoComplete={CREDIT_CARD_FIELD_DATA.CARD_CVV.autoComplete}
                            component={renderCVVNumberField}
                            disabled={isCreatePaymentMethodDataMutating}
                            type="text"
                            label="CVV"
                            validate={[requiredFieldValidation, isCVVValid]}
                            img={cvv_shot}
                        />
                    </div>
                </div>

                {canSelectDefaultCreditCard ? (
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                name="is_default"
                                disabled={isCreatePaymentMethodDataMutating}
                                type="checkbox"
                                component={renderCheckboxField}
                                label="Set as default Credit Card"
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}

                <div className="form__row">
                    <div className="form__column full">
                        {renderButton(
                            pristine,
                            submitting,
                            valid && paymentMethodID && !isCreatePaymentMethodDataMutating,
                            isCreatePaymentMethodDataMutating ? <RequestLoader /> : 'Add Credit Card'
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CreditCardForm.propTypes = {
    /**
     * The submit function for the form, becomes `handleSubmit` in props
     */
    onSubmit: PropTypes.func.isRequired
};

CreditCardForm = reduxForm({
    form: creditCardFormName,
    enableReinitialize: true,
    initialValues: {
        is_default: false
    }
})(CreditCardForm);

export default CreditCardForm;
