/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CardSelectGrid } from 'components/Form/CardSelectGrid/_CardSelectGrid';
import { _CardSelectGridHookForm } from 'components/Form/CardSelectGrid/wrappers/hookForm';
import { _CardSelectGridReduxForm } from 'components/Form/CardSelectGrid/wrappers/reduxForm';

export const CardSelectGrid = Object.assign(_CardSelectGrid, {
    ReduxForm: _CardSelectGridReduxForm,
    HookForm: _CardSelectGridHookForm
});
