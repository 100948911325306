/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { cloneDeep, has } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 */
export function useDeleteKatanaSectionMutation(serviceID, options = {}) {
    return useMutation({
        /**
         * @param {number} sectionID
         */
        mutationFn: async (sectionID) => {
            /**
             * Optimistically remove the section from the view.
             * first cancel existing queries, then set the data to the new data.
             */
            const cancelQueries = [
                katanaQuery.serviceID.getSections.cancelQueries(serviceID),
                katanaQuery.serviceID.getService.cancelQueries(serviceID)
            ];

            await Promise.allSettled(cancelQueries);

            katanaQuery.serviceID.getSingleSection.removeQueries({ serviceID, sectionID });

            katanaQuery.serviceID.getSections.setQueryData(serviceID, (oldData) => {
                if (oldData?.status !== 200) {
                    return;
                }

                const clonedData = cloneDeep(oldData);

                if (has(clonedData, 'data')) {
                    clonedData.data = clonedData.data.filter((section) => section.id !== sectionID);
                }
                return clonedData;
            });

            return KATANA_API.katana.site.service_id.section.section_id.DELETE({ serviceID, sectionID });
        },
        onError: handleDefaultErrorNotification,
        onSettled: () => {
            katanaQuery.serviceID.getSections.invalidateQueries(serviceID);
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
        },
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
