/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, change, untouch } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { Input } from 'components/Form/Input';
import RequestLoader from 'components/Loaders/Request';
import NXTable from 'components/NXTable';
import Tooltip from 'components/Tooltip';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useDebounce from 'utilities/hooks/useDebounce';
import { requiredCheckboxFieldValidation, requiredFieldValidation } from 'utilities/methods/form';
import { usePreviewPresetChanges, useSquarespaceVerificationCode } from './hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ThirdPartyPresetName } from 'containers/domain/forms/presets';
import { apiPresetNames, presets } from '../types';

/*   ACTIONS
 *****************************************************/
import { previewPresetChanges } from 'containers/domain/action';

const constants = /** @type {const} */ ({
    verificationCodeTooltip:
        'Squarespace uses a CNAME record to verify domains. They may have provided you with a verification code in the DNS Settings panel of your Squarespace account. If you have this code, enter it here and we will add the Verification CNAME record for you. If you have already verified your domain, leave the field blank.',
    confirmationNotice: (
        <>
            <Text uppercase bold>
                Please Note:{' '}
            </Text>
            The preset records you are about to add may conflict with some of your existing records.
        </>
    ),
    confirmationLabel: (
        <Text secondary size--s bold>
            I want to remove any existing records that conflict with the new preset records
        </Text>
    )
});

const FieldNames = /** @type {const} */ ({
    SQUARE_SPACE: 'verification_code',
    REMOVE_CONFLICTING: 'remove_conflicting'
});

/**
 * gets the object of replacements to pass to the API. This way, redux can maintain the state of the squarespace field
 * while optionally sending it to the backend based on the preset (useful for maintaining state when this component is unmounted due to submitting)
 *
 * @param {import('../types').TPresetName} preset
 * @param {string | undefined} verification_code
 */
const getReplacements = (preset, verification_code) => {
    const replacements = {
        replacements: {}
    };

    if (preset === presets.SQUARESPACE) {
        replacements.replacements.verification_code = verification_code;
    }

    return replacements;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *   preset: import('../types').TPresetName
 * }} props
 */
const PreviewPreset = ({ preset }) => {
    /***** STATE *****/
    const { domain, id } = useSelector((/** @type {*} */ state) => ({
        domain: state.domain.domain_information_data?.attributes?.domain,
        id: state.domain.domain_information_data?.id
    }));
    const verification_code = useSquarespaceVerificationCode();
    const { status, data } = usePreviewPresetChanges();

    const tableProps = {
        columns: '1.2fr 2fr 3fr',
        gap: 2
    };

    /***** EFFECTS *****/
    useDebounce(
        () => {
            if (!verification_code) {
                return;
            }

            previewPresetChanges(id, apiPresetNames[preset], getReplacements(preset, verification_code));
        },
        500,
        [verification_code, preset]
    );

    useEffect(() => {
        previewPresetChanges(id, apiPresetNames[preset], getReplacements(preset, verification_code));

        const standardCallback = () => void store.dispatch(change(ThirdPartyPresetName, FieldNames.REMOVE_CONFLICTING, false));

        if (preset !== presets.SQUARESPACE) {
            return standardCallback;
        }

        return () => {
            store.dispatch(untouch(ThirdPartyPresetName, FieldNames.SQUARE_SPACE)); //keep this field
            standardCallback();
        };
    }, []);

    /***** RENDER HELPERS *****/
    /**
     * Function to render the squarespace preset conditionally. This will be replaced in the future with any "merge fields" returned from Mercury which will be implemented
     * In the near future and should be easily modified at that time.
     */
    const renderSquarespaceInput = () => {
        if (preset !== presets.SQUARESPACE) return null;

        return (
            <>
                <Field
                    label={
                        <>
                            Squarespace Verification code
                            <Tooltip info={constants.verificationCodeTooltip} />
                        </>
                    }
                    name={FieldNames.SQUARE_SPACE}
                    type="text"
                    component={Input.ReduxForm}
                    validate={[requiredFieldValidation]}
                />
                <br />
            </>
        );
    };

    /***** RENDER *****/
    return (
        <div className="thirdPartyForm__summary">
            {renderSquarespaceInput()}

            {status === 'loading' && <RequestLoader />}
            {status === 'error' && <></>}
            {status === 'success' && (
                <>
                    <Text size--h2>Records being added</Text>
                    <br />
                    <Border top left right bottom>
                        <NXTable columns={tableProps.columns}>
                            <NXTable.Header gap={tableProps.gap}>
                                <NXTable.Header.Title>Record Type</NXTable.Header.Title>
                                <NXTable.Header.Title>Hostname</NXTable.Header.Title>
                                <NXTable.Header.Title>Content</NXTable.Header.Title>
                            </NXTable.Header>
                            {data?.add?.map(({ type, hostname, content }, index) => (
                                <NXTable.Row gap={2} key={index}>
                                    <Text size--s>{type}</Text>
                                    <Text size--s>{hostname ?? domain}</Text>
                                    <Text size--s>{content}</Text>
                                </NXTable.Row>
                            ))}
                        </NXTable>
                    </Border>
                    <br />

                    {/* Only display conflicting records if they exist */}
                    {!!data && data?.remove.length > 0 && (
                        <>
                            <Text size--h2>Conflicting Records</Text>
                            <br />
                            <Border top left right bottom>
                                <NXTable columns={tableProps.columns}>
                                    <NXTable.Header gap={tableProps.gap}>
                                        <NXTable.Header.Title>Record Type</NXTable.Header.Title>
                                        <NXTable.Header.Title>Hostname</NXTable.Header.Title>
                                        <NXTable.Header.Title>Content</NXTable.Header.Title>
                                    </NXTable.Header>
                                    {data?.remove?.map(({ type, hostName, content }, index) => (
                                        <NXTable.Row gap={2} key={index}>
                                            <Text size--s>{type}</Text>
                                            <Text size--s>{hostName ?? '@'}</Text>
                                            <Text size--s>{content}</Text>
                                        </NXTable.Row>
                                    ))}
                                </NXTable>
                            </Border>
                            <AcknowledgementBox.Compact
                                notice={constants.confirmationNotice}
                                title="Confirmation"
                                className="thirdPartyForm__acknowledgementBox"
                            >
                                <Field
                                    name={FieldNames.REMOVE_CONFLICTING}
                                    type="checkbox"
                                    validate={[requiredCheckboxFieldValidation]}
                                    component={CheckBoxList.Item.ReduxForm}
                                >
                                    {constants.confirmationLabel}
                                </Field>
                            </AcknowledgementBox.Compact>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PreviewPreset;
