/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import { Modal } from 'components/Lightboxes/Modal';
import { pushNotification } from 'components/Toast/functions';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useEnterDrawMutation, useGetDrawEligibilityQuery } from 'config/containers/promotions/queries';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import NrlImage from 'assets/images/promo/nrlGiveaway/nrlGrandFinal.jpg';
const promoId = 'nrl-final-draw-2024';
const teams = [
    'Brisbane Broncos',
    'Canberra Raiders',
    'Canterbury-Bankstown Bulldogs',
    'Cronulla-Sutherland Sharks',
    'Dolphins',
    'Gold Coast Titans',
    'Manly-Warringah Sea Eagles',
    'Melbourne Storm',
    'Newcastle Knights',
    'New Zealand Warriors',
    'North Queensland Cowboys',
    'Parramatta Eels',
    'Penrith Panthers',
    'South Sydney Rabbitohs',
    'St. George Illawarra Dragons',
    'Sydney Roosters',
    'Wests Tigers',
    "I don't support a team"
];

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface ErrorDataProps {
    already_redeemed: boolean;
    is_promo_finished: boolean;
    is_promo_started: boolean;
    is_vip_reward_member: boolean;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NrlGiveawayCard2 = () => {
    /***** STATE *****/
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState<string>("I don't support a team");
    const [modalInfo, setModalInfo] = useState({
        title: '',
        description: '',
        type: '',
        button: ''
    });

    /***** QUERIES *****/
    const {
        isError: isGetDrawEligibilityError,
        error: get_draw_eligibility_error,
        isPending: isPromoQueryPending
    } = useGetDrawEligibilityQuery(promoId);
    const { mutate: enterDraw, isPending: isEnterDrawPending } = useEnterDrawMutation(promoId, selectedTeam);

    /***** FUNCTIONS *****/
    const handleClick = (type: string) => {
        if (type === 'confirm') {
            enterDraw(undefined, {
                onSuccess: () => {
                    setModalInfo({
                        title: `You're in!`,
                        description: 'The winner will be drawn and contacted on 23rd of September 2024.',
                        type: 'success',
                        button: 'Close'
                    });
                },
                onError: (error) => {
                    pushNotification(error?.errors?.[0] || { details: 'An error occurred. Please try again later.', status: 400 });
                    setIsModalOpen(false);
                }
            });
        }

        if (type === 'success') {
            setIsModalOpen(false);
        }
    };

    const confirmTeamSelection = () => {
        setModalInfo({
            title: `Which NRL team do you support?`,
            description: 'Let us know which team you go for',
            type: 'confirm',
            button: 'Enter Now'
        });
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    /***** RENDER HELPER *****/
    function renderModal() {
        return (
            <Modal isOpen={isModalOpen} onClose={closeModal} ariaLabel="Contest Entry Confirmation">
                <Flex direction="column" justify="center" items="center" gap={0}>
                    <Text.Heading align--center h2>
                        {modalInfo.title}
                    </Text.Heading>
                    <Text align--center size--m secondary>
                        {modalInfo.description}
                    </Text>
                    {modalInfo.type === 'confirm' && (
                        <>
                            <SelectDropdown
                                noSelectionLabel="Select Your Team"
                                className="nrlGiveawayLightbox__dropdown"
                                options={teams.map((team) => ({
                                    label: team,
                                    onClick: () => {
                                        setSelectedTeam(team);
                                    }
                                }))}
                            />
                            <Text className="nrlGiveawayLightbox__Text" align--right italic span size--xs>
                                *Please note: The winner of this giveaway will be selected at random. Your choice of team will not influence the
                                outcome of the draw.
                            </Text>
                        </>
                    )}

                    <SolidButton
                        color="primary"
                        onClick={() => handleClick(modalInfo.type)}
                        disabled={!selectedTeam}
                        isLoading={isEnterDrawPending}
                        className="nrlGiveawayLightbox__button"
                    >
                        {modalInfo.button}
                    </SolidButton>
                </Flex>
            </Modal>
        );
    }

    function renderButtonContents() {
        if (!isGetDrawEligibilityError) return 'Enter Now';

        const errorData = get_draw_eligibility_error?.data as ErrorDataProps;

        if (!errorData) return 'Error: Invalid';
        if (errorData.is_promo_finished) return 'Promo Finished';
        if (errorData.already_redeemed) return 'Entered';
        if (!errorData.is_promo_started) return 'Promo Not Started';
        if (!errorData.is_vip_reward_member) return 'Not a VIP Member';
    }

    /***** RENDER *****/
    return (
        <>
            <RewardCard>
                <RewardCard.Image tag="GIVEAWAY">
                    <div className="rewardCard__bg rewardCard__bg--overflowHidden">
                        <img src={NrlImage} alt="NRL" width={278} />
                    </div>
                </RewardCard.Image>
                <RewardCard.Body
                    title="Win 2 tickets to watch the NRL Grand Final at Accor Stadium, plus flights and accommodation"
                    description="Enter now for your chance to win two corporate box tickets to watch the NRL Grand Final at Accor Stadium."
                    subDescription={
                        <Padding top={1} bottom={6}>
                            <Anchor
                                color="secondary"
                                hoverColor="secondary"
                                underline
                                href="https://www.ventraip.com.au/terms-policies-agreements/viprewards-2024-nrl-grand-final-giveaway-terms-and-conditions/"
                                target="_blank"
                            >
                                <Text italic span size--xs>
                                    *Terms and conditions apply.
                                </Text>
                            </Anchor>
                        </Padding>
                    }
                >
                    <SolidButton
                        color="primary"
                        disabled={isPromoQueryPending || isGetDrawEligibilityError}
                        isLoading={isPromoQueryPending || isEnterDrawPending}
                        onClick={confirmTeamSelection}
                    >
                        {renderButtonContents()}
                    </SolidButton>
                </RewardCard.Body>
            </RewardCard>
            {renderModal()}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
