/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _WebsiteAppearanceContent as WebsiteAppearanceContent } from 'containers/katana/modules/informationAndAppearance/_WebsiteAppearanceContent';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_KatanaInformationAndAppearanceModule.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WebsiteAppearanceCondensedModule = () => {
    /***** RENDER *****/
    return (
        <NXBox.TopAccordion title="Website Appearance">
            <NXBox.DefaultPadding>
                <WebsiteAppearanceContent />
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
