/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SetQueryDataMethod<TParams extends any, TDataObject extends object> = (
    params: TParams,
    updater: (oldData: TDataObject | undefined) => TDataObject | undefined
) => void;

type Tactics = 'set' | 'merge';

/**
 * Eventually once the the other tactics are used often enough, we can break this up into seperate functions
 */
export function createOptimisticUpdateMethod<TParams extends any, TDataObject extends object & { status?: 200 }>(
    setQueryData: SetQueryDataMethod<TParams, TDataObject>
) {
    function optimisticUpdate<TKey extends NXUtils.Path<TDataObject>, TTactic extends Tactics = 'set'>(
        params: TParams,
        updatePath: TKey,
        valueToUpdatePathWith: TTactic extends 'set'
            ? NXUtils.Choose<TDataObject, TKey>
            : NXUtils.RecursivePartial<NXUtils.Choose<TDataObject, TKey>>,
        tactic: TTactic = 'set' as TTactic
    ) {
        setQueryData(params, (oldData) => {
            if (oldData?.status !== 200) {
                return;
            }

            const clonedData = _.cloneDeep(oldData);
            if (!_.has(clonedData, updatePath)) {
                return;
            }

            switch (tactic) {
                case 'set': {
                    _.set(clonedData, updatePath, valueToUpdatePathWith);
                    return clonedData;
                }

                case 'merge': {
                    const mergedValue = _.merge(_.get(clonedData, updatePath), valueToUpdatePathWith);
                    _.set(clonedData, updatePath, mergedValue);
                    return clonedData;
                }

                default:
                    return clonedData;
            }
        });
    }
    return optimisticUpdate;
}
