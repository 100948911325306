/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { touch } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ContentEditorRenderModulesNamespace } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    e: React.KeyboardEvent | React.MouseEvent;
    form: string;
    fields: Array<string>;
    submitButtonClickEvent?: ContentEditorRenderModulesNamespace.SubmitButtonClickEvent;
};

export function handleOnDisabledClick({ e, form, fields, submitButtonClickEvent }: Params) {
    const triggerFieldValidation = touch(form, ...fields);

    /**
     * The condition e instanceof KeyboardEvent might return false because e is a React synthetic event.
     * try:
     * if (e.nativeEvent instanceof KeyboardEvent && e.nativeEvent.key !== 'Enter') {
     */
    if (e instanceof KeyboardEvent && e.key !== 'Enter') {
        return; // Do not trigger touch if key is not "Enter"
    }

    submitButtonClickEvent?.set?.(e);

    store.dispatch(triggerFieldValidation);
}
