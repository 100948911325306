/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/Form/CheckBoxList';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_indentFlush.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const IndentFlush: React.FC<Props> = ({ children }) => (
    <div className="KatanaIndentFlush">
        <CheckBoxList>
            <CheckBoxList>{children}</CheckBoxList>
        </CheckBoxList>
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
