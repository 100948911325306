/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { useRef } from 'react';
import { useFormState } from 'react-hook-form';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useHandleSubmitButtonEvent = () => {
    /***** HOOKS *****/
    const findErrorElementAttempts = useRef(0);
    const { errors } = useFormState();

    /***** HOOK RESULTS *****/
    return function handleSubmitButtonEvent() {
        const errorKeys = _.keys(errors);
        if (!errorKeys.length) {
            return;
        }

        const forElements = document.querySelectorAll(`[for]`);
        const foundElement = Array.from(forElements).find((element) => {
            return _.find(errorKeys, (key) => key === element.getAttribute('for'));
        });

        const wrapperBoxErrorElement = document.querySelector('.wrapperbox--error');
        const ccpSelectToggleErrorElement = document.querySelector('.ccp__select--toggle-error');
        const finalElement = foundElement || wrapperBoxErrorElement || ccpSelectToggleErrorElement;
        if (finalElement) {
            finalElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            findErrorElementAttempts.current = 0;
        } else if (findErrorElementAttempts.current < 3) {
            findErrorElementAttempts.current += 1;
            requestAnimationFrame(handleSubmitButtonEvent);
        }
    };
};
