/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { katanaSubPageEnums } from 'containers/katana/consts';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

const { PRESET_CONTENT, LAUNCH, FINALISE, BASIC_DETAILS } = katanaSubPageEnums;

const finaliseOrLaunch: KatanaNamespace.SubPages[] = [FINALISE, LAUNCH];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useAllowPreviewState() {
    /***** HOOKS *****/
    const { childpage, subpage, page, id } = useSetupEditorRouteParams();
    const { data: get_service_data } = katanaQuery.serviceID.getService.useQuery(id);

    /***** HOOK RESULTS *****/
    return useMemo(() => {
        if (
            !get_service_data ||
            page === 'add-section' ||
            (page !== 'overview' && (finaliseOrLaunch.includes(subpage) || (subpage === PRESET_CONTENT && !childpage))) ||
            !get_service_data?.attributes?.launch_checklist.includes('layout-and-content-completed')
        ) {
            return false;
        }

        if (
            (childpage && get_service_data?.attributes?.launch_checklist.includes('layout-and-content-completed')) ||
            get_service_data?.attributes?.launch_checklist.includes('layout-and-content-completed')
        ) {
            return true;
        }

        if (page === 'setup' && BASIC_DETAILS === subpage) {
            return false;
        }

        return true;
    }, [subpage, page, childpage, get_service_data]);
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
