/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RichTextEditor } from 'components/Form/RichTextEditor';
import { TextArea } from 'components/Form/TextArea';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getServiceInfoDataFromPath } from 'containers/katana/components/dynamicFormFieldRenderer/methods';
import { useGetRichTextCaveat } from 'containers/katana/formFields/methods/getRichTextCaveat/useGetRichTextCaveat';
import { getValidationValue } from 'containers/katana/formFields/methods/getValidation';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useReduxForm } from 'utilities/hooks/useReduxForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaTextFormFieldTextArea: React.FC<{
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
}> = ({ property }) => {
    const { defaultValue, key, name, type, caveats, validationFunctions, validation } = property;

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { form } = useContext(ContentEditorRenderModulesContext);
    const values = useReduxForm(form);
    const initialValue = get(values?.initial, key);

    /***** RENDER HELPERS *****/
    const disabled = useGetCaveat(CaveatTypes.DISABLED, caveats);
    const valueFromServiceDataPath = useGetCaveat(CaveatTypes.VALUE_FROM_SERVICE_DATA, caveats);
    const richTextFeatures = useGetRichTextCaveat(caveats);
    const valueFromServiceData = getServiceInfoDataFromPath(id, valueFromServiceDataPath);
    const placeHolderCaveat = useGetCaveat(CaveatTypes.PLACEHOLDER, caveats) ?? defaultValue;
    const placeholder = valueFromServiceData ?? placeHolderCaveat;

    const maxLength = getValidationValue(validation, 'max');
    const minLength = getValidationValue(validation, 'min');

    const fieldComponent = useMemo(() => (richTextFeatures?.length ? RichTextEditor.TextArea.ReduxForm : TextArea.ReduxForm), [richTextFeatures]);

    /***** RENDER *****/
    return (
        <Field
            key={`${key}_richTextFeaturesLength_${richTextFeatures?.length}`}
            name={key}
            label={name}
            placeholder={placeholder}
            component={fieldComponent}
            rows={5}
            disabled={disabled}
            type={type}
            validate={validationFunctions}
            features={richTextFeatures}
            initialValue={initialValue}
            intrinsicProps={{
                maxLength,
                minLength
            }}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaTextFormFieldTextArea };
