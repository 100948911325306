/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { restrictedProducts, ticket_name, ventraSynMigrationBanners } from 'config/config';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VentraSynBanner from 'containers/ventraSynMigration/modules/banner';
import Account from './sections/account';
import Admin from './sections/admin';
import Configuration from './sections/configuration';
import Reseller from './sections/reseller';
import Security from './sections/security';

import NetoriginOverview from './modules/Netorigin/overview'; // NETORIGIN PATCH
import PleskDetails from './modules/Plesk/details';
import PleskOverview from './modules/Plesk/overview';
import Addons from './modules/addons';
import BillingHosting from './modules/billing';
import Resources from './modules/changeResources';
import Details from './modules/details';
import Overview from './modules/overview';
import Primary from './modules/primary';
import Purchase from './modules/purchase';
import Upgrade from './modules/upgrade';

import { VictoriaEducationHostingUpgrade } from 'containers/hosting/modules/victoriaEducationHosting/upgrade';

import Cancel from 'components/Cancel';
import Move from 'components/Move';

import DatabaseAccess from './modules/databaseAccess';
import IPv6 from './modules/ipv6';
import SSHAccess from './modules/sshaccess';
import TempURL from './modules/tempurl';

import SubAccountsList from './modules/subaccounts';

import AutoSSL from './modules/autossl';
import Firewall from './modules/firewall';
import ModSec from './modules/modsec';
import PermissionFixer from './modules/permissionfixer';
import { Monarx } from './modules/monarx';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';
import Page from 'components/Page';
import Sidebar from 'components/Sidebar';
import Subnav from 'components/Subnav';
import Title from 'components/Title';
import Transition from 'components/Transition';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { checkRequiresMigration, getMigratableResellerAccounts } from 'containers/ventraSynMigration/action';
import { API } from 'utilities/api/hosting';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import {
    cpanelLogin,
    getHostingInformation,
    getHostingSubAccountInformation,
    getSubaccountList,
    pleskLogin,
    prepStateForSubaccount,
    resetHostingState,
    resetHostingSubaccount,
    whmLogin
} from './state/accountActions';
import { cancelRequest, resetHostingAdmin, resetHostingAdminRequests } from './state/adminActions';
import { resetHostingConfiguration, resetHostingConfigurationRequests } from './state/configActions';
import { cpanelSubAccountLogin, resetResellerState, storeUsername } from './state/resellerActions';
import { resetHostingSecurity, resetHostingSecurityRequests } from './state/securityActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkIsVictoriaEducationHosting, getHostingProduct, getHostingProductName } from 'containers/hosting/methods';
import { getTicketSubmitUrl } from 'containers/support/methods';
import { handleRedirect } from 'utilities/methods/redirects/methods';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';
import { handleSideMenuOptions, isURLValid } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './styles/_baseStyles.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Hosting extends Component {
    getHostingData = () => {
        const { match, getHostingInformation, hosting_information_data, hosting_information_status } = this.props;
        const { params } = match;
        const { id } = params;

        if (!hosting_information_data && hosting_information_status !== 'loading' && hosting_information_status !== 'error') {
            getHostingInformation(id);
        }
    };

    getSubaccountData = () => {
        const { match, getHostingSubAccountInformation, hosting_information_subaccount_data, hosting_information_subaccount_status } = this.props;
        const { params } = match;
        const { id, subaccount } = params;

        if (
            !hosting_information_subaccount_data &&
            hosting_information_subaccount_status !== 'success' &&
            hosting_information_subaccount_status !== 'error' &&
            subaccount
        ) {
            getHostingSubAccountInformation(id, subaccount);
        }
    };

    checkRestrictedServiceList() {
        const { hosting_information_data } = this.props;
        const product = getIncludedObjBasedOnType(hosting_information_data.included, 'product');
        return restrictedProducts.filter((item) => item === product.attributes.name).length > 0;
    }

    componentDidMount() {
        const { match, hosting_subaccount_username, getMigratableResellerAccounts, checkRequiresMigration } = this.props;
        const { params } = match;
        const { subaccount } = params;

        if (subaccount && !hosting_subaccount_username) {
            this.getSubaccountData();
        }

        this.getHostingData();

        if (ventraSynMigrationBanners) {
            getMigratableResellerAccounts();
            checkRequiresMigration();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            match,
            hosting_isReseller,
            getSubaccountList,
            hosting_information_data,
            hosting_information_error,
            hosting_information_status,
            history
        } = this.props;
        const { params } = match;
        const { id } = params;
        const { attributes } = hosting_information_data || {};
        const { domain_status, domain, status } = attributes || {};

        if (['success', 'error'].includes(hosting_information_status) && prevProps.hosting_information_status === 'loading') {
            if (hosting_information_error?.status === 403) {
                history.push(redirectUrls.hosting);
                pushNotification(hosting_information_error);
            } else if (hosting_information_data) {
                handleRedirect({
                    serviceType: 'hosting',
                    status: domain_status === 'Pending Cancellation' ? domain_status : status,
                    domainName: domain,
                    history
                });
            }
        }

        if (!hosting_isReseller && prevProps.hosting_isReseller) {
            getSubaccountList(id);
        }
    }

    render() {
        const {
            location: { pathname },
            match: {
                url,
                params: { subpage, section, id, subaccount }
            },
            app_viewport,
            hosting_information_data,
            hosting_information_status,
            hosting_information_subaccount_status,
            hosting_information_subaccount_data,
            hosting_subaccount_username,
            hosting_isReseller,
            cpanelSubAccountLogin,
            whmLogin,
            prepStateForSubaccount,
            history,
            vsm_reseller_list_data,
            vsm_requires_migration_data,
            hosting_cancel_status,
            hosting_addons_list_status,
            hosting_addons_list_data
        } = this.props;

        const product = getHostingProduct(hosting_information_data);
        const productName = getHostingProductName(product);

        const isCustomHosting = !!hosting_information_data?.attributes?.custom_hosting;
        const isPlesk = productName.startsWith('plesk');
        const isNetorigin = productName.startsWith('Netorigin');
        const isVictoriaEducationHosting = checkIsVictoriaEducationHosting(productName);

        const getLoginUrl = (id, type) => {
            let apiLoginPromise;
            switch (type) {
                case 'cpanel':
                    apiLoginPromise = API.hosting.GET.login.cpanel(id);
                    break;

                case 'plesk':
                    apiLoginPromise = API.hosting.GET.login.plesk(id);
                    break;

                case 'whm':
                    apiLoginPromise = API.hosting.GET.login.whm(id);
                    break;

                default:
                    break;
            }
            if (apiLoginPromise) {
                const { closePopup, goToTargetUrl } = createPopup();

                apiLoginPromise
                    .then((response) => {
                        const data = getDataFromSuccessResponse(response);
                        goToTargetUrl(data.attributes.url);
                    })
                    .catch((error) => {
                        pushNotification(getErrorFromFailResponse(error));
                        closePopup();
                    });
            }
        };

        const renderTitle = () => {
            if (hosting_information_data) {
                const { type, id, attributes } = hosting_information_data;
                const { domain } = attributes;

                if (hosting_isReseller) {
                    if (subpage === 'reseller') {
                        return (
                            <Title
                                serviceTitle={domain}
                                serviceSubTitle={`${productName} (Reseller)`}
                                serviceType={type}
                                action={[
                                    {
                                        className: 'listAccount__action',
                                        label: 'Manage',
                                        color: 'primary',
                                        type: 'onClick',
                                        onClick: (e) => {
                                            e.preventDefault();
                                            prepStateForSubaccount();
                                            history.push(`/my-services/hosting/account/overview/${id}`);
                                        }
                                    },
                                    {
                                        className: 'listAccount__action',
                                        label: 'WHM Login',
                                        color: 'notice',
                                        type: 'onClick',
                                        onClick: (e) => {
                                            e.preventDefault();
                                            getLoginUrl(id, 'whm');
                                        }
                                    },
                                    <CustomDropdown
                                        className="TitleShared__singleDropdown"
                                        label={({ labelRef, dropdownTitleOnClick }) => (
                                            <button
                                                className="TitleShared__singleDropdownButton"
                                                ref={labelRef}
                                                type="button"
                                                onClick={() => dropdownTitleOnClick()}
                                            >
                                                Web Hosting Help
                                                <i className="item__icon icon icon-chevron-down" />
                                            </button>
                                        )}
                                        key="dropdown"
                                        render={() => (
                                            <SelectOptions
                                                options={[
                                                    {
                                                        label: 'Web Hosting Help Articles',
                                                        onSelect: () => history.push('/support/support-centre/web-hosting')
                                                    },
                                                    {
                                                        label: 'Submit a Technical Support eTicket for this Service',
                                                        onSelect: () =>
                                                            history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                                    },
                                                    {
                                                        label: 'Submit a Billing eTicket for this Service',
                                                        onSelect: () =>
                                                            history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                                    }
                                                ]}
                                            />
                                        )}
                                    />
                                ]}
                            />
                        );
                    }

                    return (
                        <Title
                            serviceTitle={domain}
                            serviceSubTitle={`${productName} (Reseller)`}
                            serviceType={type}
                            action={[
                                {
                                    className: 'listAccount__action',
                                    label: 'List Accounts',
                                    color: 'secondary',
                                    type: 'onClick',
                                    onClick: (e) => {
                                        e.preventDefault();
                                        prepStateForSubaccount();
                                        history.push(`/my-services/hosting/reseller/subaccounts/${id}`);
                                    }
                                },
                                {
                                    className: 'listAccount__action',
                                    label: 'WHM Login',
                                    color: 'notice',
                                    type: 'onClick',
                                    onClick: (e) => {
                                        e.preventDefault();
                                        getLoginUrl(id, 'whm');
                                    }
                                },
                                <CustomDropdown
                                    className="TitleShared__singleDropdown"
                                    label={({ labelRef, dropdownTitleOnClick }) => (
                                        <button
                                            className="TitleShared__singleDropdownButton"
                                            ref={labelRef}
                                            type="button"
                                            onClick={() => dropdownTitleOnClick()}
                                        >
                                            Web Hosting Help
                                            <i className="item__icon icon icon-chevron-down" />
                                        </button>
                                    )}
                                    key="dropdown"
                                    render={() => (
                                        <SelectOptions
                                            options={[
                                                {
                                                    label: 'Web Hosting Help Articles',
                                                    onSelect: () => history.push('/support/support-centre/web-hosting')
                                                },
                                                {
                                                    label: 'Submit a Technical Support eTicket for this Service',
                                                    onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                                },
                                                {
                                                    label: 'Submit a Billing eTicket for this Service',
                                                    onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                                }
                                            ]}
                                        />
                                    )}
                                />
                            ]}
                        />
                    );
                } else if (product?.attributes.server_type.startsWith('plesk')) {
                    return (
                        <Title
                            serviceTitle={domain}
                            serviceSubTitle={`${productName} (Shared)`}
                            serviceType={type}
                            action={[
                                {
                                    label: 'Login',
                                    color: 'notice',
                                    type: 'onClick',
                                    onClick: (e) => {
                                        e.preventDefault();
                                        getLoginUrl(id, 'plesk');
                                    }
                                },
                                {
                                    label: 'WordPress Hosting Help',
                                    className: 'TitleShared__dropdown',
                                    size: 'medium',
                                    type: 'onClick',
                                    onClick: () => history.push(`/support/support-centre/wordpress-hosting`),
                                    list: [
                                        {
                                            label: `Submit ${ticket_name}`,
                                            type: 'onClick',
                                            onClick: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                        }
                                    ]
                                }
                            ]}
                        />
                    );
                }

                return (
                    <Title
                        serviceTitle={domain}
                        serviceSubTitle={`${productName} (Shared)`}
                        serviceType={type}
                        action={[
                            {
                                label: 'cPanel Login',
                                type: 'onClick',
                                color: 'notice',
                                onClick: (e) => {
                                    e.preventDefault();
                                    getLoginUrl(id, 'cpanel');
                                }
                            },
                            <CustomDropdown
                                className="TitleShared__singleDropdown"
                                label={({ labelRef, dropdownTitleOnClick }) => (
                                    <button
                                        className="TitleShared__singleDropdownButton"
                                        ref={labelRef}
                                        type="button"
                                        onClick={() => dropdownTitleOnClick()}
                                    >
                                        Web Hosting Help
                                        <i className="item__icon icon icon-chevron-down" />
                                    </button>
                                )}
                                key="dropdown"
                                render={() => (
                                    <SelectOptions
                                        options={[
                                            {
                                                label: 'Web Hosting Help Articles',
                                                onSelect: () => history.push('/support/support-centre/web-hosting')
                                            },
                                            {
                                                label: 'Submit a Technical Support eTicket for this Service',
                                                onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                            },
                                            {
                                                label: 'Submit a Billing eTicket for this Service',
                                                onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                            }
                                        ]}
                                    />
                                )}
                            />
                        ]}
                    />
                );
            } else {
                return '';
            }
        };

        const renderSubaccountTitle = () => {
            if (!hosting_information_data || !hosting_information_subaccount_data) {
                return '';
            }

            const { type, id, attributes } = hosting_information_data;
            const { domain } = attributes;

            return (
                <Title
                    serviceTitle={domain}
                    serviceSubTitle={`${productName} (Reseller)`}
                    serviceType={type}
                    useSubtitle={true}
                    subtitleData={{
                        title: hosting_information_subaccount_data.domain,
                        action: {
                            label: 'Login to cPanel',
                            onClick: (e) => {
                                e.preventDefault();
                                cpanelSubAccountLogin(id, hosting_information_subaccount_data.username);
                            }
                        }
                    }}
                    action={[
                        {
                            className: 'listAccount__action',
                            label: 'List Accounts',
                            type: 'onClick',
                            color: 'secondary',
                            onClick: (e) => {
                                e.preventDefault();
                                prepStateForSubaccount();
                                history.push(`/my-services/hosting/reseller/subaccounts/${id}`);
                            }
                        },
                        {
                            className: 'listAccount__action',
                            label: 'WHM Login',
                            type: 'onClick',
                            color: 'notice',
                            onClick: (e) => {
                                e.preventDefault();
                                whmLogin(id);
                            }
                        }
                    ]}
                />
            );
        };

        const moduleCondition = (/** @type {string} */ module) => {
            switch (module) {
                case 'Addons': {
                    const isLoading = hosting_addons_list_status !== 'success';
                    const hasProducts = hosting_addons_list_data?.product_addons?.length > 0;
                    const hasServices = hosting_addons_list_data?.service_addons?.length > 0;

                    switch (true) {
                        case hosting_isReseller || isNetorigin || isPlesk:
                            return false;
                        case isCustomHosting && isLoading:
                        case isCustomHosting && (hasProducts || hasServices):
                            return true;
                        default:
                            return false;
                    }
                }
                default:
                    return true;
            }
        };

        const modules = {
            account: [
                {
                    sidebarLink: {
                        label: 'Overview',
                        link: `/my-services/hosting/account/overview/${id}`
                    },
                    component: <Overview hostingid={id} />,
                    condition: !isNetorigin && !isPlesk && !subaccount,
                    identifier: 'hosting/overview'
                },
                {
                    sidebarLink: {
                        label: 'Renew',
                        link: [`/my-services/hosting/account/renew/${id}`, `/my-services/hosting/account/billing/${id}`]
                    },
                    component: <BillingHosting />,
                    condition: !isPlesk && !hosting_isReseller && !isVictoriaEducationHosting,
                    identifier: 'hosting/renew'
                },
                {
                    sidebarLink: {
                        label: 'Upgrade/Downgrade',
                        link: `/my-services/hosting/account/custom-hosting/${id}`
                    },
                    component: <Resources hostingid={id} />,
                    condition: !hosting_isReseller && !isNetorigin && !isPlesk && isCustomHosting && !isVictoriaEducationHosting,
                    identifier: 'hosting/upgrade-downgrade'
                },
                {
                    sidebarLink: {
                        label: 'Addons',
                        link: `/my-services/hosting/account/custom-addons/${id}`
                    },
                    component: <Addons hostingid={id} />,
                    condition: moduleCondition('Addons') && !isVictoriaEducationHosting,
                    identifier: 'hosting/addons'
                },
                {
                    sidebarLink: {
                        label: 'Details',
                        link: `/my-services/hosting/account/details/${id}`
                    },
                    component: <Details hostingid={id} />,
                    condition: !hosting_isReseller && !isNetorigin && !isPlesk,
                    identifier: 'hosting/details'
                },
                {
                    sidebarLink: {
                        label: 'Upgrade Plan',
                        link: `/my-services/hosting/account/upgrade/${id}`
                    },
                    component: <Upgrade hostingid={id} />,
                    condition: !isCustomHosting && !hosting_isReseller && !isNetorigin && !isPlesk && !isVictoriaEducationHosting,
                    identifier: 'hosting/upgrade-plan'
                },
                {
                    sidebarLink: {
                        label: 'Upgrade Plan',
                        link: `/my-services/hosting/account/upgrade/${id}`
                    },
                    component: <VictoriaEducationHostingUpgrade />,
                    condition: isVictoriaEducationHosting,
                    identifier: 'hosting/upgrade-plan'
                },
                {
                    sidebarLink: {
                        label: 'Primary Domain',
                        link: `/my-services/hosting/account/primary-domain/${id}`
                    },
                    component: <Primary hostingid={id} />,
                    condition: !hosting_isReseller && !isNetorigin && !isPlesk && !isVictoriaEducationHosting,
                    identifier: 'hosting/primary-domain'
                },
                {
                    sidebarLink: {
                        label: 'Purchase',
                        link: `/my-services/hosting/account/purchase/${id}`
                    },
                    component: <Purchase hostingid={id} />,
                    condition: !hosting_isReseller && !isNetorigin && !isPlesk && isCustomHosting && !isVictoriaEducationHosting,
                    identifier: 'hosting/purchase'
                },
                // NETORIGIN
                {
                    sidebarLink: {
                        label: 'Overview',
                        link: `/my-services/hosting/account/overview/${id}`
                    },
                    component: <NetoriginOverview hostingid={id} />,
                    condition: !hosting_isReseller && !isPlesk && isNetorigin,
                    identifier: 'hosting/netorigin-overview'
                },
                // PLESK
                {
                    sidebarLink: {
                        label: 'Overview',
                        link: `/my-services/hosting/account/overview/${id}`
                    },
                    component: <PleskOverview hostingid={id} />,
                    condition: !hosting_isReseller && isPlesk,
                    identifier: 'hosting/plesk-overview'
                },
                {
                    sidebarLink: {
                        label: 'Details',
                        link: `/my-services/hosting/account/details/${id}`
                    },
                    component: <PleskDetails hostingid={id} />,
                    condition: !hosting_isReseller && isPlesk,
                    identifier: 'hosting/plesk-details'
                },
                // RESELLER SUBACCOUNT
                {
                    sidebarLink: {
                        label: 'Details',
                        link: `/my-services/hosting/account/details/${id}/subaccount/${subaccount}`
                    },
                    component: <Details hostingid={id} subaccount={subaccount} />,
                    condition: hosting_isReseller && subaccount,
                    identifier: 'hosting/reseller-subaccount-details'
                },
                {
                    sidebarLink: {
                        label: 'Primary Domain',
                        link: `/my-services/hosting/account/primary-domain/${id}/subaccount/${subaccount}`
                    },
                    component: <Primary hostingid={id} subaccount={subaccount} />,
                    condition: hosting_isReseller && subaccount,
                    identifier: 'hosting/reseller-subaccount-primary-domain'
                }
            ],
            config: [
                {
                    sidebarLink: {
                        label: 'Temporary URL',
                        link: `/my-services/hosting/config/temp-url/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <TempURL hostingid={id} subaccount={subaccount ?? null} />,
                    condition: !isPlesk,
                    identifier: 'hosting/temporary-url'
                },
                {
                    sidebarLink: {
                        label: 'External MySQL Access',
                        link: `/my-services/hosting/config/sql/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <DatabaseAccess hostingid={id} subaccount={subaccount ?? null} />,
                    condition: !isPlesk,
                    identifier: 'hosting/external-mysql-access'
                },
                {
                    sidebarLink: {
                        label: 'SSH Access',
                        link: `/my-services/hosting/config/ssh/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <SSHAccess hostingid={id} subaccount={subaccount ?? null} />,
                    condition: true,
                    identifier: 'hosting/ssh-access'
                },
                {
                    sidebarLink: {
                        label: 'IPv6 Management',
                        link: `/my-services/hosting/config/ipv6/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <IPv6 hostingid={id} subaccount={subaccount ?? null} />,
                    condition: !isPlesk,
                    identifier: 'hosting/ipv6-management'
                }
            ],
            reseller: [
                {
                    sidebarLink: {
                        label: null,
                        link: `/my-services/hosting/reseller/subaccounts/${id}`
                    },
                    component: <SubAccountsList hostingid={id} />,
                    condition: true,
                    identifier: 'hosting/reseller-subaccounts'
                }
            ],
            security: [
                {
                    sidebarLink: {
                        label: 'Auto SSL',
                        link: `/my-services/hosting/security/autossl/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <AutoSSL hostingid={id} subaccount={subaccount ?? null} />,
                    condition: !isPlesk,
                    identifier: 'hosting/auto-ssl'
                },
                {
                    sidebarLink: {
                        label: 'Permissions Fixer',
                        link: `/my-services/hosting/security/permissions/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <PermissionFixer hostingid={id} subaccount={subaccount ?? null} />,
                    condition: !isPlesk,
                    identifier: 'hosting/permissions-fixer'
                },
                {
                    sidebarLink: {
                        label: 'Check Firewall',
                        link: `/my-services/hosting/security/firewall/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <Firewall serviceid={id} subaccount={subaccount ?? null} />,
                    condition: true,
                    identifier: 'hosting/check-firewall'
                },
                {
                    sidebarLink: {
                        label: 'Mod Security',
                        link: `/my-services/hosting/security/modsec/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <ModSec hostingid={id} subaccount={subaccount ?? null} />,
                    condition: !isPlesk,
                    identifier: 'hosting/mod-security'
                },
                {
                    sidebarLink: {
                        label: 'Monarx Security Login',
                        link: `/my-services/hosting/security/monarx/${id}${subaccount ? `/subaccount/${subaccount}` : ''}`
                    },
                    component: <Monarx />,
                    condition: !isPlesk,
                    identifier: 'hosting/monarx-security'
                }
            ],
            admin: [
                {
                    sidebarLink: {
                        label: 'Move Service',
                        link: `/my-services/hosting/admin/move/${id}`
                    },
                    component: <Move id={id} product={productName} domain={hosting_information_data?.attributes.domain} />,
                    condition: !isVictoriaEducationHosting,
                    identifier: 'hosting/move-service'
                },
                {
                    sidebarLink: {
                        label: 'Cancel Service',
                        link: `/my-services/hosting/admin/cancel/${id}`
                    },
                    component: (
                        <Cancel
                            cancel={{
                                id: id,
                                service: `webHosting`,
                                status: hosting_cancel_status,
                                keep: {
                                    title: 'Keep Hosting Service',
                                    desc: `Are you sure you'd like to cancel this request and keep your service?`,
                                    action: (e) => {
                                        e.preventDefault();
                                        cancelRequest(id);
                                    }
                                },
                                onSuccess: () => {
                                    history.push(`/my-services/hosting`);
                                }
                            }}
                            title="Cancel Service"
                            desc="Once you cancel this service, all files, emails, databases and configurations will be deleted. To keep your data, make a full backup before cancelling your service."
                            label="Cancel Hosting Service"
                        />
                    ),
                    condition: !isVictoriaEducationHosting,
                    identifier: 'hosting/cancel-service'
                }
            ]
        };

        const renderSidemenuNew = () => {
            const accountsList = {
                list_title: 'Account',
                list_icon: 'accounts',
                list_items: handleSideMenuOptions(modules, 'account')
            };

            const configList = {
                list_title: 'Configuration',
                list_icon: 'other-control-panel',
                list_items: handleSideMenuOptions(modules, 'config')
            };

            const securityList = {
                list_title: 'Security',
                list_icon: 'secure-hosting',
                list_items: handleSideMenuOptions(modules, 'security')
            };

            const adminList = {
                list_title: 'Admin',
                list_icon: 'settings',
                list_items: handleSideMenuOptions(modules, 'admin')
            };

            const links = [];

            if (accountsList.list_items.length > 0) links.push(accountsList);
            if (configList.list_items.length > 0) links.push(configList);
            if (securityList.list_items.length > 0) links.push(securityList);
            if (adminList.list_items.length > 0) links.push(adminList);

            return <Sidebar links={links} />;
        };

        const renderSection = () => {
            if (subpage && !section) {
                return <Account />;
            } else {
                switch (subpage) {
                    default:
                    case 'account':
                        return <Account modules={modules.account.filter(({ condition }) => condition)} />;

                    case 'config':
                        return <Configuration modules={modules.config.filter(({ condition }) => condition)} />;

                    case 'security':
                        return <Security modules={modules.security.filter(({ condition }) => condition)} />;

                    case 'admin':
                        return <Admin modules={modules.admin.filter(({ condition }) => condition)} />;

                    case 'reseller':
                        return <Reseller />;
                }
            }
        };

        const isLoading = () => {
            if (hosting_information_status === 'loading' || hosting_information_subaccount_status === 'loading' || !hosting_information_status) {
                return 'loading';
            } else if (hosting_information_status === 'error' || hosting_information_subaccount_status === 'error') {
                return 'error';
            }
            return 'success';
        };

        const handleRenderState = () => {
            if (isLoading() === 'loading') {
                return <RequestLoader />;
            } else if (isLoading() === 'error') {
                return <PageNotFound />;
            }

            if (isURLValid(modules, url)) {
                return (
                    <Fragment>
                        {hosting_subaccount_username ? renderSubaccountTitle() : renderTitle()}

                        {app_viewport === 'sm' || app_viewport === 'xs' ? renderSidemenuNew() : ''}

                        <Page
                            className={`hosting${hosting_subaccount_username ? ` subaccount ` : ``}`}
                            sidebar={app_viewport === 'md' || app_viewport === 'lg' || app_viewport === 'xl' ? renderSidemenuNew() : ''}
                        >
                            <Transition when={subpage} className="hosting__section">
                                {ventraSynMigrationBanners &&
                                    vsm_requires_migration_data?.requires_migration &&
                                    vsm_reseller_list_data?.find((item) => item.id.toString() === id.toString()) && <VentraSynBanner />}
                                {renderSection()}
                            </Transition>
                        </Page>
                    </Fragment>
                );
            }

            return <PageNotFound />;
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <Fragment>
                <Subnav pathname={pathname} />
                {handleRenderState()}
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_information_status: state.hosting.hosting_information_status,
        hosting_information_error: state.hosting.hosting_information_error,
        hosting_information_data: state.hosting.hosting_information_data,
        hosting_isReseller: state.hosting.hosting_isReseller,
        hosting_information_subaccount_status: state.hosting.hosting_information_subaccount_status,
        hosting_information_subaccount_data: state.hosting.hosting_information_subaccount_data,
        hosting_subaccount_username: state.hosting.hosting_subaccount_username,
        vsm_reseller_list_data: state.vsm.vsm_reseller_list_data,
        vsm_requires_migration_data: state.vsm.vsm_requires_migration_data,
        app_viewport: state.app.app_viewport,
        hosting_cancel_status: state.hosting.hosting_cancel_status,
        hosting_addons_list_status: state.hosting.hosting_addons_list_status,
        hosting_addons_list_data: state.hosting.hosting_addons_list_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getHostingInformation,
            prepStateForSubaccount,
            resetHostingState,
            resetHostingSubaccount,
            getHostingSubAccountInformation,
            getSubaccountList,
            cpanelSubAccountLogin,
            resetResellerState,
            resetHostingSecurity,
            resetHostingSecurityRequests,
            resetHostingConfiguration,
            resetHostingConfigurationRequests,
            resetHostingAdmin,
            resetHostingAdminRequests,
            storeUsername,
            cpanelLogin,
            pleskLogin,
            whmLogin,
            checkRequiresMigration,
            getMigratableResellerAccounts
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hosting));
