/**
 * Extracts the first key from a dot-separated string.
 * @param {string} val - The input string.
 * @returns {string} The first key.
 */
function extractValueKey(val) {
    return val.includes('.') ? val.split('.')[0] : val;
}

/**
 * Extracts the form keys from a module's formFields.
 * @param {Object} module - The module containing formFields.
 * @returns {string[]} An array of extracted form keys.
 */
function extractFormKeys(module) {
    if (module?.type?.formFields) {
        return Object.values(module.type.formFields).map(extractValueKey);
    }
    return [];
}

/**
 * Gets the accepted form keys from an array of modules.
 * @param {Object[]} modules - The array of modules containing formFields.
 * @returns {string[]} An array of accepted form keys.
 */
function getAcceptedFormKeys(modules) {
    if (!modules) return [];
    return modules.flatMap(extractFormKeys);
}

/**
 * Filters an array of initial value keys based on accepted form keys.
 * @param {string[]} initialValuesKeys - Array of initial value keys.
 * @param {string[]} acceptedFormFieldKeys - Array of accepted form keys.
 * @returns {string[]} Filtered initial value keys.
 */
function filterInitialValuesKeys(initialValuesKeys, acceptedFormFieldKeys) {
    return initialValuesKeys.filter((valueKey) => acceptedFormFieldKeys.includes(valueKey));
}

/**
 * Creates a new object with selected keys from initialValues.
 * @param {Object} initialValues - Original initial values object.
 * @param {string[]} keys - Keys to include in the new object.
 * @returns {Object} A new object with selected keys from initialValues.
 */
function createNewInitialValuesObject(initialValues, keys) {
    return keys.reduce((newInitialValues, initValueKey) => {
        newInitialValues[initValueKey] = initialValues[initValueKey];
        return newInitialValues;
    }, {});
}

/**
 * Modifies initial values based on accepted form keys from modules.
 * @param {Object} params - The parameters object.
 * @param {Object} params.initialValues - Original initial values object.
 * @param {Object[]} params.modules - The array of modules containing formFields.
 * @returns {{ [x: string]: any; }} Modified initial values object.
 */
export function modifyInitialValues({ initialValues, modules }) {
    if (!initialValues) return {};
    const acceptedFormFieldKeys = getAcceptedFormKeys(modules);
    const initialValuesKeys = Object.keys(initialValues);
    const filteredInitialValuesKeys = filterInitialValuesKeys(initialValuesKeys, acceptedFormFieldKeys);
    return createNewInitialValuesObject(initialValues, filteredInitialValuesKeys);
}
