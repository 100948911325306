/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useEnterNrlDrawMutation, useGetNrlEligibilityQuery } from 'config/containers/promotions/queries';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import NrlImage from 'assets/images/promo/nrlGiveaway/nrlGiveaway.jpg';

/**********************************************************************************************************
 *   TYPES
 **********************************************************************************************************/
type TError = {
    errors?: { details: string };
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NrlGiveawayCard = () => {
    /***** STATE *****/
    const [isModalOpen, setIsModalOpen] = useState(false);

    /***** QUERIES *****/
    const { isError: isEligibilityError, error: eligibilityError } = useGetNrlEligibilityQuery() as { isError: boolean; error: TError };
    const { mutate: enterDraw, isPending: isEnterDrawPending } = useEnterNrlDrawMutation();

    /***** FUNCTIONS *****/
    const handleClick = () => {
        enterDraw(undefined, {
            onSuccess: () => {
                setIsModalOpen(true);
            }
        });
    };

    const closeModal = () => setIsModalOpen(false);

    /***** RENDER HELPER *****/
    function renderConfirmationLightBox() {
        return (
            <Modal isOpen={isModalOpen} onClose={closeModal} ariaLabel="Contest Entry Confirmation">
                <Flex direction="column" justify="center" items="center" gap={0}>
                    <Text.Heading h2>{`You're in!`}</Text.Heading>
                    <Text size--m secondary>
                        The winner will be drawn and contacted on 14 August 2024.
                    </Text>

                    <Padding top={7}>
                        <SolidButton color="primary" onClick={() => setIsModalOpen(false)} className="nrlGiveawayLightbox__button">
                            Go back
                        </SolidButton>
                    </Padding>
                </Flex>
            </Modal>
        );
    }

    function renderButtonContents() {
        if (eligibilityError?.errors[0]?.details === 'Already Redeemed!') {
            return 'Entered';
        }

        if (eligibilityError?.errors[0]?.details === 'Promo has ended') {
            return 'Promo Finished';
        }

        if (isEnterDrawPending) {
            return <RequestLoader />;
        }

        return 'Enter Now';
    }

    /***** RENDER *****/
    return (
        <>
            <RewardCard>
                <RewardCard.Image tag="GIVEAWAY">
                    <div className="rewardCard__bg rewardCard__bg--overflowHidden">
                        <img src={NrlImage} alt="NRL" width={278} />
                    </div>
                </RewardCard.Image>
                <RewardCard.Body
                    title="Win 2 tickets to watch Rabbitohs vs Roosters at Accor Stadium, plus flights and accommodation"
                    description="Enter now for your chance to win two corporate box tickets to watch the South Sydney Rabbitohs take on the Sydney Roosters at Accor Stadium."
                    subDescription={
                        <Padding top={1} bottom={6}>
                            <Anchor
                                color="secondary"
                                hoverColor="secondary"
                                underline
                                href="https://ventraip.com.au/terms-policies-agreements/viprewards-nrl-round-27-rabbitohs-vs-roosters-giveaway/"
                                target="_blank"
                            >
                                <Text italic span size--xs>
                                    *Terms and conditions apply.
                                </Text>
                            </Anchor>
                        </Padding>
                    }
                >
                    <SolidButton color="primary" disabled={isEligibilityError} onClick={handleClick}>
                        {renderButtonContents()}
                    </SolidButton>
                </RewardCard.Body>
            </RewardCard>

            {/* renders onSuccess of entering the draw */}
            {renderConfirmationLightBox()}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
