/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ShowAll } from '../helpers/showAll';
import { ShowMore } from '../helpers/showMore';
import { VPSTableBody } from './components/body';
import { VPSTableTitleBar } from './components/titlebar';
import { VPSOverviewContext, useVPSTableSelector } from './own';

/***** MEGAMAY24 START *****/
import { MegaMayVpsBanner } from 'config/containers/promotions/megamay2024/components/servicesBanner/panels';
/***** MEGAMAY24 END *****/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXTable from 'components/NXTable';
import Border from 'components/Utils/Border';
import { WrapWithComponent } from 'components/WrapWithComponent';

/*   ACTIONS
 *****************************************************/
import { cancelListVPS } from 'utilities/api/services';
import { removeVPSCancellation } from '../../../vps/action';
import { getVPSList } from '../../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { activePromotion } from 'utilities/methods/commonActions';
import { useSearchState } from '../helpers/useSearchState';
import { useServiceLightboxState } from '../helpers/useServiceLightboxState';
import { useSort } from '../helpers/useSort';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('./types').TVPSOverview.TVPSTable} TVPSTable
 */

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';
import './_VPSOverview.scss';
import { VPSBanner } from './components/vps';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {TVPSTable}
 */
export const VPSTable = ({ isExpressServiceManager = false }) => {
    /***** HOOKS *****/
    const { vps_list_status, vps_list_data, vps_list_meta, vps_search_status, vps_search_data } = useVPSTableSelector((state) => state.services);
    const { vps_remove_cancellation_status } = useVPSTableSelector((state) => state.vps);
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** STATE *****/
    const [keepServiceLightbox, setKeepServiceLightbox] = useServiceLightboxState();
    const [search, setSearch] = useSearchState(vps_search_status);
    const [filters, , { handleSort }] = useSort({ disabled: search.isSearching });
    const [numberOfRows, setNumberOfRows] = useState(/** @type {number | null} */ (null));

    /***** FUNCTIONS *****/
    /**
     * Gets the table body content to display when no VPSs are found.
     */
    const getErrorMessage = () => {
        switch (true) {
            case isExpressServiceManager:
                return 'You have no VPSs active on your account.';
            case search.isSearching:
                return 'No VPSs matched your search.';
            default:
                return "You have no VPSs active on your account. Why don't you purchase some below to get started!";
        }
    };

    const getNumberOfRecords = () => {
        switch (true) {
            case isExpressServiceManager:
                return 5;
            case typeof numberOfRows === 'number':
                return numberOfRows;
            default:
                return 10;
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        getVPSList({
            ...filters,
            record_per_page: getNumberOfRecords()
        });

        return cancelListVPS;
    }, [filters, numberOfRows]);

    useEffect(() => {
        if (vps_remove_cancellation_status === 'success') {
            setKeepServiceLightbox();
        }
    }, [vps_remove_cancellation_status]);

    /***** RENDER HELPERS *****/
    const listData = (search.isSearching ? vps_search_data : vps_list_data) ?? [];
    const isLoading = vps_list_status === 'loading' || vps_search_status === 'loading';
    const isError = vps_list_status === 'error' || listData.length === 0;

    const classes = /** @type {const} */ ({
        table: classNames('VPSTable', {
            'VPSTable--dashboard': isExpressServiceManager
        })
    });

    const context = /** @satisfies {import("./types").TVPSOverview.TDefaultContext} */ ({
        initialized: true,
        isExpressServiceManager,
        numberOfRows,
        search,
        setNumberOfRows,
        setSearch,
        setKeepService: setKeepServiceLightbox
    });

    /***** RENDER *****/
    return (
        <div className={classes.table}>
            {/* MEGAMAY24 START */}
            {!isExpressServiceManager && activePromotion('megamay2024') ? <MegaMayVpsBanner /> : ''}
            {/* MEGAMAY24 END */}

            {/* Main Component */}
            <VPSOverviewContext.Provider value={context}>
                {!isExpressServiceManager && <VPSTableTitleBar title="Manage Virtual Private Servers" />}

                <WrapWithComponent component={Border} all radius="S_border-radius_default" wrap={!isExpressServiceManager}>
                    {isMobile ? (
                        <NXTable.Body isLoading={isLoading}>
                            {isError ? <NXTable.ErrorMessage message={getErrorMessage()} /> : <VPSTableBody data={listData} />}
                        </NXTable.Body>
                    ) : (
                        <NXTable columns="clamp(150px, 250px, 30%) 1fr 1fr NXActions" onSort={handleSort}>
                            <NXTable.Header>
                                <NXTable.Header.SortableTitle disabled={search.isSearching} sortKey="name">
                                    HOSTNAME
                                </NXTable.Header.SortableTitle>
                                <NXTable.Header.SortableTitle disabled={search.isSearching} sortKey="status">
                                    STATUS
                                </NXTable.Header.SortableTitle>
                                <NXTable.Header.Title>RENEWS</NXTable.Header.Title>
                                <NXTable.Header.Title></NXTable.Header.Title>
                            </NXTable.Header>
                            <NXTable.Body isLoading={isLoading}>
                                {isError ? <NXTable.ErrorMessage message={getErrorMessage()} /> : <VPSTableBody data={listData} />}
                            </NXTable.Body>
                        </NXTable>
                    )}

                    <ShowAll
                        isExpressServiceManager={isExpressServiceManager}
                        isLoading={isLoading}
                        totalRows={vps_list_meta?.total}
                        numberOfRows={numberOfRows}
                        onClick={() => setNumberOfRows(vps_list_meta?.total)}
                    />

                    <ShowMore
                        isExpressServiceManager={isExpressServiceManager}
                        isLoading={isLoading}
                        totalRows={vps_list_meta?.total}
                        title="show all vps"
                        link="/my-services/vps"
                    />
                </WrapWithComponent>
            </VPSOverviewContext.Provider>

            {/* Lightboxes */}
            {keepServiceLightbox.show && (
                <OverlayLightbox
                    title="Keep VPS Service"
                    onOpen={keepServiceLightbox.show}
                    onClose={() => setKeepServiceLightbox()}
                    loading={vps_remove_cancellation_status}
                    confirm={{
                        desc: `There is currently a pending cancellation request for this service. If you have had a change in mind and would like to keep this service active, please click the button below. Please note this may take some time to reflect in your ${application} account, but the request to keep the service will be instant.`,
                        buttonText: 'Keep Service',
                        buttonAction: () => removeVPSCancellation(keepServiceLightbox.id),
                        closeText: 'No, Go Back',
                        closeAction: () => setKeepServiceLightbox(),
                        loading: vps_remove_cancellation_status
                    }}
                />
            )}

            {/* Purchase VPS Banner */}
            {!isExpressServiceManager && <VPSBanner />}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
