/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { _getMeta } from 'components/Form/HookForm/getMeta';

/**
 * HookForm Utilities
 */
export const HookForm = Object.freeze({
    getMeta: _getMeta
});
