/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Select } from 'components/Form/Select/_Select';
import { _SelectHookFormDefault } from 'components/Form/Select/wrappers/hookForm/Default';
import { _SelectReduxFormDefault } from 'components/Form/Select/wrappers/reduxForm/Default';

const Select = Object.assign(_Select, {
    HookForm: _SelectHookFormDefault,
    ReduxForm: _SelectReduxFormDefault
});

export { Select };
