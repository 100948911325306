/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ValidationMessage } from 'components/Form/ValidationMessage';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CheckBoxListItem as CheckBoxListItem } from 'components/Form/CheckBoxList/_CheckBoxListItem';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { HookForm } from 'components/Form/HookForm';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type HookFormCheckboxItem = React.FC<{
    children?: React.ReactNode;
    name: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CheckBoxListItemReactHookFormDefault: HookFormCheckboxItem = ({ children, name }) => {
    /***** HOOKS *****/
    const { field, fieldState } = useController({ name });

    /***** FUNCTIONS *****/
    const handleChange = (e: boolean) => {
        field.onChange(e);
        field.onBlur();
    };

    /***** RENDER HELPERS *****/
    const meta = HookForm.getMeta(fieldState);

    /***** RENDER *****/
    return (
        <CheckBoxListItem isChecked={field.value} onChange={handleChange}>
            {children}
            <ValidationMessage.HookForm.Default {...meta} />
        </CheckBoxListItem>
    );
};
