/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Radio } from 'components/Form/Radio';
import SelectOptions from '../../SelectDropdown/Options';
import { _CustomDropdown as CustomDropdown } from '../_CustomDropdown';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_BillingCyclePrefab.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _BillingCyclePrefab({ isActive, selectedNewCycle, options = [], setOption }) {
    /***** RENDER *****/
    return (
        <CustomDropdown
            className="BillingCyclePrefab"
            label={({ labelRef, dropdownTitleOnClick }) => (
                <button className="BillingCyclePrefab__title" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                    <Radio.Circle isActive={isActive} />
                    {isActive && selectedNewCycle ? `Switch billing cycle to ${selectedNewCycle}` : 'Choose an alternative billing cycle'}
                    <i className="item__icon icon icon-chevron-down" />
                </button>
            )}
            render={(closeDropdown) => (
                <SelectOptions
                    options={options.map(({ label, value }) => ({
                        label,
                        value,
                        onSelect: () => {
                            setOption(value);
                            closeDropdown();
                        },
                        isActive: isActive && selectedNewCycle === value
                    }))}
                />
            )}
        />
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

_BillingCyclePrefab.propTypes = {
    // The currently selected billing cycle
    selectedNewCycle: PropTypes.string,

    // The billing cycle options
    options: PropTypes.arrayOf(
        PropTypes.shape({
            // Any ui, to be shown as the option label. Falls back to "value" if no label is provided
            label: PropTypes.node,

            // The option value
            value: PropTypes.string
        })
    ),

    // Whether or no the custom billing cycle radio button is selected
    isActive: PropTypes.bool,

    // The function to call which sets a new billing cycle
    setOption: PropTypes.func
};

export { _BillingCyclePrefab };
