/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { nonDirectAuTLDs } from 'config/config';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Admin from './sections/admin';
import DNS from './sections/dns';
import General from './sections/general';
import Security from './sections/security';

import { PhosphorIcons } from 'components/Icons/Phosphor';
import Move from 'components/Move/';
import AddKatana from 'containers/domain/modules/addKatana';
import Childns from 'containers/domain/modules/childns';
import { DeleteDomain } from 'containers/domain/modules/delete';
import { AddHosting } from './modules/addHosting';
import ChangeRegistrant from './modules/changeRegistrant';
import Contacts from './modules/contacts';
import ManageDNS from './modules/dns';
import Dnssec from './modules/dnssec';
import Eligibility from './modules/eligibility';
import EppCode from './modules/eppCode';
import Protect from './modules/idprotect';
import Overview from './modules/overview';
import Registrant from './modules/registrant';
import Renew from './modules/renew';
import TransferLock from './modules/transferLock';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { DevelopmentModuleFilter } from 'components/DevelopmentModuleFilter';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import FetchPageError from 'components/Errors/FetchPageError';
import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';
import SideBarPage from 'components/SideBarPage';
import Subnav from 'components/Subnav';
import Title from 'components/Title';
import Transition from 'components/Transition';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getAuDirectStatus, resetAuDirectStatusState } from 'containers/auDirect/action';
import { getHostingList } from 'containers/services/action';
import { handleRedirect } from 'utilities/methods/redirects/methods';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';
import { createModules, handleSideMenuOptions, isURLValid } from 'utilities/methods/sectionRendering';
import { getDomainInformation, getDomainRecords, getEligibility } from './action';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';
import { genericTerminatedCancelled } from 'utilities/methods/redirects/messageContent';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

import './_domain.scss';
const localStorageIdentifier = 'domains/sidebar';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Domains = ({
    /**
     * Redux Props
     */
    domain_information_error,
    domain_information_status,
    domain_information_data,
    domain_current_configuration_status,
    app_check_token_data,
    hosting_list_status,
    hosting_list_data,
    audirect_register_status,
    location,
    history,
    match
}) => {
    const { pathname } = location;
    const { url } = match;

    const domain = domain_information_data?.attributes?.domain;

    /***** STATE *****/
    const [ccTLD, setCcTLD] = useState(false);
    const [expired, setExpired] = useState(false);
    const [addHosting, setAddHosting] = useState(false);

    /***** HOOKS *****/
    const { id, subpage, section } = useParams();

    /***** EFFECTS *****/
    useEffect(() => {
        getDomainInformation(id);
        getDomainRecords(id);
        resetAuDirectStatusState();
    }, []);

    useEffect(() => {
        if (!['success', 'error'].includes(domain_information_status)) {
            return;
        }

        if (domain_information_error?.status === 403) {
            history.push(redirectUrls.domain);
            pushNotification(genericTerminatedCancelled);
        } else if (domain_information_data) {
            handleRedirect({
                serviceType: 'domain',
                status: domain_information_data?.attributes?.status,
                domainName: domain,
                history
            });
        }
    }, [domain_information_status]);

    useEffect(() => {
        if (domain_information_status !== 'success') {
            return;
        }

        getHostingList();

        if (domain_information_data?.attributes) {
            const { status, domain } = domain_information_data.attributes;

            // Fetch .au direct availability data if the current domain is a non-direct .au domain
            if (nonDirectAuTLDs.some((nonDirectTld) => domain.endsWith(nonDirectTld))) {
                getAuDirectStatus(id);
            }

            // COR components is now being hidden for .id.au domains, and for .au domains with Citizen/Resident eligibility type. So Eligibility data needs to be fetched here
            if (
                // Only fetch eligibility data here if its a .au domain...
                domain.endsWith('au') &&
                // and its a direct .au domain
                nonDirectAuTLDs.every((nonDirectTld) => !domain.endsWith(nonDirectTld)) &&
                // Dont fetch if the subpage = "general" because the eligibility component within general will already be fetching it
                subpage !== 'general'
            ) {
                getEligibility(id);
            }

            if (domain.endsWith('.au') || domain.endsWith('.nz') || domain.endsWith('.uk')) {
                setCcTLD({
                    au: domain.endsWith('.au'),
                    nz: domain.endsWith('.nz'),
                    uk: domain.endsWith('.uk')
                });
            }

            if (status === 'Expired') {
                setExpired(true);
            }
        }
    }, [domain_information_status]);

    useEffect(() => {
        if (hosting_list_status !== 'success') return;
        if (!hosting_list_data?.find((service) => service.attributes?.domain === domain_information_data?.attributes?.domain)) {
            setAddHosting(true);
        }
    }, [hosting_list_status]);

    useEffect(() => {
        if (audirect_register_status !== 'success') return;
        getDomainInformation();
        getAuDirectStatus(id);
    }, [audirect_register_status]);

    const showCOR = () => ccTLD?.au;

    const renderTitle = () => {
        if (domain_information_data) {
            return (
                <Title
                    serviceTitle={domain_information_data.attributes.domain}
                    serviceType={domain_information_data.type}
                    action={[
                        <CustomDropdown
                            className="TitleShared__singleDropdown"
                            label={({ labelRef, dropdownTitleOnClick }) => (
                                <button
                                    className="TitleShared__singleDropdownButton"
                                    ref={labelRef}
                                    type="button"
                                    onClick={() => dropdownTitleOnClick()}
                                >
                                    Domain Names Help
                                    <PhosphorIcons.Caret.Down className="item__icon" />
                                </button>
                            )}
                            key="dropdown"
                            render={() => (
                                <SelectOptions
                                    options={[
                                        {
                                            label: 'Domain Name Help Articles',
                                            onSelect: () => history.push('/support/support-centre/domain-names')
                                        },
                                        {
                                            label: 'Submit a Technical Support eTicket for this Service',
                                            onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                        },
                                        {
                                            label: 'Submit a Billing eTicket for this Service',
                                            onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                        }
                                    ]}
                                />
                            )}
                        />
                    ]}
                />
            );
        }
    };

    const { data: check_katana_domain_data } = katanaQuery.checkDomain.useQuery(domain);

    const { modules, originalModules } = createModules(
        {
            general: [
                {
                    sidebarLink: {
                        label: 'VIPsites Service',
                        link: `/my-services/domains/general/add-vip-site/${id}`
                    },
                    component: <AddKatana domain={domain} />,
                    condition: check_katana_domain_data?.site_exists,
                    identifier: 'domain/add-vip-site/site_exists'
                },
                {
                    sidebarLink: {
                        label: 'Overview',
                        link: `/my-services/domains/general/overview/${id}`
                    },
                    component: <Overview ccTLD={ccTLD} />,
                    condition: true,
                    identifier: 'domain/overview'
                },
                {
                    sidebarLink: {
                        label: 'Renew',
                        link: `/my-services/domains/general/renew/${id}`
                    },
                    component: <Renew domainid={id} expiry={domain_information_data?.attributes.expiry_date} />,
                    condition: true,
                    identifier: 'domain/renew'
                },
                {
                    sidebarLink: {
                        label: 'Registrant Email',
                        link: `/my-services/domains/general/registrant-email/${id}`
                    },
                    component: <Registrant domainid={id} disabled={expired} />,
                    condition: true,
                    identifier: 'domain/registrant-email'
                },
                {
                    sidebarLink: {
                        label: 'Contacts',
                        link: `/my-services/domains/general/contacts/${id}`
                    },
                    component: <Contacts domainid={id} disabled={expired} />,
                    condition: true,
                    identifier: 'domain/contacts'
                },
                {
                    sidebarLink: {
                        label: 'Eligibility Information',
                        link: `/my-services/domains/general/eligibility/${id}`
                    },
                    component: <Eligibility domainid={id} />,
                    condition: ccTLD && ccTLD.au,
                    identifier: 'domain/eligibility-information'
                },
                {
                    sidebarLink: {
                        label: 'Add Hosting Service',
                        link: `/my-services/domains/general/add-hosting/${id}`
                    },
                    component: <AddHosting />,
                    condition: addHosting,
                    identifier: 'domain/add-hosting'
                },
                {
                    sidebarLink: {
                        label: check_katana_domain_data?.site_exists ? 'VIPsites Service' : 'Add VIPsites Service',
                        link: `/my-services/domains/general/add-vip-site/${id}`
                    },
                    component: <AddKatana domain={domain} />,
                    condition: check_katana_domain_data && !check_katana_domain_data?.site_exists,
                    identifier: 'domain/add-vip-site/site_does_not_exists'
                }
            ],
            dns: [
                {
                    sidebarLink: {
                        label: 'Manage DNS',
                        link: `/my-services/domains/manage/dns/${id}`
                    },
                    component: <ManageDNS disabled={expired} />,
                    condition: true,
                    identifier: 'domain/manage-dns'
                },
                {
                    sidebarLink: {
                        label: 'Child Nameservers',
                        link: `/my-services/domains/manage/child-nameservers/${id}`
                    },
                    component: <Childns domainid={id} disabled={expired} />,
                    condition: true,
                    identifier: 'domain/child-nameservers'
                },
                {
                    sidebarLink: {
                        label: 'DNSSEC DS Data',
                        link: `/my-services/domains/manage/dnssec/${id}`
                    },
                    component: <Dnssec domainid={id} disabled={expired} />,
                    condition: true,
                    identifier: 'domain/dnssec-ds-data'
                }
            ],
            security: [
                {
                    sidebarLink: {
                        label: 'ID Protection',
                        link: `/my-services/domains/security/id-protection/${id}`
                    },
                    component: <Protect domainid={id} disabled={expired} />,
                    condition: !ccTLD,
                    identifier: 'domain/id-protection'
                },
                {
                    sidebarLink: {
                        label: 'Transfer Lock',
                        link: `/my-services/domains/security/transfer/${id}`
                    },
                    component: <TransferLock domainid={id} disabled={expired} />,
                    condition: !ccTLD,
                    identifier: 'domain/transfer-lock'
                },
                {
                    sidebarLink: {
                        label: 'EPP Password',
                        link: `/my-services/domains/security/epp/${id}`
                    },
                    component: <EppCode domainid={id} />,
                    condition: true,
                    identifier: 'domain/epp-password'
                }
            ],
            admin: [
                {
                    sidebarLink: {
                        label: 'Move Domain Name',
                        link: `/my-services/domains/admin/move/${id}`
                    },
                    component: <Move id={id} product="DOMAIN NAME" domain={domain_information_data?.attributes.domain} />,
                    condition: true,
                    identifier: 'domain/move-domain-name'
                },
                {
                    sidebarLink: {
                        label: 'Change of Registrant',
                        link: `/my-services/domains/admin/cor/${id}`
                    },
                    component: <ChangeRegistrant domainid={id} disabled={expired} />,
                    condition: showCOR(),
                    identifier: 'domain/change-of-registrant'
                },
                {
                    sidebarLink: {
                        label: 'Delete Domain Name',
                        link: `/my-services/domains/admin/delete/${id}`
                    },
                    component: <DeleteDomain />,
                    condition: !!domain_information_data?.attributes?.auto_online_giveaway,
                    identifier: 'domain/delete-domain'
                }
            ]
        },
        localStorageIdentifier
    );

    const renderSection = () => {
        if (subpage && !section) {
            return <General />;
        } else {
            switch (subpage) {
                default:
                case 'general':
                    return <General modules={modules.general.filter(({ condition }) => condition)} />;

                case 'manage':
                    return <DNS modules={modules.dns.filter(({ condition }) => condition)} />;

                case 'security':
                    return <Security modules={modules.security.filter(({ condition }) => condition)} />;

                case 'admin':
                    return <Admin modules={modules.admin.filter(({ condition }) => condition)} />;
            }
        }
    };

    const handleRenderState = () => {
        if (domain_information_status === 'error') {
            return <PageNotFound />;
        }

        if (domain_current_configuration_status === 'error') {
            return <FetchPageError />;
        }

        if (domain_information_status === 'loading' || domain_current_configuration_status === 'loading') {
            return <RequestLoader />;
        }

        const generalList = {
            list_title: 'General',
            list_icon: 'accounts',
            list_items: handleSideMenuOptions(modules, 'general')
        };

        const dnsList = {
            list_title: 'DNS',
            list_icon: 'dns-records',
            list_items: handleSideMenuOptions(modules, 'dns')
        };

        const securityList = {
            list_title: 'Security',
            list_icon: 'secure-hosting',
            list_items: handleSideMenuOptions(modules, 'security')
        };

        const adminList = {
            list_title: 'Admin',
            list_icon: 'settings',
            list_items: handleSideMenuOptions(modules, 'admin')
        };

        if (isURLValid(modules, url)) {
            return (
                <Fragment>
                    {renderTitle()}
                    <SideBarPage title="Manage Domain" icon="domain-names" sidebarList={[generalList, dnsList, securityList, adminList]}>
                        <Transition when={subpage}>{renderSection()}</Transition>
                    </SideBarPage>
                </Fragment>
            );
        }

        return <PageNotFound />;
    };

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <Fragment>
            {import.meta.env.MODE === 'development' && (
                <DevelopmentModuleFilter
                    modules={originalModules}
                    localStorageIdentifier={localStorageIdentifier}
                    forceRerender={() => this.setState({ forcedRerenders: this.state.forcedRerenders + 1 })}
                />
            )}
            <Subnav pathname={pathname} />
            {handleRenderState()}
        </Fragment>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    domain_current_configuration_status: state.domain.domain_current_configuration_status,
    domain_information_status: state.domain.domain_information_status,
    domain_information_error: state.domain.domain_information_error,
    domain_information_data: state.domain.domain_information_data,
    hosting_list_status: state.services.hosting_list_status,
    hosting_list_data: state.services.hosting_list_data,
    app_check_token_data: state.app.app_check_token_data,
    audirect_register_status: state.auDirect.audirect_register_status
});

const mapDispatchToProps = {
    getEligibility,
    getAuDirectStatus
};

Domains = connect(mapStateToProps, mapDispatchToProps)(Domains);

Domains = withRouter(Domains);

export default Domains;
