/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { bindActionCreators } from 'redux';
import { showNavigation } from 'utilities/consts';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { scrollOptions, scrollToComponent } from 'utilities/methods/commonActions';
import MobileSidebar from './MobileSidebar';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { focusableElementsSelector } from 'components/Lightboxes/Modal/consts';
import './_Sidebar.scss';

/**********************************************************************************************************
 *   DESKTOP COMPONENT START
 **********************************************************************************************************/
class DesktopSidebar extends Component {
    /************** LIFECYCLE METHODS **************/
    componentWillUnmount() {
        if (this.delay) {
            window.clearTimeout(this.delay);
        }
    }

    render() {
        const { links, title, icon, location, history, sidebarRefs } = this.props;
        const { pathname } = location;

        /*   IS LINK SELECTED
         **********************************************************************************************************/
        const isSelected = (href) => {
            return pathname.trim() === href;
        };

        /*   PROCESS LINKS FOR SECTION
         **********************************************************************************************************/
        /** @param {Array<{ link: string, label: string }>} listItems */
        const handleLinksRender = (listItems) => {
            /**
             * @param {{ link: string }} i
             * @param {number} index
             */
            const handleClick = ({ link }, index) => {
                return () => {
                    const isFirst = Number(index) === 0;

                    history.push(link);
                    if (sidebarRefs[link] && !isFirst) {
                        const doc = document.documentElement;
                        const scrollvar = (window.scrollY || doc.scrollTop) - (doc.clientTop || 0);
                        const location = sidebarRefs[link].getBoundingClientRect().top + scrollvar;

                        scroll.scrollTo(location - 15, scrollOptions);

                        // Focus first focusable element in that module
                        const firstFocusableElementWithiModule = sidebarRefs[link].querySelector(focusableElementsSelector);
                        if (firstFocusableElementWithiModule?.focus) {
                            firstFocusableElementWithiModule.focus();
                        }
                    } else {
                        scroll.scrollTo(0, scrollOptions);
                    }
                };
            };

            if (listItems?.length) {
                return listItems.map(({ link, label }, key) => (
                    <li key={key}>
                        <Anchor onClick={handleClick({ link }, key)} className={isSelected(link) ? 'selected' : ''}>
                            <div>{label}</div>
                        </Anchor>
                    </li>
                ));
            }

            return null;
        };

        /*   HANDLE SECTION RENDER
         **********************************************************************************************************/
        const handleSectionRender = () => {
            if (links && links.length > 0) {
                return links.map((item, index) => {
                    const { list_title, list_items } = item;

                    if (list_items.length <= 0) return '';

                    const firstItem = list_items[0];

                    return (
                        <div key={index} className="Sidebar__content">
                            {list_title ? (
                                <Anchor
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(firstItem.link);
                                        scroll.scrollTo(0);
                                    }}
                                    className={`Sidebar__heading${isSelected(firstItem.link) ? ' selected' : ''}`}
                                >
                                    {list_title}
                                </Anchor>
                            ) : (
                                ''
                            )}
                            <ul className="Sidebar__list">{handleLinksRender(list_items)}</ul>
                        </div>
                    );
                });
            }
            return '';
        };

        /*   HANDLE SIDEBAR HEADER RENDER
         **********************************************************************************************************/
        const handleHeaderRender = () => {
            if (title && icon) {
                return (
                    <div className="Sidebar__header">
                        <i className={`icon icon-${icon}`}></i>
                        <span className="text">{title}</span>
                    </div>
                );
            }

            return '';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <aside className={classNames('Sidebar', `Sidebar--sticky`, { noheader: !title })}>
                {handleHeaderRender()}
                {handleSectionRender()}
            </aside>
        );
    }
}

const mapStateToPropsSidebar = (state) => {
    return {
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToPropsSidebar = (dispatch) =>
    bindActionCreators(
        {
            scrollToComponent
        },
        dispatch
    );

DesktopSidebar = connect(mapStateToPropsSidebar, mapDispatchToPropsSidebar)(DesktopSidebar);

/**********************************************************************************************************
 *   DESKTOP COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   EXPORT COMPONENT START
 **********************************************************************************************************/

class Sidebar extends Component {
    render() {
        const { app_viewport, links, title, icon, location, history } = this.props;

        if (app_viewport === 'xs' || app_viewport === 'sm') {
            return <MobileSidebar title={title} links={links} />;
        }

        return <DesktopSidebar title={title} icon={icon} links={links} location={location} history={history} />;
    }
}

const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            scrollToComponent
        },
        dispatch
    );

Sidebar = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

// Render nothing instead of the Sidebar if showNavigation is false
if (!showNavigation) Sidebar = () => '';

export default Sidebar;

/**********************************************************************************************************
 *   EXPORT COMPONENT END
 **********************************************************************************************************/
