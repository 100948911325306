/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _DevFormTypeChoiceWrapper as DevFormTypeChoiceWrapper } from 'containers/katana/formFields/_DevFormTypeChoiceWrapper/_DevFormTypeChoiceWrapper';
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';
import { KatanaRepeatedFormFieldWrapper } from 'containers/katana/formFields/repeated/KatanaRepeatedFormFieldWrapper';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SITE_SECTION_REPEATED_PROPERTY_INPUT_TYPE } from 'containers/katana/formFields/repeated/consts';
import { _AccordionFieldArrayRenderer as AccordionFieldArrayRenderer } from 'containers/katana/formFields/repeated/inputTypes/accordion/_AccordionFieldArrayRenderer';
import { _GridFieldArrayRenderer as GridFieldArrayRenderer } from 'containers/katana/formFields/repeated/inputTypes/grid/_GridFieldArrayRenderer';
import { _InlineListFieldArrayRenderer as InlineListFieldArrayRenderer } from 'containers/katana/formFields/repeated/inputTypes/inline_list/_InlineListFieldArrayRenderer';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaRepeatedFormFieldSwitchRenderer: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { name, input_type } = property;

    /***** RENDER *****/
    return (
        <DevFormTypeChoiceWrapper value={input_type} options={Object.values(SITE_SECTION_REPEATED_PROPERTY_INPUT_TYPE)}>
            {({ derivedValue }) => {
                switch (derivedValue) {
                    case SITE_SECTION_REPEATED_PROPERTY_INPUT_TYPE.INLINE_LIST:
                        return <KatanaRepeatedFormFieldWrapper property={property} component={InlineListFieldArrayRenderer} />;

                    case SITE_SECTION_REPEATED_PROPERTY_INPUT_TYPE.ACCORDION:
                        return <KatanaRepeatedFormFieldWrapper property={property} component={AccordionFieldArrayRenderer} />;

                    case SITE_SECTION_REPEATED_PROPERTY_INPUT_TYPE.GRID:
                        return <KatanaRepeatedFormFieldWrapper property={property} component={GridFieldArrayRenderer} />;

                    default:
                        return <InvalidInputType name={name} inputType={derivedValue} fieldType="Repeated" />;
                }
            }}
        </DevFormTypeChoiceWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
