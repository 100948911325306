/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _SingleFieldAutoCompleteAddress } from 'components/Form/SingleFieldAutoCompleteAddress/_SingleFieldAutoCompleteAddress';
import { _SingleFieldAutoCompleteAddressHookForm } from 'components/Form/SingleFieldAutoCompleteAddress/wrappers/hookForm/Default';
import { _SingleFieldAutoCompleteAddressReduxFormDefault } from 'components/Form/SingleFieldAutoCompleteAddress/wrappers/reduxForm/Default';

const SingleFieldAutoCompleteAddress = Object.assign(_SingleFieldAutoCompleteAddress, {
    ReduxForm: _SingleFieldAutoCompleteAddressReduxFormDefault,
    HookForm: _SingleFieldAutoCompleteAddressHookForm
});

export { SingleFieldAutoCompleteAddress };
