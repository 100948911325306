/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import QRCode from 'qrcode';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CompanyLogo from 'assets/images/company_logo_white.svg';
import Back from 'assets/images/dashboard/referral/arrow.svg';
import Card from 'assets/images/dashboard/referral/card.png';
import Close from 'assets/images/dashboard/referral/close.svg';
import Email from 'assets/images/dashboard/referral/email_result.svg';
import HighFive from 'assets/images/dashboard/referral/highfive.png';
import MoneyPattern from 'assets/images/dashboard/referral/money-pattern.png';
import QRBackground from 'assets/images/dashboard/referral/qr_code_background.png';
import Referring from 'assets/images/dashboard/referral/referring.png';
import Shopping from 'assets/images/dashboard/referral/shopping.png';
import SMS from 'assets/images/dashboard/referral/sms_result.svg';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { trackingParams } from 'router/google';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';

/*   ACTIONS
 *****************************************************/
import { createReferralCode, sendReferralCode } from 'containers/dashboard/action';
import { copyToClipboard } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';
import { referralReadMoreLink } from 'containers/account/modules/referral';

const referralLoadingMessage = 'Generating your referral code...';

const mixBlendModeFallback = !window.getComputedStyle(document.body).mixBlendMode ? ' fallback' : '';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ReferralLightbox extends Component {
    constructor(props) {
        super(props);

        this.codeRef = React.createRef();

        this.handleEmail = this.handleEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.setForm = this.setForm.bind(this);
        this.generateQrCode = this.generateQrCode.bind(this);

        this.state = {
            showReferralForm: false,
            referralCode: '',
            currentForm: 'referral',
            email: '',
            sms: '',
            currentStep: 1,
            qrCodeData: '',
            qrCodeFullImage: ''
        };
    }

    setForm = (form) => {
        this.setState({
            currentForm: form,
            email: '',
            sms: '',
            currentStep: 1
        });
    };

    handleEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    };

    handleSMS = (e) => {
        this.setState({
            sms: e.target.value
        });
    };

    handleSubmit = (channel) => {
        const { sendReferralCode, history } = this.props;
        const { email, sms } = this.state;

        if (channel === 'email') {
            const attributes = {
                email_address: email
            };

            history.push(`${history.location.pathname}?${trackingParams.referral.email}`);
            sendReferralCode(attributes);
        }

        if (channel === 'sms') {
            const attributes = {
                phone_number: sms
            };

            history.push(`${history.location.pathname}?${trackingParams.referral.sms}`);
            sendReferralCode(attributes);
        }
    };

    handleKeyDown = (e, channel) => {
        const { handleSubmit } = this;
        const { referral_code_send_status } = this.props;

        if (referral_code_send_status !== 'loading') {
            if (e.keyCode === 13) {
                handleSubmit(channel);
            }
        }
    };

    handleNext = (step) => {
        const { currentStep } = this.state;

        if (currentStep !== 4) {
            this.setState({
                currentStep: step + 1
            });
        }
    };

    handlePrevious = (step) => {
        const { currentStep } = this.state;

        if (currentStep !== 1) {
            this.setState({
                currentStep: step - 1
            });
        }
    };

    generateQrCode = (referralCode) => {
        const getCanvasDataUrl = (dataUrl) => {
            // create a canvas of the styled background with the qr code over the top

            // create the canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = 700;
            canvas.height = 1120;

            // create background image
            const backgroundImg = document.createElement('img');
            backgroundImg.src = QRBackground;

            backgroundImg.addEventListener(
                'load',
                () => {
                    // add background img to canvas when it's finished loading
                    ctx.drawImage(backgroundImg, 0, 0);

                    // create qr code image
                    const qrCodeImg = document.createElement('img');
                    qrCodeImg.src = dataUrl;

                    qrCodeImg.addEventListener(
                        'load',
                        () => {
                            // add qr code img to canvas when it's finished loading
                            ctx.drawImage(qrCodeImg, 122, 338);

                            // add link text
                            ctx.font = '30px Proxima-nova';
                            ctx.fillStyle = 'white';
                            ctx.textAlign = 'center';
                            ctx.fillText(referralCode, canvas.width / 2, 235);

                            // convert canvas to an img src
                            const canvasDataUrl = canvas.toDataURL();

                            this.setState({
                                qrCodeFullImage: canvasDataUrl
                            });
                        },
                        { once: true }
                    );
                },
                { once: true }
            );
        };

        QRCode.toDataURL(referralCode || '', { width: 450 })
            .then((dataUrl) => {
                this.setState({
                    qrCodeData: dataUrl
                });
                getCanvasDataUrl(dataUrl);
            })
            .catch(() => {
                this.setState({
                    qrCodeFullImage: 'error'
                });
            });
    };

    renderForm = (form) => {
        const { codeRef, setForm, handleEmail, handleSMS, handleSubmit, handleKeyDown, handleNext, handlePrevious } = this;
        const { referral_code_send_status, closeReferralForm, app_user_data, history, copyToClipboard, lightboxInitialFocusRef } = this.props;
        const { referralCode, email, sms, currentStep, qrCodeData, qrCodeFullImage } = this.state;
        const { id } = app_user_data;
        const currentRoute = window.location.pathname;

        switch (form) {
            case 'referral':
                return (
                    <ReferralForm
                        history={history}
                        codeRef={codeRef}
                        code={referralCode}
                        currentStep={currentStep}
                        setForm={setForm}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                        closeReferralForm={closeReferralForm}
                        currentRoute={currentRoute}
                        id={id}
                        copyToClipboard={copyToClipboard}
                        lightboxInitialFocusRef={lightboxInitialFocusRef}
                        qrCodeFullImage={qrCodeFullImage}
                    />
                );
            case 'email':
                return (
                    <EmailForm
                        email={email}
                        status={referral_code_send_status}
                        handleEmail={handleEmail}
                        setForm={setForm}
                        submit={handleSubmit}
                        handleKeyDown={handleKeyDown}
                        closeReferralForm={closeReferralForm}
                    />
                );
            case 'sms':
                return (
                    <SMSForm
                        sms={sms}
                        status={referral_code_send_status}
                        handleSMS={handleSMS}
                        setForm={setForm}
                        submit={handleSubmit}
                        handleKeyDown={handleKeyDown}
                        closeReferralForm={closeReferralForm}
                    />
                );
            case 'qr':
                return <QrForm qrCodeData={qrCodeData} referralCode={referralCode} setForm={setForm} closeReferralForm={closeReferralForm} />;
            case 'confirmation':
                return (
                    <ConfirmationForm
                        history={history}
                        qrCodeFullImage={qrCodeFullImage}
                        codeRef={codeRef}
                        code={referralCode}
                        setForm={setForm}
                        closeReferralForm={closeReferralForm}
                        copyToClipboard={copyToClipboard}
                    />
                );
            default:
                return null;
        }
    };

    componentDidMount() {
        const { createReferralCode } = this.props;

        createReferralCode();
    }

    componentDidUpdate(prevProps) {
        const { referral_code_create_status, referral_code_create_data, referral_code_send_status } = this.props;
        const { generateQrCode } = this;

        if (referral_code_create_status === 'success' && prevProps.referral_code_create_status === 'loading') {
            const referralCode = `https://getonline.vip/${referral_code_create_data.attributes.code}`;
            generateQrCode(referralCode);

            this.setState({
                referralCode
            });
        }

        if (referral_code_send_status === 'success' && prevProps.referral_code_send_status === 'loading') {
            this.setState({
                currentForm: 'confirmation',
                email: '',
                sms: '',
                currentStep: 1
            });
        }
    }

    render() {
        const { renderForm } = this;
        const { currentForm } = this.state;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return <Fragment>{renderForm(currentForm)}</Fragment>;
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ReferralForm = ({
    history,
    code,
    copyToClipboard,
    currentStep,
    setForm,
    handleNext,
    handlePrevious,
    codeRef,
    closeReferralForm,
    lightboxInitialFocusRef,
    qrCodeFullImage
}) => {
    /***** HOOKS *****/
    const { isAtOrAboveTier } = useVipRewards();

    /***** RENDER HELPERS *****/
    const payoutAmount = `$${isAtOrAboveTier('silver') ? 75 : 50}`;

    const renderStep = (currentStep) => {
        switch (currentStep) {
            default:
            case 1:
                return 'Use the link provided or download the QR code and share your unique referral code with someone who needs a domain name and web hosting.';
            case 2:
                return 'When your referral code is used during an eligible purchase, our system will log the referral so you can keep track of the process in your account.';
            case 3:
                return `If the referee remains active 60 days after their first purchase, you are eligible for ${payoutAmount} in cash!`;
            case 4:
                return (
                    <>
                        Once your referral is eligible for payout, you’ll receive a confirmation email and {payoutAmount} will be added to your{' '}
                        <Anchor to="/billing/ventraip-wallet">VentraIP Wallet</Anchor>.
                    </>
                );
        }
    };

    /***** RENDER *****/
    return (
        <>
            <div className="referral__form">
                <div className={`referral__form--wrapper${mixBlendModeFallback}`}></div>
                <div className="referral__navigate">
                    <div className="referral__navigate--right">
                        <button className="referral__navigate--close" onClick={() => closeReferralForm()}>
                            <span className="referral__navigate--back">Back to {application}</span>
                            <img className="referral__navigate--cross" src={Close} alt="Close" />
                        </button>
                    </div>
                </div>
                <div className="referral__form--container">
                    <div className="referral__form--heading">
                        Refer a friend and earn
                        <span className="referral__form--highlight"> {payoutAmount} CASH</span>
                    </div>
                    <div className="referral__form--options">
                        <div className="referral__form--subheading">Copy and share your personal invite link below</div>

                        <div className="referral__form--copy">
                            <div className="referral__form--clipboard">
                                <input
                                    ref={codeRef}
                                    className="referral__form--link"
                                    type="text"
                                    value={code || referralLoadingMessage}
                                    readOnly
                                    tabIndex={-1}
                                />
                                <div className="referral__tooltip">
                                    <button
                                        ref={lightboxInitialFocusRef}
                                        className="referral__form--button"
                                        onClick={() => copyToClipboard(codeRef?.current?.value)}
                                    >
                                        Copy Code
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="referral__form--text">OR</div>
                        <button className="referral__formButton referral__formButton--email" onClick={() => setForm('email')}>
                            <i className="icon-email2 icon" /> Email link and QR code
                        </button>
                        <button className="referral__formButton referral__formButton--sms" onClick={() => setForm('sms')}>
                            <i className="icon-online-sms icon" />
                            Send SMS invite
                        </button>

                        <a
                            className="referral__formButton referral__formButton--qr"
                            href={qrCodeFullImage || ''}
                            download="referral_qrcode.png"
                            onClick={() => {
                                if (qrCodeFullImage) {
                                    setForm('qr');
                                    history.push(`${history.location.pathname}?${trackingParams.referral.qr}`);
                                }
                            }}
                        >
                            {qrCodeFullImage ? (
                                <>
                                    <i className="icon-qr-code icon" />
                                    Download QR code
                                </>
                            ) : (
                                <>
                                    <i className="icon icon-spinner spinner" />
                                    Generating QR code...
                                </>
                            )}
                        </a>
                    </div>
                </div>
            </div>
            <div className="referral__instruction">
                <div className="referral__instruction--container">
                    <div className="referral__instruction--heading">How it works</div>
                    <div className="referral__instruction--steps">
                        <div className="referral__instruction--wrapper">
                            <div className="referral__instruction--icon">
                                {currentStep !== 1 && (
                                    <button type="button" onClick={() => handlePrevious(currentStep)}>
                                        <i className="referral__instruction--left icon-arrow2" />
                                    </button>
                                )}
                            </div>
                            <div className="referral__instruction--illustrations">
                                {currentStep === 1 && <img className="referral__instruction--illustration" src={Referring} alt="Referring" />}
                                {currentStep === 2 && <img className="referral__instruction--illustration" src={Shopping} alt="Domain shopping" />}
                                {currentStep === 3 && <img className="referral__instruction--illustration" src={Card} alt="Raining cards" />}
                                {currentStep === 4 && <img className="referral__instruction--illustration" src={HighFive} alt="HighFive" />}
                            </div>
                            <div className="referral__instruction--icon">
                                {currentStep !== 4 && (
                                    <button type="button" onClick={() => handleNext(currentStep)}>
                                        <i className="referral__instruction--right icon-arrow2" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="referral__instruction--text">{renderStep(currentStep)}</div>
                    <div className="referral__instruction--indicator">
                        <div className={`referral__instruction--dot ${currentStep === 1 ? 'referral__instruction--active' : ''}`} />
                        <div className={`referral__instruction--dot ${currentStep === 2 ? 'referral__instruction--active' : ''}`} />
                        <div className={`referral__instruction--dot ${currentStep === 3 ? 'referral__instruction--active' : ''}`} />
                        <div className={`referral__instruction--dot ${currentStep === 4 ? 'referral__instruction--active' : ''}`} />
                    </div>
                    <div className="referral__instruction--faq">
                        <div className="referral__instruction--term">Terms and conditions apply.</div>
                        <a className="referral__instruction--link" href={referralReadMoreLink} target="_blank" rel="noopener noreferrer">
                            &nbsp;Read our FAQ Article for more information
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

const EmailForm = ({ email, handleEmail, submit, handleKeyDown, closeReferralForm, setForm, status }) => {
    /***** HOOKS *****/
    const { isAtOrAboveTier } = useVipRewards();

    /***** RENDER HELPERS *****/
    const payoutAmount = `$${isAtOrAboveTier('silver') ? 75 : 50}`;

    /***** RENDER *****/
    return (
        <div className="referral__email">
            <div className="referral__email--wrapper">
                <img className={`referral__backgroundImage${mixBlendModeFallback}`} src={MoneyPattern} alt="Cash" />
                <div className="referral__navigate">
                    <div className="referral__navigate--left">
                        <button type="button" onClick={() => setForm('referral')}>
                            <img src={Back} alt="Arrow" />
                        </button>
                    </div>
                    <button className="referral__navigate--right">
                        <button className="referral__navigate--close" onClick={() => closeReferralForm()}>
                            <span className="referral__navigate--back">Back to {application}</span>
                            <img className="referral__navigate--cross" src={Close} alt="Close" />
                        </button>
                    </button>
                </div>
                <div className="referral__email--container">
                    <div className="referral__email--heading">Email a friend about VentraIP Australia</div>
                    <div className="referral__email--subheading">
                        You&apos;ll receive {payoutAmount} cash when they purchase a hosting plan, and a domain name.
                    </div>
                    <input
                        className="referral__email--field"
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={handleEmail}
                        onKeyDown={(e) => handleKeyDown(e, 'email')}
                    />
                    <button
                        disabled={status === 'loading' ? true : false}
                        className="referral__email--submit"
                        onClick={() => {
                            submit('email');
                        }}
                    >
                        {status === 'loading' ? (
                            <Fragment>
                                <i className="icon icon-spinner spinner" />
                                <span>Sending...</span>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <i className="icon icon-email2" />
                                <span>Send Referral</span>
                            </Fragment>
                        )}
                    </button>
                </div>
            </div>
            <div className="referral__emailResult">
                <div className="referral__emailResult--container">
                    <div className="referral__emailResult--heading">What we send</div>
                    <img className="referral__emailResult--image" src={Email} alt="Email sent" />
                </div>
            </div>
        </div>
    );
};

const SMSForm = ({ sms, handleSMS, submit, handleKeyDown, closeReferralForm, setForm, status }) => {
    /***** HOOKS *****/
    const { isAtOrAboveTier } = useVipRewards();

    /***** RENDER HELPERS *****/
    const payoutAmount = `$${isAtOrAboveTier('silver') ? 75 : 50}`;

    return (
        <div className="referral__sms">
            <div className="referral__sms--wrapper">
                <img className={`referral__backgroundImage${mixBlendModeFallback}`} src={MoneyPattern} alt="Cash" />
                <div className="referral__navigate">
                    <div className="referral__navigate--left">
                        <button type="button" onClick={() => setForm('referral')}>
                            <img src={Back} alt="Arrow" />
                        </button>
                    </div>
                    <button className="referral__navigate--right">
                        <button className="referral__navigate--close" onClick={() => closeReferralForm()}>
                            <span className="referral__navigate--back">Back to {application}</span>
                            <img className="referral__navigate--cross" src={Close} alt="Close" />
                        </button>
                    </button>
                </div>
                <div className="referral__sms--container">
                    <div className="referral__sms--heading">SMS a Friend about VentraIP Australia</div>
                    <div className="referral__sms--subheading">
                        You&apos;ll receive {payoutAmount} cash when they purchase a hosting plan, and a domain name.
                    </div>
                    <div className="referral__sms--phone">
                        <div className="referral__sms--country">+61</div>
                        <input
                            className="referral__sms--field"
                            type="text"
                            value={sms}
                            placeholder="Enter mobile number"
                            onChange={handleSMS}
                            onKeyDown={(e) => handleKeyDown(e, 'sms')}
                        />
                    </div>
                    <button
                        disabled={status === 'loading' ? true : false}
                        className="referral__sms--submit"
                        onClick={() => {
                            submit('sms');
                        }}
                    >
                        {status === 'loading' ? (
                            <Fragment>
                                <i className="icon icon-spinner spinner" />
                                <span>Sending...</span>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <i className="icon-online-sms icon" />
                                <span>Send Referral</span>
                            </Fragment>
                        )}
                    </button>
                </div>
            </div>
            <div className="referral__smsResult">
                <div className="referral__smsResult--container">
                    <div className="referral__smsResult--heading">What we send</div>
                    <img className="referral__smsResult--image" src={SMS} alt="SMS sent" />
                </div>
            </div>
        </div>
    );
};

const QrForm = ({ closeReferralForm, setForm, qrCodeData, referralCode }) => {
    /***** RENDER *****/
    return (
        <div className="referral__qr">
            <div className="referral__qr--wrapper">
                <img className={`referral__backgroundImage${mixBlendModeFallback}`} src={MoneyPattern} alt="Cash" />
                <div className="referral__navigate">
                    <div className="referral__navigate--left">
                        <button type="button" onClick={() => setForm('referral')}>
                            <img src={Back} alt="Arrow" />
                        </button>
                    </div>
                    <div className="referral__navigate--right">
                        <button className="referral__navigate--close" onClick={() => closeReferralForm()}>
                            <span className="referral__navigate--back">Back to {application}</span>
                            <img className="referral__navigate--cross" src={Close} alt="Close" />
                        </button>
                    </div>
                </div>
                <div className="referral__qr--container">
                    <div className="referral__qr--heading">QR code downloaded!</div>
                </div>
            </div>
            <div className="referral__qrResult">
                <div className="referral__qrResult--container">
                    <div className="referral__qrResult--image">
                        <img className="referral__qrResult--companyLogo" src={CompanyLogo} alt="VentraIP Australia" />
                        <Anchor className="referral__qrResult--url" href={referralCode} target="_blank">
                            {referralCode}
                        </Anchor>
                        <p className="referral__qrResult--or">Or</p>
                        <div className="referral__qrResult--codeContainer">
                            <img className="referral__qrResult--code" src={qrCodeData} alt="Referral QR code" />
                            <h4 className="referral__qrResult--scan">
                                <i className="icon icon-mobile" />
                                Scan me
                            </h4>
                        </div>
                        <h3>
                            Have your referral scan this QR code or click the link before purchasing a new hosting and domain service to enable your
                            unique referral ID.
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ConfirmationForm = ({ codeRef, code, copyToClipboard, setForm, closeReferralForm, qrCodeFullImage, history }) => {
    const renderMore = () => {
        return (
            <Fragment>
                <div className="referral__more--heading">Want to send more referral codes?</div>
                <div className="referral__more--subheading">Copy and share your personal invite link below</div>
                <div className="referral__more--options">
                    <div className="referral__more--copy">
                        <div className="referral__more--clipboard">
                            <input ref={codeRef} className="referral__more--link" type="text" value={code || referralLoadingMessage} readOnly />
                            <div className="referral__tooltip">
                                <button className="referral__form--button" onClick={() => copyToClipboard(codeRef?.current?.value)}>
                                    Copy Code
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="referral__more--text">OR</div>
                    <button className="referral__more--email" onClick={() => setForm('email')}>
                        <i className="icon-email2 icon" /> Email a friend
                    </button>
                    <button className="referral__more--sms" onClick={() => setForm('sms')}>
                        <i className="icon-online-sms icon" /> Send SMS invite
                    </button>
                    <a
                        className="referral__formButton referral__formButton--qr"
                        href={qrCodeFullImage || ''}
                        download="referral_qrcode.png"
                        onClick={() => {
                            if (qrCodeFullImage) {
                                setForm('qr');
                                history.push(`${history.location.pathname}?${trackingParams.referral.qr}`);
                            }
                        }}
                    >
                        {qrCodeFullImage ? (
                            <>
                                <i className="icon-qr-code icon" />
                                Download QR code
                            </>
                        ) : (
                            <>
                                <i className="icon icon-spinner spinner" />
                                Generating QR code...
                            </>
                        )}
                    </a>
                </div>
            </Fragment>
        );
    };

    return (
        <div>
            <div className="referral__confirmation">
                <div className="referral__confirmation--wrapper">
                    <img className={`referral__backgroundImage${mixBlendModeFallback}`} src={MoneyPattern} alt="Cash" />
                    <div className="referral__navigate">
                        <div className="referral__navigate--right">
                            <button className="referral__navigate--close" onClick={() => closeReferralForm()}>
                                <span className="referral__navigate--back">Back to {application}</span>
                                <img className="referral__navigate--cross" src={Close} alt="Close" />
                            </button>
                        </div>
                    </div>
                    <div className="referral__confirmation--container">
                        <div className="referral__confirmation--heading">Your referral has been sent!</div>
                        <div className="referral__confirmation--subheading">
                            We&apos;ll let you know when someone signs up to VentraIP Australia with your unique code.
                        </div>
                        <div className="referral__confirmation--icon">
                            <i className="icon-tick-solid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="referral__more">
                <div className="referral__more--container">{renderMore()}</div>
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            referral_code_create_status: state.dashboard.referral_code_create_status,
            referral_code_create_data: state.dashboard.referral_code_create_data,
            referral_code_send_status: state.dashboard.referral_code_send_status,
            app_user_data: state.app.app_user_data
        }),
        {
            createReferralCode,
            sendReferralCode,
            copyToClipboard
        }
    )(ReferralLightbox)
);
