/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Radio as Radio } from 'components/Form/Radio/_Radio';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NRadio } from 'components/Form/Radio/_Radio/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RadioHookFormDefaultProps = Omit<NRadio.Props, 'setActiveRadioButton' | 'activeRadioButton'> & {
    label?: React.ReactNode;
    fullWidth?: boolean;
    name: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RadioHookFormDefault: React.FC<RadioHookFormDefaultProps> = ({ name, label, fullWidth, ...props }) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value, onChange } = field;

    /***** RENDER *****/
    return (
        <FormItem>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner.HookForm name={name} noBackground fullWidth={fullWidth}>
                <Radio {...props} activeRadioButton={value} setActiveRadioButton={onChange} />
            </FormItemInner.HookForm>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
