export function getNestedKeyListIndex(nestedName:string, originalKey:string): number | null {
    const originalKeyEnding = `].${originalKey}`;
    const isItemInList = nestedName.endsWith(originalKeyEnding);
    if (!isItemInList) {
        return null;
    }
    const slicedName = nestedName.slice(0, -originalKeyEnding.length);
    const slicedLength = slicedName.length;
    const subStringedName = Number(slicedName.substring(slicedLength - 1, slicedLength));

    return isNaN(subStringedName) ? null : subStringedName;
}
