/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useCheckTokenData } from 'utilities/hooks/redux/useCheckTokenData';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { katanaSubPageEnums } from 'containers/katana/consts';
const { REGISTER_DOMAIN } = katanaSubPageEnums;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const useDomainIsNotRegistered = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const location = useLocation();

    /***** QUERIES *****/
    const { data: katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);
    const { has_trial_invoice } = katana_service_data?.attributes ?? {};
    const { data: trial_invoice_data } = useGetInvoiceQuery(katana_service_data?.attributes?.trial_invoice_id);

    const { is_user_detail_completed } = useCheckTokenData();

    /***** RENDER HELPERS *****/
    const isOnRegistrationPage = location.pathname.includes(REGISTER_DOMAIN);

    /***** HOOK RESULTS *****/
    return (
        has_trial_invoice &&
        (trial_invoice_data?.attributes?.status === 'unpaid' || (trial_invoice_data?.attributes?.status === 'paid' && !is_user_detail_completed)) &&
        !isOnRegistrationPage
    );
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export default useDomainIsNotRegistered;
