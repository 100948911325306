/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DiskSpaceMessage from '../diskSpaceMessage';
import EditDetailsButton from '../editDetailsButton';
import { getHostingVpsDomain, validateMigrationDetails } from '../methods';
import SkipDetailValidation from '../skipDetailValidation';
import TargetNotEmptyMessage from '../targetNotEmptyMessage';
import { MigrationReduxControlPanelFormPart1 } from './controlPanelValidate';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FieldTypesEnum } from 'components/DynamicForm/utils';
import { REDUX_FORM_BUTTON_STATE, ReduxFormButton } from 'components/Form/Button/reduxForm';
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const formData = [
    {
        label: 'Old Provider Username',
        name: 'username'
    },
    {
        label: 'Old Provider Password',
        name: 'password'
    },
    {
        label: 'Old Provider Domain',
        name: 'domain'
    },
    {
        label: 'Old Provider IP Address',
        name: 'ip_address'
    }
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ControlPanelDetails = (props) => {
    const {
        support_submit_migration_details_check_service_status,
        support_submit_migration_details_check_service_data,
        handleSubmit,
        valid,
        form,
        validationAttempts,
        stepOneComplete,
        handleSkipNow,
        serviceValidationData,
        handleFormDetailsFieldChange,
        serviceValidationModifiedData,
        allowEditDetails,
        canEditDetails,
        selectedService
    } = props;

    const fieldsDisabled = support_submit_migration_details_check_service_status === 'loading';

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <form
            onSubmit={handleSubmit}
            onChange={handleFormDetailsFieldChange}
            className="migrationRequestForm__form migrationRequestForm__controlPanel"
        >
            <EditDetailsButton {...{ canEditDetails, allowEditDetails }} />

            {formData.map((field) => {
                const { label, name } = field;
                return (
                    <Field
                        key={name}
                        component={Input.ReduxForm}
                        label={label}
                        name={name}
                        type={FieldTypesEnum.TEXT}
                        disabled={fieldsDisabled || !canEditDetails}
                        prepend={name === 'domain' ? 'www.' : null}
                        validated={
                            serviceValidationData && !serviceValidationModifiedData.includes(name)
                                ? validateMigrationDetails({ serviceValidationData, name, support_submit_migration_details_check_service_data })
                                : undefined
                        }
                    />
                );
            })}

            <DiskSpaceMessage serviceValidationData={serviceValidationData} selectedService={selectedService} />

            <TargetNotEmptyMessage />

            {!stepOneComplete && validationAttempts <= 0 ? (
                <div className="migrationRequestForm__stepWrapper">
                    <SkipDetailValidation onSkip={handleSkipNow} />
                </div>
            ) : (
                ''
            )}

            <ReduxFormButton
                className="PanelDetailsValidationButton"
                form={form}
                force={(!valid || stepOneComplete) && (!valid || !canEditDetails) ? REDUX_FORM_BUTTON_STATE.INACTIVE : undefined}
            >
                Validate Details
            </ReduxFormButton>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state, props) => {
    const { selectedService } = props;
    const { hosting_list_data, vps_list_data } = state.services;
    const domain = getHostingVpsDomain({ hosting_list_data, vps_list_data, selectedService });
    return {
        support_submit_migration_details_check_service_data: state.support.support_submit_migration_details_check_service_data,
        support_submit_migration_details_check_service_status: state.support.support_submit_migration_details_check_service_status,
        hosting_list_data: state.services.hosting_list_data,
        initialValues: {
            domain
        }
    };
};

ControlPanelDetails = reduxForm(MigrationReduxControlPanelFormPart1)(ControlPanelDetails);

ControlPanelDetails = connect(mapStateToProps)(ControlPanelDetails);

export default ControlPanelDetails;
