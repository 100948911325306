/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _, { keys } from 'lodash';
import React, { useEffect, useRef } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';
import { IconifyIcon } from 'components/Icons/Iconify';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Gradient from 'components/Utils/Gradient';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SuperUserItem } from 'components/StaffMenu/SuperUser/SuperUserItem';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { addSuperAction, canAddSuperActionCondition, matchSuperCode, superActionSubscriberChanges } from 'components/StaffMenu/SuperUser/methods';
import { useStore } from 'utilities/hooks/useStore';
import { useStaffMenu } from '../context';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SUPER_ACTIONS, maxActionKeyLength, superActionNoCheats, superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import './_SuperUser.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      isShowingAvailableActions: boolean;
 *      hideAvailableActions: () => void;
 *      toggleShowAvailableActions: () => void;
 * }>}
 */
const SuperUser = () => {
    /***** STATE *****/
    const [superActions, setSuperActions] = useStore(superUserSuperActionsState);

    /***** HOOKS *****/
    const superCodeRef = useRef('');
    const { active, toggle, setActive, id } = useStaffMenu();

    /***** EFFECTS *****/
    useEffect(() => {
        /** @param {KeyboardEvent} e */
        function handleKeyPress(e) {
            superCodeRef.current += e.key;

            if (superCodeRef.current.length > maxActionKeyLength) {
                const subStringTarget = maxActionKeyLength - superCodeRef.current.length;
                superCodeRef.current = superCodeRef.current.substring(subStringTarget);
            }

            const matchedSuperCode = matchSuperCode(superCodeRef.current);
            const canAddSuperAction = canAddSuperActionCondition(matchedSuperCode);

            if (matchedSuperCode && canAddSuperAction) {
                addSuperAction(matchedSuperCode);
                superCodeRef.current = '';
            }
        }

        document.addEventListener('keydown', handleKeyPress);

        const actionSubscriber = superActionSubscriberChanges();

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            actionSubscriber();
        };
    }, []);

    useEffect(() => {
        if (!keys(superActions).length) {
            setActive((previous) => (previous === id ? '' : previous));
        }
    }, [superActions]);

    const visibleActions = Array.from(new Set([...superActionNoCheats, ...keys(superActions)])).filter(
        (actionKey) => SUPER_ACTIONS[actionKey].menuContent
    );

    const totalActiveActions = _.values(superActions).filter(Boolean).length;

    /***** RENDER *****/
    if (!visibleActions.length) {
        return;
    }

    return (
        <Gradient primary className="SuperUser">
            <Flex fullHeight fullWidth justify="center" items="center">
                <button type="button" style={{ width: '100%', height: '100%' }} onClick={toggle}>
                    <IconifyIcon icon="simple-icons:superuser" white size={28} />
                    {!active && Boolean(totalActiveActions) && (
                        <Flex className="SuperUser__totalActiveActions" gap={1}>
                            <Text size--xs white>
                                {totalActiveActions}
                            </Text>
                            <CheckBox isChecked />
                        </Flex>
                    )}
                </button>
                {active && (
                    <Border all inject>
                        <div className="SuperUser__actions">
                            <Text>Super User Actions</Text>
                            <hr />
                            <div className="SuperUser__actionEntries">
                                {visibleActions.map((action) => {
                                    return <SuperUserItem key={action} action={action} />;
                                })}
                            </div>
                        </div>
                    </Border>
                )}
            </Flex>
        </Gradient>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { SuperUser };
