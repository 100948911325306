/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { modifyInitialValues } from './methods';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * A React hook that modifies initial values based on form fields from modules.
 * @param initialValues - Original initial values object.
 * @param modules - The array of modules containing formFields.
 * @returns Modified initial values object.
 */
export function useModifiedInitialValues(
    initialValues: Object,
    modules: (string | number | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal)[],
    isSubmitting = false
) {
    const [newInitialValues, setNewInitialValues] = useState<Object>({});
    useEffect(() => {
        if (isSubmitting) return;
        if (!initialValues) {
            return setNewInitialValues({});
        }
        setNewInitialValues(modifyInitialValues({ initialValues, modules }));
    }, [initialValues, modules]);

    return newInitialValues;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
