/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getExpiryInfo } from 'containers/services/modules/domains/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The expiry date as displayed in the TPP domain renew module
 */
function TPPDomainRenewExpiry({ expiryDate }) {
    /***** RENDER HELPERS *****/
    const expiryInfo = getExpiryInfo(expiryDate);

    /***** RENDER *****/
    return (
        <div>
            <Text black>
                <span>{expiryInfo.partTwo}</span>
            </Text>{' '}
            <Text size--s secondary bold italic>
                <span>({expiryInfo.formattedExpiryDate})</span>
            </Text>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

TPPDomainRenewExpiry.propTypes = {
    /**
     * The expiry date of the domain, in the format: "yyyy-MM-dd"
     */
    expiryDate: PropTypes.string
};

export default TPPDomainRenewExpiry;
