/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { merge, values } from 'lodash';
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import { Hr } from 'components/Utils/Hr';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FONT_META_TYPES } from 'containers/katana/containers/contentEditorModules/webpageFonts/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      siteData: KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes;
 *      setSiteData: React.Dispatch<React.SetStateAction<KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes>>;
 *      closeFontOptions: () => void;
 *      preventClickRef: React.MutableRefObject<HTMLElement | null>;
 * }>}
 */
const PresetCustomiserFontSelect = ({ siteData, setSiteData, closeFontOptions, preventClickRef }) => {
    /***** HOOKS *****/

    const fontSelectRef = useRef();
    const { id } = useSetupEditorRouteParams();

    useClickAway([fontSelectRef, preventClickRef], closeFontOptions);

    /***** FUNCTIONS *****/
    /**
     *
     * @param {'heading' | 'body'} siteStyle
     */
    function selectSiteStyleType(siteStyle) {
        /**
         * @param {KatanaAPI.Katana.Site.ServiceID.Meta.Fonts.GET.FontData} font
         * @returns {NSelectDropDown.Option}
         */
        return function formatForSelectDropdown(font) {
            return {
                label: <Text _style={{ fontFamily: font.font_family }}>{font.name}</Text>,
                value: font.id,
                onClick: () => {
                    setSiteData((prev) => {
                        const mergedSiteData = merge({}, prev, {
                            style: {
                                fonts: {
                                    [siteStyle]: font.id
                                }
                            }
                        });
                        return mergedSiteData;
                    });
                }
            };
        };
    }
    /***** QUERIES *****/
    const { data: get_fonts_meta_data } = katanaQuery.serviceID.meta.getFonts.useQuery(id);
    const { data: get_service_data } = katanaQuery.serviceID.getService.useQuery(id);

    /***** RENDER HELPERS *****/
    const fonts = values(get_fonts_meta_data);

    const primaryFontOptions = fonts.filter(({ types }) => types.includes(FONT_META_TYPES.HEADING)).map(selectSiteStyleType('heading'));
    const preselectedPrimaryFontIndex = primaryFontOptions.findIndex(({ value }) => {
        if (siteData?.style?.fonts?.heading) {
            return siteData?.style?.fonts?.heading === value;
        }
        return value === get_service_data?.site?.style?.fonts?.heading;
    });
    const preselectedSecondaryFontIndex = primaryFontOptions.findIndex(({ value }) => {
        if (siteData?.style?.fonts?.body) {
            return siteData?.style?.fonts?.body === value;
        }
        return value === get_service_data?.site?.style?.fonts?.body;
    });
    const bodyFontOptions = fonts.filter(({ types }) => types.includes(FONT_META_TYPES.BODY)).map(selectSiteStyleType('body'));

    /***** RENDER *****/
    return (
        <div className="PictureInPictureFontsButton__fontOptions" ref={fontSelectRef}>
            <SelectDropdown
                direction="up"
                preselectedOptionIndex={preselectedPrimaryFontIndex}
                noSelectionLabel="Choose Primary Font"
                label="Primary"
                options={primaryFontOptions}
                optionDisplayLimit={6}
            />
            <Hr grey />
            <SelectDropdown
                direction="up"
                preselectedOptionIndex={preselectedSecondaryFontIndex}
                noSelectionLabel="Choose Secondary Font"
                label="Secondary"
                options={bodyFontOptions}
                optionDisplayLimit={6}
            />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { PresetCustomiserFontSelect };
