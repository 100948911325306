/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useStateValueHasFile(value: unknown) {
    /***** STATE *****/
    const useStateContext = useState(Boolean(Array.isArray(value) && value.length) || (!Array.isArray(value) && Boolean(value)));
    const [, setHasFile] = useStateContext;

    /***** EFFECTS *****/
    useEffect(() => {
        setHasFile(Boolean(Array.isArray(value) && value.length) || (!Array.isArray(value) && Boolean(value)));
    }, [value]);

    /***** HOOK RESULTS *****/
    return useStateContext;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
