/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _PillMultiSelect } from 'components/Form/PillMultiSelect/_PillMultiSelect';
import { _RenderPillMultiSelectHookFormDefault as RenderPillMultiSelectHookFormDefault } from 'components/Form/PillMultiSelect/wrappers/hookForm/Default';
import { _RenderPillMultiSelectReduxFormDefault as RenderPillMultiSelectReduxFormDefault } from 'components/Form/PillMultiSelect/wrappers/reduxForm/Default';

const PillMultiSelect = Object.assign(_PillMultiSelect, {
    ReduxForm: RenderPillMultiSelectReduxFormDefault,
    HookForm: RenderPillMultiSelectHookFormDefault
});

export { PillMultiSelect };
