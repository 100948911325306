/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Select as Select } from 'components/Form/Select/_Select';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectNamespace } from 'components/Form/Select/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SelectReduxFormDefault: React.FC<SelectNamespace.ReduxForm.Props> = ({ input, meta, label, options, onSelect }) => {
    const { name, value, onChange } = input;

    function handleChange(value: string) {
        onChange(value);
        onSelect?.(value);
    }

    /***** RENDER *****/
    return (
        <FormItem>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner meta={meta}>
                <Select options={options} value={value} onChange={handleChange} />
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
