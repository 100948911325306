/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import { NXDropZone } from 'components/Form/NXDropZone';
import Grid from 'components/Grid';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';
import Tip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';
import {
    RenderField,
    RenderSelectField,
    renderButton,
    requiredACNorABNValidation,
    requiredAcceptedValidation,
    requiredFieldValidation
} from 'utilities/methods/form';
import { getCoRPrice, getEligibility } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { TextArea } from 'components/Form/TextArea';
import Text from 'components/Utils/Text';
import { getDaysBetween, pluralize, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const checkboxField = getFieldComponentAndClassName(FieldTypesEnum.CHECKBOX);
const FIELDS = {
    expiry_renew: 'expiry_renew',
    accept_charges: 'accept_charges'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ChangeRegistrantForm = (props) => {
    const {
        handleSubmit,
        domainid,
        /**
         * Redux State
         */
        app_check_token_data,
        dispatch,
        domain_cor_data,
        domain_cor_status,
        domain_eligibility_data,
        domain_eligibility_status,
        domain_get_cor_price_data,
        domain_get_cor_price_status,
        domain_information_data,
        form,
        getEligibility,
        pristine,
        submitting,
        valid,
        years
    } = props;

    /***** STATE *****/
    const [eligibility, setEligibility] = useState({
        registrant_name: null,
        registrant_id: null,
        registrant_id_type: null
    });
    const [manualCorStarted, setManualCorStarted] = useState(false);

    /***** EFFECTS *****/
    useEffect(() => {
        dispatch(change(form, 'method', 'keep'));

        if (!domain_eligibility_data) {
            getEligibility(domainid);
        } else {
            const { attributes } = domain_eligibility_data;
            setEligibility(attributes);
        }
    }, []);

    useEffect(() => {
        if (domain_eligibility_status === 'success') {
            const { attributes } = domain_eligibility_data;

            setEligibility(attributes);

            getCoRPrice(domainid);
        }
    }, [domain_eligibility_status]);

    useEffect(() => {
        dispatch(change(form, FIELDS.accept_charges, false));
        dispatch(change(form, FIELDS.expiry_renew, false));
    }, [years]);

    /***** RENDER HELPERS *****/
    const renderRegistrantInfo = () => {
        const registration_years = years ?? 1;

        function getCurrentDomainExpiryDays() {
            const expiry_date = domain_information_data?.attributes?.expiry_date;
            if (!expiry_date) return 0;

            return getDaysBetween(toLuxonDate(expiry_date, 'yyyy-MM-dd'));
        }

        function getDomainExpiryDaysAfterCoR() {
            const currentExpiryDays = getCurrentDomainExpiryDays();

            if (currentExpiryDays === 0) return 0;
            //first calculate how many days we have after we lose part-years, taking into account the %365th day
            const daysAfterLoss = Math.floor(currentExpiryDays / 366) * 365;

            //then calculate how many days we're adding with the CoR, plus the whole years we have left
            const newExpiryDays = registration_years * 365 + daysAfterLoss;

            return newExpiryDays;
        }

        const registrantInformation = [
            {
                header: 'Registrant Name',
                info: eligibility?.registrant_name || 'Not Available'
            },
            {
                header: 'Registrant ID',
                info: eligibility?.registrant_id || 'Not Available'
            },
            {
                header: 'Eligibility Type',
                info: eligibility?.registrant_id_type || 'Not Available'
            },
            {
                header: 'Current domain expiry',
                info: `${getCurrentDomainExpiryDays()} Days`
            },
            {
                header: 'Domain expiry after change of registrant',
                info: (
                    <Grid columns="auto 1fr">
                        {getDomainExpiryDaysAfterCoR()} Days
                        <Tip
                            iconOverride={<i className="icon icon-help" />}
                            info="Changing the registrant will reset the domain's license period. You will keep any existing full years and add the additional year(s) selected below, up to the maximum period allowed. Partial years will not be carried over."
                        />
                    </Grid>
                )
            }
        ];

        function getRegistrationYearsOptions() {
            if (!domain_get_cor_price_data) return;

            const corPrice = domain_get_cor_price_data?.price;
            const corMaxRenewableYears = domain_get_cor_price_data?.max_renewable_years;
            const registrationYearsArray = [1, 2, 3, 4, 5];

            function getYearPrice(year) {
                if (domain_get_cor_price_status === 'success') return ` - $${(year * corPrice).toFixed(2)}`;
                return '';
            }

            const filteredRegistrationYearsArray = registrationYearsArray.filter((year) => {
                return year <= corMaxRenewableYears;
            });

            return filteredRegistrationYearsArray.map((year) => ({
                label: `${year} Year${pluralize(year)}${getYearPrice(year)}`,
                value: year
            }));
        }
        return (
            <>
                <div className="initiateCor__registrant">
                    <div className="registrant__title">Current Registrant Information</div>
                    <div className="registrant__wrapper">
                        {registrantInformation.map(({ header, info }, i) => (
                            <div className="registrant__row" key={i}>
                                <div className="registrant__column--header">
                                    <Text size--s secondary medium>
                                        {header}
                                    </Text>
                                </div>
                                <div className="registrant__column--info">
                                    <Text size--s secondary medium>
                                        {info}
                                    </Text>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="initiateCor__registrant">
                    <Field
                        name="years"
                        label={
                            <Text bold secondary size--s>
                                Extend Your Domain Registration Period
                            </Text>
                        }
                        component={RenderSelectField}
                        validate={[requiredFieldValidation]}
                        placeholder=""
                        options={getRegistrationYearsOptions()}
                    />

                    <AcknowledgementBox className="initiateCor__AcknowledgementBox">
                        <Field
                            label={`Changing the registrant will reset the domain's license period. You will keep any existing full years and add the additional year(s) selected above, up to the maximum period allowed. Partial years will not be carried over.`}
                            name={FIELDS.expiry_renew}
                            validate={[requiredAcceptedValidation]}
                            component={checkboxField.component}
                            type={FieldTypesEnum.CHECKBOX}
                            className={classNames(checkboxField.className, 'inline')}
                        />
                        <Field
                            label="This COR process has a fee, which is equal to the registration/renewal fee for the additional year(s) selected above."
                            name={FIELDS.accept_charges}
                            validate={[requiredAcceptedValidation]}
                            component={checkboxField.component}
                            type={FieldTypesEnum.CHECKBOX}
                            className={checkboxField.className}
                        />
                    </AcknowledgementBox>
                </div>
            </>
        );
    };

    function renderManualCorForm() {
        return (
            <>
                <DialogNotification className="manualCor__dialogNotificationError" type="error" outline>
                    The {eligibility?.registrant_id ? `${eligibility.registrant_id_type} ${eligibility.registrant_id}` : 'ID'} for this domain is no
                    longer valid to perform the Change of Registrant. Please provide the relevant documentation for our staff to review manually.
                </DialogNotification>
                <div className="manualCor sharedBox__container ">
                    <div className="sharedBox__wrapper--top">
                        <div className="sharedBox__title ">Required Details</div>
                        <div className="sharedBox__description">Please supply any other relevant details around the transfer of this license.</div>
                    </div>
                </div>
                <div className="initiateCor__registrant manualCor">
                    <Field
                        className="manualCor__eligibilityDetails"
                        component={RenderField}
                        name="new_eligibility_details"
                        label="New ABN/ACN"
                        type="text"
                        validate={[requiredFieldValidation, requiredACNorABNValidation]}
                    />
                    <p className="manualCor__attachmentLabel">Upload Documentation</p>
                    <p className="manualCor__attachmentDescription">
                        Please supply us with a document dated prior to when the old ABN/ACN was deactivated, stating that the domain license was sold
                        or gifted to the new registrant.
                    </p>
                    <p className="manualCor__attachmentDescription">
                        If you do not have the required documentation, please fill out and attach the{' '}
                        <Anchor href="https://ventraip.com.au/wp-content/uploads/2020/08/Transfer-of-Domain-Chain-Form.pdf" target="_blank">
                            transfer of domain chain form
                        </Anchor>
                    </p>
                    <Field
                        component={NXDropZone.ReduxForm.WithNamedAttachments}
                        compact
                        accept={{
                            'image/*': ['.png', '.jpeg', '.jpg'],
                            'application/pdf': ['.pdf'],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                        }}
                        name="attachments"
                        type="dropzone"
                        validate={[requiredFieldValidation]}
                    />
                    <p className="manualCor__allowedFiles">Allowed files include: .doc, .pdf, .jpg, .png</p>
                    <Field
                        className="manualCor__additionalDetails"
                        component={TextArea.ReduxForm}
                        type="message"
                        name="additional_information"
                        label="Additional Details"
                    />
                    {renderButton(pristine, submitting, valid, 'Submit COR Request')}
                </div>
            </>
        );
    }

    const renderForm = () => {
        if (domain_eligibility_status === 'loading' || domain_cor_status === 'loading' || domain_get_cor_price_status === 'loading')
            return <RequestLoader />;

        if (!domain_cor_data?.attributes?.requires_manual || app_check_token_data?.is_vpn)
            return (
                <>
                    {renderRegistrantInfo()}

                    <Field name="method" component={RenderField} type="hidden" />

                    <div className="initiateCor__submit--cor">{renderButton(pristine, submitting, valid, 'Begin Change of Registrant')}</div>
                </>
            );

        if (manualCorStarted) return renderManualCorForm();

        return (
            <>
                {renderRegistrantInfo()}
                <div className="initiateCor__submit--cor">
                    <SolidButton
                        type="onClick"
                        onClick={() => {
                            setManualCorStarted(true);
                        }}
                    >
                        Begin Change of Registrant
                    </SolidButton>
                </div>
            </>
        );
    };

    /***** RENDER *****/
    return (
        <form className="inline__form" onSubmit={handleSubmit}>
            {renderForm()}
        </form>
    );
};

function validateManualCor(values) {
    const errors = {};
    if (values?.attachments?.length === 0) {
        errors.attachments =
            'Please supply us with a document dated prior to when the old ABN was deactivated, stating that the domain license was sold or gifted to the new registrant.';
    }

    return errors;
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ChangeRegistrantForm = reduxForm({
    form: 'changeRegistrantForm',
    validate: validateManualCor
})(ChangeRegistrantForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector('changeRegistrantForm');
    const years = selector(state, 'years');

    return {
        years,
        form: 'changeRegistrantForm',
        app_check_token_data: state.app.app_check_token_data,
        domain_cor_data: state.domain.domain_cor_data,
        domain_cor_status: state.domain.domain_cor_status,
        domain_eligibility_data: state.domain.domain_eligibility_data,
        domain_eligibility_status: state.domain.domain_eligibility_status,
        domain_get_cor_price_data: state.domain.domain_get_cor_price_data,
        domain_get_cor_price_status: state.domain.domain_get_cor_price_status,
        domain_information_data: state.domain.domain_information_data
    };
};

const mapDispatchToProps = { getEligibility };

ChangeRegistrantForm = connect(mapStateToProps, mapDispatchToProps)(ChangeRegistrantForm);

export default ChangeRegistrantForm;
