/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useHistory, useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useSearchParams from 'utilities/hooks/useSearchParams';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SearchParams = Record<string, any>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useUrlDropdown(path: string, searchParams: SearchParams = { open: true }) {
    /***** HOOKS *****/
    const { search, pathname } = useLocation();
    const history = useHistory();
    const { searchParamsGet } = useSearchParams();

    const isDropdownOpen =
        pathname === path &&
        Object.entries(searchParams).every(([key, value]) => {
            const currentSearchParamValue = searchParamsGet(key);
            const passedInValue = String(value);
            return currentSearchParamValue === passedInValue;
        });

    /***** FUNCTIONS *****/
    function openDropdown() {
        const newParams = new URLSearchParams(search);
        for (const [key, value] of Object.entries(searchParams)) {
            newParams.set(key, String(value));
        }
        history.replace(`${path}?${String(newParams)}`);
    }

    function closeDropdown() {
        const newParams = new URLSearchParams(search);
        for (const key of Object.keys(searchParams)) {
            newParams.delete(key);
        }
        history.replace(`${path}?${String(newParams)}`);
    }

    function toggleDropdown() {
        if (isDropdownOpen) {
            closeDropdown();
        } else {
            openDropdown();
        }
    }

    /***** HOOK RESULTS *****/
    return {
        isDropdownOpen,
        openDropdown,
        closeDropdown,
        toggleDropdown
    } as const;
} /**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
