/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_checkBoxPanel.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { CheckBoxPanelNamespace } from 'components/Form/CheckBox/wrappers/Panel/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CheckBoxPanel: React.FC<CheckBoxPanelNamespace.Props> = ({ className, children, onChange, onIsCheckedClick, ...props }) => {
    const { isChecked } = props;

    /***** FUNCTIONS *****/
    function handleOnChange(_isChecked: boolean) {
        if (isChecked) {
            onIsCheckedClick?.();
        }
        onChange?.(_isChecked);
    }

    /***** RENDER *****/
    return (
        <CheckBox
            className={classNames(className, 'CheckBoxCirclePanel', {
                'CheckBoxCirclePanel--isChecked': props.isChecked,
                'CheckBoxCirclePanel--fullWidth': props.fullWidth
            })}
            onChange={handleOnChange}
            {...props}
        >
            {typeof children === 'string' ? (
                <Text medium size--m black align--left>
                    {children}
                </Text>
            ) : (
                children
            )}
        </CheckBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
