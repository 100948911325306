/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useBooleanStore } from 'utilities/hooks/useBooleanStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
import { unsavedChangesWritable } from 'containers/katana/containers/ContentEditorLightbox/unsavedChangesModal/consts';
const { OVERVIEW } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const UnsavedChangesModal = function UnsavedChangesModal() {
    /***** HOOKS *****/
    const { value: isShowingUnsavedChangesLightbox, setFalse: hideUnsavedChangesLightbox } = useBooleanStore(unsavedChangesWritable);
    const { getKatanaDestination } = useKatanaURL();

    /***** RENDER *****/
    return (
        <Modal.Compact isOpen={isShowingUnsavedChangesLightbox} onClose={hideUnsavedChangesLightbox} ariaLabel="Unsaved Changes Modal">
            <Text.Heading h2 align--center>
                You have unsaved changes
            </Text.Heading>
            <Padding y={4}>
                <Text size--m secondary align--center>
                    {`You haven't finished configuring this.`}
                    <br />
                    Do you want to continue your progress?
                </Text>
            </Padding>
            <Flex justify="center">
                <OutlineButton to={getKatanaDestination(OVERVIEW)} onClick={hideUnsavedChangesLightbox}>
                    Leave
                </OutlineButton>
                <SolidButton type="onClick" onClick={hideUnsavedChangesLightbox}>
                    Resume Configuration
                </SolidButton>
            </Flex>
        </Modal.Compact>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default UnsavedChangesModal;
