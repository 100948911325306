/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import AutocompleteAddress from 'components/AutocompleteAddress';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import RenderPhoneField from 'components/Form/PhoneInput/RenderPhoneField';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification/index';

/*   ACTIONS
 *****************************************************/
import { RenderField, RenderFieldWithLink, peopleNameFieldValidation, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'personalDetailsForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DetailsForm = ({
    handleSubmit,
    onOpenChangeEmailForm,
    history,

    // Redux props
    form,
    selectedCountry,
    selectedState,
    account_update_user_status
}) => {
    /***** RENDER *****/
    if (account_update_user_status === 'loading') return <RequestLoader />;

    return (
        <form className="accountPersonalDetails__form" onSubmit={handleSubmit}>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="First Name"
                        name="firstname"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation, peopleNameFieldValidation]}
                        className="form__textfield"
                    />
                </div>
                <div className="form__column half">
                    <Field
                        label="Last Name"
                        name="lastname"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation, peopleNameFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column half">
                    <Field label="Company Name" name="company" component={RenderField} type="text" placeholder="" className="form__textfield" />
                </div>
                <div className="form__column half">
                    <Field
                        label="Phone"
                        name="phone"
                        component={RenderPhoneField}
                        required
                        countrySelectEnabled={false}
                        country={selectedCountry}
                        type="tel"
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>

            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Email"
                        name="email"
                        component={RenderFieldWithLink}
                        type="email"
                        placeholder=""
                        className="form__textfield"
                        linktext="Change Email"
                        linkonclick={(e) => {
                            e.preventDefault();
                            onOpenChangeEmailForm();
                        }}
                        readOnly="readonly"
                    />
                </div>
            </div>

            <DialogNotification type="warning" tail={{ pos: 'top' }}>
                PLEASE NOTE: The email address listed above is NOT sent expiry notices and correspondence regarding your domain names. This is
                controlled by the Registrant Contact Email address that is listed on the domain name.
            </DialogNotification>

            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Password"
                        name="password"
                        component={RenderFieldWithLink}
                        type="password"
                        placeholder=""
                        className="form__textfield"
                        linktext="Change Password"
                        linkonclick={() => {
                            history.push('/account/security/password?open=true');
                        }}
                        readOnly="readonly"
                        fakevalue="fakevalue"
                    />
                </div>
            </div>

            <AutocompleteAddress
                form={form}
                selectedCountry={selectedCountry}
                selectedState={selectedState}
                onlyAUandNZ={true}
                halfWidthFields={true}
                fieldOrder={['address1', 'address2', 'city', 'state', 'country', 'postcode']}
            />

            <div className="form__row">
                <ReduxFormButton form={formName} className="form__column full">
                    Save Changes
                </ReduxFormButton>
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DetailsForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(DetailsForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const selectedState = selector(state, 'state');
    const selectedCountry = selector(state, 'country');

    return {
        initialValues: state.app.app_user_data,
        account_update_user_status: state.account.account_update_user_status,
        selectedState,
        selectedCountry
    };
};

DetailsForm = connect(mapStateToProps, {
    change
})(DetailsForm);

export default withRouter(DetailsForm);
