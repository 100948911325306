/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { values } from 'lodash';
import { useMemo } from 'react';
import GoogleFontLoader from 'react-google-font';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CardSelectGrid } from 'components/Form/CardSelectGrid';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useFilteredBrandFonts } from 'containers/katana/containers/contentEditorModules/basicDetails/useFilteredBrandFonts';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PickLogoStyle.scss';
const { STYLE } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _PickLogoStyle = () => {
    /***** HOOKS *****/

    const isMobile = useAppViewport(['xs', 'sm']);
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_fonts_meta_data, isLoading: isGetFontsMetaLoading } = katanaQuery.serviceID.meta.getFonts.useQuery(id);

    /***** RENDER HELPERS *****/
    const filteredFonts = useFilteredBrandFonts();
    const availableFonts = useMemo(() => {
        return values(get_fonts_meta_data ?? {})
            .filter((font) => font.types.includes('brand'))
            .map(({ name }) => ({ font: name }));
    }, [get_fonts_meta_data]);

    function onChangeProxy(value: CardSelectGridNamespace.SelectCard.OnChangeValue) {
        return { fonts: { brand: value } };
    }

    /***** RENDER *****/
    if (isGetFontsMetaLoading) {
        return <RequestLoader message="Loading Fonts..." />;
    }

    return (
        <>
            <GoogleFontLoader fonts={availableFonts} />
            <CardSelectGrid.HookForm
                name={STYLE}
                alignItems="stretch"
                textAlign="left"
                selectionText="Choose logo style"
                options={filteredFonts}
                itemsPerRow={isMobile ? 1 : 2}
                onChangeProxy={onChangeProxy}
                valueProxy={(value) => value?.fonts?.brand}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
