/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import KatanaModuleCTA from 'containers/katana/components/moduleCTA';
import { KatanaSectionSettingsModule } from 'containers/katana/components/sectionSettingsModule';
import { ActivePresetContentModule } from 'containers/katana/modules/presetContent/ActivePresetContentModule';
import { AddSectionButton } from 'containers/katana/modules/presetContent/AddSectionButton';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { isChecklistItemEnabled } from 'containers/katana/modules/launchChecklist/methods';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SiteSetupStage, katanaModuleImages, katanaServicePages } from 'containers/katana/consts';
import './_katanaPresetContentModule.scss';

const { SETUP } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaPresetContentModule = function KatanaPresetContentModule() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { data: get_katana_service_data, isSuccess: isGetKatanaServiceSuccess } = katanaQuery.serviceID.getService.useQuery(id);

    /***** RENDER HELPERS *****/
    const isActive =
        get_katana_service_data.attributes.launch_checklist.includes(SiteSetupStage.LayoutAndContentCompleted) ||
        !!get_katana_service_data?.sections?.length;

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={isGetKatanaServiceSuccess}>
            <KatanaSectionSettingsModule>
                <NXBox.TopAccordion title="Template and Content" topChildren={!isMobile && isActive && <AddSectionButton />}>
                    <NXBox.DefaultPadding>
                        {isActive ? (
                            <ActivePresetContentModule />
                        ) : (
                            <KatanaModuleCTA
                                image={katanaModuleImages.webpagePresetContentModule}
                                alt="Template and Content"
                                text={
                                    <>
                                        Pick your favourite template
                                        <br />
                                        and add your content
                                    </>
                                }
                                buttonText="Pick a layout and content"
                                disabled={
                                    !isChecklistItemEnabled(
                                        get_katana_service_data.attributes.launch_checklist,
                                        SiteSetupStage.LayoutAndContentCompleted
                                    )
                                }
                                to={getKatanaDestination(SETUP.PRESET_CONTENT)}
                            />
                        )}
                    </NXBox.DefaultPadding>
                </NXBox.TopAccordion>
            </KatanaSectionSettingsModule>
        </ScrollableComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaPresetContentModule };
