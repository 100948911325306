/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { change } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function RenderLargeListRadioButtons({ label, input, itemlist, disabledItemList, className, meta }) {
    /***** RENDER HELPERS *****/
    const { dispatch, form } = meta;
    const { value, name } = input;

    const renderList = () => {
        if (itemlist && itemlist.length > 0) {
            return itemlist.map((item, index) => {
                return (
                    <button
                        key={index}
                        onClick={() => {
                            dispatch(change(form, name, item.value));
                        }}
                        className={`LargeListRadioButtons__item${item.value === value ? ' selected' : ''}`}
                    >
                        <span
                            className={`LargeListRadioButtons__radio-dummy${
                                item.value === value ? ' LargeListRadioButtons__radio-dummy--selected' : ''
                            }`}
                        />
                        <input style={{ display: 'none' }} {...input} type="radio" checked={item.value === value} value={item.value} />
                        <div className="LargeListRadioButtons__data data">
                            {item.label && (
                                <div className={`data__heading${!item.description ? ' data__heading--no-description' : ''}`}>{item.label}</div>
                            )}
                            {item.listItems && Array.isArray(item.listItems) ? (
                                <div className="data__list">
                                    {item.listItems.map((description, index) => {
                                        return (
                                            <li key={index} className="data__list--item">
                                                <span className="icon">
                                                    <span className="dot" />
                                                </span>
                                                {description}
                                            </li>
                                        );
                                    })}
                                    {disabledItemList &&
                                        disabledItemList.map((description, index) => {
                                            return (
                                                <li key={index} className="data__list--item disabled">
                                                    <span className="icon icon-x" />
                                                    {description}
                                                </li>
                                            );
                                        })}
                                </div>
                            ) : (
                                <li className="data__list--item">
                                    <span className="icon">
                                        <span className="dot" />
                                    </span>
                                    {item.listItems}
                                </li>
                            )}
                            {item.description && (
                                <div className="data__description">
                                    <span className="icon">
                                        <span className="dot" />
                                    </span>
                                    {item.description}
                                </div>
                            )}
                        </div>
                    </button>
                );
            });
        } else {
            return '';
        }
    };

    /***** RENDER *****/
    return (
        <FormItem>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner meta={meta}>
                <div className={classNames('LargeListRadioButtons', className)}>{renderList()}</div>
            </FormItemInner>
        </FormItem>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
