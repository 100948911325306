/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SSLPurchase from '../../forms/ssl';
import { ShowAll } from '../helpers/showAll';
import { ShowMore } from '../helpers/showMore';
import { SSLTableBody } from './components/body';
import { SSLTableTitleBar } from './components/titlebar';

/***** MEGAMAY24 START *****/
import { MegaMaySslBanner } from 'config/containers/promotions/megamay2024/components/servicesBanner/panels';
/***** MEGAMAY24 END *****/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXTable from 'components/NXTable';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/*   ACTIONS
 *****************************************************/
import { cancelListSSL } from 'utilities/api/services';
import { getSslList } from '../../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useMatchMedia } from 'utilities/hooks/useMatchMedia';
import { activePromotion } from 'utilities/methods/commonActions';
import { useSearchState } from '../helpers/useSearchState';
import { useSort } from '../helpers/useSort';
import { SSLOverviewContext, useSSLTableSelector } from './own';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SSLOverview.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('./types').NSSLTable.TSSLTable} TSSLTable
 * @typedef {import('./types').NSSLTable.TContext} TContext
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {TSSLTable}
 */
export const SSLTable = ({ isExpressServiceManager = false }) => {
    /***** HOOKS *****/
    const { ssl_list_status, ssl_search_status, ssl_list_meta, ssl_list_more_status, ssl_list_data, ssl_search_data } = useSSLTableSelector(
        (state) => state.services
    );
    const isMobile = useAppViewport(['xs', 'sm']);
    const isSmallTablet = useMatchMedia({ max: 940 });
    const dispatch = useDispatch();

    /***** STATE *****/
    const [search, setSearch] = useSearchState(ssl_search_status);
    const [filters, , { handleSort }] = useSort({ disabled: search.isSearching });
    const [numberOfRows, setNumberOfRows] = useState(/** @type {number | null} */ (null));
    const [showExpiredSSLs, setShowExpiredSSLs] = useState(true);

    /***** FUNCTIONS *****/
    const getNumberOfRecords = () => {
        switch (true) {
            case isExpressServiceManager:
                return 5;
            case typeof numberOfRows === 'number':
                return numberOfRows;
            default:
                return 10; // set to 10, otherwise the backend stores the value as the last search
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        getSslList({
            filters,
            record_per_page: getNumberOfRecords()
        })(dispatch);

        return cancelListSSL;
    }, [filters, numberOfRows]);

    /***** RENDER HELPERS *****/
    const listData = (search.isSearching ? ssl_search_data : ssl_list_data) ?? [];
    const isLoading = ssl_list_status === 'loading' || ssl_search_status === 'loading' || ssl_list_more_status === 'loading';
    const isError = ssl_list_status === 'error' || ssl_search_status === 'error' || ssl_list_more_status === 'error' || listData.length === 0;

    const getEmptyMessage = () => {
        switch (true) {
            case !!search.isSearching:
                return <Text>No SSLs matched your search.</Text>;
            case listData.length === 0 && isExpressServiceManager:
                return 'You have no SSLs active on your account.';
            default:
                return "You have no SSLs active on your account. Why don't you purchase some below to get started!";
        }
    };

    const context = /** @type {TContext} */ ({
        isExpressServiceManager,
        numberOfRows,
        setNumberOfRows,
        showExpiredSSLs,
        setSearch,
        search,
        setShowExpiredSSLs,
        initialized: true
    });

    const className = classNames('SSLOverview', {
        'SSLOverview--express': isExpressServiceManager,
        'SSLOverview--dashboard': !isExpressServiceManager
    });

    const columns = [`${isSmallTablet ? 200 : 260}px`, '180px', '1fr', 'NXActions'];

    /***** RENDER *****/
    return (
        <div className={className}>
            {/* MEGAMAY24 START */}
            {!isExpressServiceManager && activePromotion('megamay2024') ? <MegaMaySslBanner /> : ''}
            {/* MEGAMAY24 END */}

            {/* Main Component */}
            <SSLOverviewContext.Provider value={context}>
                {!isExpressServiceManager && <SSLTableTitleBar title="Manage SSL Certificates" />}

                <WrapWithComponent component={Border} all radius="S_border-radius_default" wrap={!isExpressServiceManager}>
                    {isMobile ? (
                        <NXTable.Body isLoading={isLoading}>
                            {isError ? <NXTable.ErrorMessage message={getEmptyMessage()} /> : <SSLTableBody data={listData} />}
                        </NXTable.Body>
                    ) : (
                        <NXTable columns={columns.join(' ')} onSort={handleSort}>
                            <NXTable.Header>
                                <NXTable.Header.SortableTitle disabled={search.isSearching} sortKey="name">
                                    DOMAIN / PRODUCT
                                </NXTable.Header.SortableTitle>
                                <NXTable.Header.Title>STATUS</NXTable.Header.Title>
                                <NXTable.Header.Title>EXPIRY / INFO</NXTable.Header.Title>
                                <NXTable.Header.Title></NXTable.Header.Title>
                            </NXTable.Header>
                            <NXTable.Body isLoading={isLoading}>
                                {isError ? <NXTable.ErrorMessage message={getEmptyMessage()} /> : <SSLTableBody data={listData} />}
                            </NXTable.Body>
                        </NXTable>
                    )}

                    <ShowAll
                        isExpressServiceManager={isExpressServiceManager}
                        isLoading={isLoading}
                        totalRows={ssl_list_meta?.total_all_records}
                        numberOfRows={numberOfRows}
                        onClick={() => setNumberOfRows(ssl_list_meta?.total_all_records)}
                    />

                    <ShowMore
                        title="show all SSL Certificates"
                        link="/my-services/ssl"
                        isExpressServiceManager={isExpressServiceManager}
                        isLoading={isLoading}
                        totalRows={ssl_list_meta?.total_all_records}
                    />
                </WrapWithComponent>

                {/* Purchase SSL Banner */}
                {!isExpressServiceManager && <SSLPurchase />}
            </SSLOverviewContext.Provider>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
