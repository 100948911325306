/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import FetchComponentError from 'components/Errors/FetchComponentError';
import { NXDropZone } from 'components/Form/NXDropZone';
import { TextArea } from 'components/Form/TextArea';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';

/*   ACTIONS
 *****************************************************/
import { getHrsAndMinsFromMillis, isBusinessHours } from 'utilities/methods/commonActions';
import { renderCheckboxField, requiredFieldValidation } from 'utilities/methods/form';
import { getTicketWaitTime } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**
 * List of file extensions that are supported by the backend 22/12/2023
 */
// eslint-disable-next-line no-unused-vars
const DROPZONE_ACCEPTED_FILE_TYPES = /** @type {const} */ ([
    '.mbox',
    '.doc',
    '.dot',
    '.wiz',
    '.pdf',
    '.xla',
    '.xlb',
    '.xlc',
    '.xlm',
    '.xls',
    '.xlt',
    '.xlw',
    '.msg',
    '.docx',
    '.xlsx',
    '.gif',
    '.jpg',
    '.jpeg',
    '.jfif',
    '.jif',
    '.jpe',
    '.pjpg',
    '.png',
    '.svg',
    '.svgz',
    '.apng',
    '.heic',
    '.heif',
    '.avif',
    '.avifs',
    '.webp',
    '.eml',
    '.mht',
    '.mhtml',
    '.mime',
    '.nws',
    '.csv',
    '.txt',
    '.text',
    '.log',
    '.mp4',
    '.mp4v',
    '.mpg4',
    '.webm'
]);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ETicketReplyForm = ({
    isPremium,
    form,
    queueJumpValue,
    department,
    handleSubmit,
    formRef,
    toggleLightboxTemplate,
    match: {
        params: { param4 }
    },

    // Redux props
    getTicketWaitTime,
    support_ticket_wait_time_status,
    support_ticket_wait_time_data,
    support_question_list_status,
    support_question_list_data,
    support_individual_ticket_data
}) => {
    /***** EFFECTS *****/
    useEffect(() => {
        if (['technical support', 'accounts and billing'].includes(department.toLowerCase())) getTicketWaitTime();
    }, []);

    /***** RENDER HELPERS *****/
    const renderQueueJump = () => {
        const disabled = support_question_list_data
            ? support_question_list_data.attributes.able_to_submit_urgent.status_overdue ||
              support_question_list_data.attributes.able_to_submit_urgent.status_unpaid
            : false;

        const urgentPrice =
            department.toLowerCase() === 'accounts and billing'
                ? support_ticket_wait_time_data?.attributes?.account?.urgent_price?.value
                : support_ticket_wait_time_data?.attributes?.support?.urgent_price?.value;

        const waitTime =
            department.toLowerCase() === 'accounts and billing'
                ? support_ticket_wait_time_data?.attributes?.account?.wait_time
                : support_ticket_wait_time_data?.attributes?.support?.wait_time;

        return (
            <div className="ticketReplyForm__urgent">
                <div className="ticketReplyForm__urgentHeading">Something urgent? Jump the queue to get your eTicket looked at sooner</div>
                {isPremium && department.toLowerCase() === 'technical support' && (
                    <div className="ticketReplyForm__urgentPremBanner">
                        <div className="ticketReplyForm__bannerBg"></div>
                        <i className="icon icon-star" />
                        <div className="ticketReplyForm__bannerText">
                            <h4 className="ticketReplyForm__bannerTitle">This is a priority service</h4>
                            <p className="ticketReplyForm__bannerParagraph">
                                Due to this being a premium service, this eTicket will be treated as a priority
                                {waitTime ? ', and will likely be answered quicker than the estimated time given below.' : '.'}
                            </p>
                        </div>
                    </div>
                )}
                {waitTime && (
                    <div className="ticketReplyForm__waitTime">
                        <div className="ticketReplyForm__waitTimeSmall">Current max wait time</div>
                        <div className="ticketReplyForm__waitTimeMinsContainer">
                            <div className="ticketReplyForm__urgentTooltip ticketReplyForm__urgentTooltip--waitTime">
                                This is an estimate for the time a standard eTicket has to be in a queue before being answered based on our queue
                                length, agent availability and expected handle time.
                                <br />
                                <br />
                                The actual wait time may vary slightly, you will receive an email notifying you of a response to your {
                                    application
                                }{' '}
                                email address once we respond to your eTicket.
                            </div>
                            <div className="ticketReplyForm__waitTimeMins">{getHrsAndMinsFromMillis(waitTime)}</div>
                        </div>
                    </div>
                )}
                <Field
                    name="is_urgent"
                    label={
                        <>
                            <div className="ticketReplyForm__urgentTooltip">
                                You have an outstanding queue jump invoice. Please pay the invoice to push your ticket to the front of the queue.
                            </div>
                            <div className="checkboxLabelTop">
                                Queue Jump
                                <small className="checkboxLabelPrice">${urgentPrice || '4.95'}</small>
                            </div>
                            <div className="checkboxLabelBottom">Place your eTicket at the top of the queue to be looked at sooner</div>
                        </>
                    }
                    props={{ disabled }}
                    component={renderCheckboxField}
                    type="checkbox"
                    className={`form__textfield ticketReplyForm__urgentCheckbox queueJump${queueJumpValue ? ' queueJump--selected' : ''}${
                        disabled ? ' queueJump--disabled' : ''
                    }`}
                />
            </div>
        );
    };

    const renderForm = () => {
        const isLastMessageStaff =
            Array.isArray(support_individual_ticket_data) &&
            support_individual_ticket_data.length > 0 &&
            support_individual_ticket_data[support_individual_ticket_data.length - 1].attributes?.is_worker;

        return (
            <div className="ticketReplyForm__wrapper">
                <form ref={formRef} className="ticketReplyForm" onSubmit={handleSubmit}>
                    {isLastMessageStaff ? (
                        ''
                    ) : (
                        <DialogNotification type="info" tail={{ pos: 'bot' }}>
                            PLEASE NOTE: Your previous response is currently being reviewed by our {department} team. Unless you need to supply
                            further, relevant information to this eTicket please refrain from submitting additional responses as it will place your
                            eTicket at the bottom of the queue.
                        </DialogNotification>
                    )}
                    <div className="ticketReplyForm__body">
                        <Field
                            name="message"
                            label="Leave a reply"
                            component={TextArea.ReduxForm}
                            rows={12}
                            validate={[requiredFieldValidation]}
                            type="textarea"
                            className="form__textfield reply"
                        />
                        <Field
                            name="file"
                            component={NXDropZone.ReduxForm.WithNamedAttachments}
                            type="dropzone"
                            className="form__textfield file"
                            accept=""
                            compact
                        />
                        {(department.toLowerCase() === 'technical support' ||
                            (department.toLowerCase() === 'accounts and billing' && isBusinessHours())) &&
                            renderQueueJump()}
                        <ReduxFormButton form={form}>Submit Reply</ReduxFormButton>
                    </div>
                    <div className="ticketReplyForm__footerContainer">
                        <div className="ticketReplyForm__footer">
                            Finished with this ticket?
                            <Anchor className="ticketReplyForm__footerAnchor" onClick={() => toggleLightboxTemplate('Resolve', { id: param4 })}>
                                Mark it as resolved
                            </Anchor>
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    /***** RENDER *****/
    if (support_question_list_status === 'loading' || support_ticket_wait_time_status === 'loading') {
        return <RequestLoader />;
    }
    if (support_question_list_status === 'error') {
        return <FetchComponentError />;
    }
    return renderForm();
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ETicketReplyForm = reduxForm({
    form: 'ETicketReplyForm',
    enableReinitialize: true
})(ETicketReplyForm);

const selector = formValueSelector('ETicketReplyForm');
const initialValues = {
    is_urgent: false
};

export default withRouter(
    connect(
        (state) => ({
            support_ticket_wait_time_status: state.support.support_ticket_wait_time_status,
            support_ticket_wait_time_data: state.support.support_ticket_wait_time_data,
            support_question_list_status: state.support.support_question_list_status,
            support_question_list_data: state.support.support_question_list_data,
            support_individual_ticket_data: state.support.support_individual_ticket_data,
            initialValues,
            queueJumpValue: selector(state, 'is_urgent')
        }),
        {
            getTicketWaitTime
        }
    )(ETicketReplyForm)
);
