/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _DevFormTypeChoiceWrapper as DevFormTypeChoiceWrapper } from 'containers/katana/formFields/_DevFormTypeChoiceWrapper/_DevFormTypeChoiceWrapper';
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';
import { KatanaTextFormFieldText } from 'containers/katana/formFields/text/inputTypes/text';
import { KatanaTextFormFieldTextArea } from 'containers/katana/formFields/text/inputTypes/textarea';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE } from 'containers/katana/formFields/text/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaTextFormField: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { input_type, name } = property;

    /***** RENDER *****/
    return (
        <DevFormTypeChoiceWrapper value={input_type} options={Object.values(SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE)}>
            {({ derivedValue }) => {
                switch (derivedValue) {
                    case SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE.TEXT:
                        return <KatanaTextFormFieldText property={property} />;

                    case SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE.TEXTAREA:
                        return <KatanaTextFormFieldTextArea property={property} />;

                    case SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE.RICH_TEXT:
                        return <KatanaTextFormFieldTextArea property={property} />;

                    default:
                        return <InvalidInputType name={name} inputType={derivedValue} fieldType="Text" />;
                }
            }}
        </DevFormTypeChoiceWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
