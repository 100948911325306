/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import Anchor from 'components/Anchor';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { renderButton, requiredCheckboxFieldValidation } from 'utilities/methods/form';
import DisplayNameservers from './_displayNameservers';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { configKeys } from './consts';
import './styles.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import("./consts").TConfigKeys} TConfigKeys
 * @typedef {import('redux-form').InjectedFormProps} InjectedFormProps
 *
 * @typedef {{
 *   onCancel: () => void,
 *   newDNS: TConfigKeys,
 *   newNameservers: string[]
 * } & InjectedFormProps} TFormProps
 */

const content = /** @type {const} */ ({
    AcknowledgementBox: {
        body: `Changing these settings may disrupt this domains DNS if not configured correctly. Any active record will be replaced, which may disconnect your website or emails.`,
        dnsPropogation: `I understand that DNS Propagation may take up to 1 hour to complete before my changes are reflected.`,
        dnsRecords: `I understand that any currently configured DNS Records will become inactive and will be removed after 72 hours.`
    }
});

/***** FUNCTIONS *****/
/**
 * converts the config key to a human readable name (e.g. 'free_dns' => 'DNS Hosting')
 *
 * @param {TConfigKeys} key
 * @returns {'DNS Hosting' | 'Custom Nameservers' | 'Forwarding' | `${typeof company} Hosting` | 'Parked Domain' | ''}
 */
const configToName = (key) => {
    switch (key) {
        case configKeys.FREE_DNS:
            return 'DNS Hosting';

        case configKeys.CUSTOM_HOSTING:
            return 'Custom Nameservers';

        case configKeys.FORWARDING:
            return 'Forwarding';

        case configKeys.VENTRAIP_HOSTING:
            return `${company} Hosting`;

        case configKeys.PARK_DOMAIN:
            return 'Parked Domain';

        default:
            return '';
    }
};

/**
 * returns a boolean representing if the user is moving from local Nameservers to external Nameservers (e.g. 'free_dns' => 'custom_hosting')
 *
 * @param {TConfigKeys} currentDNS
 * @param {TConfigKeys} newDNS
 */
const isMovingToExternalNameservers = (currentDNS, newDNS) => {
    const localDNS = /** @type {TConfigKeys[]} */ ([configKeys.FORWARDING, configKeys.FREE_DNS]);
    const externalDNS = /** @type {TConfigKeys[]} */ ([configKeys.PARK_DOMAIN, configKeys.CUSTOM_HOSTING, configKeys.VENTRAIP_HOSTING]);

    if (!localDNS.includes(currentDNS)) {
        return false;
    }
    if (!externalDNS.includes(newDNS)) {
        return false;
    }

    return true;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Form used when changing the Nameservers of a domain, this provides a confirmation of the changes being made
 *
 * @param {TFormProps} props
 */
const _ChangeNameserversForm = ({
    onCancel,
    newDNS,
    newNameservers,

    /** Redux Props */
    handleSubmit,
    pristine,
    submitting,
    valid
}) => {
    /***** HOOKS *****/
    const { currentDNS, originalNameservers } = useSelector((/** @type {any} */ state) => ({
        currentDNS: state.domain.domain_dns_view.config,
        originalNameservers: state.domain.domain_nameserver_store
    }));

    /***** RENDER *****/
    return (
        <form className="changeNameserver" onSubmit={handleSubmit}>
            <Text secondary align--center className="changeNameserver__title">
                <Text span>{' You are about to change your DNS configuration from '}</Text>
                <Text bold span>
                    {configToName(currentDNS)}
                </Text>
                <Text span>{' to '}</Text>
                <Text bold span>
                    {configToName(newDNS)}
                </Text>
            </Text>
            <DisplayNameservers title="New Nameservers" nameservers={newNameservers} />
            <DisplayNameservers title="Old Nameservers" nameservers={originalNameservers} />
            <AcknowledgementBox.Compact
                title="Acknowledgements"
                notice={
                    <>
                        <Text bold uppercase>
                            Please Note:{' '}
                        </Text>
                        {content.AcknowledgementBox.body}
                    </>
                }
            >
                <Field
                    name="acknowledgementDNSPropagation"
                    type="checkbox"
                    className="changeNameserver__checkbox"
                    component={CheckBoxList.Item.ReduxForm}
                    validate={[requiredCheckboxFieldValidation]}
                >
                    <Text secondary size--s bold>
                        {content.AcknowledgementBox.dnsPropogation}
                    </Text>
                </Field>

                {isMovingToExternalNameservers(currentDNS, newDNS) && (
                    <>
                        <br />
                        <Field
                            type="checkbox"
                            component={CheckBoxList.Item.ReduxForm}
                            name="acknowledgementDNSRecords"
                            className="changeNameserver__checkbox"
                            validate={[requiredCheckboxFieldValidation]}
                        >
                            <Text secondary size--s bold>
                                {content.AcknowledgementBox.dnsRecords}
                            </Text>
                        </Field>
                    </>
                )}
            </AcknowledgementBox.Compact>
            {renderButton(pristine, submitting, valid, submitting ? <RequestLoader /> : 'Set DNS', 'primary', 'changeNameserver__set')}
            <Anchor color="secondary" onClick={onCancel} className="changeNameserver__cancel">
                NO, KEEP MY CURRENT DNS SETTINGS
            </Anchor>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

// _ChangeNameserversForm.propTypes = {
//     /**
//      * The current DNS configuration
//      */
//     currentDNS: PropTypes.oneOf(Object.values(configKeys)).isRequired,

//     /**
//      * The new DNS configuration
//      */
//     newDNS: PropTypes.oneOf(Object.values(configKeys)).isRequired,

//     /**
//      * Callback to cancel the form
//      */
//     onCancel: PropTypes.func.isRequired
// };

const ChangeNameserversForm = reduxForm({
    form: 'ChangeNameserversForm',
    enableReinitialize: true
})(_ChangeNameserversForm);

export default ChangeNameserversForm;
