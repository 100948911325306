/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { useMemo } from 'react';

/**
 *
 * @param {ContentEditorLightbox.Context['activeFormValues']} activeFormValues
 * @param {ContentEditorLightbox.Context['activeFormSyncErrors']} activeFormSyncErrors
 */
export function useActiveValidFormValues(activeFormValues, activeFormSyncErrors) {
    return useMemo(() => {
        const erroredKeys = _.keys(activeFormSyncErrors);
        return _.omit(activeFormValues, erroredKeys);
    }, [activeFormValues, activeFormSyncErrors]);
}
