/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _PillMultiSelect as PillMultiSelect } from 'components/Form/PillMultiSelect/_PillMultiSelect';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { PillMultiSelectNamespace } from 'components/Form/PillMultiSelect/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RenderPillMultiSelectHookFormDefault: React.FC<PillMultiSelectNamespace.HookForm.Props> = ({
    label,
    options,
    disabled,
    initialLimit,
    name
}) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value, onChange } = field;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className="RenderPillMultiSelect">
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner.HookForm name={name} noBackground>
                <PillMultiSelect value={value} onChange={onChange} options={options} initialLimit={initialLimit} />
            </FormItemInner.HookForm>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
