/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import type { FileWithPath } from 'react-dropzone';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FileRenderer from 'components/FileRenderer';
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const baseClass = 'NXDropZone__fileRendererWrapper';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    file: FileWithPath;
    index?: number;
    removeFile: (index: number) => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const NXDropZoneFileRenderer: React.FC<Props> = ({ file, index = -1, removeFile }) => {
    /***** RENDER *****/
    if (Array.isArray(file)) {
        return;
    }

    if (!file?.type) {
        return 'file type not supported';
    }

    const flatType = file.type.replaceAll('/', '');
    return (
        <div className={classNames(baseClass, `${baseClass}--${flatType}`)}>
            <div className={`${baseClass}__wrapperChild`}>
                <FileRenderer file={file} />
                <button
                    type="button"
                    className="NXDropZone__removeFileButton"
                    onClick={() => {
                        removeFile(index);
                    }}
                >
                    <PhosphorIcons.Trash.Bold white />
                </button>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
