/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { Select } from 'components/Form/Select';
import { TextArea } from 'components/Form/TextArea';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { renderButton, requiredAcceptedValidation, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class CancelAddonForm extends Component {
    render() {
        const { loading, handleSubmit, onClose, confirmationFields, pristine, submitting, valid } = this.props;

        const renderConfirmationFields = () => {
            const finalConfirmationFields = confirmationFields ?? [
                {
                    name: 'confirm_delete',
                    label: 'I confirm all data stored as part of this addon, including backups, will be deleted.'
                },
                {
                    name: 'confirm_backup',
                    label: 'I confirm any data I require is backed up externally.'
                }
            ];

            return (
                <>
                    {finalConfirmationFields.map((field) => (
                        <div key={field.name} className="confirmation__input">
                            <Field
                                name={field.name}
                                label={field.label}
                                component={CheckBoxList.Item.ReduxForm}
                                validate={[requiredAcceptedValidation]}
                            />
                        </div>
                    ))}
                </>
            );
        };

        const renderForm = () => {
            return (
                <form onSubmit={handleSubmit}>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Primary Reason for Cancelling Addon"
                                name="reason"
                                component={Select.ReduxForm}
                                validate={[requiredFieldValidation]}
                                className="form__dropdown"
                                options={[
                                    {
                                        label: 'No longer need this addon',
                                        value: 'No longer need this addon'
                                    },
                                    {
                                        label: 'Unhappy with the performance of the addon',
                                        value: 'Unhappy with the performance of the addon'
                                    },
                                    {
                                        label: 'The quality of support provided was unsatisfactory',
                                        value: 'The quality of support provided was unsatisfactory'
                                    },
                                    {
                                        label: 'The price and inclusions are no longer competitive',
                                        value: 'The price and inclusions are no longer competitive'
                                    },
                                    {
                                        label: 'Other',
                                        value: 'other'
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Additional Comments (Optional)"
                                name="reason_comment"
                                component={TextArea.ReduxForm}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="confirmation__container">
                        <h5 className="confirmation__heading">Confirmation</h5>
                        {renderConfirmationFields()}
                    </div>
                    {renderButton(pristine, submitting, valid, 'Remove', 'primary')}
                    <Anchor className="cancelAddonForm__backLink" onClick={onClose}>
                        No, Go Back
                    </Anchor>
                </form>
            );
        };

        return (
            <OverlayLightbox className="cancelAddonForm" onOpen={true} onClose={onClose} title="Remove Addon" loading={loading}>
                {renderForm()}
            </OverlayLightbox>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CancelAddonForm = reduxForm({
    form: 'cancelAddon',
    enableReinitialize: true
})(CancelAddonForm);

export default CancelAddonForm;
