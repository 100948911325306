/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__RadioCircle.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RadioCircleNamespace } from 'components/Form/Radio/_Circle/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The RadioCircle component provides a standardized circle that should be used for a RadioButton,
 * This doesn't store any state and accepts isActive to allow the parent to control it's state such as
 * by wrapping it in a button and then using the button's state to control the circle's state.
 */
export const _RadioCircle: React.FC<RadioCircleNamespace.Props> = ({ isActive, color, disabled }) => {
    /***** RENDER *****/
    return (
        <span
            className={classNames('RadioCircle', {
                [`RadioCircle--${color}`]: color,
                'RadioCircle--selected': isActive,
                'RadioCircle--disabled': disabled
            })}
        />
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
