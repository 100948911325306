/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_TextArea.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXTextAreaNamespace } from 'components/Form/TextArea/types';

const textAreaAppliedStylingPropTypeKeys = ['readonly', 'disabled', 'isPlaceholderItalic'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * CCP `textarea` abstraction
 */
export const _TextArea: React.FC<NXTextAreaNamespace.Props> = (props) => {
    const { name, readOnly, placeholder, disabled, rows = 5, value, spellCheck = false, textAreaRef, intrinsicProps } = props;

    /***** RENDER HELPERS *****/
    const appliedClassNames = createAppliedStylingClasses({
        props,
        keyBoundary: textAreaAppliedStylingPropTypeKeys,
        delimiter: '--',
        componentName: 'NXTextArea'
    });

    /***** RENDER *****/
    return (
        <textarea
            className={classNames('NXTextArea', appliedClassNames)}
            name={name}
            readOnly={readOnly}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows}
            value={value}
            spellCheck={spellCheck}
            ref={textAreaRef}
            {...intrinsicProps}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
