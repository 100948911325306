/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useUrlDropdown } from 'components/NXBox/Dropdown/hooks/useUrlDropdown';
import { useDiscountRegeneration } from './hooks';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function BillingCycleSection() {
    /***** HOOKS *****/
    const { invoice } = useDiscountRegeneration();
    const { id: serviceId } = useParams<{ id: string }>();
    const { toggleDropdown } = useUrlDropdown(`/my-services/hosting/account/renew/${serviceId}`, {
        type: 'billing',
        open: true
    });

    /***** RENDER *****/
    return (
        <div className="hostingBilling__box--container">
            <div className="hostingBilling__box--title">Billing Cycle</div>
            <div className="hostingBilling__box--section">
                <div className="hostingBilling__box--desc">
                    {invoice?.id
                        ? `You can change your billing cycle to make when you’re invoiced more convenient. Changing your billing cycle now will reissue your current outstanding invoice #${invoice?.id} with your new billing cycle.`
                        : "You can change your billing cycle to make when you're invoiced more convenient. You will be invoiced on the new billing cycle at the end of your current prepaid period."}
                </div>
                <OutlineButton
                    className="hostingBilling__button"
                    size="large"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleDropdown();
                    }}
                >
                    Change
                </OutlineButton>
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
