/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { EntryCreationBehaviourTypeValues } from 'containers/katana/formFields/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type entryCreationBehaviourParserReturn = {
    method: EntryCreationBehaviourTypeValues;
    target: string;
} | null;

export function entryCreationBehaviourParser(entryCreationBehaviour: string): entryCreationBehaviourParserReturn {
    const behaviourData = entryCreationBehaviour?.split(':');
    const entryBehaviourObject = {
        method: behaviourData[0] as EntryCreationBehaviourTypeValues,
        target: behaviourData[1]
    };
    return behaviourData?.length ? entryBehaviourObject : null;
}
