/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import _ from 'lodash';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { MINUTE } from 'utilities/consts';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
import { createOptimisticUpdateMethod } from 'utilities/methods/tanstack/createOptimisticUpdateMethod';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';

type TData = Awaited<KatanaAPI.Katana.ServiceID.Info.GET.ReturnType>;

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(['katana', 'service', serviceID, 'info'] as const);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.service_id.info.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

const setQueryData = createSetQueryDataMethod<number, TData>(createQueryKey);

export function cancelQueries(serviceID: number) {
    return queryClient.cancelQueries({
        queryKey: createQueryKey(serviceID)
    });
}

function resetQueries(serviceID: number) {
    return queryClient.resetQueries({
        queryKey: createQueryKey(serviceID)
    });
}

function prefetchQuery(serviceID: number) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function invalidateQueries(serviceID: number) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the site info
 */
function _useQuery(serviceID: number) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

function applyOptimisticColourUpdate(serviceID: number, color: string) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = _.cloneDeep(oldData);

        if (!_.has(clonedData, 'data.color')) {
            return;
        }

        clonedData.data.color = color;

        return clonedData;
    });
}

export const getSiteInfo = Object.freeze({
    useQuery: _useQuery,
    setQueryData,
    cancelQueries,
    resetQueries,
    prefetchQuery,
    invalidateQueries,
    optimistic: createOptimisticUpdateMethod(setQueryData),
    applyOptimisticColourUpdate,
    createQueryKey,
    createQueryOptions
});
