/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import React, { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXPill from 'components/NXPill';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import type { PillMultiSelectNamespace } from 'components/Form/PillMultiSelect/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PillMultiSelect.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _PillMultiSelect: React.FC<PillMultiSelectNamespace.Props> = ({ value, onChange, initialLimit = 0, disabled, options }) => {
    /***** STATE *****/
    const [displayLimit, setDisplayLimit] = useState(initialLimit);

    /***** RENDER HELPERS *****/
    const selectedValues = Array.isArray(value) ? value : [];
    const filteredOptionValues = selectedValues.filter((selectedValue) => !options.includes(selectedValue));
    const concatenatedOptions = _.sortBy([...options, ...filteredOptionValues]);
    const finalOptions =
        displayLimit === 0
            ? concatenatedOptions
            : concatenatedOptions.slice(0, displayLimit < concatenatedOptions.length ? displayLimit : concatenatedOptions.length);

    /***** FUNCTIONS *****/
    function toggleOption(option: string) {
        if (selectedValues.includes(option)) {
            const selectedOptions = [...selectedValues];
            const optionIndex = selectedOptions.indexOf(option);
            selectedOptions.splice(optionIndex, 1);

            if (selectedOptions.length) {
                onChange(selectedOptions);
                return;
            }

            onChange([]);
            return;
        }

        const selectedOptions = [...selectedValues, option];
        onChange(selectedOptions);
    }

    /***** RENDER *****/
    return (
        <Flex gap={2} wrap>
            {finalOptions.map((webpageType) => {
                const isHighlighted = selectedValues.includes(webpageType);
                return (
                    <NXPill isHighlighted={isHighlighted} hoverEffect onClick={() => toggleOption(webpageType)} key={webpageType}>
                        {webpageType}
                    </NXPill>
                );
            })}
            {Boolean(displayLimit) && concatenatedOptions.length > displayLimit && (
                <NXPill primary hoverEffect onClick={() => setDisplayLimit(0)}>
                    Show More
                </NXPill>
            )}
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
