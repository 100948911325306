/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXDropZone } from 'components/Form/NXDropZone';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import FileSelectControl from 'components/ImageSelectControl/variants/FileSelectControl';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStateValueHasFile } from 'components/Form/NXDropZone/hooks/useStateValueHasFile';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A component that renders a drop zone for file uploads with file control.
 */
const NXDropZoneWithKatanaFileUploadAndControl: React.FC<NXDropZoneNamespace.Variants.WithKatanaFileUploadAndControl.Props> = ({
    input,
    label,
    accept,
    singleFile = false,
    onZoneRef,
    serviceID,
    currentFormValues
}) => {
    const { name, value, onChange } = input;
    /***** STATE *****/
    const zoneRef = useRef<DropzoneRef>();
    const [hasFile, setHasFile] = useStateValueHasFile(currentFormValues?.file);

    /***** HOOKS *****/
    // conditionally run the query hook so it doesn't run the query for other radio buttons
    const { data: get_single_file_info, isLoading: isGetSingleFileLoading } = katanaQuery.serviceID.getSingleFileInfo.useQuery({
        serviceID,
        uploadID: currentFormValues?.type === 'file' ? value : null
    });
    const { url, name: fileName, mime_type } = get_single_file_info ?? {};

    /***** FUNCTIONS *****/
    const onRef = useCallback((ref: DropzoneRef) => {
        if (ref !== zoneRef.current) {
            zoneRef.current = ref;
            onZoneRef?.(ref);
        }
    }, []);

    function handleOnDrop(newFile: File[]) {
        // Do not setHasFile to false if the new uploaded file is the same as the existing file
        if (newFile[0]?.name === fileName) return;

        setHasFile(false);
    }

    /***** RENDER *****/
    return (
        <>
            <Field
                name={name}
                label={label}
                component={NXDropZone.ReduxForm.WithKatanaPDFUpload}
                onDropZoneDrop={handleOnDrop}
                serviceID={serviceID}
                zoneRef={onRef}
                className={classNames('NXDropZoneWithImageControl', { hidden: hasFile })}
                type="dropzone"
                accept={accept}
                singleFile={singleFile}
            />
            <FileSelectControl
                url={url}
                fileName={fileName}
                mimeType={mime_type}
                isGetSingleFileLoading={isGetSingleFileLoading}
                className={classNames('NXDropZoneWithImageControl', { hidden: !hasFile })}
                onClickUploadNew={async () => {
                    zoneRef?.current?.open?.();
                }}
                onClickRemove={() => {
                    onChange(null);
                }}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXDropZoneWithKatanaFileUploadAndControl;
