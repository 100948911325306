/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BillingCycleForm from '../forms/billingCycleForm';
import PrepayForm from '../forms/renewForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import InfoNotification from 'components/Notifications/InfoNotification';

/*   ACTIONS
 *****************************************************/
import { getIncludedObjBasedOnType, renderPrepaidForDuration, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getRenewVPSInfo, getSelectedVPS, getVPSBillingCycles, renewVPS, updateVPSBillingCycle } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class BillingVPS extends Component {
    constructor(props) {
        super(props);
        this.toggleInvoiceLightbox = this.toggleInvoiceLightbox.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.toggleBillingForm = this.toggleBillingForm.bind(this);
        this.togglePrepayForm = this.togglePrepayForm.bind(this);
        this.submitBillingRequest = this.submitBillingRequest.bind(this);
        this.submitPrepayRequest = this.submitPrepayRequest.bind(this);
        this.state = {
            showBillingForm: false,
            billingOptions: [],
            showPrepayForm: false,
            prepayOptions: [],
            prepayPrices: null,
            showInvoiceLightbox: false,
            invoiceID: null,
            billingInformation: {
                cycle: undefined,
                invoice: undefined,
                prepaid: undefined
            }
        };
    }

    /************** INVOICE TOGGLES **************/

    toggleInvoiceLightbox(invoiceId = null) {
        this.setState({
            showInvoiceLightbox: !this.state.showInvoiceLightbox,
            invoiceId
        });
    }

    closePayInvoiceSuccess() {
        const { getSelectedVPS, serviceid } = this.props;

        this.setState(
            {
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getSelectedVPS(serviceid);
            }
        );
    }

    /************** FORM TOGGLES **************/

    toggleBillingForm() {
        this.setState({
            showBillingForm: !this.state.showBillingForm,
            showPrepayForm: false
        });
    }

    togglePrepayForm() {
        this.setState({
            showPrepayForm: !this.state.showPrepayForm,
            showBillingForm: false
        });
    }

    /************** SUBMIT METHODS **************/
    submitBillingRequest(values) {
        const { serviceid, updateVPSBillingCycle } = this.props;

        const attributes = {
            billing_cycle_id: values.newBillingCycle
        };
        updateVPSBillingCycle(serviceid, attributes);
    }

    submitPrepayRequest(values) {
        const { serviceid, renewVPS } = this.props;
        const attributes = {
            billing_cycle: values.cycle
        };
        renewVPS(serviceid, attributes);
    }

    /************** LIFECYCLE METHODS **************/

    componentDidMount() {
        const { vps_information_data, getRenewVPSInfo, getVPSBillingCycles, serviceid, location } = this.props;
        const { togglePrepayForm } = this;

        getRenewVPSInfo(serviceid);
        getVPSBillingCycles(serviceid);

        if (vps_information_data) {
            const {
                attributes: { billing_cycle, next_invoice_date, next_due_date }
            } = vps_information_data;
            const invoice = getIncludedObjBasedOnType(vps_information_data.included, 'invoice');

            const prepaidFor = renderPrepaidForDuration(toLuxonDate(next_due_date, 'yyyy-MM-dd TT'));

            this.setState({
                invoiceID: invoice ? invoice.id : null,
                billingInformation: {
                    cycle: billing_cycle,
                    invoice: toLuxonDate(next_invoice_date, 'yyyy-MM-dd TT').toFormat('cccc, d LLLL y'),
                    prepaid: prepaidFor
                }
            });
        }

        if (location.pathname.includes('renew')) {
            const query = new URLSearchParams(location.search);
            const open = query.get('open');

            if (open) {
                togglePrepayForm();
                scroll.scrollTo(this.scrollRef.getBoundingClientRect().top);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {
            getSelectedVPS,
            vps_information_data,
            vps_renew_info_status,
            vps_renew_info_data,
            vps_billing_cycle_status,
            vps_billing_cycle_data,
            vps_billing_cycle_update_status,
            vps_renew_status,
            vps_renew_data,
            serviceid,
            location
        } = this.props;
        const { toggleBillingForm, togglePrepayForm } = this;

        registerScrollEvents(this, true /*todo*/);

        if (location !== prevProps.location && location.pathname.includes('billing')) {
            const query = new URLSearchParams(location.search);
            const open = query.get('open');

            if (open) {
                toggleBillingForm();
                scroll.scrollTo(this.scrollRef.getBoundingClientRect().top);
            }
        }

        if (location !== prevProps.location && location.pathname.includes('renew')) {
            const query = new URLSearchParams(location.search);
            const open = query.get('open');

            if (open) {
                togglePrepayForm();
                scroll.scrollTo(this.scrollRef.getBoundingClientRect().top);
            }
        }

        // Billing cycle info success
        if (vps_billing_cycle_status === 'success' && prevProps.vps_billing_cycle_status === 'loading') {
            const { billing_cycles } = vps_billing_cycle_data.attributes;

            const billingOptions = billing_cycles.map((item) => ({
                label: `${item.name.name} - $${item.amount} AUD${
                    vps_information_data?.attributes.billing_cycle === item.name.name ? ' (Same As Current)' : ''
                }`,
                value: item.id
            }));

            this.setState({
                billingOptions
            });
        }

        // Prepey info success
        if (vps_renew_info_status === 'success' && prevProps.vps_renew_info_status === 'loading') {
            const { prices } = vps_renew_info_data;

            const processOptions = (options) => {
                const { billing_cycle } = vps_information_data.attributes;
                if (options) {
                    return Object.entries(options).map(([key, value]) => {
                        const { duration, sub_total } = value;

                        return {
                            label: `${duration} - $${sub_total}${key === billing_cycle ? ' (Current Billing Cycle)' : ''}`,
                            value: key
                        };
                    });
                }
            };

            this.setState({
                prepayPrices: prices,
                prepayOptions: processOptions(prices)
            });
        }

        // Billing cycle update success
        if (vps_billing_cycle_update_status === 'success' && prevProps.vps_billing_cycle_update_status === 'loading') {
            this.setState(
                {
                    showBillingForm: false
                },
                () => getSelectedVPS(serviceid)
            );
        }

        // Prepay success
        if (vps_renew_status === 'success' && prevProps.vps_renew_status === 'loading') {
            const { id } = vps_renew_data;

            this.setState({
                showPrepayForm: false,
                showInvoiceLightbox: true,
                invoiceID: id
            });
        }

        // Prepay error
        if (vps_renew_status === 'error' && prevProps.vps_renew_status === 'loading') {
            this.setState({
                showPrepayForm: false,
                showInvoiceLightbox: false,
                invoiceID: null
            });
        }
    }

    render() {
        const { serviceid, vps_billing_cycle_status, vps_renew_info_status, vps_billing_cycle_update_status, vps_renew_status } = this.props;
        const { showBillingForm, showPrepayForm, showInvoiceLightbox, invoiceID, billingInformation, billingOptions, prepayOptions, prepayPrices } =
            this.state;
        const { toggleBillingForm, togglePrepayForm, toggleInvoiceLightbox, closePayInvoiceSuccess, submitBillingRequest, submitPrepayRequest } =
            this;

        const renderBillingCycleButton = () => {
            return (
                <OutlineButton
                    className="hostingBilling__button"
                    size="large"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleBillingForm();
                    }}
                >
                    Change
                </OutlineButton>
            );
        };

        const renderPrepayButton = () => {
            if (invoiceID) {
                return (
                    <InactiveButton className="hostingBilling__button" type="onClick" size="large">
                        <i className="icon icon-lock"></i>Renew
                    </InactiveButton>
                );
            }

            return (
                <OutlineButton
                    className="hostingBilling__button"
                    size="large"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        togglePrepayForm();
                    }}
                >
                    Renew
                </OutlineButton>
            );
        };

        /*   HANDLE INVOICE STATUS
         **********************************************************************************************************/
        const renderPayInvoiceNotification = () => {
            return (
                <InfoNotification type="warning">
                    <div className="text">
                        You have an outstanding invoice for this service. Please, contact our Billing Team for further assistance.
                    </div>
                    <div className="action">
                        <SolidButton
                            type="onClick"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleInvoiceLightbox(invoiceID);
                            }}
                            size="medium"
                            color="white"
                        >
                            Pay Invoice
                        </SolidButton>
                    </div>
                </InfoNotification>
            );
        };

        /*   HANDLE COLUMN RENDER
         **********************************************************************************************************/
        const handleBoxLoadingStatus = () => {
            if (
                vps_billing_cycle_status === 'loading' ||
                vps_renew_info_status === 'loading' ||
                vps_billing_cycle_update_status === 'loading' ||
                vps_renew_status === 'loading'
            )
                return 'loading';

            if (vps_billing_cycle_status === 'error' || vps_renew_info_status === 'error') return 'error';

            return 'success';
        };

        /*   CONDITIONAL PROPS
         **********************************************************************************************************/
        const conditionalProps = {
            columns: [
                {
                    render: (
                        <div className="billingCycle__column">
                            <div className="title">Current Billing Cycle</div>
                            <div className="desc">{billingInformation.cycle}</div>
                        </div>
                    )
                },
                {
                    render: (
                        <div className="billingCycle__column">
                            <div className="title">Next Invoice Date</div>
                            <div className="desc">{billingInformation.invoice}</div>
                        </div>
                    )
                }
            ],
            dropdown: {
                title: 'Change Billing Cycle',
                render: <BillingCycleForm onSubmit={submitBillingRequest} options={billingOptions} />,
                condition: showBillingForm,
                close: toggleBillingForm,
                status: vps_billing_cycle_update_status
            },
            columnsAtTop: true
        };

        if (billingInformation.prepaid) {
            conditionalProps.columns.push({
                render: (
                    <div className="hostingRenew__column">
                        <div className="title">Prepaid For</div>
                        <div className="desc">{billingInformation.prepaid}</div>
                    </div>
                )
            });
        }

        if (showPrepayForm) {
            conditionalProps.dropdown = {
                title: `Renew For`,
                render: <PrepayForm vpsId={serviceid} options={prepayOptions} prices={prepayPrices} submitPrepayRequest={submitPrepayRequest} />,
                condition: showPrepayForm,
                close: togglePrepayForm,
                status: vps_renew_status
            };
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="hostingBilling"
            >
                <Box
                    premounted={true}
                    className="hostingBilling__box"
                    title="Billing & Payments"
                    status={handleBoxLoadingStatus()}
                    custom={{
                        render: (
                            <>
                                <div className="hostingBilling__box--container">
                                    <div className="hostingBilling__box--title">Billing Cycle</div>
                                    <div className="hostingBilling__box--section">
                                        <div className="hostingBilling__box--desc">
                                            {invoiceID
                                                ? `You can change your billing cycle to make when you’re invoiced more convenient. Changing your billing cycle now will reissue your current outstanding invoice #${invoiceID} with your new billing cycle.`
                                                : "You can change your billing cycle to make when you're invoiced more convenient. You will be invoiced on the new billing cycle at the end of your current prepaid period."}
                                        </div>
                                        {renderBillingCycleButton()}
                                    </div>
                                </div>
                                <div className="hostingBilling__box--container">
                                    {invoiceID ? renderPayInvoiceNotification() : null}
                                    <div className="hostingBilling__box--title">Renew</div>
                                    <div className="hostingBilling__box--section">
                                        <div className="hostingBilling__box--desc">Keep a good thing going by renewing your service today.</div>
                                        {renderPrepayButton()}
                                    </div>
                                </div>
                            </>
                        ),
                        pos: `bottom`
                    }}
                    {...conditionalProps}
                />
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceID}
                        invoiceid={invoiceID}
                        onOpen={showInvoiceLightbox}
                        onClose={toggleInvoiceLightbox}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            vps_information_data: state.vps.vps_information_data,
            vps_renew_info_status: state.vps.vps_renew_info_status,
            vps_renew_info_data: state.vps.vps_renew_info_data,
            vps_billing_cycle_status: state.vps.vps_billing_cycle_status,
            vps_billing_cycle_data: state.vps.vps_billing_cycle_data,
            vps_billing_cycle_update_status: state.vps.vps_billing_cycle_update_status,
            vps_renew_status: state.vps.vps_renew_status,
            vps_renew_data: state.vps.vps_renew_data
        }),
        {
            getVPSBillingCycles,
            updateVPSBillingCycle,
            getRenewVPSInfo,
            renewVPS,
            getSelectedVPS
        }
    )(BillingVPS)
);
