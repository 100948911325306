/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXDropZone } from 'components/Form/NXDropZone';
import ImageSelectControl from 'components/ImageSelectControl';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStateValueHasFile } from 'components/Form/NXDropZone/hooks/useStateValueHasFile';
import { useGetKatanaFileUploadValue } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/useGetKatanaFileUploadValue';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { imageAcceptsTypes } from 'components/Form/NXDropZone/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A component that renders a drop zone for file uploads with an image control.
 */
export const _NXDropZoneHookFormWithKatanaImageUploadAndControl: React.FC<NXDropZoneNamespace.HookForm.WithKatanaImageUploadAndControl.Props> = ({
    label,
    name,
    imageControlProps,
    accept = imageAcceptsTypes,
    singleFile = false,
    onZoneRef,
    serviceID
}) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value } = field;
    const imageURL = useGetKatanaFileUploadValue(value);
    const zoneRef = useRef<DropzoneRef>();
    const [hasFile, setHasFile] = useStateValueHasFile(value);

    /***** FUNCTIONS *****/
    function onChange(files: NXDropZoneNamespace.Values) {
        field.onChange(files);
        field.onBlur();
    }

    const onRef = useCallback((ref: DropzoneRef) => {
        if (ref !== zoneRef.current) {
            zoneRef.current = ref;
            onZoneRef?.(ref);
        }
    }, []);

    /***** RENDER HELPERS *****/
    function handleOnDrop() {
        setHasFile(false);
    }

    /***** RENDER *****/
    return (
        <>
            <NXDropZone.HookForm.WithKatanaFileUpload
                name={name}
                label={label}
                onDropZoneDrop={handleOnDrop}
                serviceID={serviceID}
                zoneRef={onRef}
                className={classNames('NXDropZoneWithImageControl', { hidden: hasFile })}
                accept={accept}
                singleFile={singleFile}
            />
            <ImageSelectControl
                imageURL={imageURL}
                className={classNames('NXDropZoneWithImageControl', { hidden: !hasFile })}
                onClickUploadNew={() => {
                    zoneRef?.current?.open?.();
                }}
                onClickRemove={() => {
                    onChange(singleFile ? null : []);
                }}
                {...imageControlProps}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
