/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaRepeatedFormFieldSwitchRenderer } from 'containers/katana/formFields/repeated/switchRenderer';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_katanaRepeatedFormField.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaRepeatedFormField: KatanaNamespace.PropertyComponent = ({ property }) => {
    /***** RENDER *****/

    return (
        <div className="KatanaRepeatedFormField">
            <KatanaRepeatedFormFieldSwitchRenderer property={property} />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { KatanaRepeatedFormField };
