/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { modifyPropertyKeys } from 'containers/katana/formFields/methods/modifyPropertyKeys';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useModifiedFieldArrayProperties(
    member: any,
    properties: KatanaNamespace.SectionDefinitions.PropertiesModified[],
    isFieldArrayMember?: boolean
): KatanaNamespace.SectionDefinitions.PropertiesModified[] {
    /***** STATE *****/
    const [modifiedProperties, setModifiedProperties] = useState([]);

    /***** EFFECTS *****/
    useEffect(() => {
        const clonedModifiedProperties = modifyPropertyKeys(member, properties, isFieldArrayMember);
        setModifiedProperties(clonedModifiedProperties);
    }, [member, properties, isFieldArrayMember]);

    /***** RENDER *****/
    return modifiedProperties;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
