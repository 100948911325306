/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/Form/CheckBoxList';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SUPER_ACTIONS, useSuperUserActionKey } from 'components/StaffMenu/SuperUser/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SuperUserItem = ({ action }) => {
    /***** HOOKS *****/
    const { value: superActions, set: setSuperActions } = useSuperUserActionKey(action);
    const shouldDisplay = SUPER_ACTIONS[action]?.useDisplayCondition?.();

    /***** RENDER *****/
    if (SUPER_ACTIONS[action]?.useDisplayCondition && !shouldDisplay) return;
    return (
        <CheckBoxList.Item
            key={action}
            isChecked={superActions}
            onChange={(isChecked) => {
                setSuperActions(isChecked);
            }}
        >
            {SUPER_ACTIONS[action].menuContent}
        </CheckBoxList.Item>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SuperUserItem };
