/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useLayoutEffect } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Grid from 'components/Grid';
import GridSpan from 'components/Grid/GridSpan';
import { Subset } from 'components/Subset';
import Gradient from 'components/Utils/Gradient';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Revealer as Revealer } from 'components/Revealer/_Revealer';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStateEffect } from 'utilities/hooks/useStateEffect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { NAnchor } from 'components/Anchor/types';
import type { TGrid } from 'components/Grid/types';
import type { TSubSet } from 'components/Subset/types';
import './_GridHide.scss';

interface GridHideProps<T> {
    className?: string;
    gridProps: Omit<TGrid.Props, 'children'>;
    isDefaultOpen: boolean;
    data: Array<T>;
    children: TSubSet.ChildrenProp<T>;
    range: Readonly<[number, number] | [undefined | null, number] | [number, undefined | null]>;
    itemsPerRow: number;
    onOpenChange?: (isOpen: boolean) => void;
    anchorColour?: NAnchor.Props['color'];
    anchorContent?: (isOpen: boolean) => React.ReactNode;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const _GridHide = <T,>(props: GridHideProps<T>) => {
    const {
        className,
        gridProps,
        children,
        data,
        range,
        isDefaultOpen,
        itemsPerRow,
        onOpenChange,
        anchorColour = 'primary',
        anchorContent = (isOpen) => (isOpen ? 'SHOW LESS' : 'SHOW ALL')
    } = props;
    /***** STATE *****/
    const [isOpen, setIsOpen] = useStateEffect(isDefaultOpen);

    const hasSubGrid = data.length > range[1];

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        onOpenChange?.(isOpen);
    }, [isOpen]);

    /***** RENDER *****/
    return (
        <Grid
            {...gridProps}
            className={classNames(
                'Revealer__GridHide',
                {
                    'Revealer__GridHide--isOpen': isOpen,
                    'Revealer__GridHide--hasSubGrid': hasSubGrid
                },
                className
            )}
        >
            <Subset range={range} data={data}>
                {children}
            </Subset>
            {hasSubGrid && (
                <GridSpan span={itemsPerRow}>
                    <Revealer isOpen={isOpen}>
                        <Grid {...gridProps}>
                            <Subset range={[range[1]]} data={data}>
                                {children}
                            </Subset>
                        </Grid>
                    </Revealer>
                    <Gradient white />
                    <Anchor onClick={() => setIsOpen(!isOpen)} className="Revealer__GridHide__Anchor" color={anchorColour}>
                        <Text medium>{anchorContent(isOpen)}</Text>
                    </Anchor>
                </GridSpan>
            )}
        </Grid>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _GridHide };
