/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import { HookFormButton } from 'components/Form/Button/hookForm';
import { Select } from 'components/Form/Select';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useEnterDrawMutation, useGetDrawEligibilityQuery } from 'config/containers/promotions/queries';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { zodResolver } from '@hookform/resolvers/zod';
import AflImage from 'assets/images/promo/aflGiveaway/aflGrandFinalGiveaway.jpg';
import '../_rewardCard.scss';
import { aflTeams } from '../consts';
const aflPromoId = 'afl-final-draw-2024';

const schema = z.object({
    // @ts-ignore
    team: z.string()
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AflGrandFinalGiveaway2024Card = () => {
    /***** STATE *****/
    const [modalToOpen, setModalToOpen] = useState('');

    /***** HOOKS *****/
    const methods = useForm({
        defaultValues: {
            team: ''
        },
        resolver: zodResolver(schema)
    });

    const selectedTeam = methods.watch('team');

    /***** QUERIES *****/
    const { error: get_draw_eligibility_error, isLoading: isGetDrawEligibilityLoading } = useGetDrawEligibilityQuery(aflPromoId);
    const { mutate: enterDraw, isPending: isEnterDrawLoading } = useEnterDrawMutation(aflPromoId, selectedTeam);

    const { already_redeemed, is_promo_finished, is_promo_started } = get_draw_eligibility_error?.data ?? {};

    /***** FUNCTIONS *****/
    function closeModals() {
        setModalToOpen('');
    }

    function openConfirmationModal() {
        setModalToOpen('confirmation');
    }

    function openSelectTeamModal() {
        setModalToOpen('selectTeam');
    }

    function isButtonDisabled() {
        return get_draw_eligibility_error?.data && (already_redeemed || is_promo_finished || is_promo_started === false);
    }

    function onSubmit(formData: { team: string }) {
        const slecetedTeam = formData.team;

        enterDraw(
            { slecetedTeam },
            {
                onSuccess: () => {
                    openConfirmationModal();
                }
            }
        );
    }

    /***** RENDER HELPER *****/
    function renderButtonContents() {
        if (is_promo_started === false) {
            return 'Promo Not Started';
        }

        if (is_promo_finished) {
            return 'Promo Finished';
        }

        if (already_redeemed) {
            return 'Entered';
        }

        return 'Enter Now';
    }

    function renderConfirmationLightBox() {
        return (
            <Modal isOpen={modalToOpen === 'confirmation'} onClose={closeModals} ariaLabel="Contest Entry Confirmation">
                <Flex direction="column" justify="center" items="center" gap={0}>
                    <Text.Heading h2>{`You're in!`}</Text.Heading>
                    <Text size--m secondary>
                        The winner will be drawn and contacted on the 20th of September 2024.
                    </Text>
                    <Padding top={7}>
                        <SolidButton color="primary" onClick={closeModals} className="nrlGiveawayLightbox__button">
                            Go back
                        </SolidButton>
                    </Padding>
                </Flex>
            </Modal>
        );
    }

    const aflTeamOptions = aflTeams.map((team) => {
        return {
            label: team,
            value: team
        };
    });

    function renderTeamSelectionLightBox() {
        return (
            <Modal isOpen={modalToOpen === 'selectTeam'} onClose={closeModals} ariaLabel="Contest Entry Confirmation">
                <Flex direction="column" justify="center" items="center" gap={0}>
                    <Text.Heading h2>Which AFL team do you support?</Text.Heading>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Select.HookForm
                                label={
                                    <Text size--m secondary>
                                        Let us know which team you go for
                                    </Text>
                                }
                                name="team"
                                options={aflTeamOptions}
                            />
                            <Padding top={2} bottom={5} className="rewardCard__aflGrandFinal--disclaimerText">
                                <Text italic span size--xs align--right color="S_color_text_secondary_base">
                                    *Please note: The winner of this giveaway will be selected at random.
                                    <br /> Your choice of team will not influence the outcome of the draw.
                                </Text>
                            </Padding>
                            <HookFormButton loading={isEnterDrawLoading}>Enter Now</HookFormButton>
                        </form>
                    </FormProvider>
                </Flex>
            </Modal>
        );
    }

    /***** RENDER *****/
    return (
        <>
            <RewardCard>
                <RewardCard.Image tag="GIVEAWAY">
                    <div className="rewardCard__bg rewardCard__bg--nrlGiveaway">
                        <img src={AflImage} alt="AFL" width={278} />
                    </div>
                </RewardCard.Image>
                <RewardCard.Body
                    title="Win 2 tickets to watch the AFL Grand Final at the MCG, plus flights and accommodation"
                    description="Enter now for your chance to win two corporate box tickets to watch the AFL Grand Final at the MCG. T’s & C’s Apply."
                    subDescription={
                        <Padding top={1} bottom={6}>
                            <Anchor
                                color="secondary"
                                hoverColor="secondary"
                                underline
                                href="https://www.ventraip.com.au/terms-policies-agreements/viprewards-2024-afl-grand-final-giveaway-terms-and-conditions/"
                                target="_blank"
                            >
                                <Text italic span size--xs>
                                    *Terms and conditions apply.
                                </Text>
                            </Anchor>
                        </Padding>
                    }
                >
                    <SolidButton color="primary" disabled={isButtonDisabled()} isLoading={isGetDrawEligibilityLoading} onClick={openSelectTeamModal}>
                        {renderButtonContents()}
                    </SolidButton>
                </RewardCard.Body>
            </RewardCard>

            {/* renders on promo tile button click */}
            {renderTeamSelectionLightBox()}
            {/* renders onSuccess of entering the draw */}
            {renderConfirmationLightBox()}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
