/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import DialogNotification from 'components/Notifications/DialogNotification';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useMatchMedia } from 'utilities/hooks/useMatchMedia';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PublishedWithIssues.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PublishedWithIssues = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const verticalViewports = useAppViewport(['lg', 'md', 'xs']);
    const isFlexVertical = useMatchMedia({ max: 1120 }) && verticalViewports;

    /***** QUERIES *****/
    const { data: serviceData } = katanaQuery.serviceID.getService.useQuery(id);
    const { data: dnsData } = katanaQuery.serviceID.getDNS.useQuery(id, { enabled: serviceData?.attributes?.site_status === 'published' });

    /***** RENDER *****/
    if (serviceData?.attributes?.site_status !== 'published' || dnsData?.is_dns_configured) {
        return null;
    }

    return (
        <Grid.Span span={2} className="PublishedWithIssues">
            <DialogNotification type="warning">
                <Flex gap={5} items="center" direction={isFlexVertical && 'column'}>
                    <Text align--left bold lead--s>
                        PLEASE NOTE: You have some misconfigured DNS records on your domain name which may prevent your webpage from being live.
                    </Text>
                    <SolidButton to={`/my-services/vip-sites/${id}/admin/dns`} color="white">
                        <Text semiBold>Fix Issues</Text>
                    </SolidButton>
                </Flex>
            </DialogNotification>
        </Grid.Span>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
