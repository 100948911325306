/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { Radio } from 'components/Form/Radio';
import { Modal } from 'components/Lightboxes/Modal';
import SolidTag from 'components/Tags/SolidTag';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_autoRenewForm.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AutoRenewForm = ({ isOpen, handleAutoRenewCheck, autoRenew, setAutoRenew }) => {
    /***** RENDER *****/
    return (
        <Modal ariaLabel="auto renew domain name lightbox" onClose={() => {}} isOpen={isOpen} removeCloseButton>
            <div className="autoRenewForm__container">
                <section className="autoRenewForm__textContainer">
                    <Text className="autoRenewForm__title">
                        We noticed you don&apos;t have
                        <br />{' '}
                        <Text bold capitalize>
                            <span>auto renew</span>
                        </Text>{' '}
                        enabled on this domain name
                    </Text>
                    <Text align--center size--s secondary>
                        With Auto Renew enabled, we&apos;ll automatically invoice you 14 days before your domain is due to expire and attempt to
                        charge any stored credit card.
                    </Text>
                </section>
                <section className="autoRenewForm__formContainer">
                    <button type="button" onClick={() => setAutoRenew('enable')} className="autoRenewForm__radioButtonBox">
                        <div>
                            <Radio.Circle isActive={autoRenew === 'enable'} color="primary" />
                        </div>
                        <div className="autoRenewForm__buttonBoxTextContainer">
                            <div className="autoRenewForm__recommended ">
                                <Text bold uppercase size--xs secondary>
                                    enable auto renew
                                </Text>
                                <SolidTag className="autoRenewForm__solidTag" color="success">
                                    recommended
                                </SolidTag>
                            </div>
                            <Text size--s secondary>
                                Renewal will automatically process next time
                            </Text>
                        </div>
                    </button>
                    <button type="button" onClick={() => setAutoRenew('disable')} className="autoRenewForm__radioButtonBox">
                        <Radio.Circle isActive={autoRenew === 'disable'} color="primary" />
                        <div className="autoRenewForm__buttonBoxTextContainer">
                            <Text bold uppercase align--left size--xs secondary>
                                keep auto renew disabled
                            </Text>
                            <Text size--s secondary>
                                Renewal will need to be done manually
                            </Text>
                        </div>
                    </button>
                    <SolidButton className="autoRenewForm__button" type="onClick" onClick={handleAutoRenewCheck} disabled={autoRenew === null}>
                        Continue to Payment
                    </SolidButton>
                </section>
            </div>
        </Modal>
    );
};

export default AutoRenewForm;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
