/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Select as Select } from 'components/Form/Select/_Select';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectNamespace } from 'components/Form/Select/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SelectHookFormDefault: React.FC<SelectNamespace.HookForm.Props> = ({ label, name, options, validate = null, size }) => {
    /***** HOOKS *****/
    // TODO: Check validation stuff what it does and how it should work, what the correct type should be
    const { field } = useController({ name, rules: { validate } });

    /***** RENDER *****/
    return (
        <FormItem size={size}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner.HookForm name={name}>
                <Select value={field.value} onChange={field.onChange} options={options} />
            </FormItemInner.HookForm>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _SelectHookFormDefault };
