/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _ColourSelect as ColourSelect } from 'components/Form/ColourSelect/_ColourSelect';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ColourSelectNamespace } from 'components/Form/ColourSelect/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ColourSelectHookFormDefault: React.FC<ColourSelectNamespace.HookForm.Props> = ({ name, options, preventDeselect }) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value, onChange } = field;

    /***** RENDER *****/
    return <ColourSelect value={value} onChange={onChange} options={options} preventDeselect={preventDeselect} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
