/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCallback, useContext, useEffect, useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStoreEffect } from 'utilities/hooks/useStoreEffect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ContentEditorRenderModulesNamespace } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/types';
import type { FieldArrayMetaProps } from 'redux-form';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useFieldArrayMetaOverrideOnSubmitButtonClick(meta: FieldArrayMetaProps) {
    /***** STATE *****/
    const [metaOverride, setMetaOverride] = useState(meta);

    /***** HOOKS *****/
    const { submitButtonClickEvent } = useContext(ContentEditorRenderModulesContext);

    /***** FUNCTIONS *****/
    const handleSubmitButtonEvent = useCallback(
        (e: ContentEditorRenderModulesNamespace.SubmitButtonClickEventValue) => {
            if (metaOverride.pristine && meta.pristine && (meta.error || meta.warning)) {
                setMetaOverride({ ...meta, pristine: false });
            }
        },
        [meta, metaOverride]
    );

    /***** EFFECTS *****/
    useStoreEffect(submitButtonClickEvent, handleSubmitButtonEvent, false);
    useEffect(() => {
        setMetaOverride({ ...meta, pristine: metaOverride.pristine });
    }, [meta]);

    return metaOverride;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
