/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { TextArea } from 'components/Form/TextArea';

/*   ACTIONS
 *****************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import { renderCheckboxField, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class FeedbackForm extends Component {
    render() {
        const { handleSubmit, form } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form onSubmit={handleSubmit} className="feedbackForm">
                <div className="feedbackForm__row form__row">
                    <div className="form__column form__column--full">
                        <Field
                            name="message"
                            component={TextArea.ReduxForm}
                            validate={requiredFieldValidation}
                            className="feedbackForm__textarea form__textfield"
                        />
                    </div>
                </div>
                <div className="feedbackForm__row form__row">
                    <div className="form__column form__column--full">
                        <Field
                            name="need_callback"
                            label="I would like to be contacted via a phone call using the contact number listed on my account in regards to my feedback"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="feedbackForm__checkbox form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <ReduxFormButton className="form__column form__column--full" form={form}>
                        Submit Feedback
                    </ReduxFormButton>
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
FeedbackForm = reduxForm({
    form: 'FeedbackForm',
    enableReinitialize: true
})(FeedbackForm);

export default FeedbackForm;
