/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useIsMutating } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import DialogNotification from 'components/Notifications/DialogNotification';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';
import CheckListItems from 'containers/katana/modules/launchChecklist/checkListItems';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useCreateKatanaSite } from 'containers/katana/queries/mutations';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaServiceSetupURL } from 'containers/katana/hooks/useKatanaServiceSetupURL';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useKatanaChecklistData } from 'containers/katana/modules/launchChecklist/useKatanaChecklistData';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import katanaBannerBackground from 'assets/images/katana/katana_banner_background.jpeg';
import katanaIcon from 'assets/images/katana/vipsites_banner_icon.svg';
import { SiteSetupStageOrder, katanaServicePages } from 'containers/katana/consts';
import './_addKatana.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AddKatanaComponent = React.FC<{
    domain: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AddKatana: AddKatanaComponent = ({ domain }) => {
    /***** STATE *****/
    const [optimisticKatanaServiceID, setOptimisticKatanaServiceID] = useState<number | null>(null);

    /***** HOOKS *****/
    const history = useHistory();
    const isMobile = useAppViewport(['xs', 'sm']);
    const isTablet = useAppViewport('md');

    /***** QUERIES *****/
    const { mutate: mutateCreateKatanaSite } = useCreateKatanaSite();
    const { data: check_katana_domain_data, isLoading: isCheckKatanaDomainLoading, isFetching, isSuccess } = katanaQuery.checkDomain.useQuery(domain);

    const katanaServiceID =
        typeof check_katana_domain_data === 'object' && !!check_katana_domain_data && 'site' in check_katana_domain_data
            ? check_katana_domain_data?.site?.id
            : 0;

    /***** HOOKS *****/
    const launchChecklistData = useKatanaChecklistData({ serviceID: katanaServiceID || optimisticKatanaServiceID });

    const { getKatanaDestination } = useKatanaURL(katanaServiceID || optimisticKatanaServiceID);

    const mutations = useIsMutating({ mutationKey: useCreateKatanaSite.mutationKey });
    const finalKatanaURL = useKatanaServiceSetupURL(katanaServiceID);

    const hasKatanaService = Boolean(katanaServiceID || check_katana_domain_data?.site_exists || optimisticKatanaServiceID);

    /***** FUNCTIONS *****/
    function claimKatanaPage() {
        mutateCreateKatanaSite(domain, {
            onSuccess: (data) => {
                if (data?.data?.site?.id) {
                    setOptimisticKatanaServiceID(data?.data?.site?.id);
                }
            }
        });
    }

    /***** RENDER HELPERS *****/
    const isKatanaSiteInProgress = useMemo(() => {
        return (
            _.has(check_katana_domain_data, 'site') &&
            'site' in check_katana_domain_data &&
            check_katana_domain_data?.site?.attributes?.site_status === 'in-progress'
        );
    }, [check_katana_domain_data]);

    const isKatanaSitePublished = useMemo(() => {
        return (
            _.has(check_katana_domain_data, 'site') &&
            'site' in check_katana_domain_data &&
            check_katana_domain_data?.site?.attributes?.site_status === 'published'
        );
    }, [check_katana_domain_data]);

    function renderClaimKatanaButton() {
        if (hasKatanaService) {
            return (
                <SolidButton
                    to={finalKatanaURL}
                    onMouseOver={() => katanaQuery.serviceID.getService.prefetchQuery(optimisticKatanaServiceID ?? katanaServiceID)}
                >
                    {isKatanaSiteInProgress ? 'Continue Building with VIPsites Service' : 'Go to VIPsites Service'}
                </SolidButton>
            );
        }

        return (
            <SolidButton isLoading={mutations > 0 || isFetching} onClick={claimKatanaPage} className="AddKatana__claimButton">
                Claim your FREE VIPsites website
            </SolidButton>
        );
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (optimisticKatanaServiceID) {
            history.push(getKatanaDestination(katanaServicePages.SETUP));
        }
    }, [optimisticKatanaServiceID]);

    /***** RENDER HELPERS *****/

    const overViewHeadingText = useMemo(() => {
        if (isKatanaSiteInProgress) {
            return "Your website isn't quite finished";
        }

        if (isKatanaSitePublished) {
            return 'Your website is live!';
        }

        return 'Need a simple website?';
    }, [isKatanaSiteInProgress, isKatanaSitePublished]);

    const overViewDescriptionText = useMemo(() => {
        if (isKatanaSiteInProgress) {
            return 'Continue building your website with our VIPsite Builder';
        }

        if (isKatanaSitePublished) {
            return 'Continue to manage your website with our VIPsite Builder';
        }

        return 'Build a FREE website fast with our VIPsite Builder';
    }, [isKatanaSiteInProgress, isKatanaSitePublished]);

    const iconSize = useMemo(() => {
        if (isMobile) {
            return 36;
        } else if (isTablet) {
            return 40;
        }

        return 50;
    }, [isMobile, isTablet]);

    const filteredSiteSetupStageOrder = SiteSetupStageOrder.filter((key) => launchChecklistData.find(({ checkListKey }) => checkListKey === key));

    // Render error dialog box if the site is not in progress and the site has been marked for manual review
    const renderManualReviewDialog = useMemo(() => {
        const isOrderManualReview = check_katana_domain_data?.order_manual_review;
        const showDialogError = !isKatanaSiteInProgress && isOrderManualReview;

        if (showDialogError) {
            return (
                <NXBox.DefaultPadding>
                    <DialogNotification type="error">
                        PLEASE NOTE: You have an order that requires manual review. <br /> Please submit an eTicket to our Technical Support team who
                        can assist you with getting this resolved.
                    </DialogNotification>
                </NXBox.DefaultPadding>
            );
        }

        return null;
    }, [isKatanaSiteInProgress, check_katana_domain_data]);

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={isSuccess}>
            <NXBox className="AddKatana" isLoading={isCheckKatanaDomainLoading}>
                <ServiceOverviewBanner slim src={katanaBannerBackground}>
                    <ServiceOverviewBanner.ContentWrapper>
                        <ServiceOverviewBanner.Heading size--custom={26}>{overViewHeadingText}</ServiceOverviewBanner.Heading>
                        <ServiceOverviewBanner.Description>{overViewDescriptionText}</ServiceOverviewBanner.Description>
                    </ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Image src={katanaIcon} alt="vipsites logo" />
                </ServiceOverviewBanner>
                {!isKatanaSiteInProgress && (
                    <NXBox.Top
                        description={
                            isKatanaSitePublished
                                ? 'With an easy-to-use interface and a guided setup process, continuing to work on your website has never been easier. Continue editing by visiting your VIPsites service.'
                                : 'With an easy-to-use interface and a guided setup process, setting up a new website has never been easier. Get started today by claiming your FREE VIPsites website.'
                        }
                    />
                )}
                {renderManualReviewDialog}
                {isKatanaSiteInProgress && (
                    <NXBox.DefaultPadding>
                        <Flex direction="column" gap={5}>
                            <Flex direction="column" gap={2}>
                                <Text size--xl>
                                    <Text semiBold primary>
                                        {domain}
                                    </Text>{' '}
                                    <Text semiBold>website checklist</Text>
                                </Text>

                                <Text size--s>Once these have all been checked off you can launch your website!</Text>
                            </Flex>
                            <Grid
                                className="KatanaLaunchChecklist__checklist"
                                columns={`repeat(${filteredSiteSetupStageOrder.length}, 1fr)`}
                                alignItems--center
                                fullWidth
                            >
                                <CheckListItems
                                    circleSize={iconSize}
                                    serviceID={katanaServiceID || optimisticKatanaServiceID}
                                    showItemButton={false}
                                />
                            </Grid>
                        </Flex>
                    </NXBox.DefaultPadding>
                )}
                <NXBox.Bottom>
                    <NXBox.BottomColumns
                        columns={React.Children.toArray([
                            <NXBox.InfoPair
                                title="Status"
                                description={
                                    <Text size--m medium>
                                        {check_katana_domain_data?.site_exists ? 'Claimed' : 'Unclaimed'}
                                    </Text>
                                }
                            />,
                            renderClaimKatanaButton()
                        ])}
                    />
                </NXBox.Bottom>
            </NXBox>
        </ScrollableComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default AddKatana;
