/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _DevFormTypeChoiceWrapper as DevFormTypeChoiceWrapper } from 'containers/katana/formFields/_DevFormTypeChoiceWrapper/_DevFormTypeChoiceWrapper';
import { KatanaGroupFormFieldCallToAction } from 'containers/katana/formFields/group/inputTypes/call_to_action';
import { KatanaGroupFormFieldGrid } from 'containers/katana/formFields/group/inputTypes/grid';
import { KatanaGroupFormFieldGroup } from 'containers/katana/formFields/group/inputTypes/group';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE } from 'containers/katana/formFields/group/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaGroupFormField: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { input_type, name } = property;

    /***** RENDER *****/
    return (
        <DevFormTypeChoiceWrapper value={input_type} options={Object.values(SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE)}>
            {({ derivedValue }) => {
                switch (derivedValue) {
                    case SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE.GROUP:
                        return <KatanaGroupFormFieldGroup property={property} />;

                    case SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE.GRID:
                        return <KatanaGroupFormFieldGrid property={property} />;

                    case SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE.CALL_TO_ACTION:
                        return <KatanaGroupFormFieldCallToAction property={property} />;

                    default:
                        return <InvalidInputType name={name} inputType={derivedValue} fieldType="Group" />;
                }
            }}
        </DevFormTypeChoiceWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
