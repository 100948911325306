/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import _ from 'lodash';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';

const modifiers = [restrictToVerticalAxis, restrictToParentElement];
const keyboardSensorOption = {
    coordinateGetter: sortableKeyboardCoordinates
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @type {React.FC<NSortableRepeatedWrapper.Props>}
 */
const SortableRepeatedWrapper = function SortableRepeatedWrapper({ fields, children }) {
    /***** STATE *****/
    const [items, setItems] = useState([]);

    /***** HOOKS *****/
    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, keyboardSensorOption));

    /***** FUNCTIONS *****/
    /**
     * @param {import('@dnd-kit/core').DragEndEvent} event
     */
    function handleDragEnd(event) {
        const { active, over } = event;

        if (!over?.id || active.id === over?.id) {
            return;
        }

        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        // setItems(arrayMove(items, oldIndex, newIndex));
        fields.move(oldIndex, newIndex);
    }

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        const allFields = fields.getAll();
        if (!allFields?.length) {
            setItems([]);
            return;
        }
        allFields.forEach((field) => {
            if (!_.has(field, SORTABLE_ID_KEY)) {
                field[SORTABLE_ID_KEY] = uuidv4();
            }
        });
        const fieldItems = allFields.map((field) => field[SORTABLE_ID_KEY]);
        setItems(fieldItems);
    }, [fields]);

    /***** RENDER HELPERS *****/
    const filteredResults = useMemo(() => {
        const mappedFieldsData = fields.map((member, index, fields) => ({ member, index, fields }));
        /**
         * @param {string} id
         * @returns {NSortableRepeatedWrapper.FieldData | undefined}
         */
        function findField(id) {
            return mappedFieldsData.find(({ index, fields }) => fields?.get(index)?.[SORTABLE_ID_KEY] === id);
        }
        return items.filter(findField).map((id) => ({ ...findField(id), [SORTABLE_ID_KEY]: id }));
    }, [items, fields]);

    /***** RENDER *****/
    return (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd} modifiers={modifiers}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {children({ filteredResults })}
            </SortableContext>
        </DndContext>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { SortableRepeatedWrapper };
