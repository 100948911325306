/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { getActiveAddons } from 'containers/hosting/state/baseActions';
import { getHostingInformation } from 'containers/hosting/state/accountActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSelector } from 'react-redux';
import { useDiscountRegeneration } from './hooks';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type BillingInvoiceLightBoxProps = {
    setShowInvoiceLightbox: React.Dispatch<React.SetStateAction<boolean>>;
    showInvoiceLightbox: boolean;
    toggleInvoiceLightbox: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function BillingInvoiceLightBox({ setShowInvoiceLightbox, showInvoiceLightbox, toggleInvoiceLightbox }: BillingInvoiceLightBoxProps) {
    /***** HOOKS *****/
    const dispatch = useDispatch();
    const { id: serviceId } = useParams<{ id: string }>();
    const { invoice } = useDiscountRegeneration();
    const hosting_prepay_data = useSelector((state: any) => state.hosting.hosting_prepay_data);

    /***** FUNCTIONS *****/
    function closePayInvoiceSuccess() {
        setShowInvoiceLightbox(false);
        getHostingInformation(serviceId)(dispatch);
        getActiveAddons(serviceId)(dispatch);
    }

    function getInvoiceId() {
        const { id } = hosting_prepay_data ?? {};

        if (id) return id;

        return invoice?.id;
    }

    /***** RENDER *****/
    return (
        <OverlayLightbox
            title={'Pay Invoice #' + getInvoiceId()}
            invoiceid={getInvoiceId()}
            onOpen={showInvoiceLightbox}
            onClose={toggleInvoiceLightbox}
            onSuccessClose={closePayInvoiceSuccess}
        />
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
