/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXPicturedIframeSourceDocPreview from 'components/PicturedIframeSourceDocPreview';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRefContext } from 'components/Utils/RefProvider/useRefContext';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useStore } from 'utilities/hooks/useStore';
import { useTruthyValue } from 'utilities/hooks/useTruthyValue';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface SitePresetPreviewBrowserBoxProps {
    stateStoreData: PictureInPicture.CreateStateData;
    presetID?: string;
    selectedPresetID: string;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SitePresetPreviewBrowserBox: React.FC<SitePresetPreviewBrowserBoxProps> = ({ stateStoreData, presetID, selectedPresetID }) => {
    /***** HOOKS *****/
    const { siteData } = useSiteDataPreviewValues();
    const [stateStoreValue, setStateStoreValue] = useStore(stateStoreData.store);
    const isOpen = ['maximised', 'visible'].includes(stateStoreValue);
    const { id } = useSetupEditorRouteParams();
    const leftAnchorElement = useRefContext('ContentEditorLightboxMainContent');

    /***** QUERIES *****/
    const {
        data: get_preset_preview_data,
        isFetching: isGetPresetPreviewFetching,
        isError
    } = katanaQuery.serviceID.getPresetPreview.useQuery({ serviceID: id, presetID, site: siteData, enabled: isOpen });
    const displayingPreview = useTruthyValue(get_preset_preview_data);

    /***** EFFECTS *****/
    useEffect(() => {
        if (stateStoreValue === 'visible' && selectedPresetID === 'katana.v1.custom') {
            setStateStoreValue('hidden');
        }
    }, [stateStoreValue, selectedPresetID]);

    /***** RENDER HELPERS *****/
    const siteEditorLoadingData = useMemo(
        () => [{ condition: isGetPresetPreviewFetching, message: 'Fetching template preview...' }],
        [isGetPresetPreviewFetching]
    );

    /***** RENDER *****/
    return (
        <NXPicturedIframeSourceDocPreview
            className="SitePresetPreviewBrowserBox"
            srcDoc={displayingPreview}
            stateStoreData={stateStoreData}
            loaderData={siteEditorLoadingData}
            isError={isError}
            leftAnchorElement={leftAnchorElement}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SitePresetPreviewBrowserBox };
