/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { DisabledEvent } from 'components/Buttons/DisabledEvent';
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getAllPaths } from 'utilities/methods/getAllPaths/getAllPaths';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { BUTTON_TYPES } from 'components/Buttons/_BaseButton';

const HOOK_FORM_BUTTON_STATE = /** @type {const} */ ({
    ACTIVE: 'active',
    INACTIVE: 'inactive'
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @description
 * Button to be used within a React Hook Form as the submit button. This button will automatically disable itself if the form is invalid,
 * pristine, or submitting. If the form is not valid, the button will be disabled and clicking it will trigger the form to be touched,
 * which will display the errors to the user.
 *
 * If the form that this button is associated with has no initial fields, ensure that initial values are provided to the form otherwise
 * the button will not be able to determine if the form exists or not.
 *
 * While in development mode, instead of displaying the default text when the form does not exist, a warning will be displayed to the developer.
 *
 * @type {HFButton.THookFormButton}
 */
export const HookFormButton = ({ children, color, className, loading, force, ignorePristine, onDisabledClick }) => {
    /***** HOOKS *****/
    const { formState, getValues, setValue, trigger } = useFormContext();
    const { isSubmitting, isDirty, isValid } = formState;

    /***** FUNCTIONS *****/

    /**
     * @type {RFButton.TOnInactiveClick}
     */
    const handleDisabledClick = useCallback(
        async (e) => {
            if (e instanceof KeyboardEvent && e.key !== 'Enter') {
                return; // Do not trigger touch if key is not "Enter"
            }

            // First trigger validation to run. Then loop over all form values and set them all to touched, so that all validation errors show. This assumes that all fields were provided with an initial value, which we should be doing anyway
            await trigger();
            const formValues = getValues();
            const allPaths = getAllPaths(formValues);
            allPaths.forEach((path) => {
                const value = _.get(formValues, path);
                setValue(path, value, { shouldTouch: true });
            });

            onDisabledClick?.(e);
        },
        [onDisabledClick]
    );

    /***** RENDER *****/
    const button = {
        [HOOK_FORM_BUTTON_STATE.INACTIVE]: (
            <DisabledEvent active onDisabledClick={handleDisabledClick}>
                <SolidButton disabled key="inactive" className={className}>
                    {children}
                </SolidButton>
            </DisabledEvent>
        ),

        [HOOK_FORM_BUTTON_STATE.ACTIVE]: (
            <SolidButton className={className} color={color} isLoading={loading || isSubmitting} type={BUTTON_TYPES.SUBMIT}>
                {children}
            </SolidButton>
        )
    };

    switch (true) {
        case !!force:
            return button[force];
        case !isDirty && !ignorePristine:
        case !isValid:
            return button[HOOK_FORM_BUTTON_STATE.INACTIVE];
        case isSubmitting:
        case loading:
        default:
            return button[HOOK_FORM_BUTTON_STATE.ACTIVE];
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
