/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { eft_details } from 'config/config';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Radio } from 'components/Form/Radio';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import eftImage from 'assets/images/eft.jpeg';
import { PAYMENT_METHODS, selectedPaymentMethodPropTypes } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let EFTPaymentMethod = ({
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    /**
     * Redux Props
     */
    app_user_data
}) => {
    /***** RENDER HELPERS *****/
    const isEFTSelected = selectedPaymentMethod === PAYMENT_METHODS.EFT;

    /***** RENDER *****/
    return (
        <div key="eft" className={classNames('method', { 'method--selected': isEFTSelected })}>
            <Radio.Circle.Button
                className="method__top"
                isActive={isEFTSelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.EFT)}
            >
                <div className="method__top--title">
                    <div className="label">EFT Transfer</div>
                </div>
                <div className="method__top--img">
                    <img className="cc_icon eft" src={eftImage} alt="eft" />
                </div>
            </Radio.Circle.Button>
            {isEFTSelected ? (
                <div className="method__bottom eft__wrapper">
                    <div className="eft__row">
                        <div className="label">Account Name</div>
                        <div className="value">{eft_details.name}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">BSB</div>
                        <div className="value">{eft_details.bsb}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">Account Number</div>
                        <div className="value">{eft_details.account}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">Description</div>
                        <div className="value desc">{app_user_data.account_number}</div>
                    </div>
                    <div className="eft__note note">
                        Please note we recommend allowing 1-2 business days for payments to process (this may be further delayed by weekends and
                        public holidays).
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

EFTPaymentMethod.propTypes = {
    ...selectedPaymentMethodPropTypes
};

const mapStateToProps = (state) => {
    return {
        app_user_data: state.app.app_user_data
    };
};

EFTPaymentMethod = connect(mapStateToProps)(EFTPaymentMethod);

export default EFTPaymentMethod;
