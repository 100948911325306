/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import NXBox from 'components/NXBox';
import Border from 'components/Utils/Border';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _WebsiteAppearanceContent as WebsiteAppearanceContent } from 'containers/katana/modules/informationAndAppearance/_WebsiteAppearanceContent';
import { _WebsiteInformationContent as WebsiteInformationContent } from 'containers/katana/modules/informationAndAppearance/_WebsiteInformationContent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_KatanaInformationAndAppearanceModule.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const InformationAndAppearanceModule = () => {
    /***** HOOKS *****/
    const isTablet = useAppViewport('md');

    /***** RENDER *****/
    return (
        <NXBox>
            <Grid columns={isTablet ? '2fr 3fr' : '1fr 1fr'} alignItems--stretch justify-children--stretch>
                <div>
                    <NXBox.DefaultPadding>
                        <NXBox.Title title="Website Information" />
                    </NXBox.DefaultPadding>
                    <WebsiteInformationContent />
                </div>

                <Border left>
                    <NXBox.DefaultPadding>
                        <NXBox.Title title="Website Appearance" />
                    </NXBox.DefaultPadding>
                    <NXBox.DefaultPadding>
                        <WebsiteAppearanceContent />
                    </NXBox.DefaultPadding>
                </Border>
            </Grid>
        </NXBox>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
