/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _DevFormTypeChoiceWrapper as DevFormTypeChoiceWrapper } from 'containers/katana/formFields/_DevFormTypeChoiceWrapper/_DevFormTypeChoiceWrapper';
import { KatanaChoiceFormFieldSelect } from 'containers/katana/formFields/choice/inputTypes/select';
import { KatanaChoiceFormFieldSquareGrid } from 'containers/katana/formFields/choice/inputTypes/square_grid';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SITE_SECTION_CHOICE_PROPERTY_INPUT_TYPE } from 'containers/katana/formFields/choice/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaChoiceFormField: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { input_type, name } = property;

    /***** RENDER *****/
    return (
        <DevFormTypeChoiceWrapper value={input_type} options={Object.values(SITE_SECTION_CHOICE_PROPERTY_INPUT_TYPE)}>
            {({ derivedValue }) => {
                switch (derivedValue) {
                    case SITE_SECTION_CHOICE_PROPERTY_INPUT_TYPE.SELECT:
                        return <KatanaChoiceFormFieldSelect property={property} />;

                    case SITE_SECTION_CHOICE_PROPERTY_INPUT_TYPE.SQUARE_GRID:
                        return <KatanaChoiceFormFieldSquareGrid property={property} />;

                    default:
                        return <InvalidInputType name={name} inputType={derivedValue} fieldType="Choice" />;
                }
            }}
        </DevFormTypeChoiceWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
