/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { plaintTextToMarkdown } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/plainTextToMarkdown';
import { getRichTextCaveat } from 'containers/katana/formFields/methods/getRichTextCaveat/getRichTextCaveat';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RichTextData } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import type { KatanaNamespace } from 'containers/katana/types';

// Better Types in lod-13, Katana -> KatanaNamespace
export function processRichTextProperty<TValue>(
    value: TValue,
    propertyDefinition: KatanaNamespace.SectionDefinitions.Properties
): RichTextData | TValue {
    const richTextValue = getRichTextCaveat(propertyDefinition.caveats);

    if (richTextValue?.includes('markdown') && _.isString(value)) {
        return {
            type: 'markdown',
            content: plaintTextToMarkdown(value)
        };
    }

    return value;
}
