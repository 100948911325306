/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Field } from 'redux-form';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { NXForm } from 'components/Form/NXForm';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import InvoiceViewTable from './tables/invoiceViewTable';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useDownloadInvoiceMutation, useEmailInvoiceMutation, useExtendInvoiceMutation } from 'containers/billing/queries/invoice';
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { toLuxonDate } from 'utilities/methods/commonActions';
import { requiredAcceptedValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { BUTTON_COLORS } from 'components/Buttons/_BaseButton';
import { INVOICE_STATUSES } from 'utilities/consts';
import './_invoiceView.scss';

const invoiceDefaultData = { attributes: {}, isInvoiceListItem: false, isDataUpdating: false };

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const InvoiceView = () => {
    /***** STATE *****/
    const { value: isExtendLightboxShowing, setFalse: closeExtendLightbox, setTrue: openExtendLightbox } = useBoolean(false);

    /***** HOOKS *****/
    const app_viewport = useAppViewport();
    const params = useParams();
    const { current: invoiceID } = useRef(params.id);
    const scrollRef = useRef();

    /***** QUERIES *****/
    const {
        data: get_invoice_data = invoiceDefaultData,
        isLoading: isGetInvoiceLoading,
        isFetching: isGetInvoiceFetching
    } = useGetInvoiceQuery(invoiceID);

    const { mutate: mutateExtendInvoice, status: post_invoice_single_extend_status } = useExtendInvoiceMutation({ onSettled: closeExtendLightbox });
    const { mutate: mutateDownloadInvoice, isPending: isDownloadInvoicePending } = useDownloadInvoiceMutation();
    const { mutate: mutateEmailInvoice, isPending: isEmailInvoicePending } = useEmailInvoiceMutation();

    /***** FUNCTIONS *****/
    const handleEmailInvoice = () => {
        mutateEmailInvoice(invoiceID);
    };

    const handleDownloadInvoice = () => {
        mutateDownloadInvoice(invoiceID);
    };

    const handleExtendInvoice = () => {
        mutateExtendInvoice(invoiceID);
    };

    /***** RENDER HELPERS *****/
    const handleStatusRender = () => {
        if (isGetInvoiceLoading || get_invoice_data?.isDataUpdating) return '';
        const { status, date_paid } = get_invoice_data.attributes;
        const isPaid = status === INVOICE_STATUSES.PAID;
        return (
            <div className={`invoiceView__tag ${status?.toLowerCase?.() ?? ''}`}>
                <Flex>
                    {isPaid ? <PhosphorIcons.LucideCheckCircle /> : ''}
                    <span className="status">{status}</span>
                </Flex>
                {isPaid && date_paid ? <span className="date">Date Paid: {toLuxonDate(date_paid).toFormat('d MMM yyyy')}</span> : ''}
            </div>
        );
    };

    const handleTotalRender = () => {
        const { credit: creditValue, subtotal, total_discount: totalDiscountValue, total, status, amount_due } = get_invoice_data.attributes;
        const credit = Number(creditValue);
        const total_discount = Number(totalDiscountValue);

        if (!get_invoice_data || get_invoice_data?.isDataUpdating) return <RequestLoader message="Loading totals..." />;

        return (
            <div className="invoiceView__total">
                {subtotal ? (
                    <div className="total__row">
                        <div className="total__title">Sub Total</div>
                        <div className="total__value">${subtotal} AUD</div>
                    </div>
                ) : (
                    ''
                )}
                {credit ? (
                    <div className="total__row">
                        <div className="total__title">Credited Amount</div>
                        <div className="total__value">-${credit} AUD</div>
                    </div>
                ) : (
                    ''
                )}
                {total_discount > 0 ? (
                    <div className="total__row">
                        <div className="total__title">Discount</div>
                        <div className="total__value">-${total_discount} AUD</div>
                    </div>
                ) : (
                    ''
                )}
                {total ? (
                    <div className="total__row">
                        <div className="total__title">{status === INVOICE_STATUSES.PAID ? 'Total Amount Paid' : 'Total Amount Due'}</div>
                        <div className="total__value highlight">${status === INVOICE_STATUSES.PAID ? total : amount_due} AUD</div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const handleActionFooter = () => {
        const { extension_due_date, status, invoice_items, can_split, can_cancel } = get_invoice_data.attributes;

        const renderButtons = () => (
            <>
                {isEmailInvoicePending ? (
                    <InactiveButton>
                        <RequestLoader />
                    </InactiveButton>
                ) : (
                    <OutlineButton onClick={handleEmailInvoice} type="onClick" color="primary">
                        Email Invoice
                    </OutlineButton>
                )}

                {isDownloadInvoicePending ? (
                    <InactiveButton>
                        <RequestLoader />
                    </InactiveButton>
                ) : (
                    <OutlineButton onClick={handleDownloadInvoice} type="onClick" color="primary">
                        Download Invoice
                    </OutlineButton>
                )}
                {invoice_items?.length > 1 && can_split ? (
                    <OutlineButton to={`/billing/split-invoice/${invoiceID}`} color="primary">
                        Split Invoice
                    </OutlineButton>
                ) : (
                    ''
                )}
            </>
        );

        function renderDefaultStatusContent() {
            return (
                <>
                    {extension_due_date && (
                        <OutlineButton className="action__extend" type="onClick" color="primary" onClick={openExtendLightbox}>
                            Extend Invoice
                        </OutlineButton>
                    )}
                    {renderButtons()}
                    <SolidButton color="primary" to={`/billing/invoice/${invoiceID}/pay`}>
                        Pay Invoice
                    </SolidButton>
                    {!!can_cancel && (
                        <OutlineButton color={BUTTON_COLORS.NOTICE} to={`/billing/invoices/${invoiceID}/cancel`}>
                            Cancel Invoice
                        </OutlineButton>
                    )}
                </>
            );
        }

        const isMobile = ['xs', 'sm'].includes(app_viewport);

        function renderInvoiceActionsContent() {
            switch (status) {
                case INVOICE_STATUSES.PAID:
                case INVOICE_STATUSES.CANCELLED:
                case INVOICE_STATUSES.REFUNDED:
                    return renderButtons();

                default:
                    return isGetInvoiceFetching ? <RequestLoader fillHeight={44} /> : renderDefaultStatusContent();
            }
        }

        return (
            <Grid columns={isMobile ? '100%' : 'repeat(auto-fill, 150px)'} className="invoiceView__action">
                {renderInvoiceActionsContent()}
            </Grid>
        );
    };

    function getAvailableMessageOrLoader() {
        const { isDataUpdating, isInvoiceListItem } = get_invoice_data;
        if (isGetInvoiceLoading || isDataUpdating || isInvoiceListItem) return <RequestLoader fillHeight={16} height={16} />;
        return 'Not Available';
    }

    function renderInvoiceViewInfo() {
        const { attributes } = get_invoice_data;
        const { date, status, total } = attributes;
        return (
            <div className="invoiceView__info">
                <div className="info__section">
                    <div className="info__title">Date Issued</div>
                    <div className="info__detail">{date ? toLuxonDate(date).toFormat('d MMM yyyy') : getAvailableMessageOrLoader()}</div>
                </div>
                <div className="info__section">
                    <div className="info__title">Due Date</div>
                    <div className="info__detail">{due_date ? toLuxonDate(due_date).toFormat('d MMM yyyy') : getAvailableMessageOrLoader()}</div>
                </div>
                <div className="info__section">
                    {get_invoice_data?.isDataUpdating ? (
                        <RequestLoader />
                    ) : (
                        <>
                            <div className="info__title">{status === INVOICE_STATUSES.PAID ? 'Amount Paid' : 'Amount Due'}</div>
                            <div className="info__detail">{total ? `$${total} AUD` : getAvailableMessageOrLoader()}</div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    const { due_date, extension_due_date } = get_invoice_data.attributes;
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div ref={scrollRef} className="invoiceView">
            <Anchor className="invoiceView__link" to="/billing/invoices">
                Back to Invoice list
            </Anchor>
            <NXBox className="invoiceView__box">
                <NXBox.Top title={`Invoice #${invoiceID}`} />
                {handleStatusRender()}
                {renderInvoiceViewInfo()}
                <div className="invoiceView__table--header">Invoice Breakdown</div>
                <InvoiceViewTable invoiceID={invoiceID} />
                {handleTotalRender()}
                {handleActionFooter()}
            </NXBox>

            {isExtendLightboxShowing ? (
                <OverlayLightbox title="Extend Invoice" onOpen onClose={closeExtendLightbox} loading={post_invoice_single_extend_status}>
                    <div className="invoiceView__extend">
                        <div className="extendLightbox__container">
                            <div className="extendLightbox__date">
                                <div className="title">Current Due Date</div>
                                <div className="date">{toLuxonDate(due_date).toFormat('d MMM yyyy')}</div>
                            </div>
                            <PhosphorIcons.Caret.Right />
                            <div className="extendLightbox__date">
                                <div className="title highlight">NEW Due Date</div>
                                <div className="date highlight">{toLuxonDate(extension_due_date, 'yyyy-MM-dd').toFormat('d MMM yyyy')}</div>
                            </div>
                        </div>

                        <NXForm
                            destroyOnUnmount
                            onSubmit={handleExtendInvoice}
                            initialValues={{ extendInvoice: false, manualRenewal: false }}
                            form="AcknowledgeRenewInvoiceForm"
                        >
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <AcknowledgementBox.Compact title="CONFIRMATION" className="extendLightbox__acknowledgment">
                                        <Field
                                            name="extendInvoice"
                                            type="checkbox"
                                            component={CheckBoxList.Item.ReduxForm}
                                            validate={[requiredAcceptedValidation]}
                                        >
                                            I acknowledge that extending this invoice will only extend the invoice due date and will not extend the
                                            service’s expiration date.
                                        </Field>
                                        <Field
                                            name="manualRenewal"
                                            type="checkbox"
                                            component={CheckBoxList.Item.ReduxForm}
                                            validate={[requiredAcceptedValidation]}
                                        >
                                            I acknowledge that the service will still need to be renewed before its expiration date.
                                        </Field>
                                    </AcknowledgementBox.Compact>

                                    <ReduxFormButton className="extendLightbox__formButton">Extend Invoice</ReduxFormButton>
                                </form>
                            )}
                        </NXForm>
                        <OverlayLightbox.BackLink text="No, Go Back" onClick={closeExtendLightbox} />
                    </div>
                </OverlayLightbox>
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default InvoiceView;
