/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { get } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import Grid from 'components/Grid';
import { Modal } from 'components/Lightboxes/Modal';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ContentEditorActionButtons } from 'containers/katana/components/contentEditorActionButtons';
import ContentEditorContentRoutes from './contentEditorRoutes';
import ContentEditorSidebar from './contentEditorSidebar';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRefContext } from 'components/Utils/RefProvider/useRefContext';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SUPER_ACTIONS_KEYS, superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import { katanaPages, katanaServicePages } from 'containers/katana/consts';
import { ContentEditorPicturePreview } from 'containers/katana/containers/ContentEditorLightbox/ContentEditorPicturePreview';
import BurnedToastDomainNotRegistered from 'containers/katana/containers/ContentEditorLightbox/burnedToastDomainNotRegistered';
import { katanaContentEditorLightboxHasChanges, katanaContentEditorRoutes } from 'containers/katana/containers/ContentEditorLightbox/consts';
import { unsavedChangesWritable } from 'containers/katana/containers/ContentEditorLightbox/unsavedChangesModal/consts';
import { useBooleanStore } from 'utilities/hooks/useBooleanStore';
import './_katanaContentEditorLightbox.scss';

const { KATANA_IMAX } = SUPER_ACTIONS_KEYS;
const { ADD_SECTION } = katanaPages;
const { OVERVIEW, SETUP, EDITOR } = katanaServicePages;

const contentEditorPages = [EDITOR.to, SETUP.to, ADD_SECTION];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaContentEditorLightbox = () => {
    /***** STATE *****/
    const { setTrue: showUnsavedChangesLightbox } = useBooleanStore(unsavedChangesWritable);
    const { value: hasChanges } = useBooleanStore(katanaContentEditorLightboxHasChanges);

    /***** HOOKS *****/
    const mainContentRef = useRef<HTMLDivElement>(null);
    const modalOverlayRef = useRef<HTMLDivElement>(null);
    const [superUserActions] = useStore(superUserSuperActionsState);

    const { getKatanaDestination } = useKatanaURL();
    const isDesktop = useAppViewport('lg');
    const { addRef } = useRefContext();
    const isMobile = useAppViewport(['xs', 'sm']);
    const isMax = useAppViewport(['sm', 'xs', 'md', 'lg'], true);
    const history = useHistory();
    const { page, childpage, subpage } = useSetupEditorRouteParams();
    const isOpen = useMemo(() => contentEditorPages.some((editorPage) => editorPage === page), [page]);

    /***** RENDER HELPERS *****/
    const isSetupPage = page === SETUP.to;
    /***** FUNCTIONS *****/

    function closeContentEditorModal() {
        history.push(getKatanaDestination(OVERVIEW));
    }
    function closeModal() {
        if (hasChanges) {
            showUnsavedChangesLightbox();
            return true;
        }
        closeContentEditorModal();
    }

    function getGridColumns() {
        if (isDesktop || isMax) {
            return '1fr minmax(100px, 3fr) 1fr';
        }

        if (isSetupPage) {
            if (isMobile) {
                return 'auto minmax(100px, 1fr)';
            }
            return '175px minmax(100px, 1fr)';
        }

        return 'minmax(100px, 1fr)';
    }

    function getSpanStart() {
        if (isDesktop || isMax || isSetupPage) {
            return 2;
        }

        return 1;
    }

    /***** EFFECTS *****/

    useEffect(() => {
        if (isOpen && modalOverlayRef.current) {
            modalOverlayRef.current.scrollTo({ top: 0 });
        }
    }, [page, subpage, childpage]);

    useEffect(() => {
        return addRef('ContentEditorLightboxMainContent', mainContentRef);
    }, [addRef, mainContentRef?.current]);

    useEffect(() => {
        return addRef('modalOverlayRef', modalOverlayRef);
    }, [addRef, modalOverlayRef?.current]);

    /***** RENDER *****/
    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            backgroundChildren={<BackgroundImage imageURL={katanaContentEditorRoutes?.[subpage]?.backgroundImage ?? ''} contain transition />}
            overlayRef={modalOverlayRef}
            ariaLabel="KatanaContentEditorLightbox"
            className={classNames('KatanaContentEditorLightbox__modal', {
                KatanaContentEditorLightbox__modal__KATANA_IMAX: get(superUserActions, KATANA_IMAX)
            })}
            preButtonContent={<ContentEditorActionButtons />}
        >
            <div className="KatanaContentEditorLightbox">
                <Grid columns={getGridColumns()} gap={isMobile ? 4 : 8} alignItems--start justify-children--stretch>
                    {isSetupPage && <ContentEditorSidebar />}
                    <Grid.Span start={getSpanStart()}>
                        <div className="KatanaContentEditorLightbox__mainContent" ref={mainContentRef}>
                            <ContentEditorContentRoutes />
                        </div>
                    </Grid.Span>
                </Grid>
            </div>

            <ContentEditorPicturePreview isOpen={isOpen} leftAnchorElement={mainContentRef?.current} />
            {!isMobile && <BurnedToastDomainNotRegistered />}
        </Modal>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default KatanaContentEditorLightbox;
