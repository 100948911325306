/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

export function modifyPropertyKeys(
    member: string,
    properties: KatanaNamespace.SectionDefinitions.PropertiesModified[],
    isFieldArrayMember?: boolean
) {
    if (!properties) {
        return [];
    }
    const clonedProperties = _.cloneDeep(properties);
    return clonedProperties.map((clonedProperty) => {
        const key = isFieldArrayMember ? clonedProperty.originalKey ?? clonedProperty.key : clonedProperty.key;
        const newMember = clonedProperty.key ? `${member}.${key}` : null;

        if (clonedProperty.properties) {
            clonedProperty.properties = modifyPropertyKeys(newMember, clonedProperty.properties, isFieldArrayMember);
        }

        return {
            ...clonedProperty,
            key: newMember,
            originalKey: clonedProperty?.originalKey ?? clonedProperty.key
        };
    });
}
