/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import SolidButton from 'components/Buttons/SolidButton';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePutDomainDNSTypeMutation } from 'containers/domain/queries/usePutDomainDNSTypeMutation';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DNSHostingChangeKatanaLaunch = () => {
    /***** STATE *****/
    const [acknowledgeDNSHostingChange, setAcknowledgeDNSHostingChange] = useState(false);
    const [loading, setLoading] = useState(false);

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: check_enable_site_data, isFetching: isCheckKatanaServiceDetailsFetching } = katanaQuery.serviceID.getServiceDetails.useQuery(id);
    const { mutate: mutatePutDomainDNSType } = usePutDomainDNSTypeMutation({
        id: check_enable_site_data?.domain_id,
        attributes: { dns_type: 'free_dns' }
    });

    /***** FUNCTIONS *****/
    function mutateChangeDNSHosting() {
        setLoading(true);
        mutatePutDomainDNSType();
    }

    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isCheckKatanaServiceDetailsFetching, message: 'Checking Service Details...' }
    ]);

    /***** RENDER *****/
    return (
        <RequestLoader.MultiLoader loaders={multiLoaderData}>
            <Text secondary size--m align--center>
                It looks like the DNS Mode for your domain <Text bold>{check_enable_site_data?.hostname}</Text>&nbsp;needs to be changed before we can
                make your site live. Your DNS mode will be changed to DNS hosting.
            </Text>
            <AcknowledgementBox.Compact title="Confirmation">
                <CheckBoxList.Item isChecked={acknowledgeDNSHostingChange} onChange={setAcknowledgeDNSHostingChange}>
                    <Text secondary size--s bold>
                        I understand that the DNS mode for my domain will be changed to DNS Hosting
                    </Text>
                </CheckBoxList.Item>
            </AcknowledgementBox.Compact>
            <SolidButton type="onClick" onClick={mutateChangeDNSHosting} disabled={!acknowledgeDNSHostingChange} isLoading={loading}>
                Change DNS Hosting
            </SolidButton>
        </RequestLoader.MultiLoader>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
