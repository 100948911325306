/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSections } from 'containers/katana/queries/serviceID/getSections';
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
import { createDataObjectCreatorMethod } from 'utilities/methods/tanstack/createDataObjectCreatorMethod';
import { createOptimisticResponseObject } from 'utilities/methods/tanstack/createOptimisticResponseObject';
import { createOptimisticUpdateMethod } from 'utilities/methods/tanstack/createOptimisticUpdateMethod';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SECOND } from 'utilities/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TParams = {
    serviceID: number;
    sectionID: number | null;
};
type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.section.section_id.GET>>;

function createQueryKey({ serviceID, sectionID }: TParams) {
    return createBaseQueryKey(['katana', 'service', serviceID, 'section', sectionID]);
}

function createQueryOptions({ serviceID, sectionID }: TParams) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, sectionID }),
        queryFn: () => KATANA_API.katana.site.service_id.section.section_id.GET({ serviceID, sectionID }),
        staleTime: SECOND * 25,
        enabled: Boolean(serviceID && sectionID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
        initialData: () => {
            const queryResult = queryClient.getQueryData(getSections.createQueryKey(serviceID)) as getSections.TData;
            if (queryResult?.status === 200) {
                const foundData = queryResult.data.find((section) => section.id === sectionID);
                if (foundData) {
                    return createOptimisticResponseObject({ data: foundData });
                }
            }
        }
    });
}

const setQueryData = createSetQueryDataMethod<TParams, TData>(createQueryKey);

const removeQueries = (params: TParams) => {
    queryClient.removeQueries({ queryKey: createQueryKey(params), exact: true });
};

const createDataObject = createDataObjectCreatorMethod<TData>();

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the sections on the site
 */
function _useQuery(params: TParams) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSingleSection = Object.freeze({
    useQuery: _useQuery,
    setQueryData,
    createDataObject,
    removeQueries,
    optimisticUpdate: createOptimisticUpdateMethod<TParams, TData>(setQueryData)
});
