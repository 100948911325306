/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { filterEndpointAttributeKeys } from 'containers/katana/queries/methods/filterEndpointAttributeKeys';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 * @param {import('@tanstack/react-query').QueryOptions} options
 */
function usePostNavigationMutation(serviceID, options = {}) {
    /***** HOOKS *****/
    const { page } = useSetupEditorRouteParams();

    /***** FUNCTIONS *****/
    /**
     * @type {(values: import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Navigation.POST.Attributes) => Promise<unknown>}
     */
    function mutationFn(values) {
        const attributes = filterEndpointAttributeKeys(values, usePostNavigationMutation.acceptableKeys);
        return KATANA_API.katana.service_id.navigation.POST({ serviceID, attributes });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        mutationKey: usePostNavigationMutation.mutationKey,
        onSettled: () => {
            katanaQuery.serviceID.getNavigation.resetQueries(serviceID);
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
        },
        onSuccess: page === 'setup' ? null : handleDefaultSuccessNotification,
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

usePostNavigationMutation.FORM_FIELD_KEYS = /** @type {const} */ ({
    CALL_TO_ACTION: 'call_to_action',
    COLOR: 'style.color',
    TRANSPARENT: 'style.transparent',
    VISIBLE: 'style.visible',
    SHOW_SOCIALS_IN_HEADER: 'show_socials_in_header',
    SHOW_SOCIALS_IN_FOOTER: 'show_socials_in_footer'
});
usePostNavigationMutation.mutationKey = /** @type {const} */ (['usePostNavigationMutation']);
usePostNavigationMutation.acceptableKeys = Object.values(usePostNavigationMutation.FORM_FIELD_KEYS);

export { usePostNavigationMutation };
