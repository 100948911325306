/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import type { WrappedFieldProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _Input as Input } from 'components/Form/Input/_Input';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXInputNamespace } from 'components/Form/Input/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * NXInput ReduxForm Wrapper
 */
export const _InputReduxFormDefault: React.FC<WrappedFieldProps & NXInputNamespace.ReduxForm.Props> = (props) => {
    const { input, meta, label, disabled, placeholder, isPlaceholderItalic, prepend, validated, readOnly, intrinsicProps, inputRef } = props;
    const { name, value, onChange, onBlur } = input;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta} prepend={prepend} validated={validated} noBackground disabled={disabled}>
                <Input
                    disabled={disabled}
                    value={value}
                    name={name}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    inputRef={inputRef}
                    intrinsicProps={{ ...intrinsicProps, onChange, onBlur }}
                />
            </FormItemInner>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
