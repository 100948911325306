/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Code } from 'components/Code';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function InvalidInputType({ name, inputType, fieldType }) {
    /***** RENDER *****/
    return (
        <Text italic>
            {name}: {fieldType} input type <Code>{inputType}</Code> {`doesn't exist`}
        </Text>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { InvalidInputType };
