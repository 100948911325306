/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useUrlDropdown } from 'components/NXBox/Dropdown/hooks/useUrlDropdown';
import { useDiscountRegeneration } from './hooks';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RenewSectionProps = {
    toggleInvoiceLightbox: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RenewSection: React.FC<RenewSectionProps> = ({ toggleInvoiceLightbox }) => {
    /***** HOOKS *****/
    const { id: serviceId } = useParams<{ id: string }>();
    const { canRegenerateWithDiscount, invoice } = useDiscountRegeneration();
    const { toggleDropdown } = useUrlDropdown(`/my-services/hosting/account/renew/${serviceId}`, {
        type: 'renew',
        open: true
    });

    /***** RENDER HELPERS *****/
    const renderPrepayButton = () => {
        if (canRegenerateWithDiscount || !invoice?.id) {
            return (
                <OutlineButton
                    className="hostingBilling__button"
                    size="large"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleDropdown();
                    }}
                >
                    {!invoice?.id ? 'Renew' : 'Apply Discount'}
                </OutlineButton>
            );
        }

        return (
            <InactiveButton className="hostingBilling__button" type="onClick" size="large">
                <i className="icon icon-lock"></i>Renew
            </InactiveButton>
        );
    };

    /***** RENDER *****/
    return (
        <div className="hostingBilling__box--container">
            {!!invoice?.id && (
                <DialogNotification type="warning" className="changeResources__payInvoiceNotification">
                    <DialogNotification.ButtonsContainer
                        text=" You have an outstanding invoice for this service. Please, contact our Billing Team for further assistance."
                        buttons={[
                            <SolidButton
                                key={1}
                                type="onClick"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleInvoiceLightbox();
                                }}
                                size="medium"
                                color="white"
                            >
                                Pay Invoice
                            </SolidButton>
                        ]}
                    />
                </DialogNotification>
            )}
            <div className="hostingBilling__box--title">Renew</div>
            <div className="hostingBilling__box--section">
                <div className="hostingBilling__box--desc">
                    {canRegenerateWithDiscount
                        ? 'Looks like you are eligible for a discount on your renewal invoice. Click "Apply Discount" to cancel your current invoice and generate a new one with the discount applied.'
                        : 'Keep a good thing going by renewing for your service today.'}
                </div>
                {renderPrepayButton()}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
