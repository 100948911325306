/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getAllTruthyPaths } from 'utilities/methods/getAllTruthyPaths/getAllTruthyPaths';
import { isObjectOrArrayEmpty } from 'utilities/methods/isObjectOrArrayEmpty';
import { deepFilter } from 'utilities/methods/recursion/deepFilter/deepFilter';
import { deepOmit } from 'utilities/methods/recursion/deepOmit/deepOmit';
import { regexes } from 'utilities/methods/regexes';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { recursiveWalkDefinitionToOmitPathsByValidationRules } from 'containers/katana/containers/ContentEditorLightbox/hooks/useRecursivePathWalkOmitErroredKeys';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 *
 * @param {Record<string, any>} activeFormValues
 * @param {Record<string, any>} activeFormSyncErrors
 * @param {import('containers/katana/types').KatanaNamespace.SectionDefinitions.ValuesModifiedPropertiesAndValidations} sectionDefinition
 */
export function useRecursiveActiveValidFormValues(activeFormValues, activeFormSyncErrors, sectionDefinition) {
    return useMemo(() => {
        const cleanedFormValues = deepOmit(activeFormValues, [SORTABLE_ID_KEY]);
        const clonedActiveFormValues = _.cloneDeep(cleanedFormValues);
        const erroredKeys = getAllTruthyPaths(activeFormSyncErrors).map((path) =>
            path.endsWith('._error') ? _.dropRight(path.split('.')).join('.') : path
        );
        const filteredErroredKeys = recursiveWalkDefinitionToOmitPathsByValidationRules(erroredKeys, sectionDefinition);
        /**
         *  We need to cut back child properties so that if the errored key is something like "properties.work_project[0].description" it gets turned into "properties.work_project[0]"
         */
        const modifiedErroredKeys = _.uniq(
            filteredErroredKeys.map((key) => {
                const match = regexes.lastOccurrenceOfArrayIndexInString().exec(key);
                if (match && !key.endsWith(match[0])) {
                    return key.slice(0, match.index + match[0].length);
                }
                return key;
            })
        );

        const omittedValues = _.omit(clonedActiveFormValues, modifiedErroredKeys);
        const deeplyFilteredValues = deepFilter(omittedValues, [_.isNil, isObjectOrArrayEmpty]);

        return deeplyFilteredValues;
    }, [activeFormValues, activeFormSyncErrors, sectionDefinition]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
