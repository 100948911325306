/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';
import { MINUTE } from 'utilities/consts';

/**
 * @param {number} serviceID
 */
function createQueryKey(serviceID) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'service', serviceID, 'navigation', 'items']));
}

/**
 * @param {number} serviceID
 */
function createQueryOptions(serviceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.navigation.items.GET(serviceID),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**
 * @param {number} serviceID
 */
function prefetchQuery(serviceID) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

/**
 * @template {Awaited<ReturnType<typeof KATANA_API.katana.service_id.navigation.items.GET>>} TData
 * @param {number} serviceID
 * @param {(oldData: TData) => TData | undefined} updater
 */
function setQueryData(serviceID, updater) {
    return queryClient.setQueryData(createQueryKey(serviceID), updater);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the navigation items for the site
 * @param {number} serviceID
 */
function _useQuery(serviceID) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *  HOOK END
 * **********************************************************************************************************/

export const getNavigationItems = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    setQueryData,
    createQueryKey,
    createQueryOptions
});
