/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZone as NXDropZone } from 'components/Form/NXDropZone/_NXDropZone';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/useKatanaFileUpload';
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';
import type { UseKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * NXDropZone HookForm Wrapper
 */
export const _NXDropZoneHookFormWithKatanaFileUpload: React.FC<NXDropZoneNamespace.HookForm.WithKatanaFileUpload.Props> = (props) => {
    const { serviceID, label, name, disabled, className, ...rest } = props;

    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value } = field;

    /***** FUNCTIONS *****/
    function _onChange(files: UseKatanaFileUpload.Values) {
        field.onChange(files);
        field.onBlur();
    }

    /***** QUERIES *****/
    const { isUploading, onChangeProxy } = useKatanaFileUpload(serviceID, _onChange);

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={className}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner.HookForm name={name} noBackground fullWidth>
                <IsDataUpdatingOverlay.StableDomStructure noBorder isDataUpdating={isUploading} message="Uploading File...">
                    <NXDropZone onChange={onChangeProxy} value={typeof value === 'string' ? null : value} {...rest} />
                </IsDataUpdatingOverlay.StableDomStructure>
            </FormItemInner.HookForm>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
