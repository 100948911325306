/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MINUTE } from 'utilities/consts';
import { createOptimisticUpdateMethod } from 'utilities/methods/tanstack/createOptimisticUpdateMethod';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.business.GET>>;

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(['katana', 'service', serviceID, 'business'] as const);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.business.GET(serviceID),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

export function prefetchQuery(serviceID: number) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

export function invalidateQueries(serviceID: number) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

const setQueryData = createSetQueryDataMethod<number, TData>(createQueryKey);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the business info of the site
 */
export function _useQuery(serviceID: number) {
    return useQuery(createQueryOptions(serviceID));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getBusinessInfo = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    optimistic: createOptimisticUpdateMethod(setQueryData),
    invalidateQueries
});
