/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { activePromotion, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type State = {
    hosting: {
        hosting_information_data: Artah.WebHosting.ID.GET._200;
        hosting_prepay_info_data: Artah.Service.ID.Renew.GET._200;
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useDiscountRegeneration() {
    /***** SELECTORS *****/
    const hosting_information_data = useSelector((state: State) => state.hosting.hosting_information_data);
    const hosting_prepay_info_data = useSelector((state: State) => state.hosting.hosting_prepay_info_data);

    /***** FUNCTIONS *****/
    const invoice = getIncludedObjBasedOnType(hosting_information_data?.included, 'invoice');
    const regenerateWithDiscountOptions = Object.entries(hosting_prepay_info_data?.prices || {})
        .filter(([, { discount_available, allow_regeneration }]) => discount_available && allow_regeneration)
        .map(([key]) => key);

    /**
     * Whenever there is a promotion that requires invoice regeneration insert the promotion's config key into this so that it verifies it during the promotion period
     * i.e.: `activePromotion('mega_may_2024') && invoiceId && !!this.options.length`
     */
    const canRegenerateWithDiscount = activePromotion('megamay2024') && invoice?.id && !!regenerateWithDiscountOptions.length;

    /***** HOOK RESULTS *****/
    return { canRegenerateWithDiscount, invoice, regenerateWithDiscountOptions };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useBillingModuleSelectorStatus() {
    /***** SELECTORS *****/
    const hosting_billing_status = useSelector((state: any) => state.hosting.hosting_billing_status);
    const hosting_billingcycle_status = useSelector((state: any) => state.hosting.hosting_billingcycle_status);
    const hosting_prepay_status = useSelector((state: any) => state.hosting.hosting_prepay_status);
    const hosting_prepay_info_status = useSelector((state: any) => state.hosting.hosting_prepay_info_status);

    /***** FUNCTIONS *****/
    const getStatus = () => {
        switch (true) {
            case hosting_billingcycle_status === 'loading':
            case hosting_billing_status === 'loading':
            case hosting_prepay_status === 'loading':
            case hosting_prepay_info_status === 'loading':
                return 'loading';

            case hosting_billingcycle_status === 'error':
            case hosting_billing_status === 'error':
            case hosting_prepay_status === 'error':
            case hosting_prepay_info_status === 'error':
                return 'error';

            default:
                return 'success';
        }
    };

    /***** HOOK RESULTS *****/
    return getStatus();
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
