import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'core-js/features/promise/all-settled';
import 'core-js/features/string/replace-all';
import 'core-js/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import queryClient from 'store/queryClient';
import store from './store/store';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CustomErrorBoundary from 'components/Errors/ErrorBoundary';
import App from './App';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { interceptorHandler } from 'utilities/methods/interceptorHandler';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { history } from 'App/consts';
import * as Config from 'config/config';

try {
    if (import.meta.env.MODE === 'production') {
        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: import.meta.env.MODE,
            release: import.meta.env.VITE_GIT_SHA,
            integrations(integrations) {
                integrations.push(
                    Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: true,
                        unmask: ['.sentry-un-mask', '[data-sentry-un-mask]']
                    }),
                    Sentry.browserTracingIntegration(),
                    Sentry.reactRouterV5BrowserTracingIntegration({ history })
                );

                return integrations;
            },
            tracePropagationTargets: [Config.api, /^\/api\//],
            ignoreErrors: ['ResizeObserver loop limit exceeded'],
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 0,
            tracesSampleRate: Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE) ?? 0.05
        });
    }
} catch (error) {
    console.error('Sentry initialisation failed.');
}

interceptorHandler();

// Note, the app is not being rendered in "strict mode"
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <CustomErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </CustomErrorBoundary>
    </Provider>
);
