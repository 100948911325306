/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useInfiniteQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { getNextPageParamDefault } from 'utilities/methods/queries/getNextPageParamDefault';
import { infiniteQuerySelectDefault } from 'utilities/methods/queries/infiniteQuerySelectDefault';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**
 * @typedef {{
 *   page: number,
 *   hide_cancelled: number
 * }} TParam
 */

/**
 * @deprecated use v5 format, see katanaQuery
 */
const useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'GET_FILTERED_AND_PAGINATED_INVOICE_LIST',
    APIRoute: (filters) => (queryFunctionContext) => {
        const { pageParam = filters } = queryFunctionContext;
        return ACCOUNT_API.user.invoice.queriedList.GET({ ...filters, ...pageParam });
    },
    defaultOptions: {
        onError: () => handleDefaultErrorNotification,
        select: () => infiniteQuerySelectDefault,
        getNextPageParam: (filters) => (lastPage) => getNextPageParamDefault({ lastPage, filters })
    }
});

const { getDefaults } = useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * User get queried invoice list mutation
 * Filters contains the "Starting Page" for example page: 1 the rest is handled through the query itself
 * it's the general endpoint for paginated invoice list data, it allows you to specify filters like due_date or date_paid as well as date ranges
 *
 * @param {TParam} filters
 */
function useGetFilteredAndPaginatedInvoiceListInfiniteQuery(filters, options = {}) {
    return useInfiniteQuery({
        ...getDefaults(filters),
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate, useGetFilteredAndPaginatedInvoiceListInfiniteQuery };
