import { useMutation } from '@tanstack/react-query';
import { getService } from 'containers/katana/queries/serviceID/getService';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Duplicate.POST.Params;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: Params['serviceID']) {
    function handleMutation(pageID: Params['pageID']) {
        return KATANA_API.katana.site.service_id.pages.page_id.duplicate.POST({ serviceID, pageID });
    }

    return useMutation({
        mutationFn: handleMutation,
        onSuccess: handleDefaultSuccessNotification,
        onError: handleDefaultErrorNotification,

        onSettled: () => {
            getService.invalidateAllQueries(serviceID);
        }
    });
}

export const duplicatePage = Object.freeze({
    useMutation: _useMutation
});
