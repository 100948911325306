/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZone as NXDropZone } from 'components/Form/NXDropZone/_NXDropZone';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * NXDropZone HookForm Wrapper
 */
export const _NXDropZoneHookFormDefault: React.FC<NXDropZoneNamespace.HookForm.Default.Props> = (props) => {
    const { label, name, disabled, className, ...rest } = props;

    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value } = field;

    /***** FUNCTIONS *****/
    function _onChange(files: NXDropZoneNamespace.Values) {
        field.onChange(files);
        field.onBlur();
    }

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={className}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner.HookForm name={name} noBackground fullWidth>
                <NXDropZone onChange={_onChange} value={typeof value === 'string' ? null : value} {...rest} />
            </FormItemInner.HookForm>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
