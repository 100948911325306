import { Store } from '@tanstack/react-store';
import type { FieldErrors, FieldValues } from 'react-hook-form';

type FormData = {
    values: {
        [x: string]: any;
    };
    errors: FieldErrors<FieldValues>;
};

export const katanaHookFormData = new Store<FormData>({ values: {}, errors: {} });
